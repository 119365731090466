import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=b621df24&scoped=true"
import script from "./Index.vue?vue&type=script&lang=js"
export * from "./Index.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Index.vue?vue&type=style&index=0&id=b621df24&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "b621df24",
  "1365998d"
  
)

export default component.exports