<template>
    <div class="developmenting-page">
        <block-data
            :imageWidth="'100%'"
            :imageHeight="'100%'"
            :imageSrc="imageSrc"
            :text="''"
            :titleText="''"
        ></block-data>
    </div>
</template>

<script>
import blockData from '@/components/BlockData.vue'
import developmenting from '@/assets/developmenting2X.png'

export default {
    name: 'developmenting',
    components: {
        blockData
    },
    data() {
        return {
            imageSrc: developmenting
        }
    }
}
</script>

<style lang="scss" scoped>
.developmenting-page {
    padding-top: 60px;
    // height: 50vh;
    border-radius: 6px;
    overflow: hidden;
    background-color: #ffffff !important;
}
</style>
