<template>
  <div id="homePgae" class="home-page" @click="closeAreaSelect">
    <div class="home-header">
      <img v-lazy="
        'http://cdn.public.xnjx666.com//upload/gw/static/img/home_bg.png'
      " alt="小牛建讯" class="home-header-img" />
      <div class="homeHeaderText">
        <!-- <div class="homeHeaderMainLine" style="margin-right: 27px;"></div> -->
        <div class="homeHeaderMainText">建筑资质服务大数据提供商</div>
        <!-- <div class="homeHeaderMainLine" style="margin-left: 27px;"></div> -->
      </div>
      <div class="features-search">
        <div class="search-tabs">
          <div v-for="(item, key) in searchType" :key="key" :class="{ isSelectTab: filterType === item.type }"
            class="search-tab" @click="selectFindType(item)">
            <!-- <div
                class="tag"
                :style="'background-image:url(' + tag + ')'"
                v-if="item.type === '4'"
            >
                +56
            </div> -->
            {{ item.title }}
          </div>
        </div>
        <div class="search-tools">
          <!-- <div class="search-lable">关键字：</div> -->
          <div v-if="!searchSelect" class="search-input">
            <a-input v-model="keyWord" :placeholder="searchPlaceholder" class="xiaoniu-input"
              @keyup.enter="goToFindKeyWord" />
            <a-button class="search-btn" @click="goToFindKeyWord">查询
            </a-button>
          </div>
          <div v-else class="search-input">
            <a-select :default-active-first-option="false" :filter-option="false" :getPopupContainer="getPopupContainer"
              :not-found-content="null" :show-arrow="false" :value="searchQua" allowClear class="xiaoniu-select"
              labelInValue placeholder="搜索您想要的资质" show-search @change="handleChange" @search="handleSearch">
              <a-select-option v-for="(s, index) in resOption" :key="index" :value="s.value">
                {{ s.label }}
              </a-select-option>
            </a-select>
            <a-button class="search-btn" @click="goToFindKeyWord">查询
            </a-button>
          </div>
        </div>
      </div>

      <div class="default-qua-select">
        <div :style="'background-image:url(' + fire + ')'" class="hot-image"></div>
        <div class="default-qua-list">
          <div v-for="item in defaultQualifications" :key="item.key" class="default-qua-item"
            @click="goToDefaultQuaSearch(item)">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>

    <div class="home-main-content">
      <product-list></product-list>
      <a-carousel arrows autoplay style="width:1200px;height: 150px;margin: 0 auto;">
        <div slot="prevArrow" class="custom-slick-arrow" style="left:60px;zIndex: 1">
          <svg t="1678073047535" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
            p-id="2937" width="40" height="40">
            <path
              d="M801.0752 862.9248a25.6 25.6 0 0 0 36.2496-36.2496L548.1984 537.6l289.1264-289.0752a25.6 25.6 0 0 0-36.2496-36.2496L475.8016 537.6z"
              fill="#ffffff" p-id="2938"></path>
            <path
              d="M493.8752 862.9248a25.6 25.6 0 0 0 36.2496-36.2496L240.9984 537.6l289.1264-289.0752a25.6 25.6 0 0 0-36.2496-36.2496L168.6016 537.6z"
              fill="#ffffff" p-id="2939"></path>
          </svg>
        </div>
        <div slot="nextArrow" class="custom-slick-arrow" style="right: 60px;">
          <svg style="transform: rotate(180deg);" t="1678073047535" class="icon" viewBox="0 0 1024 1024" version="1.1"
            xmlns="http://www.w3.org/2000/svg" p-id="2937" width="40" height="40">
            <path
              d="M801.0752 862.9248a25.6 25.6 0 0 0 36.2496-36.2496L548.1984 537.6l289.1264-289.0752a25.6 25.6 0 0 0-36.2496-36.2496L475.8016 537.6z"
              fill="#ffffff" p-id="2938"></path>
            <path
              d="M493.8752 862.9248a25.6 25.6 0 0 0 36.2496-36.2496L240.9984 537.6l289.1264-289.0752a25.6 25.6 0 0 0-36.2496-36.2496L168.6016 537.6z"
              fill="#ffffff" p-id="2939"></path>
          </svg>
        </div>
        <div>
          <img v-lazy="
            'http://cdn.public.xnjx666.com//upload/gw/static/img/vip_banner.png'
          " alt="小牛建讯" class="vip-banner" @click="goToLogin" />
        </div>
        <div>
          <img v-lazy="
            'http://cdn.public.xnjx666.com//upload/gw/static/img/productImg.png'
          " alt="小牛建讯" class="vip-banner" @click="goToProduct" />
        </div>
      </a-carousel>

      <div class="home-number-content">
        <div class="home-number-title">赋能资质服务商，提升业务效能</div>
        <div style="display: none;" class="homeHeaderNum">
          <!-- <ul> -->
          <div v-for="(item, key) in homeNumData" :key="key" class="num-item-list">
            <div :style="{ color: item.color, boxShadow: item.boxShadow }" class="num-item">
              <p>
                <span class="homeHeaderDataNum">
                  <countTo :decimals="item.decimals" :duration="3000" :endVal="item.endVal" :separator="item.separator"
                    :startVal="item.startVal" autoplay></countTo>
                </span>
                <span v-show="item.haveUnit" class="headMany"> 万 </span>
                <sup v-if="item.endVal > 0">+</sup>
              </p>
              <p class="homeHeaderNumTips">{{ item.title }}</p>
            </div>
            <div :style="'background-image:url(' + item.entyBg + ')'" class="enty-bg"></div>
          </div>
          <!-- <li>
              <p>
                <span class="homeHeaderDataNum">
                  <countTo
                    :decimals="decimals"
                    :duration="2000"
                    :endVal="235.7"
                    :startVal="startVal"
                    autoplay
                    separator="."
                  ></countTo>
                </span>
                <span class="headMany">万</span><sup>+</sup>
              </p>
              <p class="line"></p>
              <p class="homeHeaderNumTips">建筑资质信息</p>
            </li>
            <li>
              <p>
                <span class="homeHeaderDataNum">
                  <countTo
                    :decimals="0"
                    :duration="3000"
                    :endVal="5300"
                    :startVal="startVal"
                    autoplay
                  ></countTo>
                </span>
                <span class="headMany"></span><sup>+</sup>
              </p>
              <p class="line"></p>
              <p class="homeHeaderNumTips">资质服务</p>
            </li>
            <li>
              <p>
                <span class="homeHeaderDataNum">
                  <countTo
                    :decimals="0"
                    :duration="3000"
                    :endVal="newAdd"
                    :startVal="startVal"
                    autoplay
                  ></countTo>
                </span>
                <span class="headMany"></span><sup>+</sup>
              </p>
              <p class="line"></p>
              <p class="homeHeaderNumTips">今日新增</p>
            </li> -->
          <!-- </ul> -->
        </div>
      </div>
      <!-- <div class="chart-content">
        <chart-content ref="cc"></chart-content>
        <div
          class="chart-bg"
          :style="'background-image:url(' + chartBg + ')'"
        ></div>
      </div> -->
      <!-- 产品简介 -->
      <model-description></model-description>

      <!-- 产品优势 -->
      <div class="white-warp">
        <div class="main-title">产品优势</div>
        <div class="goodness-list">
          <div v-for="(item, key) in goodNessList" :key="key" class="good-item">
            <div class="left">
              <img v-if="item.left.isImage" v-lazy="item.left.src" alt="小牛建讯" class="good-info" />
              <div v-else class="good-info text-info">
                <div class="good-title">{{ item.left.title }}</div>
                <div class="good-des">{{ item.left.description }}</div>
                <div class="good-btn">
                  <a-button type="primary" @click="goToProduct">
                    免费试用
                  </a-button>
                </div>
              </div>
            </div>
            <div class="right">
              <div v-if="item.right.isImage" :style="'background-image:url(' + item.right.src + ')'" class="good-info">
              </div>
              <div v-else class="good-info text-info">
                <div class="good-title">{{ item.right.title }}</div>
                <div class="good-des">{{ item.right.description }}</div>
                <div class="good-btn">
                  <a-button type="primary" @click="goToProduct">免费试用
                  </a-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 合作企业 -->
      <cooperate-enterprise></cooperate-enterprise>
      <!-- 行业资讯 -->
      <div class="trade-info"
        style="background-image:url('http://cdn.public.xnjx666.com//upload/gw/static/img/bg_home_information.png')">
        <div class="main-title">行业资讯</div>
        <div class="trade-list">
          <div class="lf-trade">
            <div v-for="(item, key) in tableData" :key="key">
              <div v-if="key > 0 && key < 4" :style="key === 3 ? 'border-bottom:none' : ''" class="lf-item">
                <a :href="'details/' + item.dynamicId" class="lf-trade-title">
                  {{ item.title }}
                </a>
                <div class="lf-trade-des">
                  {{ item.description | overfllowtText }}
                </div>
                <a :href="'details/' + item.dynamicId" class="trade-detail">
                  查看详情 <img :src="tradeArrowG" width="20px" />
                </a>
              </div>
            </div>
          </div>
          <div class="rt-trade">
            <img :src="tableData[0] ? tableData[0].homepagePic : ''" height="284" />
            <a :href='`details/${tableData[0] && tableData[0].dynamicId}`' class="rt-trade-title">
              {{ tableData[0] ? tableData[0].title : '' }}
            </a>
            <div class="rt-trade-des">
              {{
                tableData[0] ? tableData[0].description : '' | overfllowtText
              }}
            </div>
            <a href="/dynamic/2" class="more-trade-info">
              查看更多行业资讯
              <img :src="tradeArrow" width="20px" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="advVisible" class="mask">
      <!--      @click.stop="handleCancel(advImg.index)"-->
      <div v-for="(item, key) in advList" :key="key" v-show="advIndex === key">
        <div v-if="item.type === 'image'" class="advBox">
          <div class="close-mask" @click.stop="handleCancel">
            <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>关闭备份 8</title>
              <g id="1.2.5-活动" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="官网-活动-活动完成弹窗" transform="translate(-1261.000000, -252.000000)">
                  <g id="关闭备份-8" transform="translate(1261.000000, 252.000000)">
                    <circle id="椭圆形" fill-opacity="0.4" fill="#000000" opacity="0" cx="16" cy="16" r="16"></circle>
                    <circle id="椭圆形" stroke="#FFFFFF" stroke-width="2" fill-opacity="0" fill="#000000" cx="16" cy="16"
                      r="13"></circle>
                    <rect id="矩形" fill="#FFFFFF"
                      transform="translate(16.000000, 16.000000) rotate(-45.000000) translate(-16.000000, -16.000000) "
                      x="15" y="10" width="2" height="12" rx="1"></rect>
                    <rect id="矩形" fill="#FFFFFF"
                      transform="translate(16.000000, 16.000000) rotate(-135.000000) translate(-16.000000, -16.000000) "
                      x="15" y="10" width="2" height="12" rx="1"></rect>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div class="component">
            <a v-if="advIndex === key" :href="item.clickUrl" target="_blank">
              <img :src="item.src" alt="小牛建讯" />
            </a>
          </div>
        </div>
        <div v-else class="advBox">
          <div class="close-mask" @click.stop="handleCancel">
            <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>关闭备份 8</title>
              <g id="1.2.5-活动" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="官网-活动-活动完成弹窗" transform="translate(-1261.000000, -252.000000)">
                  <g id="关闭备份-8" transform="translate(1261.000000, 252.000000)">
                    <circle id="椭圆形" fill-opacity="0.4" fill="#000000" opacity="0" cx="16" cy="16" r="16"></circle>
                    <circle id="椭圆形" stroke="#FFFFFF" stroke-width="2" fill-opacity="0" fill="#000000" cx="16" cy="16"
                      r="13"></circle>
                    <rect id="矩形" fill="#FFFFFF"
                      transform="translate(16.000000, 16.000000) rotate(-45.000000) translate(-16.000000, -16.000000) "
                      x="15" y="10" width="2" height="12" rx="1"></rect>
                    <rect id="矩形" fill="#FFFFFF"
                      transform="translate(16.000000, 16.000000) rotate(-135.000000) translate(-16.000000, -16.000000) "
                      x="15" y="10" width="2" height="12" rx="1"></rect>
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <component :is="item.src" v-if="advIndex === key" class="component"></component>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import developmenting from './components/Developing'
import enterpriseBg from '@/assets/home/enterprise_bg.png'
import homePageNumBg from '@/assets/home/homePageNumBg.png'
import tag from '@/assets/home/tag.png'
import chartBg from '@/assets/home/chart_bg.png'
import tradeArrow from '@/assets/home/trade/arrow_more_black.png'
import tradeArrowG from '@/assets/home/trade/more_grey.png'
import yellowBg from '@/assets/home/animeBg/yellow.png'
import greenBg from '@/assets/home/animeBg/green.png'
import orangeBg from '@/assets/home/animeBg/orange.png'
import blueBg from '@/assets/home/animeBg/blue.png'
import productList from '@/components/ProductList.vue'
import ChartContent from '@/components/ChartContent.vue'
import modelDescription from '@/components/Home/ModelDescription.vue'
import cooperateEnterprise from '@/components/Home/cooperateEnterprise.vue'
// import adv from '@/assets/adv.png'
import levelUp from '@/components/levelUp.vue'
import Cookie from 'js-cookie'
import countTo from 'vue-count-to'
import { mapMutations, mapState } from 'vuex'
import fire from '@/assets/home/fire.png'

export default {
  name: 'Home',
  components: {
    levelUp,
    developmenting,
    countTo,
    productList,
    ChartContent,
    modelDescription,
    cooperateEnterprise
  },
  filters: {
    overfllowtText(val) {
      // console.log(val)
      let text = val
      if (val.length > 55) {
        text = text.substring(0, 54) + '...'
      }
      return text
    }
  },
  // asyncData({ store, route }) {
  //   // console.log(route)
  //   // const dynamicId = route.params.dynamicId
  //   // let params = dynamicId ? { dynamicId: parseInt(dynamicId, 10) } : null
  //   return
  //   // store.dispatch('getDynamicDetail', params)
  // },
  data() {
    return {
      fire,
      tradeArrow,
      tradeArrowG,
      chartBg,
      advList: [],
      advIndex: -1,
      quaSearchInput: '',
      oneIng: '',
      twoIng: '',
      oneClickUrl: '',
      twoClickUrl: '',
      advImg: { index: null, img: '', clickUrl: '' },
      advVisible: false,
      newAdd: null,
      startVal: 0,
      decimals: 2,
      endVal: 856.24,
      homePageNumBg,
      calleft: 0,
      speed: 1,
      lectIndex: 0,
      tag,
      enterpriseBg,
      filterType: '3',
      keyWord: '',
      searchPlaceholder: '请输入企业名称',
      searchSelect: true,
      resOption: [],
      searchQua: undefined,
      fakeQualificationOptions: [],
      defaultQualifications: [
        { key: 6, label: '建筑工程施工总承包二级资质' },
        { key: 42, label: '市政公用工程施工总承包二级资质' },
        { key: 78, label: '建筑幕墙工程专业承包二级资质' },
        { key: 50, label: '地基基础工程专业承包一级资质' },
        { key: 83, label: '城市及道路照明工程专业承包二级资质' },
        { key: 57, label: '电子与智能化工程专业承包一级资质' }
      ],
      searchType: [
        { title: '查资质', type: '3', placeholder: '请搜索您想要的资质' },
        { title: '查企业', type: '1', placeholder: '请输入企业名称' },
        { title: '查法人', type: '2', placeholder: '请输入法人名称' },
      ],
      goodNessList: [
        {
          left: {
            isImage: true,
            src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/pc_information.png'
          },
          right: {
            isImage: false,
            title: '海量信息，精准线索源源不断',
            description:
              '聚合全网海量资质信息，助您快速锁定目标客户，营销效率全面提升'
          }
        },
        {
          left: {
            isImage: false,
            title: '智能匹配，精准获客',
            description:
              '全网智能匹配需求，短信及时通知，加速资质流转，提升经营效率'
          },
          right: {
            isImage: true,
            src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/pc_matching.png'
          }
        },
        {
          left: {
            isImage: true,
            src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/pc_change.png'
          },
          right: {
            isImage: false,
            title: '资源互换，抢占市场先机',
            description:
              '资源整合，海量一手货源；全网比价，帮助您以更低的价格快速找到货源，抢占市场先机'
          }
        },
        {
          left: {
            isImage: false,
            title: '客户管理，显著提升客户成交效率',
            description:
              '线索全链路管理，助力业务人员随时监控客户动态，提升跟踪沟通效率，大幅提升成交率'
          },
          right: {
            isImage: true,
            src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/pc_manage.png'
          }
        },
        {
          left: {
            isImage: true,
            src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/pc_data.png'
          },
          right: {
            isImage: false,
            title: '小牛大数据，预见未来',
            description:
              '小牛建讯利用平台大数据分析，对市场资质变化情况以及流动趋势了如指掌；资质服务企业可以通过解读定期发布的小牛报告优化发展策略'
          }
        }
      ],
      serverNum: 0,
      userIpArea: null
    }
  },
  computed: {
    homeNumData() {
      return [
        {
          startVal: this.startVal,
          endVal: this.endVal,
          color: '#FFBC34',
          title: '建筑企业',
          decimals: this.decimals,
          separator: '.',
          boxShadow: '0px 0px 20px 0px rgba(255, 162, 18, 0.2)',
          haveUnit: true,
          entyBg: yellowBg
        },
        {
          startVal: this.startVal,
          endVal: 235.7,
          color: '#00D2B6',
          title: '建筑资质信息',
          decimals: this.decimals,
          separator: '.',
          boxShadow: '0px 0px 20px 0px rgba(0, 210, 182, 0.15)',
          haveUnit: true,
          entyBg: greenBg
        },
        {
          startVal: this.startVal,
          endVal: this.serverNum,
          color: '#FF7B29',
          title: '资质服务',
          decimals: 0,
          separator: '',
          boxShadow: '0px 0px 20px 0px rgba(255, 123, 41, 0.15)',
          haveUnit: false,
          entyBg: orangeBg
        },
        {
          startVal: this.startVal,
          endVal: this.newAdd,
          color: '#52C5FF',
          title: '昨日新增用户数',
          decimals: 0,
          separator: '',
          boxShadow: '0px 0px 20px 0px rgba(82, 197, 255, 0.15)',
          haveUnit: false,
          entyBg: blueBg
        }
      ]
    },
    widthData() {
      return 240 * Number(this.enterpriseList.length * 2)
    },
    projectEnv() {
      if (process.env.VUE_APP_TYPE) {
        return process.env.VUE_APP_TYPE
      } else {
        return 'online'
      }
    },
    ...mapState(['dynamicData']),

    tableData() {
      return this.dynamicData
    }
  },
  methods: {
    ...mapMutations(['setShowLoginModal', 'setShowActivityModal']),
    closeAreaSelect() {
      // this.$refs.cc.close()
    },
    // 点击播放视频
    handlePlay(item, index) {
      item.showVideo = !item.showVideo
      this.$refs.video[index].play()
    },
    handleChange(item) {
      this.searchQua = item
      if (!item) {
        this.resOption = []
        this.quaSearchInput = undefined
        return
      }
      this.handleSearch(item.label)
    },
    handleSearch(search) {
      this.quaSearchInput = search
      if (!search) {
        this.resOption = []
      } else {
        this.resOption = []
        this.fakeQualificationOptions.forEach(s => {
          if (s.label.indexOf(search) > -1) {
            this.resOption.push(s)
          }
        })
      }
    },
    goTradeDetail(item) {
      this.$router.push({
        path: 'details/' + item.dynamicId
      })
      // console.log(item)
    },
    goToXiaoniuQr() {
      // 资质集市
      if (this.projectEnv === 'dev') {
        window.open(
          'http://localhost:8080/#/longna/qualificationResources/main'
        )
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open(
          'http://www.xnjx666.com:9000/#/longna/qualificationResources/main'
        )
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open(
          'http://192.168.0.108:8099/#/longna/qualificationResources/main'
        )
      }
    },
    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('homePgae')
    },
    selectFindType(item) {
      this.filterType = item.type
      this.searchPlaceholder = item.placeholder
      if (this.filterType === '3') {
        this.searchSelect = true
      } else {
        this.searchSelect = false
      }
    },
    goToDefaultQuaSearch(item) {
      let url = ''
      if (this.projectEnv === 'dev') {
        url = 'http://localhost:8080/#/'
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        url = 'http://www.xnjx666.com:9000/#/'
      } else if (this.projectEnv === 'test') {
        // 测试环境
        url = 'http://192.168.0.108:8099/#/'
      }
      let xiaoniuPage =
        'longna/receipt?qualificationId=' +
        item.key +
        '&userArea=' +
        JSON.stringify(this.userIpArea)
      window.open(url + xiaoniuPage)
    },
    goToFindKeyWord() {
      // console.log('filterType',this.filterType);
      let url = ''
      if (this.projectEnv === 'dev') {
        url = 'http://localhost:8080/#/'
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        url = 'http://www.xnjx666.com:9000/#/'
      } else if (this.projectEnv === 'test') {
        // 测试环境
        url = 'http://192.168.0.108:8099/#/'
      }
      let xiaoniuPage = ''
      if (this.filterType === '1') {
        // 企业
        xiaoniuPage = 'longna/receipt?enterpriseName=' + this.keyWord
      } else if (this.filterType === '2') {
        // 法人
        xiaoniuPage = 'longna/receipt?legalPerson=' + this.keyWord
      } else if (this.filterType === '3') {
        if (this.searchQua) {
          this.quaSearchInput = this.searchQua.label
        }
        let key = ''
        if (!this.quaSearchInput) {
          key = 6
        } else {
          const findQua = this.fakeQualificationOptions.find(e => {
            return (
              e.label === this.quaSearchInput.replace(/[^\u4e00-\u9fa5]/gi, '')
            )
          })
          if (findQua) {
            key = findQua.value
          } else {
            this.$message.error('请输入正确的资质名称')
            return
          }
        }
        xiaoniuPage =
          'longna/receipt?qualificationId=' +
          key +
          '&userArea=' +
          JSON.stringify(this.userIpArea)
      }
      window.open(url + xiaoniuPage)
    },
    goToVip() {
      this.$router.push({
        name: 'Vip'
      })
    },
    goToDownload() {
      this.$router.push({
        name: 'Download'
      })
      // location.href = 'http://www.xnjx666.com/'
    },

    goToLogin() {
      // const hvaeUserId = Cookie.get('userId')
      // if (hvaeUserId) {
      //   this.$message.info('你已登录，无需重复登录', 0.5)
      // } else {
      //   this.setShowLoginModal(true)
      // this.$router.push({
      //   name: "Login",
      //   query: {
      //     from: this.$route.name
      //   }
      // });
      // }
      // 开发环境
      if (this.projectEnv === 'dev') {
        window.open('http://localhost:8080/#/login')
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open('http://www.xnjx666.com:9000/#/login')
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open('http://192.168.0.108:8099/#/login')
      }
    },
    goToProduct(item) {
      // 收货
      if (this.projectEnv === 'dev') {
        window.open('http://localhost:8080/#/longna/receipt')
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open('http://www.xnjx666.com:9000/#/longna/receipt')
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open('http://192.168.0.108:8099/#/longna/receipt')
      }
      // let url = ''
      // if (this.projectEnv === 'dev') {
      //   url = 'http://localhost:8080/#/'
      // } else if (
      //   this.projectEnv === 'online' ||
      //   this.projectEnv === 'preview'
      // ) {
      //   // 预发  正式环境
      //   url = 'http://www.xnjx666.com:9000/#/'
      // } else if (this.projectEnv === 'test') {
      //   // 测试环境
      //   url = 'http://192.168.0.108:8099/#/'
      // }
      // let xiaoniuPage = 'longna/receipt'
      // window.open(url + xiaoniuPage)

      // if (!item.link) {
      //   this.$message.warning(
      //     '该功能暂未开放，如需体验请下载小牛建讯客户端',
      //     0.5
      //   )
      //   return
      // } else {
      //   this.$router.push(item.link)
      // }
    },
    getQualification() {
      this.$http.get('qualification/selQualificationAll').then(r => {
        this.fakeQualificationOptions = []
        r.forEach(e => {
          e.qualificationLevels.forEach(q => {
            this.fakeQualificationOptions.push({
              label: e.qualificationName + q.level,
              value: q.id
            })
          })
        })
        //  this.setFakerQualification(this.fakeQualificationOptions)
      })
    },

    handleCancel(index) {
      this.advIndex += 1
      if (this.advIndex >= this.advList.length) {
        this.advIndex = -1
        this.advVisible = false
        this.$emit('closeAllAdv')
      }
      // if (index === 1) {
      //   if (this.twoIng) {
      //     this.advImg = {
      //       index: 2,
      //       img: this.twoIng,
      //       clickUrl: this.twoClickUrl
      //     }
      //   } else {
      //     this.advVisible = false
      //   }
      // } else if (index === 2) {
      //   this.advVisible = false
      // }
      // this.advVisible = false
    },
    advShow() {
      let cookiesTomorrow = Cookie.get('Tomorrow')
      let cookiesToday = Cookie.get('Today')
      if (!(cookiesToday && cookiesTomorrow)) {
        this.advVisible = true
        const dayVal = new Date(new Date(new Date().toLocaleDateString()).getTime())
        const tomorrowVal = new Date(new Date().setHours(23, 59, 59, 999)).getTime()
        Cookie.set('Today', dayVal, { expires: 1 })
        Cookie.set('Tomorrow', tomorrowVal, { expires: 1 })
        this.$emit('showSomeAdv')
      } else if (cookiesToday && cookiesTomorrow) {
        if (parseInt(cookiesToday) > parseInt(cookiesTomorrow)) {
          this.$nextTick(() => {
            this.advVisible = true
            this.$emit('showSomeAdv')
          })
        } else {
          this.advVisible = false
          this.advIndex = -1
        }
      }
    },
    getAdvImg() {
      this.$https
        .post('advertisement/releaseList', {
          advertisementType: 'WEBSITE_HOME_PAGE'
        })
        .then(res => {
          console.log(res)
          this.advList = res.map(e => {
            return { src: e.url, clickUrl: e.clickUrl, type: 'image' }
          })
          // this.advList.unshift({
          //   src: levelUp,
          //   clickUrl: '#',
          //   type: 'components'
          // })
          this.advIndex = 0
          // console.log(this.advList)
          if (this.advList.length > 0) {
            this.advShow()
          }

          // res.forEach(item => {
          //   if (item.advertisementType === 'WEBSITE_HOME_PAGE_ONE') {
          //     this.oneIng = item.url
          //     this.oneClickUrl = item.clickUrl
          //     this.advImg = { index: 1, img: item.url, clickUrl: item.clickUrl }
          //     this.advShow()
          //   } else if (item.advertisementType === 'WEBSITE_PAGE_TWO') {
          //     this.twoIng = item.url
          //     this.twoClickUrl = item.clickUrl
          //   }
          //   if (!this.oneIng && this.twoIng) {
          //     this.advImg = {
          //       index: 2,
          //       img: this.twoIng,
          //       clickUrl: this.twoClickUrl
          //     }
          //     this.advShow()
          //   }
          // })
        })
    },
    getDyListData() {
      const params = {
        current: 1,
        size: 9,
        orderByReadCount: 1,
        dynamicTypeEnum: 'INFO'
      }
      this.$store.dispatch('getDynamicList', params)
    },
    getServerNumList() {
      this.$http.get('sys/config/query/qualified/provider').then(res => {
        this.serverNum = res.info ? parseInt(res.info, 10) : 0
      })
    },
    getYesterdayRegisterNum() {
      this.$http
        .get('official/website/statistics/query/yesterdayRegister')
        .then(res => {
          this.newAdd = res.userRegisterNum
          //  > 0
          //   ? res.userRegisterNum
          //   : Math.floor(Math.random() * 20)
        })
    },
    getAreaIp() {
      this.$http.get('areaIp/get').then(res => {
        this.userIpArea = res
      })
    },
    // 显示活动弹窗（每天弹一次）
    showActivityModal() {
      let now = this.$day().format('YYYY-MM-DD') // 当前日期
      let oldDate = localStorage.getItem('oldDate') || null
      if (oldDate != now) {
        this.setShowActivityModal(true)
        localStorage.setItem('oldDate', now)
      }
    }
  },
  created() {
    if (!this.$isServer) {
      // this.move()
      this.getAdvImg()
      this.getDyListData()
    }
    // this.showActivityModal()
  },
  mounted() {
    // this.newAdd = Math.floor(Math.random() * (2000 - 1000) + 1000)
    this.getQualification()
    this.getDyListData()
    this.getServerNumList() // 资质服务商数量
    this.getYesterdayRegisterNum() // 昨日注册数量
    this.getAreaIp()
    // localStorage.removeItem('caseId')
    // var imgBox = document.getElementsByClassName('imgBoxoul')[0]
    // imgBox.innerHTML += imgBox.innerHTML
  }
}
</script>

<style lang="scss">
.ant-carousel>.slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}

.ant-carousel>.slick-slide h3 {
  color: #fff;
}

.carousel-image {
  width: 100%;
  height: 400px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .first-image {
    .first-title {
      font-size: 48px;
      color: #fff;
      display: flex;
      // font-weight: bold;
      justify-content: center;
      height: 80px;
      line-height: 80px;
      text-shadow: #000 2px 3px 4px;
      position: absolute;
      left: 150px;
      top: 110px;
      width: 600px;

      div {
        width: 300px;
        // margin: 0 30px;
        letter-spacing: 8px;
        text-align: center;
      }
    }

    .first-subtitle {
      font-size: 28px;
      color: #fff;
      width: 600px;
      display: flex;
      justify-content: center;
      position: absolute;
      left: 150px;
      top: 200px;

      div {
        width: 300px;
        text-align: center;
        letter-spacing: 3px;
        // margin: 0 60px;
      }
    }

    .btn-line {
      width: 600px;
      text-align: center;
      position: absolute;
      left: 150px;
      top: 260px;
    }
  }

  .image-btn {
    // padding: px 0;
    font-size: 18px;
    display: inline-block;
    color: #fff;
    border: 1px #fff solid;
    width: 140px;
    border-radius: 4px;
    height: 38px;
    line-height: 38px;
    cursor: pointer;

    &:hover {
      color: #cccccc;
      border-color: #cccccc;
    }
  }

  .second-image {
    .second-title {
      height: 80px;
      line-height: 80px;
      font-size: 40px;
      color: #fff;
      letter-spacing: 3px;
      width: 100%;
      text-align: center;
      margin-top: 50px;
      text-shadow: #000 2px 3px 4px;
    }

    .second-tips {
      width: 100%;
      justify-content: center;
      color: #fff;
      display: flex;
      font-size: 18px;

      p {
        margin: 0 20px;
      }
    }

    .btn-line {
      width: 100%;
      text-align: center;
      margin-top: 20px;
    }

    .second-subtitle {
      font-size: 22px;
      margin-top: 20px;
      letter-spacing: 3px;
      color: #fff;
    }
  }
}
</style>
<style lang="scss" scoped>
.mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: rgba($color: #000000, $alpha: 0.64);
  display: flex;
  justify-content: center;
  align-items: center;

  .welcome-image {
    max-width: 1000px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .advBox {
    max-width: 1000px;

    //min-width: 600px;
    //min-height: 500px;
    position: relative;

    .component {

      //position: absolute;
      //left: 50%;
      //top: 50%;
      //transform: translate(-50%, -50%);
      img {
        max-width: 1000px;
        //position: relative;
      }
    }

    .close-mask {
      width: 21px;
      height: 21px;
      z-index: 999;
      position: absolute;
      right: 0;
      top: 0;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

@keyframes showBg {
  from {
    transform: scale(0.25);
  }

  to {
    display: block;
    transform: scale(1);
  }
}

.home-page {
  width: 100vw;
  min-width: 1200px;
  z-index: 11;

  .home-header {
    width: 100%;
    height: 404px;
    position: relative;

    .home-header-img {
      width: 100%;
      height: 404px;
      position: absolute;
      top: 0;
      left: 0;
    }

    .homeHeaderText {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      margin: auto;
      top: 60px;

      .homeHeaderMainText {
        letter-spacing: 2px;
        font-size: 40px;
        font-weight: medium;
        color: #ffffff;
        text-align: center;
      }
    }

    .features-search {
      position: absolute;
      left: 0;
      right: 0;
      top: 157px;
      bottom: 0;
      margin: auto;
      width: 820px;

      .search-tabs {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .search-tab {
          font-size: 18px;
          padding: 2px 16px;
          margin-bottom: 16px;
          height: 30px;
          line-height: 30px;
          color: #fff;
          font-weight: 500;
          cursor: pointer;
          position: relative;
          border-radius: 4px;
          margin-right: 40px;

          .tag {
            width: 30px;
            height: 15px;
            background-size: cover;
            position: absolute;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            font-weight: 400;
            right: -10px;
            top: -10px;
          }

          &:hover {
            background-color: #00ab94;

            &:after {
              content: '';
              bottom: -3px;
              height: 0px;
              width: 0px;
              left: 0;
              right: 0;
              margin: 0 auto;
              position: absolute;
              border: 5px solid;
              border-top-color: #00ab94;
              border-bottom-color: transparent;
              border-right-color: #00ab94;
              border-left-color: transparent;
              transform: scaleX(1) rotate(135deg) translateZ(0);
            }
          }
        }

        .isSelectTab {
          background-color: #00ab94;

          &:after {
            content: '';
            bottom: -3px;
            height: 0px;
            width: 0px;
            left: 0;
            right: 0;
            margin: 0 auto;
            position: absolute;
            border: 5px solid;
            border-top-color: #00ab94;
            border-bottom-color: transparent;
            border-right-color: #00ab94;
            border-left-color: transparent;
            transform: scaleX(1) rotate(135deg) translateZ(0);
          }
        }
      }

      .search-tools {
        display: flex;
        justify-content: center;
        align-items: center;

        .search-input {
          width: 820px;
          flex-shrink: 1;
          display: flex;
          align-items: center;

          .xiaoniu-input {
            font-size: 18px !important;
            height: 64px;
            padding-left: 30px;
            width: 693px;
            border-radius: 4px 0 0 4px;

            /deep/ .ant-input:focus {
              border-color: transparent;
              box-shadow: none;
            }

            &::-webkit-input-placeholder {
              /* placeholder颜色 */
              // color: #478cf3;
              /* placeholder字体大小 */
              color: #aaa;
              font-size: 18px !important;
            }
          }

          .xiaoniu-select {
            font-size: 18px !important;
            height: 64px;
            // padding-left: 30px;
            width: 693px;
            border-radius: 6px 0 0 6px;

            /deep/ .ant-select-selection {
              height: 64px;
              border-radius: 6px 0 0 6px;
              border-color: transparent;
              box-shadow: none;
            }

            /deep/ .ant-select-selection__rendered {
              line-height: 64px;
              padding-left: 21px;
            }

            /deep/ .ant-select-selection__clear {
              font-size: 18px;
              margin-top: -12px;
              right: 22px;
            }

            /deep/ .ant-select-selection__placeholder {
              height: 64px !important;
              line-height: 64px !important;
              font-size: 18px !important;
              top: 0 !important;
              margin-top: 0 !important;
              padding-left: 21px !important;
            }
          }

          .search-btn {
            height: 64px;
            line-height: 64px;
            width: 127px;
            font-size: 18px;
            font-weight: 500;
            background-color: #00ab94;
            border-color: #00ab94;
            color: #f4f5ff;
            border-radius: 0 6px 6px 0px;
          }
        }
      }
    }

    .default-qua-select {
      color: #ffffff;
      position: absolute;
      left: 0;
      right: 0;
      top: 285px;
      bottom: 0;
      margin: 0 auto;
      width: 820px;
      display: flex;
      // flex-wrap: wrap;
      align-items: flex-start;
      height: 48px;

      .hot-image {
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        margin-right: 8px;
        background-size: cover;
      }

      .default-qua-list {
        display: flex;
        width: 788px;
        flex-shrink: 0;
        flex-wrap: wrap;

        .default-qua-item {
          line-height: 20px;
          height: 20px;
          margin-bottom: 8px;
          cursor: pointer;
          margin-right: 32px;

          &:hover {
            color: #00ab94;
          }
        }
      }
    }
  }

  .home-number-content {
    // padding-bottom: 60px;

    .home-number-title {
      text-align: center;
      font-size: 32px;
      color: #181818;
      font-weight: 500;
      margin-top: 60px;
      // margin-bottom: 56px;
    }
  }

  .homeHeaderNum {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .num-item-list {
      position: relative;

      &:hover {
        .enty-bg {
          display: block;
          animation-name: showBg;
          animation-duration: 1s;
        }
      }

      .enty-bg {
        width: 326px;
        height: 163px;
        background-position: center;
        background-size: cover;
        position: absolute;
        top: -33px;
        left: -33px;
        display: none;
        // transform: scale(0);
        z-index: 97;
      }
    }

    .num-item {
      text-align: center;
      width: 260px;
      padding: 12px 0;
      cursor: pointer;
      margin-right: 50px;
      flex-shrink: 0;
      border-radius: 4px;
      background-color: #fff;
      z-index: 99;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      p {
        margin-bottom: 0;
      }

      .homeHeaderDataNum {
        font-size: 40px;
        font-weight: 700;
      }

      .headMany {
        font-size: 14px;
      }

      .homeHeaderNumTips {
        font-size: 14px;
      }
    }
  }

  .home-main-content {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 46px;
    background-color: #fff;

    .custom-slick-arrow {
      top: 50%;
      transform: translateY(-50%);

    }

    .vip-banner {
      width: 100%;
      height: 150px;
      border-radius: 4px;
      cursor: pointer;
    }

    .chart-content {
      margin-top: 80px;
      width: 100%;
      background-color: #071b32;
      height: 970px;
      position: relative;

      .chart-bg {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 400px;
        background-size: cover;
        z-index: 18;
        background-repeat: no-repeat;
        background-position: bottom;
      }
    }

    .white-warp {
      width: 100%;
      background-color: #fbfbff;
      text-align: center;
      padding-bottom: 48px;

      .goodness-list {
        display: inline-block;
        width: 1200px;

        .good-item {
          width: 100%;
          display: flex;
          align-items: center;
          background-color: #fff;
          border-radius: 4px;
          margin-bottom: 32px;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

          .good-info {
            width: 600px;
            height: 260px;
            background-repeat: no-repeat;
            background-size: cover;
          }

          .text-info {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 40px;

            .good-title {
              width: 100%;
              text-align: left;
              font-size: 24px;
              color: #181818;
              font-weight: 500;
              margin-bottom: 10px;
            }

            .good-des {
              width: 100%;
              margin-bottom: 20px;
              text-align: left;
              color: #585858;
              font-size: 14px;
            }

            .good-btn {
              width: 100%;
              text-align: left;
            }
          }
        }
      }
    }

    .main-title {
      text-align: center;
      font-size: 32px;
      color: #181818;
      font-weight: 500;
      margin-top: 80px;
      margin-bottom: 56px;
    }

    .trade-info {
      width: 100%;
      background-size: cover;
      background-position: center;
      // background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;

      .trade-list {
        border: 1px solid #e6e9f0;
        width: 1200px;
        margin-bottom: 70px;
        display: flex;

        .rt-trade {
          flex-shrink: 0;
          width: 553px;
          padding: 24px 24px 48px 24px;
          border-left: 1px solid #e6e9f0;
          position: relative;

          .rt-trade-title {
            display: block;
            font-size: 24px;
            margin-top: 16px;
            color: #181818;
            font-weight: 500;
            cursor: pointer;
            text-align: left;
          }

          .rt-trade-des {
            margin: 12px 0 24px 0;
            font-size: 14px;
            color: #585858;
            line-height: 22px;
            height: 50px;
            width: 100%;
            text-align: left;
          }

          .more-trade-info {
            width: 100%;
            height: 56px;
            line-height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #181818;
            font-size: 16px;
            left: 0;
            position: absolute;
            border-top: 1px solid #e6e9f0;
            bottom: 0;
            cursor: pointer;
          }
        }

        .lf-trade {
          flex-shrink: 0;
          width: 599px;
          flex-grow: 1;

          .lf-item {
            padding: 24px;
            border-bottom: 1px solid #e6e9f0;
            position: relative;

            .trade-detail {
              font-size: 14px;
              display: flex;
              align-items: center;
              color: #a0a6ab;
              position: absolute;
              right: 24px;
              cursor: pointer;
              bottom: 24px;
            }

            .lf-trade-title {
              display: block;
              cursor: pointer;
              color: #181818;
              font-size: 20px;
              text-align: left;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .lf-trade-des {
              margin-top: 12px;
              margin-bottom: 20px;
              text-align: left;
              line-height: 22px;
              color: #585858;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .component {
    padding: 40px 30px;
  }
}
</style>
