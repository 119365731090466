import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=521f1d3b&scoped=true"
import script from "./Details.vue?vue&type=script&lang=js"
export * from "./Details.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Details.vue?vue&type=style&index=0&id=521f1d3b&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "521f1d3b",
  "4b5ae47e"
  
)

export default component.exports