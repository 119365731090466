<template>
  <div id="clientFilter" class="mine-client-page">
    <div class="filter-box">
      <div class="filter-list">
        <div class="filter-item">
          <div class="label-title">企业名称：</div>
          <a-input
              v-model="filterOptions.enterpriseName"
              class="filter-input"
              placeholder="请输入查询企业"
          ></a-input>
        </div>
        <div class="filter-item">
          <div class="label-title">状态更新时间：</div>
          <a-date-range
              v-model="dateRanger"
              :disabled-date="disabledEndDate"
              :getCalendarContainer="getPopupContainer"
              class="filter-input"
          ></a-date-range>
        </div>
        <div class="filter-item">
          <div class="label-title">数据来源：</div>

          <a-select
              v-model="clueSources"
              :getPopupContainer="getPopupContainer"
              allowClear
              class="filter-input"
              placeholder="请选择数据来源"
          >
            <a-select-option
                v-for="item in sourceOptions"
                :key="item.value"
                :value="item.value"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div v-if="!isSales" class="filter-item">
          <div class="label-title">跟进人：</div>
          <staffSelect ref="searchQua" @followStaffChange="followStaffChange"></staffSelect>

          <!--          <a-tree-select
                      placeholder="请选择跟进人员或部门"
                      show-search
                      :getPopupContainer="getPopupContainer"
                      v-model="followStaff"
                      treeCheckable
                      @change="setDepartmentStaff"
                      treeNodeFilterProp="title"
                      labelInValue
                      allow-clear
                      tree-default-expand-all
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      class="filter-input have-margin"
                    >
                      <a-tree-select-node
                        v-for="root in staffList"
                        :key="root.key"
                        :value="root.value"
                        :title="root.title"
                      >
                        <a-tree-select-node
                          v-for="level2 in root.children"
                          :key="level2.key"
                          :value="level2.value"
                          :title="level2.title"
                        >
                          <a-tree-select-node
                            v-for="level3 in level2.children"
                            :key="level3.key"
                            :value="level3.value"
                            :title="level3.title"
                          >
                          </a-tree-select-node>
                        </a-tree-select-node>
                      </a-tree-select-node>
                    </a-tree-select>-->
        </div>
      </div>
      <div class="operate-btns">
        <a-button class="btns rt" type="primary" @click="getClientList">
          查询
        </a-button>
        <a-button class="btns" @click="renewFilter">重置</a-button>
      </div>
    </div>
    <div class="table-content">
      <div class="table-btns">
        <a-Tooltip arrow-point-at-center placement="top">
          <template slot="title">
            <div class="client-tips">
              您在“客户查询”中获取的电话，仅沟通状态为“有意向”的企业转入“我的客户”。<br/>
              您可在“我的客户”列表中进行状态修改，“未成交”或“已成交”状态下该资源都将退回“客户查询”首页列表。
            </div>
          </template>
          <div class="client-description">
            <a-icon class="icon" type="question-circle"/>
            跟进中的客户规则说明
          </div>
        </a-Tooltip>
      </div>
      <a-table
          v-if="total"
          :columns="columns"
          :data-source="tableData"
          :pagination="pagination"
          rowKey="idDisplay"
          @change="handleTableChange"
      >
        <template slot="goToCompanyDetail" slot-scope="text, item">
          <div>
            <a @click="goToCompanyDetail(item)">{{ text }}</a>
          </div>
        </template>
        <template slot="followStatus" slot-scope="text, item">
          <editable-cell
              :cssJudge="cssJudge"
              :displayText="text"
              :isCanEdit="false"
              :statusList="statusList"
              :text="item.followStatusCode"
              @change="e => onCellChange(e, item)"
          />
        </template>
      </a-table>
      <block-data
          v-else
          :imageSrc="notFind"
          :text="''"
          :titleText="''"
      ></block-data>
    </div>
  </div>
</template>

<script>
import {DatePicker} from 'ant-design-vue'
import {mapState, mapMutations} from 'vuex'
import EditableCell from '@/components/EditableCell.vue'
import notFind from '@/assets/image/no_date_block.png'
import BlockData from '@/components/BlockData.vue'
import staffSelect from './StaffSelect.vue'
import Cookie from 'js-cookie'
import httpRequestor from '@/utils/http'

export default {
  name: 'processing',
  components: {
    aDateRange: DatePicker.RangePicker,
    BlockData,
    EditableCell,
    staffSelect
  },
  data() {
    return {
      isLead:false,
      isSuperAdmin:false,
      isCompanyAdmin:false,
      isSales:false,
      userInfo: null,
      filterOptions: {
        enterpriseName: '',
        followUpUserId: []
      },
      sourceText: ['', '收货查询', '出货查询'],
      sourceOptions: [
        {label: '收货查询', value: '1'},
        {label: '出货查询', value: '2'}
      ],
      clueSources: [],
      followStaff: [],
      notFind,
      pageSize: 10,
      pageNum: 1,
      dateRanger: [null, null],
      columns: [
        {title: '区域', dataIndex: 'areaName', width: 100},
        {
          title: '企业全称',
          dataIndex: 'enterpriseName',
          scopedSlots: {customRender: 'goToCompanyDetail'}
        },
        {title: '沟通资质', dataIndex: 'qualificationItemsText', width: 300},
        {
          title: '客户联系电话',
          dataIndex: 'phoneText'
        },
        {
          title: '跟进状态',
          dataIndex: 'followStatus',
          scopedSlots: {customRender: 'followStatus'}
        },
        {
          title: '跟进人',
          dataIndex: 'memberName',
          width: '100px'
        },
        {
          title: '状态更新时间',
          dataIndex: 'updateTime',
          width: 150
        },
        {
          title: '数据来源',
          dataIndex: 'sourceText',
          width: 90
        }
      ],
      tableData: [],
      total: 0,
      status: -1,
      staffList: [],
      cssJudge: {
        green: '1',
        yellow: '0',
        blue: '3'
      },
      clientDetail: false,
      statusList: [
        {value: '1', text: '有意向', disabled: false},
        {value: '2', text: '无意向', disabled: false},
        {value: '3', text: '已成交', disabled: false},
        {value: '4', text: '未成交', disabled: false},
        {value: '0', text: '未确认', disabled: true}
      ]
    }
  },

  computed: {


    pagination() {
      return {
        total: this.total,
        pageSize: this.pageSize,
        current: this.pageNum,
        showTotal: total => {
          return `共${total}条数据`
        },
        showSizeChanger: true
        /*onChange: (pageNum ) => {
                    this.changePageNum(pageNum)
                },*/
        // onShowSizeChange: (page, pageSize) => {
        //   this.pageSize = pageSize
        //   this.pageNum = page
        // },
        // onChange: (page, pageSize) => {
        //   //仅页码改变才触发,页面大小修改不会触发
        //   // console.log('onChange' + 'pageSize ' + pageSize + ' page' + page)
        //   this.pageSize = pageSize
        //   this.pageNum = page
        //   //this.changePageNum()
        // }
      }
    }
  },
  methods: {
    ...mapMutations(['setEnterpriseInfo']),
    followStaffChange(val) {
      if (val) {
        this.filterOptions.followUpUserId = val
      } else {
        this.filterOptions.followUpUserId = []
      }
    },
    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('clientFilter')
    },
    onCellChange(value, item) {
      const params = {
        enterpriseId: item.enterpriseId,
        enterpriseName: item.enterpriseName,
        followStatus: value
      }
      this.$http.post('followRecord/addFollowRecord', [params]).then(r => {
        // console.log(r)
        this.$message.success('操作成功', 0.5)
        this.getClientList()
      })
    },
    goToCompanyDetail(item) {
      localStorage.removeItem('enterpriseItem')
      this.setEnterpriseInfo(item)
      this.$router.push({
        path: `/enterpriseDetail/${item.enterpriseId}/${item.clueSources}/1/0`
        // query: {
        //   id: item.enterpriseId,
        //   isFromClient: 1
        // }
      })
    },
    changePageNum() {
      this.getClientList()
    },
    disabledEndDate(endValue) {
      const currentDate = new Date().getTime()
      return endValue.valueOf() > currentDate
    },
    setStatus(value) {
      if (value === this.status) {
        this.status = -1
        this.filterOptions.followStatus = ''
      } else {
        this.status = value
        this.filterOptions.followStatus = this.status
      }
    },
    userChange(value) {
      // console.log(value)
      this.filterOptions.followUpUserId = [value]
    },
    getStaffListForDepentment() {
      const params = {
        companyId: this.userInfo.companyId
      }
      this.staffList = []
      this.$http.get('member/listAllByDepartment', params).then(r => {
        r.forEach((e, i) => {
          this.staffList.push({
            id: e.id || -1,
            children: e.subordinateOrg,
            subordinateOrg: e.subordinateOrg,
            title: e.name || '超级管理员',
            label: e.subordinateOrg,

            key: e.name ? e.name + i : '超级管理员' + i
          })
        })

        function setArrayToChildren(item, id) {
          if (item.subordinateOrg) {
            item.children = []
            item.subordinateOrg.forEach((e, j) => {
              item.children.push({
                subordinateOrg: e.subordinateOrg,
                key: e.name ? e.name + id : '超级管理员' + id,
                title: e.name || '超级管理员',
                label: e.subordinateOrg,
                children: e.subordinateOrg || []
              })
              setArrayToChildren(e, j)
            })
          } else {
            item.children = []
            item.key = item.id + item.name
            item.title = item.name
            item.label = item.subordinateOrg
          }
        }

        function setArrayValue(item) {
          let value = ''

          function loopValue(i) {
            i.children.forEach((e, j) => {
              if (e.subordinateOrg) {
                e.value = 'level' + j + ',' + setArrayValue(e)
                loopValue(e)
              } else {
                e.value = 'min,' + e.id
                value += e.id + ','
              }
            })
          }

          loopValue(item)
          return value
        }

        this.staffList.forEach(e => {
          setArrayToChildren(e, e.id)
        })
        this.staffList.forEach(e => {
          e.value = 'root,' + setArrayValue(e)
        })
      })
    },
    getLeadStaff() {
      const params = {
        upleadId: this.userInfo.id
      }
      this.$http.get('member/listAll', params).then(r => {
        this.staffList = r.map(e => {
          return {
            value: e.id,
            label: e.memberName,
            key: e.id
          }
        })
      })
    },
    //设置分页大小与过滤字段(改变后的页码及每页条数修改后都会触发)
    handleTableChange(pagination, filters, sorter) {
      // console.log("handleTableChange" + " pagesize " + pagination.pageSize + " pageNum " + pagination.pageNum);
      this.pageSize = pagination.pageSize
      this.getClientList()
    },

    getClientList() {
      const params = {
        ...this.filterOptions,
        clueSources: this.clueSources ? parseInt(this.clueSources, 10) : 0,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        startTime: this.dateRanger[0]
            ? this.$day(this.dateRanger[0]).format('YYYY-MM-DD')
            : '',
        endTime: this.dateRanger[1]
            ? this.$day(this.dateRanger[1]).format('YYYY-MM-DD')
            : ''
      }
      this.$http.post('followRecord/selList', params).then(r => {
        r.data.forEach((e, i) => {
          e.idDisplay = this.pageSize * (this.pageNum - 1) + i + 1
          e.qualificationItemsText = e.qualificationItems.join('，')
          e.phoneText = e.phone
          e.sourceText = this.sourceText[e.clueSources]
          //   e.phoneList.length > 1 ? e.phoneList[0] + '...' : e.phoneList[0]
          // e.phoneListText = e.phoneList.join(',')
        })
        this.tableData = r.data
        this.total = r.totalSize
      })
    },
    renewFilter() {
      this.$refs.searchQua.clearFollowStaffId()
      this.filterOptions = {
        enterpriseName: '',
        // followStatus: '',
        followUpUserId: []
      }
      this.clueSources = []
      this.followStaff = []
      this.status = -1
      this.dateRanger = [null, null]
      // this.getClientList()
    },

    setDepartmentStaff(option) {
      this.filterOptions.followUpUserId = []
      if (option.length) {
        option.forEach(o => {
          const value = o.value.split(',')
          value.splice(0, 1)
          const filterValue = value.filter(e => {
            return e
          })
          this.filterOptions.followUpUserId.push(...filterValue)
        })
      }
      // this.filterOptions =
    }
  },
  mounted() {
    // this.getQualificationOptions()
    this.$nextTick(() => {
      const userId = Cookie.get('userId')
      this.$http.get('member/get', {id: userId}).then(r => {
        this.userInfo = r
        if (this.userInfo.roleLevel <= 3 || this.userInfo.isLead) {
          this.getStaffListForDepentment()
        }
        this.isLead = this.userInfo.isLead
        this.isSuperAdmin = this.userInfo.roleLevel === 1
        this.isCompanyAdmin = this.userInfo.roleLevel === 2
        this.isSales = this.userInfo.roleLevel === 4

      })
      this.getClientList()

    })
  },

}
</script>

<style lang="scss" scoped>
.mine-client-page {
  position: relative;

  .filter-box {
    background-color: #ffffff;
    width: 100%;
    border-radius: 0 0 4px 4px;
    padding: 16px 25px;

    .filter-title {
      font-size: 16px;
      color: #909399;
    }

    .filter-list {
      width: 100%;

      .filter-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        flex-grow: 0;
        margin-bottom: 16px;

        .label-title {
          width: 110px;
          text-align: left;
          font-size: 14px;
          color: #666666;
        }

        .filter-input {
          width: 326px;
        }

        .filter-btn {
          width: 86px;
          height: 36px;
          line-height: 36px;
        }

        .isSelect {
          background-color: rgba($color: #3b68e7, $alpha: 0.1);
          color: #3b68e7;
          border-color: #3b68e7;
        }

        .have-margin {
          margin-right: 24px;
        }
      }
    }

    .operate-btns {
      margin-top: 24px;
      margin-bottom: 16px;

      .btns {
        height: 40px;
        width: 126px;
        font-size: 16px;
        line-height: 40px;
      }

      .rt {
        margin-right: 24px;
      }
    }
  }

  .table-content {
    background-color: #ffffff;
    padding: 24px;
    margin-top: 28px;
    border-radius: 4px;

    .status-tag {
      width: 55px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      margin: 0;
    }

    .noConfirm {
      background-color: rgba($color: #fa8f00, $alpha: 0.1);
      color: #fa8f00;
    }

    .haveIntention {
      color: #67c23a;
      background-color: rgba($color: #67c23a, $alpha: 0.1);
    }

    .haveDeal {
      color: #4d7cfe;
      background-color: rgba($color: #4d7cfe, $alpha: 0.1);
    }

    .table-btns {
      // margin-bottom: 24px;
      // margin-top: 12px;
      height: 40px;
      position: relative;
      width: 100%;
      line-height: 40px;

      .client-description {
        position: absolute;
        right: 12px;
        bottom: 10px;
        cursor: pointer;

        .icon {
          margin-right: 8px;
        }
      }
    }

    .rt {
      margin-right: 24px;
    }
  }

  .select-status {
    width: 100%;
  }
}
</style>
