<template>
    <div class="wx-login">
        <div v-if="isLogin" class="wx-scarn">
            <!--            <div class="sub-title">扫码关注公众号，使用微信登录</div>-->
            <div class="wx-image" :style="'background-image:url(' + wxImageUrl + ')'">
                <div v-show="showReload" @click="getWxImageUrl" class="reload-mask">
                    <p>
                        <a-icon type="redo" style="font-size: 44px" />
                    </p>
                    <p>二维码已失效</p>
                    <p>点击刷新重新获取</p>
                </div>
            </div>
            <div class="wx-tips">未注册用户扫码绑定即注册成功</div>
        </div>
        <div v-if="isBtn" class="bind-content">
            <div class="not-bind-title">该微信暂未绑定小牛建讯账号</div>
            <a-button class="bind-btn primary" @click="bindNewAccount">去注册新账号</a-button>
            <a-button class="bind-btn" @click="bindAccount">去绑定已有账号</a-button>
        </div>
        <div v-if="isBind" class="bind-form">
            <verify-code :isBind="true" :authonKey="this.status"></verify-code>
        </div>
        <a-button class="back-methods" @click="backUseSelect" v-if="isBind || isBtn" type="link"
            >返回登录
        </a-button>
        <!--    <div class="bind-tips" v-if="!isLogin">如有疑问，请联系您的专属管家：13438870357</div>-->
    </div>
</template>

<script>
import verifyCode from './verifyLogin.vue'
import Cookie from 'js-cookie'
import { mapMutations } from 'vuex'

export default {
    name: 'wx-login',
    components: {
        verifyCode
    },
    inject: ['reload'],

    data() {
        return {
            status: '',
            wxImageUrl: '',
            getWx: null,
            showReload: false,
            isLogin: true,
            isBtn: false,
            isBind: false
        }
    },
    methods: {
        ...mapMutations([
            'setUserInfo',
            'setExpriedModalShow',
            'setShowLoginModal',
            'setLoginSuccess'
        ]),
        showBind() {
            this.isLogin = false
            this.isBind = false
            this.isBtn = true
            this.$emit('showBtn')
        },
        backUseSelect() {
            this.isLogin = true
            this.isBtn = false
            this.isBind = false
            this.$emit('backMethods', '微信扫码登录')
        },
        bindNewAccount() {
            this.isBind = true
            this.isLogin = false
            this.isBtn = false
            this.$emit('showBind', '绑定新手机号')
        },
        bindAccount() {
            this.isBind = true
            this.isLogin = false
            this.isBtn = false
            this.$emit('showBind', '绑定微信账号')
        },
        getWxImageUrl() {
            this.$http.get('wx/login/qrcode').then(res => {
                this.showReload = false
                this.wxImageUrl = res[1]
                this.status = res[0]
                this.setWxStatus()
            })
        },
        userInfo(userId) {
            this.$http.get('member/get', { id: userId }).then(r => {
                this.setUserInfo(r)
            })
        },
        setWxStatus() {
            // this.$http.get('wx/login/qrcode').then(res => {
            let i = 0
            //  this.status = res.status
            this.getWx = setInterval(() => {
                this.$http
                    .get(`wx/login/scan/0/${this.status}`)
                    .then(r => {
                        clearInterval(this.getWx)
                        this.$message.success('登录成功', 0.5)
                        Cookie.set('JSESSIONID', r.tokeId, { expires: 7 })
                        Cookie.set('memberName', r.memberName, { expires: 7 })
                        Cookie.set('userId', r.id, { expires: 7 })
                        // console.log('?')
                        this.userInfo(r.id)
                        this.reload()
                        this.setLoginSuccess(true)
                        this.setShowLoginModal(false)
                        // this.$router.push({
                        //     name: this.$route.query.from || 'Home'
                        // })
                    })
                    .catch(err => {
                        if (err.code === '2007') {
                            i += 1
                            if (i === 30) {
                                // console.log('this')
                                clearInterval(this.getWx)
                                this.getWx = null
                                i = 0
                                this.showReload = true
                            }
                        } else if (err.code === '2006') {
                            clearInterval(this.getWx)
                            this.bindAccount()
                        } else if (err.code === '2005') {
                            clearInterval(this.getWx)
                            this.setExpriedModalShow(true)
                        } else {
                            clearInterval(this.getWx)
                            this.getWx = null
                            this.showReload = true
                        }
                    })
            }, 1000)
            // })
        }
    },
    mounted() {
        this.getWxImageUrl()
    },
    destroyed() {
        clearInterval(this.getWx)
    }
}
</script>

<style lang="scss" scoped>
.wx-login {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    //height: 400px;
    .wx-scarn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }

    .sub-title {
        margin-bottom: 15px;
        font-weight: bold;
        font-size: 24px;
        color: #02060b;
    }

    .wx-image {
        width: 200px;
        height: 200px;
        // background-color: cadetblue;
        margin-top: 0;
        background-position: center;
        background-size: contain;
        position: relative;
        z-index: 99;

        .reload-mask {
            z-index: 100;
            cursor: pointer;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: rgba($color: #fff, $alpha: 0.7);

            p {
                font-size: 18px;
                margin-bottom: 10px;
                color: #00195e;
            }
        }
    }

    .wx-tips {
        //margin-top: 20px;
        font-size: 14px;
        color: #888;
    }

    .not-bind-title {
        font-size: 18px;
        font-weight: bold;
        color: #02060b;
        margin-top: 50px;
        margin-bottom: 70px;
    }

    .bind-btn {
        display: block;
        margin-bottom: 35px;
        width: 200px;
        height: 50px;
        border-radius: 30px;
        font-size: 15px;
        color: #7d7d7d;
        border-color: #7d7d7d;
        font-weight: bold;
    }

    .primary {
        background-color: #1c8ffa;
        color: #fff;
        border-color: #1c8ffa;
    }

    .bind-form {
        margin-bottom: 40px;
    }

    .bind-content {
        position: relative;
        width: 400px;
        display: flex;
        height: 400px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .back-methods {
        margin-bottom: 40px;
    }

    .bind-tips {
        font-size: 13px;
        color: #5d5d5d;
        bottom: 0;
        position: absolute;
        // bottom: -30px;
        width: 100%;
    }
}
</style>
