<template>
    <div class="date-input">
        <a-select
            :disabled="disabled"
            class="start-select"
            optionFilterProp="value"
            @change="setDateRange"
            allowClear
            showSearch
            placeholder="年份"
            v-model="startYear"
        >
            <a-option
                v-for="item in startYearSelect"
                :value="item.value"
                :disabled="item.disabled"
                :key="item.value"
                >{{ item.label }}</a-option
            >
        </a-select>
        年
        <a-select
            :disabled="disabled"
            class="start-select"
            optionFilterProp="value"
            @change="setDateRange"
            allowClear
            showSearch
            placeholder="月份"
            v-model="startMonth"
        >
            <a-option
                v-for="item in startMonthSelect"
                :value="item.value"
                :key="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</a-option
            >
        </a-select>
        月

        <div class="split">至</div>
        <a-select
            :disabled="disabled"
            class="start-select"
            optionFilterProp="value"
            @change="setDateRange"
            allowClear
            showSearch
            placeholder="年份"
            v-model="currentYear"
        >
            <a-option
                v-for="item in yearSelect"
                :value="item.value"
                :key="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</a-option
            > </a-select
        >年
        <a-select
            :disabled="disabled"
            class="start-select"
            optionFilterProp="value"
            @change="setDateRange"
            allowClear
            showSearch
            placeholder="月份"
            v-model="currentMonth"
        >
            <a-option
                v-for="item in monthSelect"
                :value="item.value"
                :key="item.value"
                :disabled="item.disabled"
                >{{ item.label }}</a-option
            >
        </a-select>
        月
    </div>
</template>

<script>
import { Select } from 'ant-design-vue'
export default {
    name: 'date-input',
    components: {
        aSelect: Select,
        aOption: Select.Option
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            currentYear: '',
            currentMonth: '',
            startYearSelect: [],
            startMonthSelect: [],
            yearSelect: [],
            monthSelect: [
                { label: 1, value: 1, disabled: false },
                { label: 2, value: 2, disabled: false },
                { label: 3, value: 3, disabled: false },
                { label: 4, value: 4, disabled: false },
                { label: 5, value: 5, disabled: false },
                { label: 6, value: 6, disabled: false },
                { label: 7, value: 7, disabled: false },
                { label: 8, value: 8, disabled: false },
                { label: 9, value: 9, disabled: false },
                { label: 10, value: 10, disabled: false },
                { label: 11, value: 11, disabled: false },
                { label: 12, value: 12, disabled: false }
            ],
            startYear: undefined,
            startMonth: undefined,
            dateRange: []
        }
    },
    methods: {
        filterOption(value, option) {
            //console.log(value, option)
            return true
        },
        setYearSelect() {
            this.yearSelect = []
            const minYear = 1910
            const interVal = this.currentYear - minYear
            for (let i = 0; i <= interVal; i++) {
                this.yearSelect.unshift({
                    label: minYear + i,
                    value: minYear + i,
                    disabled: false
                })
            }
            this.startYearSelect = this.$deepCopy(this.yearSelect)
            this.startMonthSelect = this.$deepCopy(this.monthSelect)
            //   console.log(this.yearSelect)
        },
        setSlectOptionBegin() {
            this.yearSelect.forEach(e => {
                e.disabled = false
            })
            this.startMonthSelect.forEach(e => {
                e.disabled = false
            })
        },
        setDateRange() {
            // if (this.startYear) {
            //   // 先判断年
            //   this.yearSelect.forEach((e) => {
            //     if (e.value < this.startYear) {
            //       e.disabled = true
            //     }
            //   })
            // } else {
            //   this.yearSelect.forEach((e) => {
            //     // 清空就复原
            //     e.disabled = false
            //   })
            // }

            // if (this.currentYear) {
            //   this.startYearSelect.forEach((e) => {
            //     if (e.value > this.currentYear) {
            //       e.disabled = true
            //     }
            //   })
            // } else {
            //   this.startYearSelect.forEach((e) => {
            //     // 清空就复原
            //     e.disabled = false
            //   })
            // }
            // if (this.startYear === this.currentYear) {
            //   // 年份相同的时候判断月份，前面的不能大于后面的
            //   if (this.currentMonth) {
            //     this.startMonthSelect.forEach((e) => {
            //       if (e.value > this.currentMonth) {
            //         e.disabled = true
            //       }
            //     })
            //   }
            //   if (this.startMonth) {
            //     this.monthSelect.forEach((e) => {
            //       if (e.value < this.startMonth) {
            //         e.disabled = true
            //       }
            //     })
            //   }
            // }
            // if (!this.currentMonth) {
            //   // 没有后项就放开前项的限制
            //   this.startMonthSelect.forEach((e) => {
            //     e.disabled = false
            //   })
            // }
            // if (!this.startMonth) {
            //   // 同上
            //   this.monthSelect.forEach((e) => {
            //     e.disabled = false
            //   })
            // }
            let startTime = null
            let endTime = null
            if (this.startYear && this.startMonth) {
                startTime = this.$day(new Date(`${this.startYear}-${this.startMonth}-01`)).format(
                    'YYYY-MM-DD'
                )
            }

            if (this.currentYear && this.currentMonth) {
                endTime = this.$day(new Date(`${this.currentYear}-${this.currentMonth}-01`))
                    .endOf('month')
                    .format('YYYY-MM-DD')
                // console.log(this.dateRange)
            }

            this.dateRange = [startTime, endTime]
            this.$emit('input', this.dateRange)
        }
    },
    mounted() {
        this.currentYear = new Date().getFullYear()
        this.currentMonth = new Date().getMonth() + 1
        this.startMonth = 1
        this.setYearSelect()
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                if (!val.length) {
                    this.dateRange = []
                    this.currentYear = new Date().getFullYear()
                    this.currentMonth = new Date().getMonth() + 1
                    this.startYear = undefined
                    this.startMonth = 1
                } else {
                    if (val[1]) {
                        this.currentYear = new Date(val[1]).getFullYear()
                        this.currentMonth = new Date(val[1]).getMonth() + 1
                    }
                    if (val[0]) {
                        this.startYear = new Date(val[0]).getFullYear()
                        this.startMonth = new Date(val[0]).getMonth() + 1
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.date-input {
    display: flex;
    align-items: center;
    .start-select {
        width: 80px;
        margin: 0 5px;
        &:first-child {
            margin-left: 0;
        }
    }
    .split {
        margin: 0 10px;
    }
}
</style>
