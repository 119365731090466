import Vue from 'vue'
import App from './App.vue'
import { createRouter } from './router'
import { createStore } from './store'
import Antd from 'ant-design-vue'
import { sync } from 'vuex-router-sync'
import Meta from 'vue-meta'
// import './assets/font/font.css'
import VueLazyload from 'vue-lazyload' //引入这个懒加载插件

// import ''
// import store from './store'
import dayJS from 'dayjs'

// import
import httpRequestor from './utils/http'
import httpRequestors from './utils/https'
import { message } from 'ant-design-vue'

if (!Vue.$isServer) {
  // require('devtools-detect/index')
  require('ant-design-vue/dist/antd.less')
}
Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(Meta)
Vue.use(VueLazyload)
message.config({
  maxCount: 1,
  top: '50%'
})
Vue.prototype.$day = dayJS
Vue.prototype.$http = httpRequestor
Vue.prototype.$https = httpRequestors
Vue.prototype.$addQuery = (route, query, router) => {
  console.log('route', route)
  if (query) {
    let path = ''
    for (const key in query) {
      if (Object.prototype.hasOwnProperty.call(query, key)) {
        const queryItem = query[key]
        path += `${key}=${queryItem}&`
      }
    }
    router.replace('?' + path)
  } else {
    const path = route.path
    router.replace(path)
  }
}
Vue.prototype.$deepCopy = function(obj) {
  const newobj = obj.constructor === Array ? [] : {}
  if (typeof obj !== 'object') {
    return
  }
  for (const i in obj) {
    newobj[i] = typeof obj[i] === 'object' ? this.$deepCopy(obj[i]) : obj[i]
  }
  return newobj
}
Vue.prototype.$emitP = function(topic, varargs) {
  return new Promise((resolve, reject) => {
    const arr = Array.from(arguments)
    let invoked = false
    const promiseLike = {
      resolve: val => {
        if (!invoked) {
          invoked = true
          resolve(val)
        }
      },
      reject: val => {
        if (!invoked) {
          invoked = true
          reject(val)
        }
      }
    }
    arr.push(promiseLike)
    this.$emit.apply(this, arr)
  })
}
Vue.mixin({
  beforeMount() {
    const { asyncData } = this.$options
    if (asyncData) {
      // 将获取数据操作分配给 promise
      // 以便在组件中，我们可以在数据准备就绪后
      // 通过运行 `this.dataPromise.then(...)` 来执行其他任务
      this.dataPromise = asyncData({
        store: this.$store,
        route: this.$route
      })
    }
  }
})
Vue.mixin({
  beforeRouteUpdate(to, from, next) {
    const { asyncData } = this.$options
    if (asyncData) {
      asyncData({
        store: this.$store,
        route: to
      })
        .then(next)
        .catch(next)
    } else {
      next()
    }
  }
})
export function createApp() {
  const router = createRouter()
  const store = createStore()
  if (!Vue.$isServer) {
    router.afterEach((to, from, next) => {
      document.body.scrollTop = 0
      window.scrollTo(0, 0)

      document.documentElement.scrollTop = 0
    })
  }
  // sync(store, router)
  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })
  return { app, router, store }
}
