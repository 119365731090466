var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-page"},[_c('div',{staticClass:"topBanner",staticStyle:{"background-image":"url('http://cdn.public.xnjx666.com//upload/gw/static/img/download_bg.png')"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"downloadButton"},[_vm._m(0),_c('div',{staticClass:"qrCode"},[_c('div',{staticClass:"topQrCode"},[_c('div',{staticClass:"Qrcode"},[_c('img',{attrs:{"src":_vm.QrCode,"alt":"小牛建讯"}}),_c('img',{attrs:{"src":_vm.icApplet,"alt":"小牛建讯"}})]),_vm._m(1)])]),_c('div',{staticClass:"down-icon"},[_c('div',{staticClass:"downLoadLink"},[_c('a',{staticClass:"down",attrs:{"id":"win7first","href":_vm.win7Url}},[_c('img',{attrs:{"src":_vm.win,"alt":""}}),_c('span',{staticClass:"linkText"},[_vm._v("Win7下载")])]),_c('a',{staticClass:"down",staticStyle:{"margin-left":"20px"},attrs:{"id":"win10first","href":_vm.win10Url}},[_c('img',{attrs:{"src":_vm.win,"alt":""}}),_c('span',{staticClass:"linkText"},[_vm._v("Win10下载")])])])])])])]),_c('div',{staticClass:"download"},[_vm._m(2),_c('div',{staticClass:"keyword"},[_c('div',{staticClass:"keywordText"},[_c('ul',[_c('li',[_c('img',{attrs:{"src":_vm.one,"alt":"小牛建讯"}}),_c('p',[_vm._v("客户精准")])]),_c('li',[_c('img',{attrs:{"src":_vm.two,"alt":"小牛建讯"}}),_c('p',[_vm._v("海量资源")])]),_c('li',[_c('img',{attrs:{"src":_vm.three,"alt":"小牛建讯"}}),_c('p',[_vm._v("数据实时")])]),_c('li',[_c('img',{attrs:{"src":_vm.four,"alt":"小牛建讯"}}),_c('p',[_vm._v("资源共享")])])])])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sloganBox"},[_c('p',[_vm._v("数据创造价值")]),_c('p',[_vm._v("让资质服务更简单")]),_c('p',[_vm._v("MAKE QUALIFICATION SERVICE EASIER")]),_c('div',{staticClass:"slogan"},[_c('span',[_vm._v("精准")]),_c('div',{staticClass:"slogan-line"}),_c('span',[_vm._v("全面")]),_c('div',{staticClass:"slogan-line"}),_c('span',[_vm._v("实时")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("联系您的服务管家 : 13438870357")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduceText"},[_c('p',[_vm._v("专注赋能资质服务企业")]),_c('p',[_vm._v("\n        通过智能AI数据算法分析，为资质服务企业提供精准的数据结果；帮助企业解决拓客、资源共享问题，提升业务人员工作效能\n      ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('img',{attrs:{"src":"http://cdn.public.xnjx666.com//upload/gw/static/img/productOne.png","alt":"小牛建讯"}}),_c('div',{staticClass:"text"},[_c('p',{staticClass:"index"},[_c('span',{staticClass:"num"},[_vm._v("01")]),_c('span',{staticClass:"indexText"},[_vm._v("收货查询")])]),_c('p',{staticClass:"textInfo",staticStyle:{"margin-top":"30px"}},[_vm._v("\n          基于建筑资质行业属性，自定义多维度筛选，即可全方位多角度查企业信息（企业资质、联系电话、工商信息、风险信息），从而极大的节省销售时间提升获客效率\n        ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',{staticClass:"text"},[_c('p',{staticClass:"index"},[_c('span',{staticClass:"num"},[_vm._v("02")]),_c('span',{staticClass:"indexText"},[_vm._v("出货查询")])]),_c('p',{staticClass:"textInfo"},[_vm._v("\n          设定查询条件,一键甄选出全网所有符合条件的企业,基于AI算法智能精准营销\n        ")])]),_c('img',{attrs:{"src":"http://cdn.public.xnjx666.com//upload/gw/static/img/productTwo.png","alt":"小牛建讯"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('img',{attrs:{"src":"http://cdn.public.xnjx666.com//upload/gw/static/img/productThree.png","alt":"小牛建讯"}}),_c('div',{staticClass:"text"},[_c('p',{staticClass:"index"},[_c('span',{staticClass:"num"},[_vm._v("03")]),_c('span',{staticClass:"indexText"},[_vm._v("资质服务")])]),_c('p',{staticClass:"textInfo"},[_vm._v("\n          专属资质服务社区,整合全品类资质服务，资源共享，加速企业经营效率\n        ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product lastProduct"},[_c('div',{staticClass:"text"},[_c('p',{staticClass:"index"},[_c('span',{staticClass:"num"},[_vm._v("04")]),_c('span',{staticClass:"indexText"},[_vm._v("客户管理")])]),_c('p',{staticClass:"textInfo"},[_vm._v("\n          一站式客户跟踪管理系统,资源和业务人员线上管理,有意向的客户进行实时跟进，简单清楚记录业务进程,客户转化更高效\n        ")])]),_c('img',{attrs:{"src":"http://cdn.public.xnjx666.com//upload/gw/static/img/productFive.png","alt":"小牛建讯"}})])
}]

export { render, staticRenderFns }