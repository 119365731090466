<template>
  <div class="table-card">
    <div v-if="showCkeckBox" class="checkbox">
      <a-checkbox :checked="isChecked" @click="changeChecked"></a-checkbox>
    </div>
    <div class="sys-image">
      <p>{{ row.number }}</p>
    </div>
    <div class="row-info">
      <div class="line title">
                <span
                    :class="{ isClicked: isClicked, isTransfer: type === 'transfer' }"
                    @click="goToCompanyDetail(row)"
                    v-html="row.companyNameHTML"
                ></span>
        <a-tooltip
            v-if="!isSpecialProvince"
            :title="riskText.detail"
            placement="right"
            trigger="hover"
        >
          <span :class="riskText.type" @click="goToCompanyRisk(row)">
<!--            <a-icon type="security-scan" theme="filled" />-->
            <img :src="riskText.icon" alt="">
            {{ riskText.title }}
          </span>
        </a-tooltip>
        <div v-if="row.qualificationChangeTagList">
          <a-tooltip v-for="(item,index) in row.qualificationChangeTagList" :key="index"
                     placement="right" style="margin-right: 10px">
            <template slot="title">
              <span>近一个月内该企业有发生{{ item.applyTag }}：{{ item.qualificationName }}</span>
            </template>
            <div :style="'background-image:url(' + labelQualifications + ')'" class="labelQualificationsText">
              {{ item.applyTag }}
            </div>
          </a-tooltip>
        </div>
      </div>
      <div class="staff-history-shareholder">
        <div class="staff" @click="showRegisterPersonModal">
          注册人员：{{ row.registerPersonNum }} 人
        </div>
        <div class="history">历史业绩：{{ row.achievementsNum }}</div>
        <div class="shareholder">
          {{ row.shareholderChange ? '近三个月发生股东变更' : '' }}
        </div>
      </div>
      <div class="line finace-date">
        <!-- <span>注册资本：{{row.}}</span> -->
        <span v-if="row.establishmentDate !== 'no'"
        >成立日期：{{ row.establishmentDate || '-' }}</span
        >
      </div>
      <div v-show="row.qualificationsListLocal.length" class="line qua">
        <a-popover v-if="row.qualificationsListLocal.length >= 4" trigger="hover">
          <div slot="title" class="popover-title">施工资质</div>
          <template slot="content">
            <div style="max-width: 600px" v-html="row.qualificationsListText"></div>
          </template>
          <div :class="{ quaIsClicked: isClicked }" class="qua-detail">
            <div class="qua-title">施工资质：</div>
            <span v-html="row.qualificationsText"></span>
          </div>
        </a-popover>
        <div v-else>
                    <span :class="{ quaIsClicked: isClicked }">
                        {{ row.specialText || '施工资质：' }}
                        <span v-html="row.qualificationsText"></span>
                    </span>
        </div>
      </div>
      <div v-show="row.designQualificationListLocal.length" class="line qua">
        <a-popover v-if="row.designQualificationListLocal.length >= 3" trigger="hover">
          <div slot="title" class="popover-title">设计资质</div>
          <template slot="content">
            <div
                style="max-width: 600px"
                v-html="row.designQualificationListText"
            ></div>
          </template>
          <div :class="{ quaIsClicked: isClicked }" class="qua-detail">
            <div class="qua-title">设计资质：</div>
            <span v-html="row.desginQualificationText"></span>
          </div>
        </a-popover>
        <div v-else>
                    <span :class="{ quaIsClicked: isClicked }">
                        {{ row.specialText || '设计资质：' }}
                        <span v-html="row.desginQualificationText"></span>
                    </span>
        </div>
      </div>
      <!-- <div v-if="!isSpecialProvince" class="line phone">
<span v-if="row.contactsPerson">联系人：{{ row.contactsPerson }}</span>
</div> -->

      <div v-if="!isSpecialProvince" class="line phone">
        <!-- <a-popover v-if="row.contactInformationList.length > 2" trigger="hover">
<div class="popover-title" slot="title">联系方式</div>
<template slot="content">{{
row.contactInformationListText
}}</template>
电话：{{ phoneInfo }}
</a-popover>-->
        <span v-if="!row.phoneType" class="no-phonenumber">
                    联系电话：暂无手机号，程序正在努力解析。当前条件下您可以改变企业风险等级查询有手机号的数据
                    <a-select
                        allowClear
                        class="table-risk-filter"
                        placeholder="请选择企业风险等级"
                        size="small"
                        @change="setRiskLevelFilter"
                    >
                        <a-select-option
                            v-for="(item, key) in riskOption"
                            :key="key"
                            :value="item.value"
                        >
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </span>
        <span v-else-if="row.phoneType === '2'">
                    <a-tooltip v-if="row.haveReceive" title="双击可复制电话号码" trigger="hover">
                        <span class="point-phone">
                            <div class="point-title">联系电话：</div>
                            <span @dblclick="dblCopy" v-html="row.phoneNumDisplay"> </span>
                        </span>
                    </a-tooltip>
                    <span v-if="!row.haveReceive"> 联系电话：{{ row.phoneNumDisplay }} </span>
                    <div>
                        暂无手机号，程序正在努力解析。当前条件下您可以改变企业风险等级查询有手机号的数据
                        <a-select
                            allowClear
                            class="table-risk-filter"
                            placeholder="请选择企业风险等级"
                            size="small"
                            @change="setRiskLevelFilter"
                        >
                            <a-select-option
                                v-for="(item, key) in riskOption"
                                :key="key"
                                :value="item.value"
                            >
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </div>
                </span>

        <span v-else-if="isHavePhoneNumber(row.phoneNumDisplay) && !row.haveReceive">
                    联系电话：{{ row.phoneNumDisplay }}
                </span>
        <a-tooltip v-else title="双击可复制电话号码" trigger="hover">
                    <span class="point-phone">
                        <div class="point-title">联系电话：</div>
                        <span @dblclick="dblCopy" v-html="row.phoneNumDisplay"></span
                        ></span>
        </a-tooltip>
      </div>

      <div v-if="!isSpecialProvince" class="line receive-num">
        <span class="mini-text">被查看次数：{{ row.receiveCount }}</span>
        <span class="mini-text">{{ row.answerStatus }}</span>
      </div>
    </div>
    <div class="operate">
      <div
          v-if="!isSpecialProvince"
          :class="{ shareDisabled: isSuperAdmin }"
          :style="'background-image:url(' + icShare + ');' + ' '"
          class="qr-code-image"
          title="分享企业"
          @click="showSharQrCode(row)"
      ></div>
      <div class="region">{{ row.areaName }}</div>
      <div
          v-if="!isSpecialProvince && isHavePhoneNumber(row.phoneNumDisplay)"
          class="examine-phone"
      >
        <!-- <div class="receive btn">电话已领取</div> -->
        <div
            v-if="!row.haveReceive"
            :class="{ cDisabled: isSuperAdmin }"
            class="no-receive btn"
            @click="receiveThisItem(row)"
        >
          查看电话
        </div>
      </div>
      <div v-if="row.haveReceive" class="btns">
        <!-- <div class="btns" v-if="false"> -->
        <!-- <div v-if="row.haveReceive" class="receive btn">已领取资源</div> -->
        <div
            :class="{ cDisabled: isSuperAdmin || !row.haveReceive }"
            class="not-turn-on btn"
            @click="phoneIsNotTurnOn"
        >
          拨打未通
        </div>
        <div
            :class="{ cDisabled: isSuperAdmin || !row.haveReceive }"
            class="follow btn"
            @click="phoneIsTurnOn"
        >
          接通跟进
        </div>
      </div>
    </div>
    <a-modal v-model="staffModal" :destroyOnClose="true" title="注册人员" @cancel="closeModal">
      <a-tabs :activeKey="activeKey" type="card" @change="changeTabCard">
        <a-tab-pane
            v-for="(item, key) in registerTypeTabs"
            :key="key"
            :tab="item.name + '(' + item.number + ')'"
        >
          <a-table
              :bordered="true"
              :columns="columns"
              :data-source="item.list"
              :pagination="pagination"
              :total="item.number"
              rowKey="index"
          ></a-table>
        </a-tab-pane>
      </a-tabs>
      <div slot="footer">
        <!-- <a-button @click="closeModal" type="primary">确认</a-button> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import {Popover, Tooltip, Checkbox, Modal, Tabs, Table, Select} from 'ant-design-vue'
// import comapnyImage from '@/assets/image/company_image.png'
// import qrCodeWhite from '@/assets/image/qr_code_white.png'
import low from '@/assets/ic_danger_low.png'
import hight from '@/assets/ic_danger_hight.png'
import centre from '@/assets/ic_danger_centre.png'
import qrCodePrimary from '@/assets/image/qr_code_primary.png'
import labelQualifications from '@/assets/bg_label_qualifications.png'
import icShare from '@/assets/homePage/ic_share.png'
// import { userModule } from '@/store/modules/user'
import {mapMutations, mapState} from 'vuex'
import Cookie from 'js-cookie'

export default {
  name: 'table-card',
  components: {
    aModal: Modal,
    aPopover: Popover,
    aTooltip: Tooltip,
    aCheckbox: Checkbox,
    aTabs: Tabs,
    aTable: Table,
    aTabPane: Tabs.TabPane,
    aSelect: Select,
    aSelectOption: Select.Option
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    userEnable() {
      return this.userInfo ? this.userInfo.enable : -1
    },
    pagination() {
      return {
        current: this.pageNum,
        pageSize: 5,
        hideOnSinglePage: true,
        // total: this.row.registerPersonNum,
        onChange: num => {
          this.pageNum = num
        }
      }
    },
    isSuperAdmin() {
      const hvaeUserId = Cookie.get('userId')
      if (!hvaeUserId) {
        return false
      } else {
        return this.userInfo.roleLevel === 1
      }
    },

    isSales() {
      return this.userInfo.roleLevel === 4
    },
    riskText() {
      if (this.row.riskLevel !== undefined) {
        const riskDetail = [
          {
            title: '无风险',
            icon: low,
            type: 'no-risk risk',
            detail: '企业经营状况良好'
          },
          {
            title: '低风险',
            icon: low,
            type: 'no-risk risk',
            detail: '企业存在工商异常、行政处罚等信息'
          },
          {
            title: '中风险',
            icon: centre,
            type: 'medium-risk risk',
            detail: '企业存在被执行信息'
          },
          {
            title: '高风险',
            icon: hight,
            type: 'hight-risk risk',
            detail: '企业存在失信被执行信息'
          }
        ]

        return this.row.riskLevel >= 0 ? riskDetail[this.row.riskLevel] : {}
      } else {
        return {}
      }
    }
  },
  data() {
    return {
      low,
      hight,
      centre,
      labelQualifications,
      riskLevelFilter: undefined,
      riskOption: [
        {label: '无风险', value: '0'},
        {label: '低风险', value: '1'},
        {label: '中风险', value: '2'},
        {label: '高风险', value: '3'}
      ],
      activeKey: 0,
      // 0=挂断，1=未接，2=空号，3=无法接通，4=不在服务器/国内，5=其他,可用
      qrCodeImage: '',
      //   qrCodePrimary,
      checked: false,
      // comapnyImage,
      qrCodePrimary,
      icShare,
      // qrCodeWhite,

      staffModal: false,
      registerTypeTabs: [],
      pageNum: 1,
      columns: [
        {title: '序号', dataIndex: 'index', width: 70, align: 'center'},
        {title: '姓名', dataIndex: 'personName', width: 110, align: 'center'},
        {title: '注册类别', dataIndex: 'registerType', align: 'center'},
        {
          title: '注册专业',
          dataIndex: 'registerMajor',
          width: 150,
          align: 'center'
        }
      ]
    }
  },
  props: {
    type: {
      type: String,
      default: 'default'
    },
    isSpecialProvince: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: -1
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    showCkeckBox: {
      type: Boolean,
      default: false
    },
    isClicked: {
      type: [Boolean, Number, String],
      default: false
    },
    row: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  methods: {
    ...mapMutations(['setShowLoginModal']),
    setRiskLevelFilter(val) {
      this.$emit('setRiskLevelFilter', val)
    },
    changeTabCard(key) {
      this.activeKey = key
      this.pageNum = 1
    },
    closeModal() {
      this.staffModal = false
    },
    showRegisterPersonModal() {
      if (!this.row.registerTypeList.length) {
        return
      }
      // console.log('this.registerTypeTabs', this.registerTypeTabs)
      this.registerTypeTabs = this.row.registerTypeList.map(e => {
        return {
          name: e.registerType,
          list: e.registerPersonList.map((r, i) => {
            return {...r, index: i + 1}
          }),
          number: e.registerPersonList.length
        }
      })
      this.pageNum = 1
      this.activeKey = 0
      this.staffModal = true
    },
    isHavePhoneNumber(num) {
      if (num.indexOf('还未采集到电话号码') > -1) {
        return false
      } else {
        return true
      }
    },
    dblCopy() {
      this.$message.success('电话号码已复制到粘贴板', 0.5)
    },
    phoneIsNotTurnOn() {
      this.$emit('phoneIsNotTurnOn', this.row)
    },
    phoneIsTurnOn() {
      this.$emit('phoneIsTurnOn', this.row)
    },
    changeChecked() {
      //console.log(this.index)
      this.$emit('changeChecked', this.index)
    },
    selectThisRow() {
      //console.log(this.checked)
    },
    goToCompanyDetail(row) {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      } else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)
          // this.$router.push({
          //   path: "/login?from=Product"
          // });
        }, 800)
      } else {
        this.$emit('goToCompanyDetail', row)
      }
    },
    goToCompanyRisk(row) {
      this.$emit('goToCompanyRisk', row)
    },
    receiveThisItem(row) {
      if (this.isSuperAdmin) {
        return
      }
      this.$emit('receiveThisItem', row)
    },
    staffFollowUp(row) {
      if (this.isSuperAdmin) {
        return
      }
      if (!row.haveReceive) {
        this.$message.error('请领取后再进行操作', 0.5)
        return
      }
      this.$emit('staffFollowUp', row)
    },
    showSharQrCode(row) {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      } else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)
          // this.$router.push({
          //   path: "/login?from=Product"
          // });
        }, 800)
      } else {
        if (this.isSuperAdmin) {
          return
        }

        // if (!row.haveReceive) {
        //  this.$message.error('请领取后再进行操作')
        //  return
        //}
        this.$emit('showSharQrCode', row)
      }
    }
  }
}
</script>
<style lang="scss">
.labelQualificationsText {
  padding-left: 18px;
  padding-right: 6px;
  font-size: 14px;
  font-family: 'songti';
  font-weight: 400;
  color: #4D7CFE;
}

.hightlight {
  color: #ff8800 !important;
}

.qua-hightlight {
  color: #ff8800 !important;
}
</style>

<style lang="scss" scoped>
.table-card {
  width: 100%;
  padding: 24px 174px 24px 24px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  border-bottom: solid 1px #f2f2f2;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
  // justify-content: space-between;
  .checkbox {
    height: 94px;
    width: 40px;
  }

  .row-info {
    margin-left: 34px;

    .staff-history-shareholder {
      display: flex;
      align-items: center;
      margin: 5px 0;

      div {
        margin-right: 10px;
      }

      .staff {
        padding: 3px 10px;
        font-size: 12px;
        border-radius: 4px;
        border: 1px solid #999999;

        &:hover {
          border-color: #759fff;
          color: #759fff;
          cursor: pointer;
        }
      }
    }

    .line {
      margin-top: 6px;
      color: #8e8e90;

      span {
        display: inline-block;
        margin-right: 20px;
      }

      .point-title {
        width: 75px;
        display: inline-block;
      }

      .mini-text {
        font-size: 12px;
      }

      .point-phone {
        width: fit-content;
        font-size: 15px;
        margin-top: -4px;
        display: flex;
        align-items: flex-start;
        color: #333333;

        .poine-title {
          flex-shrink: 0;
        }
      }

      .no-phonenumber {
        font-size: 13px;
      }
    }

    .qua {
      color: #000000;

      .qua-detail {
        display: flex;
        align-items: flex-start;

        .qua-title {
          flex-shrink: 0;
        }
      }
    }

    .quaIsClicked {
      color: #8e8e90;
    }

    .title {
      font-size: 16px;
      color: #4d7cfe;
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;

      .risk {
        //  width: 50px;
        height: 20px;
        line-height: 17px;
        font-size: 14px;
        display: inline-block;
        // margin-left: 17px;
        text-align: center;
        color: #fff;
        border-radius: 2px;
        img{
          //vertical-align: bottom;
        }
      }
      .hight-risk {
        color: #DC0000;
        //    border: 1px solid #dc0000;
      }

      .medium-risk {
        color: #ff8800;
        //  border: 1px solid #ff8800;
      }

      .no-risk {
        color: #80A56D;
        // border: 1px solid #4ebf56;
      }
    }

    .isClicked {
      color: #490092;
    }

    .isTransfer {
      cursor: auto;
    }
  }

  .sys-image {
    flex-shrink: 0;
    width: 92px;
    height: 92px;
    overflow: hidden;
    text-align: center;
    border-radius: 50%;
    background-color: #dceeff;
    // background-color: wheat;
    position: relative;
    // margin-bottom: 18px;
    p {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      line-height: 88px;
      font-size: 45px;
      font-weight: bold;
      color: #fff;
      margin: auto;
    }

    img {
      position: absolute;
      left: -1px;
      right: 0;
      bottom: 0;
      top: 0;
      margin: auto;
    }
  }

  .operate {
    position: absolute;
    height: 100%;
    width: 195px;
    right: 24px;
    bottom: 24px;
    flex-shrink: 0;

    .examine-phone {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;

      .btn {
        width: 90px;
        // width: fit-content;
        cursor: pointer;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        //  color: #ffffff;
        border-radius: 4px;
      }
    }

    .btns {
      position: absolute;
      bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;

      .btn {
        width: 90px;
        cursor: pointer;
        padding: 0 10px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        // color: #ffffff;
        border-radius: 4px;
      }
    }

    .receive {
      background-color: #b8b8b8;
    }

    .no-receive {
      background-color: #ffffff;
      color: #4d7cfe;
      border: 1px solid #4d7cfe;

      &:hover {
        background-color: #759fff;
        color: #fff;
        border: 1px solid #759fff;
      }
    }

    .follow {
      background-color: #4d7cfe;
      color: #ffffff;
      border: 1px solid #4d7cfe;

      &:hover {
        background-color: #759fff;
        border: 1px solid #759fff;
      }
    }

    .not-turn-on {
      color: #4d7cfe;
      border: 1px solid #4d7cfe;
      background-color: #ffffff;

      &:hover {
        color: #ffffff;
        background-color: #759fff;
        border: 1px solid #759fff;
      }
    }

    .qr-code-image {
      top: 32px;
      right: 0px;
      width: 34.5px;
      height: 34.5px;
      cursor: pointer;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      //background-color: #afafaf;
      position: absolute;
    }

    .shareDisabled {
      cursor: not-allowed !important;
    }

    .region {
      position: absolute;
      top: 80px;
      width: 100%;
      color: #8e8e90;
      text-align: right;
    }
  }
}
</style>
