<template>
  <div class="side-tool">
    <!-- <a-popover title="" placement="left">
            <template slot="content">
                <img :src="OfficialAccounts" width="120" />
                <div style="width: 120px;text-align:center">欢迎关注</div>
            </template>
            <a-icon type="qrcode" class="item-icon" @click="showOfficalQrCode" />
            公众号
        </a-popover> -->

    <!-- 活动期间展示 -->
    <div   v-if="notAdv&&showSpringFrame"
          class="level-btn-box" >
      <div
          class="level-btn"
          :style="'background-image:url(' + levelBtn + ')'"
          @click="showSellModal"
      >
        <span class="surplusTime">倒计时：{{sidebarTime}}</span>

      </div>
    </div>

    <!-- <div v-if="!true" @click="handleActivityModalShow">
      <img src="@/assets/activity/activity.png" width="100%" alt="" />
    </div> -->
    <!-- 非活动期间展示 -->
    <a-popover title="" placement="left" v-else>
      <template slot="content">
        <div class="service-content">
          <img :src="pcApplet" width="120" />
        </div>
      </template>
      <div class="tool-item">
        <img :src="icApplet" alt="小牛建讯" @click="showServerInfo" />
        小程序
      </div>
    </a-popover>

    <div @click="goToCustomerServer" class="tool-item">
      <img :src="customers" width="26" alt="小牛建讯" @click="showServerInfo" />
      在线客服
    </div>
    <div class="tool-item">
      <img :src="download" width="26" alt="小牛建讯" @click="goToDownload" />
      下载
    </div>
    <a-popover title="" placement="left">
      <template slot="content">
        <div class="service-content">
          <div>客服微信：</div>
          <img :src="buttomQr" width="120" />
          <div>客服电话:13438870357</div>
        </div>
      </template>
      <div class="tool-item have-radius">
        <img :src="qrCode" width="26" alt="小牛建讯" @click="showServerInfo" />
        扫一扫
      </div>
    </a-popover>
    <div class="tool-item last-item" @click="goBackTop">
      <img :src="backTop" width="26" alt="小牛建讯" />
    </div>
    <div class="level-up-mask" @click.stop="closeLevelUp" v-if="levelUpShow">
      <a-icon
        type="close-circle"
        class="close-icon"
        @click.stop="closeLevelUp"
      />
      <level-up @click.stop></level-up>
    </div>
  </div>
</template>

<script>
import backTop from '@/assets/sideTool/backtop.png'
import customers from '@/assets/sideTool/customers.png'
import download from '@/assets/sideTool/download.png'
import buttomQr from '@/assets/contactUs/service.png'
import qrCode from '@/assets/sideTool/officialaccount.png'
import icApplet from '@/assets/sideTool/ic_applet.png'
import pcApplet from '@/assets/download/pc_applet.png'
import {mapActions, mapMutations, mapState} from 'vuex'
import levelUp from '@/components/levelUp.vue'
import levelBtn from '@/assets/levelUp/level_btn.png'

export default {
  name: 'side-tool',
  computed: {
    ...mapState(['sidebarTime','showSpringFrame']),
  },
  components: {
    levelUp
  },
  props: {
    notAdv: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      timer:'',
      backTop,
      customers,
      download,
      buttomQr,
      icApplet,
      pcApplet,
      qrCode,
      levelUpShow: false,
      levelBtn
    }
  },
  methods: {
    ...mapMutations(['setShowActivityModal']),
    ...mapActions(['getEndTime']),

    showSellModal() {
      this.levelUpShow = true
    },
    closeLevelUp() {
      this.levelUpShow = false
    },
    goToDownload() {
      this.$router.push({
        name: 'Download'
      })
    },
    showOfficalQrCode() {},
    showServerInfo() {},
    // 点击活动浮窗打开弹窗
    handleActivityModalShow() {
      this.setShowActivityModal(true)
    },
    goBackTop() {
      this.$emit('goBackTop')
    },
    goToCustomerServer() {
      // window.open(
      //   'https://p.qiao.baidu.com/cps/chat?siteId=17118623&userId=34142684&siteToken=5089577a41231c994f79804432e8cfc7'
      // )
      window.open(
        'https://tb.53kf.com/code/client/08d52c58415dc88c23af5972ad0a6eb98/1'
      )
    }
  },
  created(){
    this.timer = setInterval(this.getEndTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);

  }
}
</script>
<style lang="scss">
.service-content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>

<style lang="scss" scoped>
.side-tool {
  position: fixed;
  right: 0;
  bottom: 15vh;
  // width: 56px;
  border-top: none;
  // height: 370px;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  // padding-top: 30px;
  z-index: 555;
  font-size: 12px;
  color: #fff;
  .level-btn-box{
    width: 132px;
    height: 150px;
    cursor: pointer;
    margin-bottom: 25px;
    position: absolute;
    top: -150px;
    right: 15px;

    .level-btn {
      background-size: cover;
      width: 146px;
      height: 119px;
      position: relative;

    }
    .surplusTime{
      position: absolute;
      bottom: 11px;
      left: 17px;
      font-size: 12px;
      font-weight: bold;
      color: #EB1100;

    }

  }


  .tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    padding: 8px;
    background-color: #00ab94;
    img {
      display: block;
      margin-right: 0;
    }

    // &:last-child {
    //   background-color: #2474e5;
    //   padding: 20px 0 10px 0;
    //   box-shadow: 0px 0px 13px 0px #1f6ce2;
    // }

    // .item-icon {
    // width: 70px;
    // font-size: 37px;
    // // width: 70px;
    // margin-top: 10px;
    // display: block;
    // margin-bottom: 5px;
    // color: #fff;
    // cursor: pointer;
    // }
    // color: #232323;

    // height: 100px;
  }
  .have-radius {
    border-radius: 0px 0px 0px 4px;
  }
  .last-item {
    margin-top: 8px;
    padding: 16px;
    border-radius: 4px 0px 0px 4px;
  }

  .tool-item:first-child {
    border-radius: 4px 0px 0px 0px;
  }
}
.level-up-mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1002;
  background-color: rgba($color: #000000, $alpha: 0.64);
  display: flex;
  justify-content: center;
  align-items: center;
  .close-icon {
    position: absolute;
    right: 40px;
    top: 20px;
    font-size: 35px;
    color: #fff;
  }
}
</style>
