<template>
  <div class="risk-info">
    <div class="info-item">
      <div class="sub-title">
        <span style="color:#dc0000" v-if="riskLevel === 3">
          失信被执行({{ breakFaithTd.length }})
        </span>
        <span v-else> 失信被执行({{ breakFaithTd.length }})</span>
      </div>
      <div class="content">
        <customer-table
          blockText="失信被执行"
          :th="breakFaithTh"
          :td="breakFaithTd"
          :enterpriseId="enterpriseId"
        ></customer-table>
      </div>
      <div class="tips">数据来源：该数据源于中国执行信息公开网</div>
    </div>

    <div class="info-item">
      <div class="sub-title">
        <span style="color:#ff8800" v-if="riskLevel === 2">
          被执行人信息({{ executionTd.length }})
        </span>
        <span v-else> 被执行人信息({{ executionTd.length }})</span>
      </div>
      <div class="content">
        <customer-table
          blockText="被执行人信息"
          :th="executionTh"
          :td="executionTd"
        ></customer-table>
      </div>
      <div class="tips">数据来源：该数据源于中国执行信息公开网</div>
    </div>

    <div class="info-item">
      <div class="sub-title">工商异常({{ businessErrorTd.length }})</div>
      <div class="content">
        <customer-table
          blockText="工商异常"
          :th="businessErrorTh"
          :td="businessErrorTd"
        ></customer-table>
      </div>
      <div class="tips">数据来源：该数据源于国家企业信用信息公示系统</div>
    </div>

    <!-- <div class="info-item">
      <div class="sub-title">裁判文书({{ refereeTd.length }})</div>
      <div class="content">
        <customer-table
          blockText="裁判文书"
          :th="refereeTh"
          :td="refereeTd"
        ></customer-table>
      </div>
      <div class="tips">数据来源：该数据源于国家企业信用信息公示系统</div>

    </div> -->
    <div class="info-item">
      <div class="sub-title">行政处罚({{ penaltiesTd.length }})</div>
      <div class="content">
        <customer-table
          blockText="行政处罚"
          :th="penaltiesTh"
          :td="penaltiesTd"
        ></customer-table>
      </div>
      <div class="tips">数据来源：该数据源于国家企业信用信息公示系统</div>
    </div>
  </div>
</template>
<script>
import CustomerTable from '@/components/CustomerTable.vue'
import brakeFaithDetail from './brakeFaithDetail.vue'

export default {
  components: {
    CustomerTable,
    brakeFaithDetail
  },
  props: {
    enterpriseId: {},
    riskLevel: {}
  },
  computed: {
    defaultParams() {
      return {
        enterpriseId: this.enterpriseId,
        rows: 200,
        page: 1
      }
    }
  },
  data() {
    return {
      // 工商异常
      businessErrorTh: [
        { title: '序号', key: 'index', width: '40' },
        { title: '被列入日期', key: 'includedDate', width: '80' },
        {
          title: '列入经营异常名录原因',
          key: 'inExceptionReason',
          width: '140'
        },
        { title: '列入决定机关', key: 'inDecisionOffice', width: '110' },
        { title: '移除日期', key: 'removeDate', width: '70' },
        { title: '移除经营异常名单原因', key: 'removeExceptionReason' },
        { title: '移出决定机关', key: 'removeDecisionOffice', width: '110' }
      ],

      businessErrorTd: [],
      // 失信
      breakFaithTh: [
        { title: '序号', key: 'index', width: '40' },
        { title: '案号', key: 'caseNumber' },
        { title: '执行法院', key: 'court' },
        // { title: '执行依据文号', key: 'caseNumber' },
        { title: '履行情况', key: 'performance' },
        { title: '立案日期', key: 'filingDate', width: '70' },
        // { title: 'id', key: 'id', width: '70' },
        { title: '操作', key: 'operate', slot: brakeFaithDetail }
      ],
      breakFaithTd: [],
      // 执行人
      executionTh: [
        { title: '序号', key: 'index', width: '40' },
        { title: '案号', key: 'caseNumber' },
        { title: '立案日期', key: 'createCaseDate', width: '70' },
        { title: '执行法院', key: 'executionCourt' },
        { title: '执行标的(元)', key: 'executionTarget' }
        // { title: '案件身份', key: 'courtName' },
      ],
      executionTd: [],
      // 裁判文书
      refereeTh: [
        { title: '序号', key: 'index', width: '40' },
        { title: '裁判文书', key: 'judgmentTitle' },
        { title: '案由', key: 'causeAction' },
        { title: '发布日期', key: 'releaseTime', width: '70' },
        { title: '案号', key: 'caseNo' },
        { title: '案件身份', key: 'caseStatus' },
        { title: '法院名称', key: 'courtName' }
      ],
      refereeTd: [],
      // 行政处罚
      penaltiesTh: [
        { title: '序号', key: 'index', width: '40' },
        { title: '公示日期', key: 'publicityDate', width: '70' },
        { title: '决定文书号', key: 'decisionDocumentNo' },
        { title: '行政处罚内容', key: 'administrativePunishment' },
        { title: '违法行为类型', key: 'violationsType' },
        { title: '决定日期', key: 'decisionDate', width: '70' },
        { title: '决定机关', key: 'decisionOffice' }
      ],
      penaltiesTd: []
    }
  },
  methods: {
    getBusinessErrorList() {
      this.$http
        .get(
          'business/riskinformation/queryBusinessExceptionByPage',
          this.defaultParams
        )
        .then(r => {
          //   console.log(r)
          this.businessErrorTd = r.businessExceptionVO.list
        })
    },
    //  企业失信
    getBreakFaithList() {
      this.$http
        .get(
          'business/riskinformation/queryBreakfaithInfoByPage',
          this.defaultParams
        )
        .then(r => {
          //   console.log('失信list', r)
          this.breakFaithTd = r.records
        })
    },
    //被执行人
    getExecutionPeople() {
      this.$http
        .get(
          'business/riskinformation/queryExecutedPersonByPage',
          this.defaultParams
        )
        .then(r => {
          this.executionTd = r.records
        })
    },
    getRefereeList() {
      this.$http
        .get(
          'business/riskinformation/queryJudgmentDocumentByPage',
          this.defaultParams
        )
        .then(r => {
          this.refereeTd = r.records
        })
    },
    getPenalties() {
      this.$http
        .get(
          'business/riskinformation/queryPoliticsPenalizeByPage',
          this.defaultParams
        )
        .then(r => {
          this.penaltiesTd = r.records
        })
    }
  },
  mounted() {
    this.$nextTick(() => {
      // console.log(this.enterpriseId)
      this.getBusinessErrorList() // 工商异常
      this.getBreakFaithList() //  企业失信
      this.getExecutionPeople() // 被执行人
      this.getRefereeList() // 裁判文书
      this.getPenalties() // 行政处罚
    })
  }
}
</script>

<style lang="scss" scoped>
.risk-info {
  .info-item {
    margin-bottom: 50px;
    .sub-title {
      font-size: 18px;
      color: #2a7be7;
      width: 100%;
      text-align: center;
      font-weight: 600;
      height: 60px;
      line-height: 60px;
      border: #e3e3e3 solid 1px;
      border-bottom: transparent;
      background-color: rgba(#eeeeee, 0.3);
    }
    .content {
      //  margin-top: 16px;
    }
    .block-table {
      height: 36px;
      text-align: center;
      font-size: 13px;
      color: #606366;
      line-height: 36px;
      border: #e3e3e3 solid 1px;
    }
    .content-table {
      width: 100%;
      border: #e3e3e3 solid 1px;
    }
  }
}
</style>
