<template>
    <div class="editable-cell">
        <div v-if="editable" class="editable-cell-input-wrapper">
            <!-- <a-input :value="value" @change="handleChange" @pressEnter="check" /> -->

            <a-select
                v-model="value"
                size="small"
                class="cell-select"
                :autoFocus="true"
                @change="handleChange"
                @blur="check"
                @pressEnter="check"
            >
                <a-select-option
                    v-for="item in statusList"
                    :key="item.value"
                    :disabled="item.disabled"
                    :title="item.text"
                    >{{ item.text }}</a-select-option
                >
            </a-select>

            <!-- <a-icon type="check" class="editable-cell-icon-check" @click="check" /> -->
        </div>
        <div
            v-else
            @click="edit"
            :class="{
                yellow: text === cssJudge.yellow,
                green: text === cssJudge.green,
                blue: text === cssJudge.blue
            }"
            class="status-tag"
        >
            {{ displayText || ' ' }}
            <!-- <a-icon type="edit" class="editable-cell-icon" @click="edit" /> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'edit-cell',
    props: {
        text: {
            type: String,
            default: ''
        },
        displayText: {
            type: String,
            default: ''
        },
        isCanEdit: {
            type: Boolean,
            default: true
        },
        cssJudge: {
            type: Object,
            default() {
                return {
                    green: '0',
                    yellow: '1',
                    blue: '2'
                }
            }
        },
        statusList: {
            type: Array,
            default() {
                return [
                    { value: '0', text: '未处理' },
                    { value: '1', text: '已处理' }
                ]
            }
        }
    },
    data() {
        return {
            editable: false,
            value: this.text
        }
    },
    methods: {
        handleChange(e) {
            // const value = e.target.value;
            // this.value = value;
            // console.log('handleChange')
        },
        check() {
            this.editable = false
            if (this.value === this.text) {
                //console.log('选择未改变')
                return
            } else {
                this.$emit('change', this.value)
            }
        },
        edit() {
            if (!this.isCanEdit) {
                return
            }
            this.value = this.text
            // console.log(this.statusList)
            this.editable = true
        }
    }
}
</script>

<style lang="scss" scoped>
.editable-cell {
    position: relative;
    width: 100px;
}

.cell-select {
    width: 80px;
    margin-right: 10px;
}
.yellow {
    color: #fa8f00;
    background-color: rgba($color: #fa8f00, $alpha: 0.1);
}
.green {
    color: #67c23a;
    background-color: rgba($color: #67c23a, $alpha: 0.1);
}
.blue {
    color: #4d7cfe;
    background-color: rgba($color: #4d7cfe, $alpha: 0.1);
}
.editable-cell-input-wrapper {
    padding-right: 24px;
    height: 28px;
}

.status-tag {
    width: 55px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    font-size: 13px;
    border-radius: 4px;
    cursor: auto;
    margin: 0;
}

.editable-cell-icon,
.editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
}

.editable-cell-icon {
    line-height: 18px;
    display: none;
}

.editable-cell-icon-check {
    line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
    display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
    color: #108ee9;
}

.editable-add-btn {
    margin-bottom: 8px;
}
</style>
