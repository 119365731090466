import { render, staticRenderFns } from "./receipt.vue?vue&type=template&id=f642b562&scoped=true"
import script from "./receipt.vue?vue&type=script&lang=js"
export * from "./receipt.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("../../../utils/table.scss?vue&type=style&index=0&prod&lang=scss&external")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./receipt.vue?vue&type=style&index=1&id=f642b562&prod&lang=scss&scoped=true")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f642b562",
  "13d804f4"
  
)

export default component.exports