<template>
    <div class="helpCenter">
        <!-- 默认内容 -->
        <ul class="w1200 helpCenter-content">
            <!-- 公示公告 -->
            <li class="content-item">
                <h2>公示公告</h2>
                <div class="item-content" v-if="playList.length && playList.length > 0">
                    <router-link tag="div" :to="{ path: '/details/' + playList[0].dynamicId }" class="content-left-box">
                        <img :src="playList.length && playList[0].homepagePic" alt="小牛建讯" >
                        <p class="textHide">{{ playList.length && playList[0].title || '' }}</p>
                    </router-link>
                    <div class="content-right">
                        <router-link tag="div" :to="{ path: '/details/' + playList[1].dynamicId }" class="right-item">
                            <img class="right-item-img" :src="playList.length && playList[1].homepagePic" alt="小牛建讯">
                            <div class="right-item-title">
                                {{ playList.length && playList[1].title || '' }}
                            </div>
                        </router-link>
                        <router-link tag="div" :to="{ path: '/details/' + playList[2].dynamicId }" class="right-item">
                            <img class="right-item-img" :src="playList.length && playList[2].homepagePic" alt="小牛建讯">
                            <div class="right-item-title">
                                {{ playList.length && playList[2].title || '' }}
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="item-more" @mouseenter="enter()" @mouseleave="leave()" @click="handleLookMore({ type: 1 })">
                    查看更多
                    <img v-if="isActive" :src="icArrowMoreGreen" alt="查看更多">
                    <img v-else :src="icArrowMoreBlack" alt="查看更多">
                </div>
            </li>

            <!-- 行业资讯 -->
            <li class="content-item">
                <h2>行业资讯</h2>
                <div class="item-content2">
                    <ul class="content-list">
                        <router-link tag="li" :to="{ path: '/details/' + item.dynamicId }" v-for="(item, index) in infoList" :key="index">
                            <h3 class="textHide"><span>{{ index + 1 }}. </span>{{ item.title || '' }}</h3>
                            <p class="textHide">{{ item.description || '' }}</p>
                        </router-link>
                    </ul>

                    <swiper ref="mySwiper" :options="swiperOptions" class="content-img">
                        <swiper-slide v-for="(item, index) in infoList" :key="index">
                            <router-link tag="div" :to="{ path: '/details/' + item.dynamicId }" >
                                <img
                                    :src="item.homepagePic"
                                    :alt="item.title"
                                />
                                <p class="textHide">{{ item.title || '' }}</p>
                            </router-link>
                        </swiper-slide>
                        <div class="swiper-pagination" slot="pagination"></div>
                    </swiper>
                </div>
                <div class="item-more" @mouseenter="enter()" @mouseleave="leave()" @click="handleLookMore({ type: 2 })">
                    查看更多行业资讯
                    <img v-if="isActive" :src="icArrowMoreGreen" alt="查看更多行业资讯">
                    <img v-else :src="icArrowMoreBlack" alt="查看更多行业资讯">
                </div>
            </li>

            <!-- 资质问答 -->
            <li class="content-item">
                <h2>资质问答</h2>
                <ul class="item-content3">
                    <router-link tag="li" :to="{ path: '/details/' + item.dynamicId }" class="item" v-for="(item, index) in faqList" :key="index">
                        <img :src="item.homepagePic" :alt="item.title">
                        <div>
                            <h3 class="textHide"><span></span>{{ item.title || '' }}</h3>
                            <p class="textHide_line2">{{ item.description || '' }}</p>
                        </div>
                    </router-link>
                </ul>
                <div class="item-more" @mouseenter="enter()" @mouseleave="leave()" @click="handleLookMore({ type: 3 })">
                    查看更多资质问答
                    <img v-if="isActive" :src="icArrowMoreGreen" alt="查看更多资质问答">
                    <img v-else :src="icArrowMoreBlack" alt="查看更多资质问答">
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import icArrowMoreBlack from '@/assets/helpCenter/ic_arrow_more_black.png'
import icArrowMoreGreen from '@/assets/helpCenter/ic_arrow_more_green.png'
import pcNodata from '@/assets/helpCenter/pc_nodata.png'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name: 'HelpCenter',
    components: {
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    data(){
        return {
            isActive: false,
            icArrowMoreBlack,
            icArrowMoreGreen,
            pcNodata,

            searchValue: '',  // 搜索内容

            swiperOptions: {
                pagination: {
                    el: '.swiper-pagination'
                },
            },

            playList: [],  // 公示公告

            infoList: [],  // 行业资讯

            faqList: [],  // 资质问答

            // 搜索结果
            resultList: [
                {
                    title: '资质办理咨询的转让合同签订小张要注意什么？',
                    description: `
                        现在很多企业都会因为一些原因进行资质的转让，但是签订协议的时候还是需要谨慎的
                        但是签订协议的时候还是需要谨慎的但是签订协议的时候傻子还是现在很多企业都会因为一些原因进行资质的转
                        让，但是签订协议的时候还是需要谨慎的但是签订协议小猪的时候还是需要谨慎的但是签订协议的时候还是但是
                        签订协议的时候还是需要谨慎的但是签订协议的时候还是但是签订协议的时资质的转让，但是签订协议的时
                        候还是需要谨慎的但是签订协议的时候还是需要谨慎的但是签订协议的时候还是但是签订协议的时候还是需
                        要谨慎的但是签订协议的时候还是但是签订协议的时
                    `
                },
                {
                    title: '傻子资质办理咨询的转让合傻子同签订要注意什么？',
                    description: `
                        现在很多企业都会因为一些原傻子因进行资质小狗的转让，但是签订协议的时候还是需要谨慎的
                        但是签订协议的时候还是需要谨慎的但是签订协议的时候还是现在很多企业都会因为一些原因进行资质的转
                        让，但是签订协议的时候还是需要谨慎的但是傻子签订协议的时候还是需要谨慎的但是签订协议的时候还是但是
                        签订协议的时候还是需要傻子谨慎的但是签订协议的时候还是但是签订协议的时资质的转让，但是签订协议的时
                        候还是需要谨慎的但是签订协议的时候还是需要谨慎的但是签订协议的时候还是但是签订协议的时候还是需
                        要谨慎的但是签订协议的时候还是但是签订协议的时傻子
                    `
                },
            ]
        }
    },
    created(){
        this.getSelList('PLAY', 3)
        this.getSelList('INFO', 3)
        this.getSelList('FAQ', 6)
    },
    methods: {
        // 鼠标移入
        enter(){
            this.isActive = true
        },
        // 鼠标移出
        leave(){
            this.isActive = false
        },

        // 获取需要的信息
        getSelList(dynamicType, size){
            // dynamicType => PLAY,INFO,FAQ
            // PLAY => 公示公告
            // INFO => 行业资讯
            // FAQ => 资质问答

            //size => 获取指定数量的数据

            const params = {
                current: 1,
                dynamicTypeEnum: dynamicType,
                size: size
            }
            this.$https.post('dynamic/selList', params).then(res => {
                if (dynamicType === 'PLAY') {

                    this.playList = res.list
                } else  if (dynamicType === 'INFO') {

                    this.infoList = res.list
                } else if (dynamicType === 'FAQ') {

                    this.faqList = res.list
                }
            })
        },

        // 查看更多
        handleLookMore({ type }){
            this.$router.push({ 
                path: `/dynamic/${ type }`
            })
        },
    }
}
</script>

<style lang="scss">
.w1200{
    width: 1200px;
    margin: 0 auto;
}
.textHide{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 1; //显示的行
}

.textHide_line2{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
}

.helpCenter{
    width: 100%;
    //margin-top: 80px;

    .helpCenter-content{
        margin-bottom: 80px;
        .content-item{
            h2{
                margin: 80px 0 56px;
                text-align: center;
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #181818;
            }
            
            .item-content{
                height: 330px;
                display: flex;
                justify-content: space-between;

                .content-left-box{
                    width: 584px;
                    position: relative;
                    cursor: pointer;

                    img{
                        width: 584px;
                        height: 100%;
                        border-radius: 4px;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }

                    p{
                        width: 536px;
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        position: absolute;
                        left: 24px;
                        bottom: 16px;
                        margin: 0;
                    }
                }

                .content-right{
                    width: 584px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .right-item{
                        height: 149px;
                        border-radius: 4px;
                        border: 1px solid #E6E9F0;
                        display: flex;
                        border-radius: 4px;
                        overflow: hidden;
                        cursor: pointer;

                        .right-item-img{
                            width: 265px;
                            height: 100%;
                        }

                        .right-item-title{
                            margin: 16px 24px;
                            flex: 1;
                            font-size: 20px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #181818;
                        }
                    }
                }
            }

            .item-content2{
                height: 284px;
                display: flex;
                justify-content: space-between;

                .content-list{
                    width: 607px;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    li{
                        width: 100%;
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        cursor: pointer;

                        h3{
                            font-size: 20px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #181818;
                        }

                        p{
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #585858;
                            margin: 0;
                        }
                    }
                }

                .content-list li:nth-child(1) span{
                    color: #F30C0C;
                }

                .content-list li:nth-child(2) span{
                    color: #FF7F28;
                }

                .content-list li:nth-child(3) span{
                    color: #00BC87;
                }

                .content-list li:nth-child(2){
                    width: 100%;
                    height: 110px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-top: 1px solid #E6E9F0;
                    border-bottom: 1px solid #E6E9F0;
                }

                .content-img{
                    width: 504px;
                    height: 284px;
                    border-radius: 4px;
                    overflow: hidden;
                    position: relative;
                    cursor: pointer;
                    
                    img{
                        width: 504px;
                        height: 284px;
                    }

                    p{
                        width: 504px;
                        line-height: 50px;
                        position: absolute;
                        bottom: -10px;
                        z-index: 1;
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #FFFFFF;
                        margin: 0;
                        padding: 24px;
                    }
                }

                .swiper-container{
                    margin-right: 0;
                }

                .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
                    background: #FFFFFF;
                }
            }

            .item-content3{
                display: grid;
                justify-content: space-between;
                grid-row-gap: 34px;
                grid-template-columns: repeat(3, 378px);

                .item{
                    width: 378px;
                    height: 334px;
                    border-radius: 4px;
                    border: 1px solid #E6E9F0;
                    overflow: hidden;

                    img{
                        width: 100%;
                        height: 213px;
                    }

                    div{
                        padding: 20px 24px;

                        h2{
                            font-size: 18px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #181818;
                        }

                        p{
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #585858;
                        }
                    }
                }
            }

            .item-content3 li:hover{
                box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
                border: 1px solid #00AB94 ;
                cursor: pointer;
                transform: translateY(-2px);
            }

            .item-more{
                height: 64px;
                background: #FFFFFF;
                border-radius: 4px;
                border: 1px solid #E6E9F0;
                text-align: center;
                line-height: 64px;
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #181818;
                margin: 32px 0 0;

                img{
                    width: 20px;
                    height: 20px;
                    transform: translateY(-1px);
                }
            }

            .item-more:hover{
                color: #00AB94;
                border: 1px solid #00AB94;
                cursor: pointer;
            }
        }
    }
}
</style>