<template>
  <div class="shared-page">
    <div class="shared-filter" id="sharedFilter">
      <div class="filter-item">
        <a-breadcrumb>
          <a-breadcrumb-item><a @click="goBack">资质社区</a></a-breadcrumb-item>
          <a-breadcrumb-item> 我要买资质</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="filter-item">
        <div class="filter-label">区域：</div>
        <a-select
          :getPopupContainer="getPopupContainer"
          allow-clear
          @change="selectArea"
          style="width: 305px"
          v-model="area"
          placeholder="请选择查询区域"
        >
          <a-option
            v-for="item in areaOptions"
            :key="item.value"
            :value="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</a-option
          >
        </a-select>
      </div>
      <div class="filter-item">
        <div class="filter-label">资质：</div>
        <tree-checkbox-single-select
          v-model="qualifications"
          class="auto-width"
          containerId="sharedFilter"
          :designQualificationOptions="designQualificationOptions"
          :fakeQualification="fakeQualification"
          :qualificationOptions="qualificationOptions"
        ></tree-checkbox-single-select>
      </div>
      <div class="filter-item">
        <div class="filter-label">资质状态：</div>
        <div>
          <a-checkbox
            @change="selectQualificationStatus(0)"
            :checked="qualificationStatus === 0"
            >现货</a-checkbox
          >
          <a-checkbox
            @change="selectQualificationStatus(1)"
            :checked="qualificationStatus === 1"
            >预售</a-checkbox
          >
          <!-- </a-checkbox-group> -->
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-label">更多特性：</div>
        <div>
          <a-checkbox-group v-model="other">
            <a-checkbox
              :value="item.value"
              v-for="(item, key) in otherOptions"
              :key="key"
            >
              {{ item.label }}
            </a-checkbox>
          </a-checkbox-group>
        </div>
      </div>
      <div class="filter-item">
        <a-button
          size="large"
          class="large-button"
          @click="clickFindBtn"
          type="primary"
          >查询</a-button
        >
        <a-button
          size="large"
          class="large-button"
          @click="e => reSetFilter(false)"
          >重置</a-button
        >
        <a-button
          size="large"
          @click="goToSellQualification"
          class="large-button rt-abs-button"
          >我要出售资源</a-button
        >
      </div>
    </div>

    <div class="table-content">
      <div class="loading" v-show="dataLoading">
        <a-spin size="large" tip="加载中......" />
      </div>
      <a-tabs v-model="channel" @change="selectChannel">
        <a-tab-pane
          v-for="item in tabOptions"
          :key="item.value"
          :tab="item.label"
        >
        </a-tab-pane>
      </a-tabs>
      <div class="qr-tools">
        <div class="tips buy-tips">
          小牛建讯为您查询出
          <span class="primary-text">{{ total }}</span>
          个符合条件的资质
        </div>
        <div class="sort-tool">
          <div
            class="tools"
            @click="changeSortRule(0)"
            :class="{ selected: sortIndex === 0 }"
          >
            默认排序
          </div>
          <div class="dividing-line"></div>
          <div
            class="tools"
            @click="changeSortRule(1)"
            :class="{ selected: sortIndex === 1 }"
          >
            最新
          </div>
        </div>
      </div>
      <hr class="line" />
      <div v-if="tableData.length" style="min-height:200px">
        <div v-if="tableData[0].qualificationNameAndLevel">
          <table-item
            :freeCount="freeCount"
            v-for="(item, index) in tableData"
            @refreshList="getSharedList"
            :key="index"
            :index="index"
            :row="item"
          ></table-item>
        </div>
        <div class="card-pagination">
          <a-pagination
            v-model="pageNum"
            @change="tableChange"
            @showSizeChange="tableChange"
            :showTotal="paginationShowTotal"
            :showSizeChanger="true"
            :showQuickJumper="true"
            :pageSize.sync="pageSize"
            :total="total"
          ></a-pagination>
          <a-button v-if="total > pageSize" class="fake-btn">确定</a-button>
        </div>
      </div>
      <div class="quickly-buy" v-else>
        <div class="block-title">没有您想要的资质？</div>
        <div class="tips">发布求购后有符合条件的资质上架将短信/消息通知您</div>
        <edit-buy-info
          @submitBuyInfo="submitBuyInfo"
          :isFromTable="true"
        ></edit-buy-info>
      </div>
    </div>

    <qr-modal ref="qr" :url="qrCode"></qr-modal>
    <div
      class="process"
      :style="'background-image:url(' + processBg + ')'"
    ></div>
  </div>
</template>

<script>
import blockData from '@/components/BlockData.vue'
import {
  TreeSelect,
  Drawer,
  Pagination,
  Select,
  Radio,
  Checkbox,
  Tabs,
  Spin
} from 'ant-design-vue'
import qrModal from '@/components/qrCode.vue'
import notFind from '@/assets/image/no_date_block.png'
import TableItem from './TableItem.vue'
import editBuyInfo from '../Buy/Edit.vue'
import processBg from '@/assets/image/qr/process.png'
import TreeCheckboxSingleSelect from '@/components/TreeCheckboxSingleSelect.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'shared',
  components: {
    blockData,
    qrModal,
    aPagination: Pagination,
    aDrawer: Drawer,
    aSpin: Spin,
    TableItem,
    aSelect: Select,
    aCheckbox: Checkbox,
    aCheckboxGroup: Checkbox.Group,
    aRadio: Radio,
    aRadioGroup: Radio.Group,
    aOption: Select.Option,
    aTabs: Tabs,
    aTabPane: Tabs.TabPane,
    aTreeSelect: TreeSelect,
    aTreeSelectNode: TreeSelect.TreeNode,
    editBuyInfo,
    TreeCheckboxSingleSelect
  },
  data() {
    return {
      processBg,
      qualificationStatus: '',
      channel: 0,
      tabOptions: [
        { value: 0, label: '快速发布' },
        { value: 1, label: '小牛优选' },
        { value: 2, label: '平台自营' }
      ],
      sortIndex: 0,
      notFind,
      qrCode: '',
      dataLoading: false,
      otherOptions: [
        { label: '财务纠纷', value: 'isFinanceDispute' },
        { label: '法律纠纷', value: 'isLawDispute' },
        { label: '行政处罚记录', value: 'isPoliticsPenalize' },
        { label: '在建工程', value: 'isBuildingProject' },
        { label: '挂靠人员', value: 'isHangingClose' },
        { label: '安全生产许可证', value: 'isSecurityLicence' },
        { label: '包函', value: 'isNeedLetter' },
        { label: '不可控股', value: 'isHoldingCompany' }
      ],
      other: ['isNeedLetter'],
      companyDetail: {},
      companyDetailDrawerDisplay: false,
      qualifications: undefined,
      defaultExpend: [],
      areaOptions: [],
      areaData: [],
      area: undefined,
      areaCode: [],
      lastFilterSave: null,
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      pageSize: 10,
      pageNum: 1,
      total: 0,
      freeCount: 0,
      tableData: [{}],
      isHaveSelfList: false
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      designQualificationOptions:state => state.designQualificationOptions,
      fakeQualification: state => state.fakeQualificationOptions,
      qualificationOptions: state => state.typeQualificationOptions
    }),
    isAdmin() {
      return this.userInfo.roleLevel === 2
    }
  },
  methods: {
    ...mapMutations(['setAreaOptions']),
    submitBuyInfo() {
      this.$info({ title: '提示', content: '求购信息发布成功' })
    },
    goBack() {
      this.$router.push({
        name: 'Product',
        query: {
          current: 3
        }
      })
    },
    goToSellQualification() {
      this.$router.push({
        name: 'excellentShare',
        query: {
          channel: '1',
          from: this.$route.name
        }
      })
    },
    selectQualificationStatus(status) {
      if (this.qualificationStatus === status) {
        this.qualificationStatus = ''
      } else {
        this.qualificationStatus = status
      }
    },
    selectChannel(channel) {
      this.channel = channel
      this.pageNum = 1
      this.getSharedList()
    },
    changeSortRule(index) {
      this.sortIndex = index
      this.getSharedList()
    },
    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('sharedFilter')
    },
    goToMyCertification() {
      if (this.isHaveSelfList) {
        this.$router.push({
          name: 'resources'
        })
      } else {
        this.$router.push({
          name: 'certification',
          query: {
            isEdit: 1,
            isFromTable: 1
          }
        })
      }
    },
    goToEditCertification() {
      this.$router.push({
        name: 'editBuyingInfo',
        query: {
          isFromShareTable: 1
        }
      })
    },
    tableChange(pageNum, pageSize) {
      this.pageSize = pageSize
      this.pageNum = pageNum
      this.$emit('goBackToTop')
      this.getSharedList()
    },
    paginationShowTotal(total) {
      return ''
      // `共${total}条数据`
    },
    selectQualification(val) {
      // console.log(val)
      // this.getSharedList()
    },
    closeCompanyDetailDrawer() {
      this.companyDetailDrawerDisplay = false
    },
    selectArea(value) {
      this.areaCode = []
      if (value) {
        this.areaData.forEach(e => {
          if (value === e.areaCode) {
            // console.log(e)
            this.areaCode = e.cityList.map(c => {
              return c.areaCode
            })
            this.areaCode.push(value)
          }
        })
      }
    },
    clickFindBtn() {
      this.pageSize = 10
      this.pageNum = 1
      this.getSharedList()
    },
    notFilter() {
      return false
    },
    reSetFilter() {
      this.areaCode = []
      this.area = undefined
      this.other = ['isNeedLetter']
      this.qualifications = undefined
      this.qualificationStatus = ''
      // console.log('reset')
    },
    getAreaOptions() {
      this.$http.get('regionCode/selList').then(r => {
        this.areaOptions = []
        this.areaData = r
        const gangAoTai = [810000, 820000, 710000]
        // 去除港澳台
        r.forEach(option => {
          if (gangAoTai.includes(option.areaCode)) {
            this.areaOptions.push({
              label: option.areaName,
              value: option.areaCode,
              disabled: true
            })
          } else {
            this.areaOptions.push({
              label: option.areaName,
              value: option.areaCode,
              disabled: false
            })
          }
        })
        this.setAreaOptions(this.areaOptions)
        const shareQua = localStorage.getItem('shareQua')
        const localChannel = localStorage.getItem('channel')
        if (localChannel) {
          this.qualifications = undefined
          this.area = undefined
          this.channel = parseInt(localChannel, 10)
          localStorage.removeItem('channel')
          this.getSharedList()
        } else if (shareQua) {
          const localQua = JSON.parse(shareQua).value
          this.$nextTick(() => {
            this.qualifications = parseInt(localQua, 10)
            localStorage.removeItem('shareQua')
            this.getSharedList()
          })
        } else {
          this.getSharedList()
        }
      })
    },
    getSharedList() {
      this.dataLoading = true
      const other = {}
      this.other.forEach(e => {
        other[e] = true
      })
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...other,
        sortType: this.sortIndex,
        areaCodes: this.areaCode,
        channel: this.channel,
        companyId: this.userInfo.companyId,
        quelificationStatus: this.qualificationStatus,
        qualification: this.qualifications ? [this.qualifications] : []
      }
      this.$http.post('shared/query', params).then(res => {
        this.tableData = res.data
        res.data.forEach((r, i) => {
          r.number = i + 1
          r.qualificationsList = ['']
          r.qualificationsText = r.enterpriseName
          r.establishmentDate = 'no'
          r.companyNameHTML = r.qualificationName

          r.specialText = ' '
          r.notShowQrcode = true
          r.haveReceive = r.checkState === 1

          r.phoneNumDisplay =
            '<span ondblclick="copyThisText(' +
            r.contactsPhone +
            ')" >' +
            r.contactsPhone +
            '</span>'

          r.riskLevel = -1
          r.updateTime = this.$day(r.updateTime).format('YYYY-MM-DD')
          r.releaseTime = this.$day(r.releaseTime).format('YYYY-MM-DD')
        })
        this.dataLoading = false
        this.getFreeQueryCount()
        this.total = res.totalSize || 0
      })
    },
    getFreeQueryCount() {
      this.$http.get('free/count/querySharedCheckCount').then(res => {
        this.freeCount = res ? res.freeCount : 0
      })
    },
    getSelfSharedList() {
      const params = {
        currentPage: 1,
        pageSize: 1
      }
      this.$http
        .get('shared/list', params, 'application/x-www-form-urlencoded')
        .then(res => {
          this.isHaveSelfList = res ? !!res.totalSize : false
        })
    }
  },
  mounted() {
    if (this.isAdmin) {
      this.getSelfSharedList()
    }
    this.getAreaOptions()

    this.$nextTick(() => {
      this.$emit('goBackToTop')
    })
  }
}
</script>

<style lang="scss" scoped>
.shared-page {
  width: 1200px;
  overflow: hidden;
  .line {
    width: 100%;
    margin-top: 15px;
    border-right: none;
    border-left: none;
    border-bottom: 0;
    margin-bottom: 0;
    height: 1px;
    border-top: solid 1px #f2f2f2;
  }
  .shared-filter {
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 25px;
    position: relative;
    z-index: 1000;
    .search-title {
      color: #909399;
      font-size: 16px;
    }
    .advanced-btns {
      margin-left: 25px;
      position: relative;
      // position: absolute;
      right: 25px;
    }
    .filter-item {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      align-items: center;
      flex-shrink: 0;
      position: relative;
      .filter-label {
        font-size: 14px;
        color: #666666;
        width: 80px;
      }
      .long-label {
        width: 100px;
      }
      .filter-search {
        width: 306px;
        margin-right: 24px;
      }
      .auto-width {
        flex-grow: 0;
        margin-right: 24px;
        min-width: 306px;
        //  max-width: calc(100% - 330px);
      }
      .short-filter {
        width: 266px;
      }
      .number-count {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .have-margin {
          margin: 0 10px;
        }
      }
      .type-radio {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        width: 180px;
        .group {
          margin-right: 5px;
        }
        .radio-tips {
          margin-bottom: 0;
          font-size: 13px;
          color: #909399;
        }
      }
      .large-button {
        width: 126px;
        margin-right: 16px;
      }
      .rt-abs-button {
        position: absolute;
        right: 0px;
      }
    }

    .collapse-expend {
      position: absolute;
      font-size: 14px;
      cursor: pointer;
      right: 25px;
      bottom: 10px;
      color: #4d7cfe;
    }
  }

  .table-content {
    // padding: 16px 24px;
    margin-top: 24px;
    background-color: #ffffff;
    border-radius: 4px;
    .free-count-info {
      margin-top: 10px;
      // margin-top: 12px;
      padding: 10px 21px;
      border: solid 1px #f2f2f2;
      background-color: #f2f2f2;
      position: relative;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-btn {
        position: absolute;
        right: 21px;
      }
    }
  }
  .qr-tools {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    justify-content: space-between;
    .sort-tool {
      position: absolute;
      top: -50px;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 110px;
      .dividing-line {
        height: 15px;
        background-color: #999999;
        width: 1px;
      }
      .tools {
        cursor: pointer;
        &:hover {
          color: #4d7cfe;
        }
      }
      .selected {
        color: #4d7cfe;
      }
    }
  }
  .quickly-buy {
    width: 700px;
    left: 0;
    right: 0;
    margin: auto;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 4px;
    .block-title {
      font-size: 19px;
      color: #333;
      margin-top: 20px;
      margin-bottom: 5px;
      // margin-top: 80px;
      text-align: center;
      font-weight: bold;
    }
    .tips {
      text-align: center;
    }
  }
  .card-pagination {
    width: 100%;
    // text-align: right;
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .fake-btn {
      margin-left: 8px;
    }
  }
  .process {
    width: 100%;
    background-size: cover;
    height: 148px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
