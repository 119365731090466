<template>
    <div class="helpCenter">
        <div class="helpCenter-header"
             style="background-image:url('http://cdn.public.xnjx666.com//upload/gw/static/img/bg_explore.png')"
        >
            <div class="w1200">
                <h2>在这里，了解小牛建讯</h2>
                <div class="search">
                    <input type="text" placeholder="在本站搜索" v-model="searchValue" @keyup.enter="handleSearch()" >
                    <button @click="handleSearch()">搜索</button>
                </div>
                <div class="hot-box">
                    <p>热门搜索：</p>
                    <ul class="hot-list">
                        <li v-for="(item, index) in hotList" :key="index" @click="handleHotItem(item)">{{ item }}</li>
                    </ul>
                </div>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'HelpCenter',
    data(){
        return {
            searchValue: '',  // 搜索内容
            // 热门搜索
            hotList: [],
        }
    },
    created(){
        this.getHotList()
    },

    methods: {
        // 搜索
        handleSearch(){
            if (!this.searchValue.trim()) return this.$message.warning('请输入查询内容', 0.5)

            this.$router.push({ 
                path: '/HelpCenter/result', 
                query: {
                    searchValue: this.searchValue
                }
            })
        },

        // 获取热点搜索
        getHotList(){
            this.$http.post('DynamicSearchKey/getTop').then(res => {
                this.hotList = res
            })
        },

        // 点击热门标签
        handleHotItem(item){
            console.log(item, 'item')
            this.searchValue = item
            this.handleSearch()
        },
    }
}
</script>

<style lang="scss">
.w1200{
    width: 1200px;
    margin: 0 auto;
}
.textHide{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 1; //显示的行
}

.textHide_line2{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box; //作为弹性伸缩盒子模型显示。
    -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
    -webkit-line-clamp: 2; //显示的行
}

.helpCenter{
    width: 100%;
    //margin-top: 80px;

    .helpCenter-header{
        width: 100%;
        height: 360px;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;

        h2{
            font-size: 40px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            margin: 80px 0 56px;
        }

        .search{
            width: 820px;
            display: flex;

            input{
                width: 693px;
                height: 64px;
                border-radius: 4px 0 0 4px;
                border: 1px solid #979797;
                border-right: none;
                outline: none;
                box-shadow: none;
                padding: 0 24px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                background: rgba($color: #000000, $alpha: 0);
            }

            button{
                width: 127px;
                height: 64px;
                background: #00AB94;
                outline: none;
                box-shadow: none;
                border-radius: 0px 4px 4px 0px;
                border: none;
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                cursor: pointer;
            }
        }

        .hot-box{
            display: flex;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            margin: 17px 0;

            .hot-list{
                display: flex;
                padding: 0;

                li{
                    padding: 0 8px;
                    height: 22px;
                    background: rgba($color: #FFFFFF, $alpha: 0.2);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 8px;
                    cursor: pointer;
                }
            }
        }
    }  
}
</style>