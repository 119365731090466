<template>
  <div class="develop-table">
    <div>
      <div
        class="develop-item"
        @click="showDetail(key)"
        v-for="(item, key) in tableData"
        :key="key"
      >
        <div class="main-warp">
          <div class="develop-title text-left have-point">
            <div
              class="develop-point"
              :style="'background-color:' + colors[key] + ''"
            ></div>
            {{ item.name }}
          </div>
          <div class="develop-title">{{ item.percentage }} %</div>

          <div class="develop-title">{{ item.num }}</div>
        </div>
        <div class="develop-detail" v-if="showIndex === key">
          <div class="detail-item" v-for="(d, k) in item.detail" :key="k">
            <div class="develop-title text-left first-title">
              {{ d.qualificationLevel }}
            </div>
            <div class="develop-title">{{ d.percentage }}%</div>

            <div class="develop-title">{{ d.num }}</div>
          </div>
        </div>
        <!-- {{ item }} -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'develop-table',
  data() {
    return {
      showIndex: -1
    }
  },
  props: {
    defaultExpand: {
      type: Number,
      default: -1
    },
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    colors: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    showDetail(key) {
      if (key === this.showIndex) {
        this.showIndex = -1
      } else {
        this.showIndex = key
      }
    }
  },
  mounted() {
    this.showIndex = this.defaultExpand
  }
}
</script>

<style lang="scss" scoped>
.develop-table {
  margin-top: 30px;
  margin-left: 24px;
  .text-left {
    text-align: left !important;
    width: 155px !important;
  }
  .develop-item {
    color: #fff;
    margin-bottom: 16px;
    .main-warp {
      display: flex;
      height: 20px;
      align-items: center;
      justify-content: center;
      .develop-title {
        width: 120px;
        text-align: right;
        margin-bottom: 12px;
        cursor: pointer;
        .develop-point {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;
          display: inline-block;
        }
      }
    }
    .develop-detail {
      color: rgba($color: #fff, $alpha: 0.8);
      .detail-item {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 8px;
        .develop-title {
          // width: 144px;
          width: 120px;
          text-align: right;
        }
        .first-title {
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
