<template>
    <div>
        <div class="contact-menu">
            <div class="menu-items" v-for="(item, index) in concatMenus" :key="index">
                <span
                    type="link"
                    :class="{
                        selected: contactType === item.type,
                        notClick: !contactList[item.type].length
                    }"
                    @click="switchContactTable(item)"
                    class="item"
                    >{{ item.title }}({{ contactList[item.type].length }})</span
                >
                <span class="circle-point" v-if="index < concatMenus.length - 1"></span>
            </div>
        </div>
        <div class="contact-list">
            <div
                class="contace-item"
                v-for="(item, index) in contactList[contactType]"
                :key="index"
            >
                <div v-if="item.contactsType === '2'">
                    <a-icon type="phone" class="icon-right" />
                    {{ item.contactsPhone }}
                </div>
                <div class="phone" v-if="item.contactsType === '1'">
                    <!-- <div v-if="isFromClient">
                        <a-icon type="mobile" class="icon-right" /> {{ item.contactsPhone }}
                    </div>
                    <div v-else>
                        <a-tooltip title="联系电话查询" arrow-point-at-center>
                            <a-icon type="mobile" class="icon-right" />
                            <a @click="setPhoneToFilter(item.contactsPhone)">
                                {{ item.contactsPhone }}
                            </a>
                        </a-tooltip>
                    </div> -->
                    <a-icon type="mobile" class="icon-right" /> {{ item.contactsPhone }}
                    <a-tooltip placement="right" title="点击复制电话到粘贴板" arrow-point-at-center>
                        <a-icon
                            type="copy"
                            class="copy-icon"
                            @click="copyPhone(item.contactsPhone)"
                        />
                        <!-- <img
              v-if="item.contactsPhone"
              :src="poke"
              class="copy-icon"
              @click="copyPhone(item.contactsPhone)"
            /> -->
                    </a-tooltip>
                </div>
            </div>
            <div class="no-data" v-if="!contactList[contactType].length">
                <p>暂无手机号，程序正在努力解析。</p>
                <!-- <p>
                    暂无手机号，程序正在努力解析。当前条件下您可以改变企业风险等级查询有手机号的数据
                </p>
                <a-select
                    class="detail-risk-filter"
                    v-model="riskLevelFilter"
                    allowClear
                    @change="setRiskLevelFilter"
                    placeholder="风险等级"
                >
                    <a-select-option
                        v-for="(item, key) in riskOption"
                        :key="key"
                        :value="item.value"
                    >
                        {{ item.label }}
                    </a-select-option>
                </a-select> -->
            </div>
        </div>
        <div class="tips">数据来源：该数据源于国家企业信用信息公示系统、百度</div>
    </div>
</template>

<script>
// import { Tooltip, Select } from 'ant-design-vue'
// import poke from '@/assets/image/poke.png'
// import Vue from 'vue'
export default {
    name: 'company-detail-concat',
    data() {
        return {
            riskLevelFilter: undefined,
            riskOption: [
                { label: '无风险', value: '0' },
                { label: '低风险', value: '1' },
                { label: '中风险', value: '2' },
                { label: '高风险', value: '3' }
            ],
            // poke,
            concatMenus: [
                { title: '全部', type: 'all' },
                { title: '手机', type: 'mobile' },
                { title: '固话', type: 'phone' }
            ],
            contactType: 'all'
        }
    },
    // components: {
    //     aSelect: Select,
    //     aSelectOption: Select.Option,
    //     aTooltip: Tooltip
    // },
    props: {
        isFromClient: {
            type: Boolean,
            default: false
        },
        contactList: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
        copyPhone(phone) {
            copyThisText(phone)
            this.$message.success('复制成功', 0.5)
        },
        switchContactTable(item) {
            this.contactType = item.type
        },
        setRiskLevelFilter(val) {
            this.$emit('setRiskLevelFilter', val)
        },
        setPhoneToFilter(phone) {
            // console.log(this.$route.query.clueSources)
            localStorage.setItem('filterPhone', phone)
            if (parseInt(this.$route.query.clueSources, 10) === 2) {
                this.$router.replace({
                    name: 'Product',
                    query: { current: 1 }
                })
            } else {
                this.$router.replace({
                    name: 'Product',
                    query: {
                        current: 0
                    }
                })
            }
            // this.$emit('setPhoneToFilter', phone)
        }
    }
}
</script>
<style lang="scss" scoped>
.contact-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #303133;
    font-weight: normal;
    .menu-items {
        display: flex;
        align-items: center;
        justify-content: center;
        .item {
            cursor: pointer;
            &:hover {
                color: #4d7cfe;
            }
        }
        .selected {
            color: #4d7cfe;
        }
        .notClick {
            color: #909399;
            cursor: not-allowed;
            pointer-events: none;
        }
        .circle-point {
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #c0c4cc;
            margin: 0 15px;
        }
    }
}
.contact-list {
    margin-top: 10px;
    .contace-item {
        height: 36px;
        line-height: 36px;
        text-align: left;
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        // padding-left: 10px;
        font-size: 16px;
        color: #333333;
        border-bottom: 1px solid rgba(204, 204, 204, 0.3);
        .phone {
            display: flex;
            align-items: center;
        }
        .copy-icon {
            cursor: pointer;
            margin-left: 10px;
            width: 20px;
            color: #ff8800;
            // &:hover {
            //   color: #4d7cfe;
            // }
        }
    }
}
.detail-risk-filter {
    width: 175px;
}
.no-data {
    width: 100%;
    text-align: center;
    margin: 20px 0;
}
</style>
