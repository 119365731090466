<template>
  <div class="tree-ceck-box-root">
    <a-select
        id="quaSelect"
        v-model="qualifications"
        :disabled="disabled"
        :dropdownMatchSelectWidth="false"
        :dropdownStyle="{
        width:'530px',
        height:'400px',
        overflow: 'auto',
        zIndex: 10001,
      }"
        :getPopupContainer="getPopupContainer"
        :mode="selectMode"
        allowClear
        class="select"
        placeholder="请选择资质"
        show-search
        @change="changeQualificationSelect"
        @dropdownVisibleChange="dropdownVisibleChange"
        @search="searchTreeCheckbox"
    >
      <div
          slot="dropdownRender"
          :class="{
          havePaddingTop:
            designQualificationOptions.length && qualificationOptions.length
        }"
          class="drop-down"
      >
        <div
            v-if="
            designQualificationOptions.length && qualificationOptions.length
          "
            class="tree-list-select-types"
            @mousedown="e => e.preventDefault()"
        >
          <a-button
              :type="typeIndex === 1 ? 'primary' : 'default'"
              class="have-rt-margin"
              @click="selectQualificationType(1)"
          >施工资质
          </a-button
          >
          <a-button
              :type="typeIndex === 2 ? 'primary' : 'default'"
              @click="selectQualificationType(2)"
          >设计资质
          </a-button
          >
        </div>
        <tree-checkbox
            v-show="typeIndex === 1"
            ref="treeCheck1"
            :treeList="qualificationOptions"
            @setTreeCollapse="setTreeCollapse"
            @setValue="setQualificationValueFromTree"
        ></tree-checkbox>
        <tree-checkbox
            v-show="typeIndex === 2"
            ref="treeCheck2"
            :treeList="designQualificationOptions"
            @setTreeCollapse="setTreeCollapse"
            @setValue="setQualificationValueFromTree"
        ></tree-checkbox>
      </div>
      <a-option
          v-for="(item, key) in fakeQualification"
          :key="key"
          :value="item.value"
      >
        {{ item.label }}
      </a-option>
    </a-select>
  </div>
</template>

<script>
import {Select} from 'ant-design-vue'
import treeCheckbox from './TreeCheckBox.vue'

export default {
  name: 'tree-checkbox-select',
  components: {
    treeCheckbox,
    aSelect: Select,
    aOption: Select.Option
  },
  props: {
    selectMode: {
      type: String,
      default: 'multiple'
    },
    containerId: {
      type: String,
      default: ''
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false
    },
    designQualificationOptions: {
      type: Array,
      default() {
        return []
      }
    },
    fakeQualification: {
      type: Array,
      defult() {
        return []
      }
    },
    qualificationOptions: {
      type: Array,
      defult() {
        return []
      }
    }
  },
  data() {
    return {
      showTreeCheck: false,
      qualifications: [],
      typeIndex: 1,
      treeCheckOne: [],
      treeCheckTwo: [],
      searchValue: ''
    }
  },
  methods: {
    selectQualificationType(index) {
      this.typeIndex = index
      this.$nextTick(() => {
        this.loopSetValue()
      })
    },
    getPopupContainer() {
      return this.containerId
          ? document.getElementById(this.containerId)
          : document.body
    },
    searchTreeCheckbox(value) {
      this.$nextTick(() => {
        this.searchValue = value
        const treeCheck = this.$refs['treeCheck' + this.typeIndex]
        treeCheck.setSearchValue(value)
      })
    },
    setTreeCollapse(item) {
      this.qualificationOptions.forEach(e => {
        if (e.key === item.key) {
          e.isExpend = !e.isExpend
        } else {
          e.isExpend = false
        }
      })
      // console.log(this.qualificationOptions)
    },
    loopSetValue() {
      // 置空 要把两个组件里面的值都置空
      for (let index = 1; index < 3; index++) {
        const treeCheck = this.$refs['treeCheck' + index]
        if (treeCheck) {
          treeCheck.setValueFromHistory(this.qualifications)
          treeCheck.setSearchValue(this.searchValue)
        }
      }
    },
    dropdownVisibleChange(open) {
      if (open) {
        this.showTreeCheck = true
        this.$nextTick(() => {
          this.searchValue = ''
          if (this.qualificationOptions.length) {
            this.typeIndex = 1
          } else if (this.designQualificationOptions.length) {
            this.typeIndex = 2
          }
          this.selectQualificationType(this.typeIndex)
        })
      }
    },
    changeQualificationSelect() {
      this.loopSetValue()
      // if (this.$refs['treeCheck' + this.typeIndex]) {
      //     this.$nextTick(() => {
      //         const treeCheck = this.$refs['treeCheck' + this.typeIndex]
      //         treeCheck.setValueFromHistory(this.qualifications)
      //     })
      // }
    },
    checkSelectValue() {
      this.$refs['treeCheck' + this.typeIndex].setValueFromHistory(
          this.qualifications
      )
    },
    setQualificationValueFromTree() {
      this.treeCheckOne = this.$refs['treeCheck1'].getValue()

      this.treeCheckTwo = this.$refs['treeCheck2']
          ? this.$refs['treeCheck2'].getValue()
          : []

      this.qualifications = this.treeCheckOne.concat(this.treeCheckTwo)
      const quaDom = document.getElementById(this.containerId)
      const quaInput = quaDom.getElementsByClassName(
          'ant-select-search__field'
      )[0]
      if (quaInput) {
        this.$nextTick(() => {
          quaInput.value = ''
          const event = document.createEvent('HTMLEvents')
          event.initEvent('input', true, true)
          event.eventType = 'message'
          quaInput.dispatchEvent(event)
        })
      }
    }
  },
  mounted() {
    // const quaDom = document.getElementById('quaSelect')
    // const quaInput = quaDom.getElementsByTagName('input')[0]
    // if (quaInput) {
    //   const quaFather = quaInput.parentElement
    //   quaFather.removeChild(quaInput)
    // }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.qualifications = val
        // this.designQualificationOptions.forEach((d) => {
        //   console.log(d)
        // })
        // this.qualificationOptions.forEach((q)=>{

        // })
      }
    },
    qualifications: {
      deep: true,
      immediate: true,
      handler(val) {
        this.$emit('input', val)
        this.$emit('isSetFormVaue', val)
      }
    }
  }
}
</script>

<style lang="scss">
.havePaddingTop {
  padding-top: 85px;
}

.drop-down {
  position: relative;
}

.tree-list-select-types {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding-left: 30px;
  padding-top: 25px;
  box-shadow: 0px 3px 0px 5px #f1f1f1;
  padding-bottom: 25px;
}
</style>
<style lang="scss" scoped>
.tree-ceck-box-root {
  .select {
    //       margin-right: 24px;
    min-width: 336px;
    width: 100%;
  }
}
</style>
