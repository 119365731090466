const formType = {
    INPUT: 1,
    SELECT: 2,
    TREESELECT: 3,
    CHECKBOX: 4,
    RADIO: 5,
    TEXTAREA: 6,
    CUSTOMER: 7,
    COMPONENT: 8,
    DATERANGE: 9,
    MONTHRANGE: 10,
    SINGLECHECKBOKTREE: 11
}

export default formType
