<template>
  <div id="qrCode" class="qr-code">
    <a-modal
        v-model="shareDisplay"
        :destroyOnClose="true"
        :getContainer="getElementDom"
        :width="400"
        title="分享企业信息"
        @cancel="closeShareModal"
        @ok="closeShareModal"
    >
      <div>
        <div class="qr-image-content">
          <div id="qrcode"></div>
          <div class="logo-content">
            <div
                :style="'background-image:url(' + qrImage + ')'"
                class="suspension-image"
            ></div>
          </div>
        </div>
        <p class="qr-tips">
          {{ tips }}
        </p>
      </div>
      <template slot="footer">
        <a-button key="back" style="right: 5px;" @click="closeShareModal"
        >关闭
        </a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import {Modal} from 'ant-design-vue'
import qrImage from '@/assets/homePage/qr_image.png'

const QRCode = window.QRCode
export default {
  name: 'qr-modal',
  components: {
    AModal: Modal
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    tips: {
      type: String,
      default: '微信“扫一扫” 企业资质信息轻松共享'
    }
  },
  data() {
    return {
      shareDisplay: false,
      qrImage,
      qrCode: '',
      codeDom: {}
    }
  },
  methods: {
    getElementDom() {
      return this.$isServer ? '' : document.getElementById('qrCode')
    },
    showModal() {
      this.shareDisplay = true
      this.$nextTick(() => {
        const url = this.url
        this.codeDom = new QRCode(document.getElementById('qrcode'), {
          text: url,
          width: 240,
          title: '',
          height: 240,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },
    closeShareModal() {
      this.codeDom.clear()
      this.shareDisplay = false
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-code {
  position: absolute;
  z-index: 2001;
}

.qr-image-content {
  text-align: center;
  position: relative;
  width: 340px;
  height: 250px;

  #qrcode {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 240px;
  }

  .logo-content {
    border-radius: 10px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    height: 50px;
    width: 50px;
    padding: 5px;
    background-color: #ffffff;
    position: absolute;

    .suspension-image {
      width: 40px;
      height: 40px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.qr-tips {
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  margin: 0 25px;
  // margin-top: 5px;
}
</style>
