<template>
  <div id="staff">
    <a-select
        v-if="isShowRole"
        :default-active-first-option="false"
        :filter-option="false"
        :getPopupContainer="getPopupContainer"
        :not-found-content="null"
        :show-arrow="false"
        :value="searchQua"
        allowClear
        class="half-input"
        labelInValue
        placeholder="搜索跟进人所属公司"
        show-search
        @change="handleChange"
        @search="handleSearch"
    >
      <a-select-option
          v-for="(s, index) in staffList"
          :key="index"
          :value="s.id"
      >
        {{ s.companyName }}
      </a-select-option>
    </a-select>
    <a-select
        v-model="followStaffId"
        mode="multiple"
        :disabled="staffDisabled"
        allowClear class="half-input" placeholder="请选择跟进人">
      <a-select-option
          v-for="(item, index) in roleList"
          :key="index"
          :value="item.id"
      >
        {{ item.memberName }}
      </a-select-option>
    </a-select>

  </div>
</template>

<script>
import {Select} from 'ant-design-vue'
import { mapState } from 'vuex'
import Cookie from 'js-cookie'


export default {
  name: "StaffSelect",
  components: {
    aSelect: Select,
    aSelectOption: Select.Option
  },

  watch: {
    followStaffId: {
      handler(val) {
        this.$emit('followStaffChange', val)
      },
    },

  },
  data() {
    return {
      userInfo: null,
      isShowRole: false,
      searchQua: undefined,
      roleList: [],
      staffList: [],
      followStaffId: [],
      staffDisabled: true
    }
  },
  methods: {
    clearFollowStaffId() {
      if (this.userInfo){
        if (this.userInfo.roleLevel === 2) {
          this.staffDisabled = false
        }
        if (this.userInfo.roleLevel=== 1) {
          this.staffDisabled = true
        }
      }
      this.searchQua = undefined
      this.roleList = []
      this.staffList = []
      this.followStaffId = []
    },
    getPopupContainer() {
      return document.getElementById('staff')
    },
    getRoleList(id) {
      const params = {
        companyId: id,
      }
      this.$http
          .get('/member/listAllMember', params)
          .then((r) => {
            if (r) {
              this.roleList = r
              this.followStaffId = []
            } else {
              this.roleList = []
              this.followStaffId = []
              this.$emit('followStaffChange', this.followStaffId)
            }
          })
    },
    handleChange(item) {
      this.searchQua = item
      this.staffDisabled = false
      if (!item) {
        this.staffList = []
        this.staffDisabled = true
        this.followStaffId = []
        return
      }
      this.getRoleList(item.key)
    },

    handleSearch(search) {
      if (!search) {
        this.staffList = []
        this.followStaffId = []
        this.staffDisabled = true
      } else {
        this.staffDisabled = true
        this.followStaffId = []
        this.staffList = []
        const params = {
          companyName: search,
        }
        this.$http
            .get('company/byCompanyName', params)
            .then((r) => {
              this.staffList = r
            })
      }
    },
  },
  mounted() {
    const userId = Cookie.get('userId')
    this.$http.get('member/get', {id: userId}).then(r => {
      this.userInfo = r
      if (this.userInfo.roleLevel === 1) {
        this.isShowRole = true
      } else if (this.userInfo.roleLevel === 2) {
        this.isShowRole = false
        this.getRoleList(this.userInfo.companyId)
        this.staffDisabled = false
      }
    })

  }
}
</script>

<style lang="scss" scoped>
#staff {
  display: flex;
  justify-content: space-between;

  .half-input {
    width: 326px;
    margin-right: 6px;
  }

}

</style>