<template>
  <div
    class="enterprise-cooperate"
    style="background-image:url('http://cdn.public.xnjx666.com//upload/gw/static/img/bg_home_business.png')"
  >
    <div class="main-title">合作企业</div>
    <a-carousel autoplay style="width:1200px;height: 250px;margin: 0 auto;">
      <div
        style="height:385px"
        v-for="(item, key) in cooperateEnterprise"
        :key="key"
      >
        <div class="cooperate-item">
          <div
            class="rt-image"
            style="background-image:url('http://cdn.public.xnjx666.com//upload/gw/static/img/pc_business.png')"
          >
            <div class="enterprise-logo">
              <img :src="item.src" />
            </div>
          </div>
          <div class="lf-text">
            <div
              class="lf-bg"
              :style="'background-image:url(' + Clogo + ')'"
            ></div>
            <div class="lf-title" @click="goToEnterpriseDetail(item.index)">
              {{ item.title }}
            </div>
            <div class="lf-short-line"></div>
            <div class="lf-des">{{ item.des }}</div>
            <div class="lf-time">使用{{ item.time }}个月后</div>
            <div class="up-list">
              <div class="up-item" v-for="(u, key) in item.up" :key="key">
                <div class="up">
                  {{ u.number }}
                  <img :src="increase" width="24px" />
                </div>
                <div class="up-text">{{ u.text }}</div>
              </div>
            </div>
            <div class="more-cooperate" @click="goToCaseList">
              更多案例
              <img :src="rtArrow" width="20" />
            </div>
          </div>
        </div>
      </div>
      <!-- <div>2</div>
          <div>3</div> -->
    </a-carousel>
  </div>
</template>

<script>
import rtArrow from '@/assets/home/cooperate/arrow_rt.png'
import Clogo from '@/assets/home/cooperate/bg_logo.png'
import increase from '@/assets/home/cooperate/increase_big.png'
export default {
  data() {
    return {
      rtArrow,
      increase,
      Clogo,
      cooperateEnterprise: [
        {
          src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/enterprise1.png',
          title: '小牛建讯让这家公司深刻体验到大数据给企业业务带来的"智慧升级"',
          des:
            '查询企业资质信息由半个小时缩短到了3分钟，筛选企业关键决策人的效率大幅提升。',
          up: [
            { number: '70%', text: '线索量' },
            { number: '25%', text: '成交量' },
            { number: '120%', text: '业绩量' }
          ],
          time: '两',
          index: 1
        },
        {
          src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/enterprise9.png',
          title: '这家公司从无人问津到客户不断',
          des:
            '查经过小牛建讯专属客服1对1协助之后，业务从毫无起色到一天至少10个意向客户。',
          up: [
            { number: '65%', text: '线索量' },
            { number: '60%', text: '成交量' },
            { number: '45%', text: '业绩量' }
          ],
          time: '两',
          index: 9
        },
        {
          src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/enterprise5.png',
          title: '他们说小牛建讯是对这个行业传统玩法的一种挑战和创新',
          des:
            '小牛建讯给资质服务这个行业带来了新的营销模式和玩法，让批量获客成为了可能，不再局限于个人的资源深浅',
          up: [
            { number: '75%', text: '线索量' },
            { number: '35%', text: '成交量' },
            { number: '90%', text: '业绩量' }
          ],
          time: '五',
          index: 5
        },
        {
          src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/enterprise4.png',
          title: '通过小牛建讯，20人的团队一个月获取了300多的客户',
          des:
            '之前公司20人的电销团队，一个月只能完成不到100+的意向客户收集，使用小牛建讯后，我们增加到了30人的团队，每月更是能开发出超过300+的意向客户',
          up: [
            { number: '50%', text: '线索量' },
            { number: '35%', text: '成交量' },
            { number: '60%', text: '业绩量' }
          ],
          time: '三',

          index: 4
        },
        {
          src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/enterprise8.png',
          title: '已经放弃其余获客软件，全部转用小牛建讯',
          des:
            '之前需要去多个平台验证、整合客户信息，用了小牛建讯只需要根据出货需求，就能快速锁定目标群体，再通过电话验证即可，非常的高效！',
          up: [
            { number: '70%', text: '线索量' },
            { number: '25%', text: '成交量' },
            { number: '120%', text: '业绩量' }
          ],
          time: '两',
          index: 8
        },
        {
          src: 'http://cdn.public.xnjx666.com//upload/gw/static/img/enterprise7.png',
          title: '试用小牛建讯之后，立即向小牛建讯官方客服申请了试用。',
          des:
            '辰轩的老总表示如今是互联网时代，新的经营模式和渠道层出不穷，企业要发展，必须具有前瞻性，敢于尝试突破，或将不被淘汰。',
          up: [
            { number: '80%', text: '线索量' },
            { number: '50%', text: '成交量' },
            { number: '70%', text: '业绩量' }
          ],
          time: '三',
          index: 7
        }
      ]
    }
  },
  methods: {
    goToEnterpriseDetail(index) {
      // console.log(key)
      this.$router.push({
        path: 'caseDetails/' + index
      })
    },
    goToCaseList() {
      this.$router.push({
        path: 'caseCenter'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.enterprise-cooperate {
  width: 100%;
  height: 607px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  .main-title {
    color: #ffffff;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    margin-top: 80px;
    margin-bottom: 56px;
  }

  .cooperate-item {
    height: 346px;
    width: 1200px;
    display: flex;
    align-items: center;
    position: relative;
    // background-color: #ffffff;
    // background: #000;
    .rt-image {
      width: 346px;
      height: 346px;
      background-size: cover;
      line-height: 346px;
      display: flex;
      // align-items: center;
      justify-content: center;
      flex-direction: column;
      .enterprise-logo {
        width: 100%;
        height: 160px;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .lf-text {
      height: 346px;
      width: 854px;
      padding: 50px 24px 24px 40px;
      background-color: #ffffff;
      border-radius: 0 4px 4px 0;
      text-align: left;
      font-weight: 500;
      position: relative;
      .lf-bg {
        width: 180px;
        height: 180px;
        background-size: cover;
        right: 0;
        top: 0;
        position: absolute;
      }
      .lf-title {
        font-size: 24px;
        cursor: pointer;
        color: #181818;
        margin-bottom: 14px;
      }
      .lf-short-line {
        width: 32px;
        height: 4px;
        background-color: #00ab94;
        margin-bottom: 16px;
      }
      .lf-des {
        color: #585858;
        font-size: 16px;
      }
      .lf-time {
        font-size: 16px;
        color: #00ab94;
        margin-top: 40px;
      }
      .up-item {
        margin-right: 35px;
      }
      .up-list {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .up {
          display: flex;
          align-items: center;
          font-size: 32px;
          color: #181818;
          font-weight: 600;
          margin-right: 2px;
        }
        .up-text {
          color: #585858;
          font-size: 14px;
        }
      }
    }
    .more-cooperate {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #00ab94;
      position: absolute;
      right: 24px;
      bottom: 24px;
    }
  }
}
</style>
