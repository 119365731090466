import { render, staticRenderFns } from "./levelUp.vue?vue&type=template&id=7679fdd6&scoped=true"
import script from "./levelUp.vue?vue&type=script&lang=js"
export * from "./levelUp.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./levelUp.vue?vue&type=style&index=0&id=7679fdd6&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "7679fdd6",
  "032e309a"
  
)

export default component.exports