import { render, staticRenderFns } from "./shipment.vue?vue&type=template&id=8434613c&scoped=true"
import script from "./shipment.vue?vue&type=script&lang=js"
export * from "./shipment.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("../../../utils/table.scss?vue&type=style&index=0&prod&lang=scss&external")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./shipment.vue?vue&type=style&index=1&id=8434613c&prod&lang=scss&scoped=true")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8434613c",
  "cd62e0e4"
  
)

export default component.exports