<template>
  <div class="AboutUs">
    <div
        style="background-image:url('http://cdn.public.xnjx666.com//upload/gw/static/img/explore.png')"
        class="bg">
      <div class="text">
        <p>关于我们</p>
        <p>Explore the calf</p>
      </div>
    </div>
    <div class="introduce">
      <div class="content">
        <div class="title">
          <div class="titTop">
            <div class="titleText">公司简介</div>
          </div>
        </div>
        <div class="introduceMain">
          <!--          <img :src="introduce" alt="小牛建讯" />-->
          <div class="introduceText">
            <p>
              仕硕科技有限公司是一家智能数据产品与服务提供商。以建筑资质服务行业诉求为引领，专注建筑施工资质智能数据处理系统研发及应用，致力于帮助建筑资质服务解决业务增长难题，推动资质代办服务智能化升级，实现人工智能与行业需求深度融合。
            </p>
            <p style="margin-top: 24px;">
              小牛建讯是仕硕科技自主研发的资质服务营销平台，依托数据挖掘、云计算、人工智能等核心技术，为建筑资质服务提供资源查询、资源互通、资源整合等一系列信息智能化解决方案。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="cost">
      <div class="content">
        <div class="title">
          <div class="titTop">
            <div class="titleText">投资成本</div>
          </div>
        </div>
        <div class="costMain">
          <ul>
            <li v-for="item in costList" :key="item.index">
              <img :src="item.url" alt="小牛建讯"/>
              <p class="costNum">
                <span>{{ item.num }}万</span>
                <span class="sup">+</span>
              </p>
              <p class="costText">{{ item.text }}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="aboutCompany">
      <div class="content">
        <div class="idea">
          <div class="title">
            <div class="titTop">
              <div class="titleText">企业理念</div>
            </div>
          </div>
          <div class="ideaMain">
            <ul>
              <li
                  v-for="item in ideaList"
                  :key="item.index"
                  :style="'background-image:url(' + item.imgUrl + ')'"
              >
                <div>
                  <p class="ideaMainTitle">{{ item.title }}</p>
                  <p>{{ item.text }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="companyDevelopment">
          <div class="title">
            <div class="titTop">
              <div class="titleText">发展历程</div>
            </div>
          </div>
          <div class="timeLine">
            <!--时间线-->
            <div class="timeLineText">
              <div
                  v-for="(item, index) in timeLineList"
                  :key="index"
                  :style="{ left: item.left+ 'px' ,top:item.top+ 'px'}"
                  class="content"
              >
                <div class="my_timeline_item_content">
                  {{ item.timestamp }}
                </div>
                <p
                    class="tit"
                >
                  {{ item.year }}
                </p>
                <ul class="infoText" v-html="item.info"></ul>
              </div>
            </div>

            <div class="ul_box">
              <ul
                  class="my_timeline"
              >
                <li
                    v-for="(item, index) in timeLineList"
                    :key="index"
                    class="my_timeline_item"
                >
                  <!--线-->
                  <div class="my_timeline_item_line"></div>
                  <!--圈圈节点-->
                  <div
                      :style="{
                      backgroundColor: item.bgColor,
                      width: item.size + 'px',
                      height: item.size + 'px'
                    }"
                      class="my_timeline_node"
                  ></div>


                </li>
              </ul>
              <!--线-->
              <div class="my_timeline_item_line"></div>

            </div>

          </div>
        </div>
        <div v-if="false" class="team">
          <div class="title">
            <div class="titTop">
              <div class="titleLine"></div>
              <div class="titleText">核心团队</div>
              <div class="titleLine"></div>
            </div>
            <div class="titleDescribe">Corporate Dilemma</div>
          </div>
          <div>
            <div>图</div>
            <div>文</div>
          </div>
        </div>
        <div class="honor">
          <div class="title">
            <div class="titTop">
              <div class="titleLine"></div>
              <div class="titleText">荣誉证书</div>
              <div class="titleLine"></div>
            </div>
            <div class="titleDescribe">Corporate Dilemma</div>
          </div>
          <div class="threeImg">
            <div class="Containt">
              <ul
                  :style="{ left: calleft + 'px', width: widthData + 'px' }"
                  class="imgBoxoul"
                  v-on:mouseout="move()"
                  v-on:mouseover="stopmove()"
              >
                <li v-for="item in honorList" :key="item.index">
                  <img :src="item.imgUrl" alt=""/>
                  {{ item.id }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import introduce from '@/assets/Explore/introduce.png'
import cost1 from '@/assets/Explore/cost1.png'
import cost2 from '@/assets/Explore/cost2.png'
import cost3 from '@/assets/Explore/cost3.png'
import cost4 from '@/assets/Explore/cost4.png'
export default {
  name: 'AboutUs',
  data() {
    return {
      introduce,
      cost1,
      cost2,
      cost3,
      cost4,
      costList: [
        {index: 1, url: cost1, text: '丰富研发团队', num: '856.24'},
        {index: 2, url: cost2, text: '项目运营成本', num: '320'},
        {index: 3, url: cost3, text: '行业市场拓展', num: '100'},
        {index: 4, url: cost4, text: '公司日常运营', num: '30'}
      ],
      ideaList: [
        {
          index: 1,
          imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/idea1.png',
          title: '企业愿景',
          text: '引领中国建筑资质行业变革的智能数据服务提供商'
        },
        {
          index: 2,
          imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/idea2.png',
          title: '企业使命',
          text: '解决中国数万家建筑资质服务业务增长难题'
        },
        {
          index: 3,
          imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/idea3.png',
          title: '核心价值观',
          text: '创造客户价值，数据驱动，合作共赢，积极进取'
        },
        {
          index: 4,
          imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/idea4.png',
          title: '经营理念',
          text: '以客户需求为导向，以客户价值为目标，以客户满意为标准'
        }
      ],
      honorList: [
        {index: 1, imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/honor1.png'},
        {index: 2, imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/honor2.png'},
        {index: 3, imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/honor3.png'},
        {index: 4, imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/honor4.png'},
        {index: 5, imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/honor5.png'},
        {index: 6, imgUrl: 'http://cdn.public.xnjx666.com//upload/gw/static/img/honor6.png'}
      ],
      calleft: 0,
      speed: 1,
      timer: null,
      timeLine: null,
      timeLineList: [
        {
          top: 0,
          left: 180,
          timestamp: '2018年6月',
          color: '#999',
          fontsize: 18,
          size: '18',
          bgColor: '#E6E9F0',
          year: '孵化-小牛建讯1.0',
          info:
              '<li>建筑资质行业数据决策支持</li><li>项目成功孵化</li><li>市场潜力无限</li>'
        },
        {
          top: 182,
          left: 148,
          timestamp: '2019年3月',
          color: '#999',
          fontsize: 18,
          size: '18',
          year: '沉淀-小牛建讯2.0',
          bgColor: '#00AB94',
          info:
              '<li>全网10万+咨询信息整合</li><li>解决数据精准问题</li><li>成为行业新宠</li><li></li>'
        },
        {
          top: 0,
          left: 117,
          timestamp: '2019年12月',
          color: '#999',
          fontsize: 18,
          size: '18',
          bgColor: '#E6E9F0',
          year: '突破-小牛建讯3.0',
          info:
              '<li>平台日益成熟</li><li>服务企业超100家</li><li></li><li></li>'
        },
        {
          top: 182,
          left: 85,
          timestamp: '2020年9月',
          color: '#999',
          fontsize: 18,
          size: '18',
          bgColor: '#00AB94',
          year: '创新-小牛建讯4.0',
          info:
              '<li>打破沉淀全新升级</li><li>以智能化推进业务增长</li><li></li><li></li>'
        },
        {
          top: 0,
          left: 50,
          timestamp: '2021年4月',
          color: '#999',
          fontsize: 18,
          size: '18',
          bgColor: '#E6E9F0',
          year: '引领--小牛建讯5.0',
          info:
              '<li>行业内同类平台翘楚</li><li>856.24万+建筑企业数据</li><li>235.7万+建筑资质信息</li><li>5300+资质服务</li>'
        }
      ]
    }
  },
  methods: {
    //移动
    move() {
      this.timer = setInterval(this.starmove, 100)
    },
    //开始移动
    starmove() {
      this.calleft -= 1.2 //*this.speed
      if (this.calleft <= -1150) {
        this.calleft = 0
      }
    },
    //鼠标悬停时停止移动
    stopmove() {
      clearInterval(this.timer)
    }
  },
  computed: {
    widthData() {
      return 240 * Number(this.honorList.length * 2)
    }
  },
  created() {
    this.move()
  },
  mounted() {
    var imgBox = document.getElementsByClassName('imgBoxoul')[0]
    imgBox.innerHTML += imgBox.innerHTML
    document.title = '全国建筑企业资质查询-关于我们_小牛建讯官网'
  },
  destroyed() {
    clearInterval(this.timer)
    clearInterval(this.timeLine)
    this.timer = null
    this.timeLine = null
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.content {
  width: 1200px;
  margin: 0 auto;
  //position: relative;
}

.title {
  margin-top: 80px;
  text-align: center;
  margin-bottom: 56px;
  width: 100%;

  .titTop {
    width: 128px;
    display: flex;
    align-items: center;
    margin: 0 auto;


    .titleText {
      font-size: 32px;
      font-weight: 500;
      color: #181818;
    }
  }


}

.AboutUs {
  width: 100%;
  background-color: #fff;

  .bg {
    width: 100%;

    .text {
      width: 1200px;
      height: 360px;
      text-align: center;
      margin: 0 auto;
      padding-top: 120px;


      p:first-child {
        font-size: 40px;
        font-weight: 500;
        color: #FFFFFF;
      }

      p:nth-child(2) {
        font-size: 20px;
        font-weight: 400;
        color: #FFFFFF;

      }

    }
  }

  .introduce {

    .introduceMain {
      display: flex;
      justify-content: space-between;

      img {
        width: 603px;
      }

      .introduceText {

        p {
          text-align: left;
          font-size: 16px;
          font-weight: 400;
          color: #181818;
          line-height: 22px;
        }
      }
    }
  }

  .cost {
    padding-top: 48px;
    box-sizing: border-box;
    height: 399px;
    width: 100%;

    .costMain {
      ul {
        display: flex;
        justify-content: space-around;

        li {
          text-align: center;

          .costText {

            font-size: 14px;
            font-weight: 400;
            color: #585858;
            line-height: 18px;
            margin-top: 4px;
          }

          .costNum {
            margin-top: 8px;

            span:first-child {
              font-size: 32px;
              font-weight: 600;
              color: #181818;
              line-height: 45px;
            }

            .sup {
              font-size: 14px;
              font-weight: 400;
              color: #585858;
              line-height: 20px;
              margin-left: 4px;
            }
          }
        }
      }
    }
  }

  .aboutCompany {
    .idea {
      .ideaMain {
        ul {
          display: flex;

          li {
            width: 301px;
            height: 399px;
            text-align: center;
            position: relative;

            &:hover {
              transform: scale(1.1, 1.1);
              z-index: 99;
            }

            div {
              width: 208px;
              height: 117px;
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              right: 0;
              margin: auto;

              .ideaMainTitle {
                text-align: center;
                font-size: 30px;
                font-weight: bold;
                color: #ffffff;
              }

              p {
                margin-top: 15px;
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
                line-height: 30px;
                text-align: left;
              }
            }
          }
        }
      }
    }

    .companyDevelopment {
      margin-bottom: 183px;

      .timeLine {
        .ul_box {
          margin-top: 12px;
          width: 1200px;
          display: flex;
          align-items: center;

          .my_timeline {
            display: flex;
            align-items: center;
          }

          .my_timeline_item {
            display: flex;
            align-items: center;
          }

          .my_timeline_node {
            box-sizing: border-box;
            border-radius: 50%;
            margin: 0 8px;

          }

          .my_timeline_item_line {
            width: 173px;
            height: 2px;
            background: #E6E9F0;
          }

        }

        .timeLineText {
          display: flex;

          .content {
            position: relative;
          }

          .my_timeline_item_content {
            font-size: 16px;
            font-weight: 500;
            color: #00AB94;
            line-height: 22px;
            margin-bottom: 4px;
          }

          .tit {
            font-size: 16px;
            font-weight: 500;
            color: #00AB94;
            line-height: 22px;
            margin-bottom: 8px;

          }
          .infoText ::v-deep li{
            font-size: 14px;
            font-weight: 400;
            color: #181818;
            line-height: 22px;
          }

        }


      }
    }

    .honor {
      .threeImg {
        z-index: 98;
        position: relative;

        .Containt {
          position: relative;
          overflow-y: auto;
          width: 1200px;
          height: 243px;
          overflow: hidden;
          margin: 0 auto;

          ul {
            margin: 0 auto;
            width: 2400px;
            position: absolute;
            left: 0px;
            cursor: pointer;
            height: 100%;
            z-index: 10;
            display: flex;
            align-items: flex-end;

            li {
              float: left;
              overflow: hidden;
              background-color: #ffffff;

              img {
                height: 243px;
                margin: 0 20px;
              }
            }
          }
        }
      }

      ul {
        display: flex;
        justify-content: space-between;
      }

      margin-bottom: 200px;
    }
  }
}
</style>
