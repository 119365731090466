<template>
  <div class="form-modal">
    <a-form-model
      id="componentForm"
      ref="formREF"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div v-for="(item, index) in formItems" :key="index">
        <a-form-model-item
          v-if="item.show === undefined ? true : item.show"
          :label="item.label"
          :prop="item.name"
        >
          <a-input
            v-model="form[item.name]"
            :placeholder="item.placeholder || '请输入文字'"
            v-if="item.type === formType.INPUT"
          />
          <a-text-area
            v-model="form[item.name]"
            :placeholder="item.placeholder || '请输入文字'"
            v-if="item.type === formType.TEXTAREA"
            :autoSize="item.autoSize || { minRows: 4, maxRows: 4 }"
          ></a-text-area>
          <a-select
            v-model="form[item.name]"
            :getPopupContainer="getPopupContainer"
            :placeholder="item.placeholder || '请选择'"
            :allowClear="item.props ? item.props.allowClear : false"
            :mode="item.mode || 'defalut'"
            :showSearch="
              item.showSearch === undefined ? false : item.showSearch
            "
            @search="e => selectSearch(e, item)"
            v-if="item.type === formType.SELECT"
          >
            <a-select-option
              v-for="op in item.options"
              :key="op.value"
              :disabled="op.disabled"
              :value="op.value"
              >{{ op.label }}</a-select-option
            >
          </a-select>
          <a-radio-group
            v-model="form[item.name]"
            v-if="item.type === formType.RADIO"
          >
            <a-radio
              v-for="op in item.options"
              :key="op.value"
              :value="op.value"
              >{{ op.label }}</a-radio
            >
          </a-radio-group>
          <a-tree-select
            v-model="form[item.name]"
            :getPopupContainer="getPopupContainer"
            v-if="item.type === formType.TREESELECT"
            :treeCheckable="
              item.treeCheckable === undefined ? true : item.treeCheckable
            "
            :labelInValue="
              item.labelInValue === undefined ? true : item.labelInValue
            "
            :showSearch="
              item.showSearch === undefined ? false : item.showSearch
            "
            treeDefaultExpandAll
            treeNodeFilterProp="label"
            :tree-node-label-prop="'label'"
            allowClear
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :placeholder="item.placeholder || '请选择'"
          >
            <a-tree-select-node
              v-for="qua in item.options"
              :key="qua.key"
              :value="qua.value"
              :selectable="qua.selectable"
              :checkable="qua.checkable"
              :label="qua.label"
              :title="qua.title"
            >
              <a-tree-select-node
                v-for="children in qua.children"
                :key="children.key"
                :value="children.value"
                :disabled="children.disabled"
                :label="children.label"
                :title="children.title"
              ></a-tree-select-node>
            </a-tree-select-node>
          </a-tree-select>
          <a-checkbox-group
            v-if="item.type === formType.CHECKBOX"
            v-model="form[item.name]"
            :options="item.options"
          >
          </a-checkbox-group>
          <a-date-picker
            v-if="item.type === formType.DATERANGE"
            v-model="form[item.name]"
            :getCalendarContainer="getPopupContainer"
            :placeholder="item.placeholder || '请选择日期'"
          ></a-date-picker>
          <a-month-picker
            v-if="item.type === formType.MONTHRANGE"
            v-model="form[item.name]"
            format="yyyy-MM"
            valueFormat="yyyy-MM"
            :getCalendarContainer="getPopupContainer"
            :placeholder="item.placeholder || '请选择月份'"
          ></a-month-picker>
          <tree-checkbox-single-select
            v-model="form[item.name]"
            containerId="componentForm"
            :id="singleId"
            :designQualificationOptions="designQualificationOptions"
            :fakeQualification="fakeQualification"
            :qualificationOptions="qualificationOptions"
            v-if="item.type === formType.SINGLECHECKBOKTREE"
          ></tree-checkbox-single-select>
          <component
            v-if="item.type === formType.COMPONENT"
            :is="item.component || null"
            :item="item"
            :ref="item.ref"
            v-bind="$attrs"
            v-on="$listeners"
          ></component>
          <span class="item-tooltip" v-if="item.haveTips">
            <a-tooltip :placement="item.tipPlace ? item.tipPlace : 'right'">
              <template slot="title">
                <span> {{ item.tips }}</span>
              </template>
              <a-icon type="question-circle" />
            </a-tooltip>
          </span>
        </a-form-model-item>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import {
  FormModel,
  Input,
  Select,
  Checkbox,
  Tooltip,
  Radio,
  DatePicker,
  TreeSelect
} from 'ant-design-vue'
import formType from './formType'
import TreeCheckboxSingleSelect from '@/components/TreeCheckboxSingleSelect.vue'

export default {
  name: 'form-modal',
  components: {
    aInput: Input,
    aTextArea: Input.TextArea,
    aSelect: Select,
    aSelectOption: Select.Option,
    aTooltip: Tooltip,
    aDatePicker: DatePicker,
    aMonthPicker: DatePicker.MonthPicker,
    // aTreeSelect: TreeSelect,
    aCheckbox: Checkbox,
    aCheckboxGroup: Checkbox.Group,
    aTreeSelect: TreeSelect,
    aTreeSelectNode: TreeSelect.TreeNode,
    aRadio: Radio,
    aRadioGroup: Radio.Group,
    aFormModel: FormModel,
    aFormModelItem: FormModel.Item,
    TreeCheckboxSingleSelect
  },
  props: {
    singleId: {
      type: String,
      default: 'quaSelect'
    },
    fakeQualification: {
      type: Array,
      default() {
        return []
      }
    },
    designQualificationOptions:{
      type: Array,
      default() {
        return []
      }
    },
    qualificationOptions: {
      type: Array,
      default() {
        return []
      }
    },
    value: {},
    formItems: {
      type: Array,
      default() {
        return []
      }
    },
    labelCol: {
      type: Object,
      default() {
        return { span: 5 }
      }
    },
    wrapperCol: {
      type: Object,
      default() {
        return { span: 19 }
      }
    }
  },
  data() {
    return {
      test: [],
      form: {},
      rules: {},
      formType,
      bgImage: ''
    }
  },
  methods: {
    getPopupContainer() {
      return document.getElementById('componentForm')
    },
    selectSearch(e, item) {
      if (item.searchFunction) {
        item.searchFunction(e)
      }
    },
    formItemValidator(prop) {
      let isValidate = false
      this.$refs.formREF.validateField(prop, err => {
        if (err) {
          isValidate = false
        } else {
          isValidate = true
        }
      })
      return isValidate
    },
    formValidator() {
      let isCanSubmit = false
      this.$refs.formREF.validate(val => {
        isCanSubmit = val
      })
      if (isCanSubmit) {
        return this.form
      } else {
        return isCanSubmit
      }
    }
  },
  watch: {
    formItems: {
      immediate: true,
      //  deep: true,
      handler() {
        this.formItems.forEach(v => {
          this.$set(this.form, v.name, v.value)
          this.$set(this.rules, v.name, v.validate)
          // form[v.name] = v.value
          // // rules[v.name] = v.validate
          // console.log(this.form, this.rules)
        })
        // 必需要深拷贝
        // this.$nextTick(() => {
        //   this.form = Object.assign({}, form)
        //   this.rules = Object.assign({}, rules)
        // })
      }
    },
    form: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
