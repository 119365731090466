import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=152264d7&scoped=true"
import script from "./AboutUs.vue?vue&type=script&lang=js"
export * from "./AboutUs.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./AboutUs.vue?vue&type=style&index=0&id=152264d7&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "152264d7",
  "d4a2e3ec"
  
)

export default component.exports