<template>
    <div class="verifycode-input">
        <div>
            <a-input
                v-if="!loginStyle"
                class="have-verifycode"
                v-model="code"
                :class="loginStyle"
                @input="inputValue"
                placeholder="请输入短信验证码"
            ></a-input>
            <input
                v-else
                v-model="code"
                :class="loginStyle"
                @input="inputValue"
                placeholder="请输入短信验证码"
            />
        </div>
        <a-button
            :disabled="codeDisabled"
            @click="beforeSend"
            class="verify-btn"
            :class="bgColor"
            type="primary"
            >{{ codeBtnText }}
        </a-button>
    </div>
</template>

<script>
import { Input } from 'ant-design-vue'

export default {
    name: 'verify-input',
    components: {
        aInput: Input
    },
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
        phone: {
            type: [String, Number],
            default: ''
        },
        bgColor: {
            type: String,
            default: ''
        },
        loginStyle: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            defaultPhone: '',
            codeBtnText: '获取验证码',
            codeDisabled: false,
            code: '',
            codeSIV: null
        }
    },
    methods: {
        clearValue() {
            this.code = ''
        },
        inputValue() {
            // console.log('inputValue')
            this.$emit('setVerifyCode', this.code)
        },
        beforeSend() {
            this.$emit('isReallyPhone')
            this.$emitP('validatePhone').then(v => {
                // console.log('v.phone',v.phone)
                if (v.validate) {
                    // console.log('validatePhone-success')
                    this.defaultPhone = v.phone
                    this.sendVerifycode()
                } else {
                    console.log('err')
                }
            })
        },
        sendVerifycode() {
            // console.log('codeSIV',this.codeSIV)
            //   const isCellPhone = /^1(3|4|5|6|7|8|9)\d{9}$/
            //   if (!isCellPhone.test(phone)) {
            //     return
            //   }
            this.$http
                .post(
                    'user/sms/verificte/send',
                    { cellPhone: this.phone || this.defaultPhone },
                    'application/x-www-form-urlencoded'
                )
                .then(() => {
                    this.codeDisabled = true
                    let time = 120
                    this.codeSIV = setInterval(() => {
                        time -= 1
                        this.codeBtnText = `重新获取(${time}S)`
                        if (time === 1) {
                            this.codeBtnText = '获取验证码'
                            this.codeDisabled = false
                            clearInterval(this.codeSIV)
                            this.codeSIV = null
                        }
                    }, 1000)
                })
        }
    },
    mounted() {
        this.code = ''
    }
}
</script>

<style lang="scss" scoped>
.verificationBtn {
    background-color: #1ebdf2;
    padding: 0 3px !important;
    text-align: center;
    &:hover {
        background-color: #1ebdf2 !important;
        padding: 0 3px !important;
        text-align: center;
    }
}
.loginStyle {
    background-color: transparent !important;
    //outline: none;
    border: 2px solid #0a78b2;
    color: #fff;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    box-shadow: none;
    text-indent: 7px;

    &:hover {
        background-color: transparent !important;
        outline: none;
        box-shadow: none;
        border: 2px solid #0a78b2 !important;
    }

    &:focus {
        background-color: transparent !important;
        outline: none;
        box-shadow: none;
        border: 2px solid #0a78b2 !important;
    }
    &-register {
        background-color: transparent !important;
        //outline: none;
        border: 2px solid #0a78b2;
        color: #fff;
        border-radius: 4px;
        box-shadow: none;
        text-indent: 7px;
        height: 32px;
        line-height: 32px;
        &:hover {
            background-color: transparent !important;
            outline: none;
            box-shadow: none;
            border: 2px solid #0a78b2 !important;
        }

        &:focus {
            background-color: transparent !important;
            outline: none;
            box-shadow: none;
            border: 2px solid #0a78b2 !important;
        }
    }
}

.verifycode-input {
    display: flex;
    align-items: center;
    .have-verifycode {
        width: 200px;
    }

    .verify-btn {
        width: 120px;
        margin-left: 5%;
    }
}
</style>
