<template>
  <div class="verify-login">
    <a-form-model
      class="verify-form"
      ref="ruleForm"
      :rules="rules"
      :label-col="{ span: 0 }"
      :wrapper-col="{ span: 24 }"
      :model="formValue"
    >
      <a-form-model-item label="" prop="phone">
        <a-input v-model="formValue.phone" placeholder="请输入手机号">
          <a-icon
            style="font-size:18px;color:#C6CED4"
            slot="prefix"
            theme="filled"
            type="mobile"
          />
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="" prop="verifyCode">
        <a-input-search
          v-model="formValue.verifyCode"
          placeholder="请输入短信验证码"
        >
          <a-icon
            style="font-size:18px;color:#C6CED4"
            slot="prefix"
            theme="filled"
            type="check-circle"
          />
          <a-button
            @click.native="beforeSendCode"
            :disabled="isSend"
            class="send-btns"
            slot="enterButton"
          >
            {{ verifyText }}
          </a-button>
        </a-input-search>
      </a-form-model-item>
    </a-form-model>

    <a-button class="verify-btn" id="loginButton" @click="login"
      >同意协议并登录</a-button
    >
    <div class="user-service">
      查看<a @click="showUserService">《小牛用户协议》</a>
    </div>
    <div class="wx-tips">未注册的手机号验证后将自动创建新账号</div>
    <user-service ref="service"></user-service>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import userService from '@/views/components/userService'
import { mapMutations } from 'vuex'
import Store from '@/store'

export default {
  name: 'verify-login',
  components: {
    userService
  },
  props: {
    isBind: {
      type: Boolean,
      default: false
    },
    authonKey: {
      type: String,
      default: ''
    }
  },
  inject: ['reload'],
  data() {
    return {
      formValue: {
        phone: '',
        verifyCode: ''
      },
      rules: {
        phone: [
          {
            validator: this.validatePhoneNumber,
            required: true,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        verifyCode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'change'
          }
        ]
      },
      verifyText: '点击获取验证码',
      isSend: false
    }
  },
  methods: {
    ...mapMutations([
      'setShowLoginModal',
      'setLoginSuccess',
      'setUserInfo',
      'setExpriedModalShow'
    ]),

    showUserService() {
      this.$refs.service.showModal()
    },
    validatePhoneNumber(rule, value, callback) {
      // const isTelephone = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/
      const isCellPhone = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (!isCellPhone.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    },
    beforeSendCode() {
      this.$refs.ruleForm.validateField('phone', val => {
        if (!val) {
          this.sendCode()
        }
      })
      // console.log(validate)
      // return
    },
    userInfo(userId) {
      this.$http.get('member/get', { id: userId }).then(r => {
        this.setUserInfo(r)
      })
    },
    goToPage(r) {
      this.$message.success('登录成功', 0.5)
      Cookie.set('JSESSIONID', r.tokeId, { expires: 7 })
      Cookie.set('memberName', r.memberName, { expires: 7 })
      Cookie.set('userId', r.id, { expires: 7 })
      const analyze = function() {
        const w = window
        const n = '_qha'
        w[n] =
          typeof w[n] === 'function'
            ? w[n]
            : function() {
                ;(w[n].c = w[n].c || []).push(arguments)
              }

        _qha('send', {
          et: 31,
          order: [
            {
              id: r.id /* 注册id, 必填项*/,
              orderType: '1' /* 常量，请勿修改*/
            }
          ]
        })
      }
      analyze()
      // (function(w, n) {
      //     w[n] =
      //         typeof w[n] === 'function'
      //             ? w[n]
      //             : function() {
      //                   ;(w[n].c = w[n].c || []).push(arguments)
      //               }

      //     _qha('send', {
      //         et: 31,
      //         order: [
      //             {
      //                 id: '' /* 注册id, 必填项*/,
      //                 orderType: '1' /* 常量，请勿修改*/
      //             }
      //         ]
      //     })
      // })(window, '_qha')
      this.setShowLoginModal(false)
      // this.$router.push({
      //       name: this.$route.query.from || 'Home'
      //   })
    },
    login() {
      const loginSource = this.$route.query.loginSource || 0
      console.log(loginSource)
      this.$refs.ruleForm.validate(val => {
        if (val) {
          if (this.isBind) {
            const params = {
              username: this.formValue.phone,
              verifycode: this.formValue.verifyCode,
              loginSource,
              authonKey: this.authonKey
            }
            this.$http
              .post(
                'wx/website/login',
                params,
                'application/x-www-form-urlencoded'
              )
              .then(r => {
                this.goToPage(r)
                this.setLoginSuccess(true)
                this.userInfo(r.id)
                this.reload()
              })
              .catch(err => {
                if (err.code === '2005') {
                  this.setExpriedModalShow(true)
                }
              })
          } else {
            const params = {
              username: this.formValue.phone,
              verifycode: this.formValue.verifyCode,
              loginSource
            }
            this.$http
              .post('user/login', params, 'application/x-www-form-urlencoded')
              .then(r => {
                this.goToPage(r)
                this.setLoginSuccess(true)
                this.userInfo(r.id)
                this.reload()
              })
              .catch(err => {
                if (err.code === '2005') {
                  this.setExpriedModalShow(true)
                }
              })
          }
        }
      })
    },
    sendCode() {
      // console.log('?')
      this.$http
        .post(
          'user/sms/verificte/send',
          { cellPhone: this.formValue.phone },
          'application/x-www-form-urlencoded'
        )
        .then(() => {
          this.isSend = true
          let time = 120
          const interVal = setInterval(() => {
            if (time === 0) {
              this.verifyText = '重新发送'
              clearInterval(interVal)
              this.isSend = false
            } else {
              this.verifyText = '重新发送(' + time + ')'
            }
            time -= 1
          }, 1000)
        })
        .catch(() => {
          this.isSend = false
        })
    }
  }
}
</script>

<style lang="scss">
.wx-tips {
  margin-top: 10px;
  font-size: 14px;
  color: #888;
}
.verify-form {
  width: 340px;

  .ant-form-item {
    margin-bottom: 20px;

    .ant-input {
      height: 45px;
      line-height: 45px;
      font-size: 16px;
      border-radius: 30px !important;
      padding-left: 40px;
      border-right: 1px solid #d9d9d9;

      &:focus {
        border-color: #40a9ff;
      }
    }

    .has-error {
      .ant-input {
        border-color: #f5222d !important;
      }
    }

    // .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    // }
    .ant-form-explain {
      font-size: 14px;
      padding-left: 10px;
      text-align: left;
    }

    .send-btns {
      height: 45px;
      line-height: 45px;
      width: 144px;
      background-color: #0088f4;
      color: #fff;
      font-size: 16px;
      border-radius: 30px !important;
      margin-left: 10px;
    }
  }
}
</style>
<style lang="scss" scoped>
.verify-login {
  width: 340px;
  margin-top: 20px;
  // display: flex;
  // align-items: center;
  // justify-content: flex-start;
  .verify-btn {
    width: 100%;
    height: 48px;
    line-height: 48px;
    background-color: #0088f4;
    color: #fff;
    font-size: 18px;
    //margin-top: 30px;

    border-radius: 30px;
  }

  .user-service {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    font-size: 16px;
  }
}
</style>
