<template>
  <div class="iframe-page">
    <iframe
      style="width:100%;"
      src="http://www.xnjx666.com:8100"
      ref="myframe"
      @load="loaded"
      frameborder="0"
      id="tempHtml"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'iframeIndex',
  data() {
    return {
      iframeHeight: 2000,
      num: 1
    }
  },
  computed: {
    projectEnv() {
      if (process.env.VUE_APP_TYPE) {
        return process.env.VUE_APP_TYPE
      } else {
        return 'online'
      }
    }
  },
  methods: {
    loaded() {
      // 只有在 iframe 加载时传递数据给子组件，$refs 才是非空的
      this.$refs.myframe.contentWindow.postMessage(
        {
          type: 'myData',
          data: 'this.data'
        },
        '*'
      )
    },

    getIframeHeight(iframeHeight) {
      const oIframe = document.getElementById('tempHtml')
      // const deviceWidth = document.documentElement.clientWidth;
      // const deviceHeight = document.documentElement.clientHeight;
      // oIframe.style.width = (Number(deviceWidth) - 2200) + 'px'; //数字是页面布局宽度差值
      oIframe.style.height = iframeHeight + 'px' //数字是页面布局高度差
    }
  },

  mounted() {
    window.addEventListener(
      'message',
      e => {
        if (e.data && e.data.cmd != 'undefined' && e.data.cmd == 'bodyHeight') {
          // 接收 bodyHeight 消息

          if (typeof e.data.data === 'number') {
            this.iframeHeight = e.data.data
            this.getIframeHeight(this.iframeHeight)
          }
        } else if (
          e.data &&
          e.data.cmd != 'undefined' &&
          e.data.cmd == 'toProduct'
        ) {
          // 接收 toProduct 消息

          if (typeof e.data.data === 'string') {
            if (this.num == 1) {
              let url = ''
              if (this.projectEnv === 'dev') {
                url = 'http://localhost:8080/#/'
              } else if (
                this.projectEnv === 'online' ||
                this.projectEnv === 'preview'
              ) {
                // 预发  正式环境
                url = 'http://www.xnjx666.com:9000/#/'
              } else if (this.projectEnv === 'test') {
                // 测试环境
                url = 'http://192.168.0.108:8099/#/'
              }
              url = url + 'longna/receipt?fromMenhu=1&keywords=' + e.data.data
              //   console.log(url)
              //   return
              window.open(url)
              // this.$router.push({
              //     path: '/product/0',
              //     query: {
              //         // current: '0',
              //         company: e.data.data
              //     },
              //     replace: true
              // })

              this.num++
            }
          }
        }
      },
      false
    )
  }
}
</script>

<style lang="scss" scoped>
.iframe-page {
  background-color: #ffffff;
  width: 100%;
  height: 80%;
  margin: 0 auto;
}
</style>
