<template>
  <div class="company-detail">
    <div
      class="detail-header"
      :style="'background-image:url(' + detailHeader + ')'"
    ></div>
    <div class="basic-info">
      <div class="back-lastpage" @click="goBackPrePage">
        <a-icon type="left" />{{ prePageName }}
      </div>
      <div class="name-n-count">
        <div
          class="enterprise-logo"
          :style="'background-image:url(' + enterpriseLogo + ')'"
        ></div>
        <div class="name">
          {{ businessInfo.companyName }}
          <span class="status">{{ businessInfo.businessStatus }}</span>
          <div class="other-info">
            社会统一信用代码： {{ businessInfo.socialCreditCode }}
          </div>
          <div
            class="other-info"
            v-html="'法定代表人：' + businessInfo.legalPerson"
          ></div>
          <!-- <a-tooltip title="联系人查询" placement="right" arrow-point-at-center v-else>
                        <div class="click-person other-info">
                            <span
                                @click="setLegalPersonToFilter(businessInfo.legalPerson)"
                                v-html="'法定代表人：' + businessInfo.legalPerson"
                            ></span>
                            <img v-if="businessInfo.legalPerson" :src="poke" width="25" />
                        </div>
                    </a-tooltip> -->
        </div>
      </div>
      <div class="distribution-btn">
        <!-- <div v-if="isFromClient" class="user-name">
                    跟进销售: {{ basicInfo.memberName }}
                </div> -->

        <div>
          <a-button
            :disabled="isSuperAdmin"
            icon="share-alt"
            type="default"
            size="large"
            v-if="!basicInfo.isSimple"
            @click="showSharQrCode"
            >分享资源</a-button
          >
        </div>

        <div class="rt-button">
          <!-- <div v-if="isFromClient" class="status">
                        跟进状态:
                        <span
                            class="status-tag"
                            :class="{
                                greeTag:
                                    basicInfo.followStatus === '有意向' ||
                                    basicInfo.followStatus === '已成交'
                            }"
                            >{{ basicInfo.followStatus }}</span
                        > 
                    </div> -->
          <div v-if="isFromClient" class="user-name">
            跟进状态: {{ basicInfo.followStatus }}
          </div>
          <a-button
            v-if="isFromClient"
            class="turn-on have-rt-margin"
            :disabled="isSuperAdmin"
            @click="addNewAssign"
            size="large"
            >添加跟进记录</a-button
          >
          <a-button
            v-if="!isFromClient && basicInfo.haveReceive"
            @click="phoneIsNotTurnOn"
            size="large"
            class="not-turn-on have-rt-margin"
            >拨打未通</a-button
          >
          <a-button
            v-if="!isFromClient && basicInfo.haveReceive"
            @click="phoneIsTurnOn"
            size="large"
            class="turn-on have-rt-margin"
            >接通跟进</a-button
          >
        </div>
      </div>
    </div>
    <div class="detail-warp">
      <div class="spin-loading" v-if="showLoading">
        <a-spin size="large" class="a-spin" tip="加载中..." />
      </div>

      <div class="detail-title-list">
        <div
          class="main-title"
          @click="selectMainTitle(index)"
          v-for="(item, index) in mainTitleList"
          :key="index"
          :class="{ isSelect: titleSelectIndex === index }"
        >
          {{ item.title }}
        </div>
      </div>
      <div class="content">
        <component
          :enterpriseId="enterpriseId"
          :contactList="contactList"
          :imageHeight="300"
          :imageWidth="300"
          :followList="followList"
          :businessInfo="businessInfo"
          text="请点击查看电话按钮获取企业联系方式"
          titleText="访问受限"
          :riskLevel="riskLevel"
          @setPhoneToFilter="setPhoneToFilter"
          @setRiskLevelFilter="setRiskLevelFilter"
          :isFromClient="isFromClient"
          :qualifications="qualifications"
          :is="mainTitleList[titleSelectIndex].component"
        >
          <template slot="btSlot">
            <a-button
              type="primary"
              class="have-rt-margin"
              @click="receiveEmterprice"
              v-if="!isFromClient || basicInfo.haveReceive"
              :disabled="isSuperAdmin"
              >查看电话</a-button
            >
          </template>
        </component>
      </div>
    </div>
    <a-modal
      @ok="addFollow"
      :destroyOnClose="true"
      @cancel="closeFollowDrawer"
      v-model="followShow"
      title="添加跟进记录"
    >
      <follow :followQualication="followQualication" ref="followRef"></follow>
      <!-- <follow ref="followRef"></follow> -->
      <!--      <template slot="footer">-->
      <!--        <a-button key="back" @click="handleCancel1" style="right: 5px;"-->
      <!--          >关闭</a-button-->
      <!--        >-->
      <!--      </template>-->
    </a-modal>
    <qr-modal ref="qr" :url="qrCode"></qr-modal>
  </div>
</template>
<script>
import Business from './components/EnterpriseDetail/Business.vue'
import Risk from './components/EnterpriseDetail/Risk.vue'
import Follow from './components/EnterpriseDetail/Follow.vue'
import BlockData from '@/components/BlockData.vue'
import QualificationContent from './components/EnterpriseDetail/Qualification.vue'
import ContactPhone from './components/EnterpriseDetail/ContactPhone.vue'
import FollowList from './components/EnterpriseDetail/FollowList.vue'
import { mapState, mapMutations } from 'vuex'
import detailHeader from '@/assets/enterpriseDetail/detailHeader.png'
import enterpriseLogo from '@/assets/enterpriseDetail/enterpriseLogo.png'
import qrModal from '@/components/qrCode.vue'
import { Modal } from 'ant-design-vue'
import poke from '@/assets/poke.png'

export default {
  name: 'enterprise-detail',
  components: {
    qrModal,
    // qrModal,
    Business,
    Risk,
    //     Assign,
    //     BlockData,
    Follow,
    QualificationContent,
    ContactPhone,
    FollowList
    //     formComponents,
  },
  computed: {
    ...mapState({
      enterpriseItem: state => state.enterpriseItem,
      userInfo: state => state.userInfo
    }),
    isSuperAdmin() {
      return this.userInfo ? this.userInfo.roleLevel === 1 : false
    },
    isAdmin() {
      return this.userInfo ? this.userInfo.roleLevel === 2 : false
    },
    isSales() {
      return this.userInfo ? this.userInfo.roleLevel === 4 : false
    },
    isLead() {
      return this.userInfo ? this.userInfo.isLead : false
    },

    mainTitleList() {
      return [
        { title: '企业资质', component: QualificationContent },
        {
          title: '联系电话',
          component:
            this.basicInfo.haveReceive || this.isSuperAdmin || this.isFromClient
              ? ContactPhone
              : BlockData
        },
        { title: '工商信息', component: Business },
        { title: '风险信息', component: Risk }
        // { title: '跟进记录', component: FollowList }
      ]
    }
    // basicInfo() {
    //     return this.enterpriseItem || {}
    // }
  },
  data() {
    return {
      prePageName: '',
      tableItemDefauletQua: [],
      isFromClient: false,
      detailHeader,
      basicInfo: {},
      enterpriseLogo,
      poke,
      currentDate: '',
      qualifications: [],
      contactList: { all: [], mobile: [], phone: [] },
      followList: [],
      businessInfo: [],
      isAttention: false,
      attentionId: -1,
      clueSources: 0, // 跟进来源
      statusList: [
        { value: '1', text: '有意向', disabled: false },
        { value: '2', text: '无意向', disabled: false },
        { value: '3', text: '已成交', disabled: false },
        { value: '4', text: '未成交', disabled: false },
        { value: '0', text: '未确认', disabled: true }
      ],
      followShow: false,
      shareDisplay: false,
      titleSelectIndex: 0,
      showLoading: false,
      enterpriseId: -1,
      qrCode: '',
      followQualication: [],
      riskLevel: '',
      typeQualificationOptions: [],
      fakeQualificationOptions: []
    }
  },
  methods: {
    ...mapMutations([
      'setQualificationOptions',
      'setEnterpriseInfo',
      'changeTurnPhoneStatus',
      'setATurnOnDefaultQua',
      'changeNotTurnPhoneStatus',
      'setPageHandleFunction',
      'changeEnterpriseKey',
      'setFakerQualification',
      'setQualification',
      'setWatchPhoneModalShow',
      'setNotPhoneCountModalShow',
      'setRowQuaStatus',
      'setShowActivityModal'
    ]),
    goBackPrePage() {
      // console.log(this.clueSources)

      if (this.isFromClient) {
        this.$router.push({
          path: '/product/2'
        })
        // this.$router.replace({
        //   name: 'Product',
        //   query: {
        //     current: '2'
        //   }
        // })
      } else if (this.clueSources === 1) {
        this.$router.push({
          path: '/product/0'
        })
        // this.$router.replace({
        //   name: 'Product',
        //   query: {
        //     current: '0'
        //   }
        // })
      } else if (this.clueSources === 2) {
        this.$router.push({
          path: '/product/1'
        })
        // this.$router.replace({
        //   name: 'Product',
        //   query: {
        //     current: '1'
        //   }
        // })
      }
    },
    phoneIsTurnOn() {
      // if (!this.defaultQua) {
      //     this.setATurnOnDefaultQua(this.tableItemDefauletQua)
      // }
      // this.setATurnOnDefaultQua(this.tableItemDefauletQua)
      this.setEnterpriseInfo({
        ...this.basicInfo,
        clueSources: this.clueSources
      })
      this.changeTurnPhoneStatus(true)
      this.setPageHandleFunction(this.afterTurnOnHandle)
    },
    phoneIsNotTurnOn() {
      this.setEnterpriseInfo({
        ...this.basicInfo,
        clueSources: this.clueSources
      })
      this.changeNotTurnPhoneStatus(true)
      this.setPageHandleFunction(this.afterNotTurnOnHandle)
    },
    afterTurnOnHandle() {
      //  this.getFollowList()
      // this.selectMainTitle(4)
      this.basicInfo = JSON.parse(localStorage.getItem('enterpriseItem'))
      //  this.$emit('havePhoneOperate')
    },
    afterNotTurnOnHandle() {
      // this.getFollowList()
      // this.selectMainTitle(4)
      this.changeEnterpriseKey({ prop: 'haveReceive', key: false })
      this.basicInfo = JSON.parse(localStorage.getItem('enterpriseItem'))
      //  this.$emit('havePhoneOperate')
    },

    // 关闭
    handleCancel() {
      this.shareDisplay = false
    },
    // 关闭
    handleCancel1() {
      this.followShow = false
    },
    setColorFromRisk(riskLevel) {
      this.riskLevel = riskLevel
    },
    selectMainTitle(index) {
      this.titleSelectIndex = index
    },
    setBasicInfoReback() {
      this.titleSelectIndex = 0
    },
    assignSuccess() {
      this.getFollowList()
    },
    setLegalPersonToFilter(person) {
      localStorage.setItem('filterKeyword', person)
      if (parseInt(this.$route.params.clueSources, 10) === 2) {
        this.$router.push({
          path: '/product/1'
        })
        // this.$router.replace({
        //   name: 'Product',
        //   query: { current: 1 }
        // })
      } else {
        this.$router.push({
          path: '/product/0'
        })
        // this.$router.replace({
        //   name: 'Product',
        //   query: {
        //     current: 0
        //   }
        // })
      }
    },
    setPhoneToFilter(phone) {
      this.$emit('setPhoneToFilter', phone)
    },
    showSharQrCode() {
      this.$http
        .get('enterpriseClue/shareEnterpriseTime', {
          enterpriseId: this.enterpriseId,
          userId: this.userInfo.id
        })
        .then(res => {
          const href = window.location.href
          const find = href.indexOf('#')
          const host = href.substr(0, find + 1)
          const currentDate = new Date().getTime()
          const link = 'http://www.xnjx666.com:9000/index.html#'

          // VUE_APP_TYPE === 'online' ? 'http://www.xnjx666.com:9000/index.html#' : host
          // const url = `${link}/enterprise/share?enterpriseId=${this.enterpriseId}&dateTime=${currentDate}&uuid=${res}`
          const pageUrl = `${link}/enterprise/share?enterpriseId=${this.enterpriseId}&dateTime=${currentDate}&uuid=${res}`
          // )
          const url =
            'http://www.xnjx666.com/shareIframe.html?src=' +
            encodeURIComponent(pageUrl)
          this.qrCode = url
          this.$refs.qr.showModal()
        })
    },
    receiveEmterprice() {
      const params = [
        {
          clueSources: this.clueSources,
          enterpriseName:
            this.basicInfo.companyName || this.basicInfo.enterpriseName,
          enterpriseId: this.enterpriseId
        }
      ]
      this.$http
        .post('enterpriseClue/addReceiveCount', params)
        .then(r => {
          const phoneArray = Object.values(r)
          let phoneList = []
          phoneArray.forEach(e => {
            phoneList = e
          })
          this.changeEnterpriseKey({ prop: 'haveReceive', key: true })
          this.changeEnterpriseKey({ prop: 'phoneList', key: phoneList })
          this.enterpriseContact()
          this.getFollowList()
          // this.$emit('detailReceive', params)
          this.basicInfo = JSON.parse(localStorage.getItem('enterpriseItem'))

          this.$message.success('查看成功', 0.5)
        })
        .catch(err => {
          this.changeEnterpriseKey({ prop: 'haveReceive', key: false })
          if (err.code === '5003') {
            Modal.info({
              title: '温馨提示',
              content: err.msg,
              okText: '确定',
              onOk: () => {
                console.log('is ok')
              }
            })
          }
          if (err.code === '5008') {
            this.setNotPhoneCountModalShow(true)
          } else if (err.code==='5013'){
            this.setWatchPhoneModalShow(true)
          } else if (err.code==='5014'){
            this.setShowActivityModal(true) // 活动期间开启该弹窗 活动结束后关闭
          }
        })
    },

    addNewAssign() {
      this.followShow = true
      let qua = []
      let lastFollow = {}
      let onlyDesign = false
      let onlyConstruction = true
      this.qualifications.forEach(e => {
        if (e.industryName.indexOf('设计') > -1) {
          onlyDesign = e.num > 0
        } else {
          onlyConstruction = e.num > 0
        }
        e.certificates.forEach(c => {
          qua = qua.concat(c.items)
        })
      })
      this.setRowQuaStatus({ onlyDesign, onlyConstruction })
      this.$nextTick(() => {
        const params = {
          enterpriseId: this.enterpriseId,
          clueSources: this.clueSources
        }
        this.$http
          .get('followRecord/selNewestFollowRecordById', params)
          .then(res => {
            lastFollow = res.selFollowRecordVO[0]
            qua = qua.filter(q => {
              return q.id
            })
            this.$refs.followRef.setFollowDefault({
              qua,
              lastFollow,
              clueSources: this.clueSources
            })
          })
      })
    },
    addFollow() {
      const follwData = this.$refs.followRef.getFollowModal()

      if (!follwData) {
        return
      }

      const params = {
        ...follwData,
        enterpriseId: this.enterpriseId,
        clueSources: this.clueSources,
        enterpriseName:
          this.basicInfo.companyName || this.basicInfo.enterpriseName
      }

      params.qualificationItemIds = params.qualificationItemIds.join(',')
      this.$http
        .post('followRecord/addFollowRecord', [params])
        .then(() => {
          this.$message.success('操作成功', 0.5)
          // this.getFollowList()
          this.closeFollowDrawer()
          // this.selectMainTitle(4)
          this.$router.push({
            path: '/product/2'
          })
          // this.$router.replace({
          //   name: 'Product',
          //   query: {
          //     current: 2
          //   }
          // })
          let followStatus = ''
          switch (follwData.followStatus) {
            case 1:
              followStatus = '有意向'
              break
            case 4:
              followStatus = '未成交'
              break
            case 3:
              followStatus = '已成交'
              break
            default:
              break
          }
          // console.log(followStatus)
          this.changeEnterpriseKey({ prop: 'followStatus', key: followStatus })
          this.basicInfo = JSON.parse(localStorage.getItem('enterpriseItem'))

          // this.$emit('followStatusChange')
        })
        .catch(() => {
          this.$refs.followRef.setFollowDefault()
        })
    },
    closeFollowDrawer() {
      this.followShow = false
    },
    setSelectStaff(item) {
      // console.log(item)
      this.basicInfo.memberName = item.label
    },

    getQualification() {
      const params = {
        enterpriseId: this.enterpriseId
      }
      this.$http
        .get('qualification/selQualificationByEnterpriseId', params)
        .then(r => {
          r.forEach(e => {
            e.num = 0
            if (e.certificates) {
              if (!e.certificates[0].certificateType) {
                e.certificates = []
              } else {
                e.certificates.forEach(c => {
                  e.num += c.qualificationNum
                })
              }
            } else {
              e.certificates = []
            }

            // this.followQualication.push(...e.items)
          })
          this.qualifications = r
        })
        .catch(err => {
          if (err === 'noqualification') {
            this.qualifications = []
          }
        })
    },
    setRiskLevelFilter(val) {
      this.$emit('setRiskLevelFilter', val)
    },
    enterpriseContact() {
      const params = {
        enterpriseId: this.enterpriseId
      }
      this.$http
        .get('business/baseInfo/selEnterprisePhoneByEnterpriseId', params)
        .then(r => {
          this.contactList.all = r
          this.contactList.mobile = r.filter(e => e.contactsType === '1')
          this.contactList.phone = r.filter(e => e.contactsType === '2')
        })
    },
    async getFollowList() {
      const r = await this.$http.get('followRecord/selFollowRecordById', {
        enterpriseId: this.enterpriseId,
        clueSources: this.clueSources
      })
      this.followList = r
    },
    async getBusinessInfo() {
      const r = await this.$http.get('business/baseInfo/selBusinessInfoById', {
        id: this.enterpriseId
      })
      this.businessInfo = r || {}
    },

    goBack() {
      this.$router.go(-1)
    },
    refreshData() {
      this.showLoading = true
      this.getBusinessInfo() // 企业信息
      const params = {
        companyName:
          this.basicInfo.companyName || this.basicInfo.enterpriseName || ' ',
        socialCreditCode: this.businessInfo.socialCreditCode || ' ',
        areaCode: this.businessInfo.areaCode || ' '
      }
      this.$http
        .get('business/baseInfo/captureDetails', params)
        .then(() => {
          this.showLoading = false
          this.$message.success('正在获取最新数据，请稍后再来查看', 0.5)
        })
        .catch(() => {
          this.showLoading = false
        })
    },
    async getAllData() {
      this.showLoading = true
      this.enterpriseId = this.$route.params.id

      this.basicInfo = JSON.parse(localStorage.getItem('enterpriseItem'))
      if (this.$route.params.clueSources) {
        this.clueSources = parseInt(this.$route.params.clueSources, 10)
      } else {
        this.clueSources = parseInt(this.basicInfo.clueSources, 10)
      }
      this.setEnterpriseInfo(this.basicInfo)
      this.isFromClient = !!parseInt(this.$route.params.isFromClient, 10)
      if (this.isFromClient) {
        this.prePageName = '我的客户'
      } else if (this.clueSources === 1) {
        this.prePageName = '收货查询'
      } else {
        this.prePageName = '出货查询'
      }
      this.currentDate = this.$day().format('YYYY/MM/DD')
      await this.getQualification() // 资质信息
      if (this.basicInfo.haveReceive || this.isFromClient) {
        await this.enterpriseContact() // 企业联系方式
      }
      if (this.basicInfo.isNotHavePhone) {
        // 没有电话
        await this.enterpriseContact()
        this.changeEnterpriseKey({ prop: 'haveReceive', key: true })
      }
      await this.getBusinessInfo() // 企业信息
      // await this.getFollowList() // 跟进记录
      this.showLoading = false
      this.$nextTick(() => {
        const findIndex = this.statusList.findIndex(
          s => s.value === this.basicInfo.followStatusCode
        )
        if (findIndex > -1) {
          this.statusList[findIndex].disabled = true
        }
        this.$set(this.statusList, findIndex, this.statusList[findIndex])
        if (parseInt(this.$route.params.risk, 10)) {
          this.selectMainTitle(3)
        }
      })
    },
    getTypeQualification() {
      this.$http.get('qualification/selQualificationAllByIndustry').then(r => {
        this.setQualification(r)
      })
    },
    getFormQualification() {
      this.$http.get('qualification/selQualificationAll').then(r => {
        this.fakeQualificationOptions = []
        r.forEach(e => {
          e.qualificationLevels.forEach(q => {
            this.fakeQualificationOptions.push({
              label: e.qualificationName + q.level,
              value: q.id
            })
          })
        })
        this.setFakerQualification(this.fakeQualificationOptions)
      })
    }
  },

  mounted() {
    console.log(this.userInfo)
    this.$nextTick(() => {
      this.getAllData()
      this.getFormQualification()
      this.getTypeQualification()
    })
  }
}
</script>
<style lang="scss">
.qr-image-content {
  text-align: center;
  position: relative;
  .logo-content {
    border-radius: 10px;
    padding: 5px;
    width: 50px;
    height: 50px;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    position: absolute;
    background-color: #ffffff;
  }
  .suspension-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 40px;
    height: 40px;
  }
}
.qr-tips {
  text-align: center;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  margin: 0 25px;
}
</style>
<style lang="scss">
.detail-title-list {
  display: flex;
  align-items: center;
  .main-title {
    font-size: 20px;
    color: rgba($color: #2a7be7, $alpha: 0.4);
    margin-bottom: 25px;
    font-weight: bold;
    width: 100px;
    cursor: pointer;
    margin-right: 20px;
    &:hover {
      color: #2a7be7;
    }
  }
  .isSelect {
    color: #2a7be7;
  }
}
</style>
<style lang="scss" scoped>
.company-detail {
  min-width: 1200px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .detail-header {
    width: 100%;
    height: 700px;

    background-position: center;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
  }
  .basic-info {
    padding-top: 150px;
    width: 1200px;
    box-sizing: border-box;
    padding-bottom: 24px;
    position: relative;
    .back-lastpage {
      color: #f4f5ff;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .name-n-count {
      display: flex;
      align-items: flex-start;
      margin-top: 13px;
      color: #f4f5ff;
      .enterprise-logo {
        width: 190px;
        height: 170px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        margin-right: 45px;
      }
      .name {
        font-size: 30px;
        font-weight: bold;
        .status {
          display: inline-block;
          margin-left: 10px;
          //background-color: rgba($color: #4d7cfe, $alpha: 0.6);
          border-radius: 5px;
          // padding: 8px 15px;
          font-size: 22px;
          font-weight: normal;
          color: #f39800;
          width: fit-content;
          text-align: center;
        }
        .other-info {
          margin-top: 20px;
          font-size: 24px;
          font-weight: normal;
        }

        .click-person {
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          img {
            margin-left: 10px;
          }
        }
      }
      .count {
        margin-top: 10px;
        font-size: 13px;
        color: #909399;
      }
    }
    .business-btn {
      font-size: 13px;
      margin-top: 10px;
      color: #4d7cfe;
      cursor: pointer;
    }
    .distribution-btn {
      display: flex;
      align-items: center;
      margin-top: 24px;
      font-size: 13px;
      color: #909399;
      width: 100%;
      justify-content: space-between;
      .user-name {
        margin-right: 50px;
        color: #f4f5ff;
        font-size: 16px;
      }
      .rt-button {
        display: flex;
        align-items: center;
        justify-self: flex-end;
        .turn-on {
          background-color: #f39800;
          color: #ffffff;
          border: 1px solid #f39800;
          &:hover {
            background-color: #ffb029;
            border: 1px solid #ffb029;
          }
        }
        .not-turn-on {
          background-color: #ffffff;
          color: #f39800;
          border: 1px solid #f39800;
          &:hover {
            background-color: #ffb029;
            color: #fff;
            border: 1px solid #ffb029;
          }
        }
      }
      .status {
        margin-right: 95px;
        background-color: #f4f5ff;
        padding: 6px 15px;
        border-radius: 4px;
        .status-tag {
          border-radius: 4px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          padding: 4px 8px;
          color: #fa8f00;
          margin-left: 6px;
          background-color: rgba($color: #fa8f00, $alpha: 0.1);
        }
        .greeTag {
          color: #67c23a;
          background-color: rgba(103, 194, 58, 0.1);
        }
      }
    }
  }
  .icon-right {
    margin-right: 4px;
  }
  .detail-warp {
    min-height: calc(100% - 214px);
    width: 1200px;
    position: relative;
    background-color: #fff;
    padding: 50px;
    border-radius: 6px;
    box-shadow: 0 0 13px 1px #d7d7d7;
    margin-bottom: 40px;
    .spin-loading {
      position: absolute;
      width: 100%;
      text-align: center;
      background-color: rgba($color: #f4f5ff, $alpha: 0.2);
      z-index: 1000;
      height: 100%;
      .a-spin {
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
      }
    }
    .content {
      background-color: #ffffff;
      width: 100%;
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
}
.not-turnon-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    font-size: 14px;
  }
  .select-input {
    width: 70%;
  }
}
</style>
