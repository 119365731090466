<template>
  <div class="lf-tool" @click="goToProduct">
    <img :src="rock" />
    立即体验
  </div>
</template>

<script>
import rock from '@/assets/sideTool/rock.png'
export default {
  name: 'lf-tool',
  computed: {
    projectEnv() {
      if (process.env.VUE_APP_TYPE) {
        return process.env.VUE_APP_TYPE
      } else {
        return 'online'
      }
    }
  },
  data() {
    return {
      rock
    }
  },
  methods: {
    goToProduct() {
      if (this.projectEnv === 'dev') {
        window.open('http://localhost:8080/#/longna/receipt')
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open('http://www.xnjx666.com:9000/#/longna/receipt')
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open('http://192.168.0.108:8099/#/longna/receipt')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lf-tool {
  width: 128px;
  height: 92px;
  background-color: #ff7f28;
  z-index: 555;
  border-radius: 0 70px 70px 0;
  position: fixed;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  bottom: 370px;
  box-shadow: 1px 0px 9px 2px rgba(255, 127, 40, 0.2);
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
  img {
    // margin-top: 12px;
    margin-bottom: 3px;
  }
}
</style>
