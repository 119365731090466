<template>
  <div class="qr-main-page">
    <div class="header-content" id="qrHeader">
      <div class="buying-content">
        <div class="title-tools">
          <div class="page-title " @click="goToShare">
            我要买资质
            <a-icon type="right" />
          </div>
          <div class="qua-search">
            <a-select
              show-search
              :value="searchQua"
              placeholder="搜索您想要的资质"
              :default-active-first-option="false"
              :show-arrow="false"
              :filter-option="false"
              :getPopupContainer="getPopupContainer"
              allowClear
              labelInValue
              style="width:90%"
              :not-found-content="null"
              @search="handleSearch"
              @change="handleChange"
            >
              <a-select-option
                v-for="(s, index) in resOption"
                :key="index"
                :value="s.value"
              >
                {{ s.label }}
              </a-select-option>
            </a-select>
            <a-button icon="search" @click="goToSharedBySearch"></a-button>
          </div>
        </div>
        <div class="qualification-list">
          <div class="qua-line">
            <a-icon type="tool" />
            <div class="qua-type">施工总承包</div>
            <div
              class="qua-item"
              @click="goToShareHaveQua(item)"
              v-for="item in EPC"
              :key="item.value"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="qua-line">
            <a-icon type="project" />
            <div class="qua-type">施工专业承包</div>
            <div
              class="qua-item"
              @click="goToShareHaveQua(item)"
              v-for="item in PC"
              :key="item.value"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="qr-line"></div>
      <div class="sell-content">
        <div class="title-tools">
          <div class="page-title" @click="goToSell">
            我要卖资质
            <a-icon type="right" />
          </div>
          <div class="sell-num">
            有 <b>{{ buySize }}</b> 人正在找资质
          </div>
        </div>
        <div class="sell-btns">
          <a-button size="large" class="sell-btn quickly" @click="quicklySubmit"
            >快速发布
          </a-button>
          <a-button
            type="primary"
            size="large"
            class="sell-btn"
            @click="goToExcellentShare"
          >
            优选发布
          </a-button>
          <a-button
            type="primary"
            size="large"
            class="sell-btn"
            @click="goToAutarkyRelease"
          >
            自营发布
          </a-button>
        </div>
      </div>
    </div>
    <div class="excellent-content">
      <a-button
        size="small"
        class="more-btn"
        type="link"
        @click="goToShareUserChannel"
        >更多小牛优选在售资源
      </a-button>
      <div class="ex-title">小牛优选</div>
      <div class="tips">由小牛平台精挑细选并审核，让您放心选取可靠的资质</div>
      <div class="ex-list">
        <div
          class="ex-item"
          @click="goToShareUserChannel"
          v-for="(item, key) in excellentData"
          :key="key"
        >
          <div
            class="item-image-box"
            :style="'background-image:url(' + quaImage + ')'"
          >
            <!-- <div
              class="item-image"
              
            ></div> -->
          </div>
          <div class="item-name">{{ item.qualificationNameAndLevel }}</div>
          <div class="item-other">
            <div class="other-info">{{ item.areaName }}</div>
            <div class="other-info">
              {{ item.authenFeatureVO.isNeedLetter ? '包函' : '不包函' }}
            </div>
            <div class="other-info">
              {{
                item.authenFeatureVO.isHoldingCompany ? '不可控股' : '可控股'
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="model-description"
      :style="'background-image:url(' + qrInfo + ')'"
    >
      <div class="tab-name-list">
        <div
          v-for="(item, key) in tabs"
          :key="key"
          class="tab-name"
          @click="changeTabs(key)"
          :class="{ selected: currentTab === key }"
        >
          {{ item.name }}
        </div>
      </div>
      <div v-for="(item, key) in tabs" :key="key">
        <div v-show="key === currentTab" class="model-pane">
          <div class="tabs-title">{{ item.title }}</div>
          <div class="tabs-description">{{ item.description }}</div>
          <div class="tabs-process">
            <div
              class="pr-item"
              v-for="(pr, index) in item.process"
              :key="index"
            >
              <a-icon class="pr-icon" :type="pr.icon"></a-icon>
              <div>
                <span class="pr-remark">
                  {{ pr.remark }}
                </span>
                <a-icon
                  v-show="index < item.process.length - 1"
                  class="next-icon"
                  type="double-right"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qr-foot" :style="'background-image:url(' + qrBottom + ')'">
      <div class="slogan-content">
        <div
          class="slogan"
          v-html="'让您快速找到 &nbsp;&nbsp; 想要的资质'"
        ></div>
        <div class="slogan-phone">
          <a-icon type="phone" theme="filled" class="right-phone"></a-icon>
          免费咨询电话13438870357
        </div>
      </div>
      <div class="jump-btns">
        <a-button
          size="large"
          class="jump-btn"
          type="primary"
          @click="goToShare"
        >
          我要买资质
        </a-button>
        <a-button size="large" class="jump-btn sell-btn" @click="goToSell">
          我要卖资质
        </a-button>
      </div>
    </div>
    <div>
      <a-modal
        title="平台自营发布"
        :visible="autarkyReleaseShow"
        :destroyOnClose="true"
        @cancel="handleCancel"
        class="contactMode"
      >
        <a-form-model
          ref="contactForm"
          :model="contactModeData"
          :rules="contactModeRules"
          :label-col="{ span: 5 }"
          :wrapper-col="{ offset: 5 }"
        >
          <a-form-model-item ref="name" label="联系人" prop="name">
            <a-input
              v-model.trim="contactModeData.name"
              placeholder="请输入联系人"
            />
          </a-form-model-item>
          <a-form-model-item ref="phone" label="手机" prop="phone">
            <a-input
              v-model.trim="contactModeData.phone"
              placeholder="请留下手机，我们会及时联系您"
            />
          </a-form-model-item>
          <a-form-model-item label="验证码:" prop="verificationCode">
            <div style="display: flex;">
              <a-input
                style="margin-right: 15px;"
                v-model.trim="contactModeData.verificationCode"
                placeholder="请输入短信验证码"
              ></a-input>
              <a-button
                :disabled="codeDisabled"
                @click="sendVerifycode"
                class="verify-btn"
                type="primary"
                >{{ codeBtnText }}
              </a-button>
            </div>
          </a-form-model-item>
          <div class="tips">
            注意：如需发布平台自营资质，请留下您的联系方式，您的专属客服稍后将会和您取得联系。
          </div>
        </a-form-model>
        <template slot="footer">
          <a-button type="primary" @click="handleOk">提交 </a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>
<script>
import { Input, Tabs, Select, FormModel } from 'ant-design-vue'
import { mapState, mapMutations } from 'vuex'

// import qrBg from '@/assets/image/qr/qr_bg.png'
import qrBottom from '@/assets/image/qr/qr_bottom.png'
import qrInfo from '@/assets/image/qr/qr_info.png'
import quaImage from '@/assets/image/qr/qua_image.png'

export default {
  name: 'qr-main',
  components: {
    aInputSearch: Input.Search,
    aTabs: Tabs,
    aSelect: Select,
    aSelectOption: Select.Option,
    aTabsPane: Tabs.TabPane,
    aFormModel: FormModel,
    aInput: Input,
    aFormModelItem: FormModel.Item
  },
  computed: {
    ...mapState({
      fakeQualification: state => state.fakeQualificationOptions,

    })
  },
  data() {
    return {
      EPC: [
        { label: '建筑工程一级', value: '5' },
        { label: '市政公用工程一级', value: '41' },
        { label: '钢结构工程二级', value: '70' },
        { label: '机电工程二级', value: '48' },
        { label: '水利水电工程二级', value: '22' }
      ], // 总承包
      PC: [
        { label: '建筑装饰装修一级', value: '72' },
        { label: '环保工程一级', value: '136' },
        { label: '建筑幕墙一级', value: '77' },
        { label: '地基基础一级', value: '50' },
        { label: '电子与智能化工程二级', value: '58' }
      ], // 专业承包
      excellentData: [],
      currentTab: 0,
      tabs: [
        {
          name: '小牛优选',
          title: '平台严选',
          description: '由平台精心严选并审核，让您放心购买',
          process: [
            { icon: 'file-done', remark: '需求确认' },
            { icon: 'audit', remark: '提交审核' },
            { icon: 'api', remark: '对接买家' }
          ]
        },
        {
          name: '平台自营',
          title: '平台自营',
          description: '由平台发布，资源100%精准',
          process: [
            { icon: 'like', remark: '确认资质' },
            { icon: 'phone', remark: '查看电话' },
            { icon: 'swap', remark: '交易成功' }
          ]
        },
        {
          name: '购买流程',
          title: '购买资质',
          description: '在资质社区中挑选或者拨打客服电话13438870357',
          process: [
            { icon: 'file-search', remark: '寻找资质' },
            { icon: 'message', remark: '联系卖家' },
            { icon: 'gift', remark: '达成交易' }
          ]
        },
        {
          name: '出售流程',
          title: '出售资质',
          description: '快速发布想要出售的资质信息',
          process: [
            { icon: 'file-add', remark: '在线发布' },
            { icon: 'audit', remark: '提交审核' },
            { icon: 'money-collect', remark: '交易达成' }
          ]
        }
      ],
      searchQua: undefined,
      resOption: [],
      searchOptions: [],
      qrBottom,
      qrInfo,
      quaImage,
      buySize: 0,
      autarkyReleaseShow: false,
      contactModeData: {
        name: '',
        phone: '',
        verificationCode: null
      },
      codeDisabled: false,
      codeBtnText: '获取验证码',
      codeSIV: null,
      contactModeRules: {
        name: [{ required: true, validator: this.verifyName, trigger: 'blur' }],
        phone: [
          { required: true, validator: this.validMobile, trigger: 'blur' }
        ],
        verificationCode: [
          {
            validator: this.verifyCodevalidate,
            required: true,
            message: '请输入正确的验证码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validMobile(rule, value, callback) {
      const mobileTest = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else if (!mobileTest.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    },
    verifyName(rule, value, callback) {
      if (value === '') {
        callback(new Error('请输入联系人'))
      }
      // else if (
      //     value.toLocaleLowerCase() !== this.identifyCode.toLocaleLowerCase()
      // ) {
      //   callback(new Error('请输入'))
      // }
      else {
        callback()
      }
    },
    changeTabs(key) {
      this.currentTab = key
    },
    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('qrHeader')
    },
    handleChange(item) {
      this.searchQua = item
      if (!item) {
        this.resOption = []
        return
      }
      this.handleSearch(item.label)
    },
    handleSearch(search) {
      if (!search) {
        this.resOption = []
      } else {
        this.resOption = []
        this.fakeQualification.forEach(s => {
          if (s.label.indexOf(search) > -1) {
            this.resOption.push(s)
          }
        })
      }
    },
    goToShareUserChannel() {
      localStorage.setItem('channel', '1')
      this.$router.push({
        name: 'shared'
      })
    },
    goToSharedBySearch() {
      if (!this.searchQua) {
        this.$message.warning('请输入要查询的资质名称关键词！', 0.5)
        return
      }
      this.goToShareHaveQua({
        label: this.searchQua.label,
        value: this.searchQua.key
      })
    },
    goToShareHaveQua(item) {
      const shareQua = JSON.stringify(item)
      localStorage.setItem('shareQua', shareQua)
      this.$router.push({
        name: 'shared'
      })
    },
    goToExcellentShare() {
      this.$router.push({
        name: 'excellentShare',
        query: {
          channel: '1',
          from: this.$route.name
        }
      })
    },
    goToAutarkyRelease() {
      this.autarkyReleaseShow = true
    },
    goToShare() {
      // this.$emit('goToShare')
      this.$router.replace({
        name: 'shared'
        // query: {
        //     current: -1
        // }
      })
    },
    goToSell() {
      this.$router.replace({
        name: 'sell'
      })
    },
    quicklySubmit() {
      this.$emit('fastSend')
    },
    getExecellentList() {
      const parmas = {
        isNeedLetter: true,
        pageSize: 5,
        sortType: 1,
        pageNum: 1,
        channel: 1 // 小牛精选
      }
      this.$http.post('shared/query', parmas).then(res => {
        // console.log(res)
        this.excellentData = res.data
      })
    },
    getAllBuyList() {
      this.$http.post('buy/buyResourcesList').then(res => {
        this.buySize = res.totalSize
      })
    },

    handleOk() {
      this.$refs.contactForm.validate(val => {
        if (val) {
          const params = {
            contactsName: this.contactModeData.name,
            contactsPhone: this.contactModeData.phone,
            verifyCodeInput: this.contactModeData.verificationCode
          }
          this.$http.post('selfRelease/add', params).then(r => {
            this.$message.success('操作成功', 0.5)
            this.autarkyReleaseShow = false
            this.emptyContactModeData()
          })
        }
      })
    },
    emptyContactModeData() {
      this.contactModeData.name = ''
      this.contactModeData.phone = ''
      this.contactModeData.verificationCode = null
      this.codeBtnText = '获取验证码'
      this.codeDisabled = false
      clearInterval(this.codeSIV)
      this.codeSIV = null
    },
    handleCancel() {
      this.autarkyReleaseShow = false
      this.emptyContactModeData()
    },
    sendVerifycode() {
      if (this.codeSIV) {
        return
      }
      this.$refs.contactForm.validateField('phone', message => {
        if (!message) {
          this.$http
            .post(
              'user/sms/verificte/send',
              { cellPhone: this.contactModeData.phone },
              'application/x-www-form-urlencoded'
            )
            .then(r => {
              this.codeDisabled = true
              let time = 120
              this.codeSIV = setInterval(() => {
                time -= 1
                this.codeBtnText = `重新获取(${time}S)`
                if (time === 1) {
                  this.codeBtnText = '获取验证码'
                  this.codeDisabled = false
                  clearInterval(this.codeSIV)
                  this.codeSIV = null
                }
              }, 1000)
            })
        }
      })
    }
  },
  mounted() {
    this.getExecellentList()
    this.getAllBuyList()
  }
}
</script>

<style lang="scss" scoped>
.qr-main-page {
  //   padding: 12px 24px;
  padding-bottom: 40px;
  background-color: transparent;
  width: 1200px;
  .header-content {
    padding: 25px;
    height: 180px;
    background-color: #fff;
    position: relative;
    display: flex;
    border-radius: 4px;
    align-items: flex-start;
    justify-content: space-between;

    .title-tools {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .page-title {
        flex-shrink: 0;
        font-size: 20px;
        cursor: pointer;
        font-weight: bold;

        &:hover {
          color: #4d7cfe;
        }
      }

      .qua-search {
        width: 50%;
        margin-right: 1%;
        display: flex;
        align-self: center;
      }

      .sell-num {
        margin-right: 10px;
      }
    }

    .buying-content {
      width: 68%;
      height: 100%;
      flex-shrink: 0;

      .qualification-list {
        margin-top: 15px;
      }

      .qua-line {
        height: 45px;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        font-size: 14px;

        .qua-type {
          font-weight: bold;
          width: 110px;
          margin-left: 10px;
        }

        .qua-item {
          flex-shrink: 0;
          width: 130px;
          cursor: pointer;
          font-size: 13px;

          &:hover {
            color: #4d7cfe;
          }

          //   width: fit-content;
          //   margin-right: 10px;
        }
      }
    }

    .sell-content {
      width: 28%;
      height: 100%;
      flex-shrink: 0;

      .sell-btns {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100px;

        .sell-btn {
          width: 100px;
          margin: 0 5px;
        }

        .quickly {
          position: relative;

          &:after {
            content: '只需一步，买家找上门';
            height: 20px;
            position: absolute;
            bottom: -30px;
            width: 100%;
            text-align: center;
            left: 0;
            font-size: 12px;
            color: rgba(51, 51, 51, 0.65);
          }
        }
      }
    }

    .qr-line {
      width: 1px;
      height: 140px;
      background-color: #ddd;
    }
  }

  .excellent-content {
    margin-top: 16px;
    border-radius: 4px;
    padding: 24px;
    background-color: #fff;
    position: relative;

    .more-btn {
      position: absolute;
      right: 24px;
      top: 24px;
    }

    .ex-title {
      font-size: 16px;
      margin-bottom: 6px;
      font-weight: bold;

      &:after {
        content: '为您精选';
        font-size: 13px;
        color: #999;
        margin-left: 15px;
      }
    }

    .ex-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-top: 20px;
      // padding-bottom: 18px;
      .ex-item {
        width: 210px;
        border: solid 1px #ddd;
        height: 290px;
        cursor: pointer;
        position: relative;
        border-radius: 4px;
        margin: 0 20px 20px 0;
        overflow: hidden;

        .item-image-box {
          width: 100%;
          height: 200px;
          // background-color: #4d7cfe;
          position: relative;
          overflow: hidden;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: top;
        }

        .item-name {
          margin-top: 20px;
          width: 100%;
          text-align: center;
          font-size: 13px;
          word-wrap: break-word;
          word-break: normal;
        }

        .item-other {
          position: absolute;
          height: 40px;
          bottom: 0;
          width: 100%;
          left: 0;
          //   background-color: #ddd;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .other-info {
            height: 25px;
            line-height: 25px;
            width: 33%;
            text-align: center;
            border-right: 1px solid #ddd;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:last-child {
              border-color: transparent;
            }
          }
        }

        &:hover {
          border-color: #4d7cfe;
          box-shadow: 1px 2px 3px 3px #f1f1f1;
        }
      }
    }
  }

  .model-description {
    height: 262px;
    background-size: cover;
    padding: 24px;
    background-color: #fff;
    color: #fff;

    .tab-name-list {
      display: flex;
      justify-content: space-between;
      width: 312px;
      height: 30px;
      box-sizing: content-box;
      padding-left: 70px;

      .tab-name {
        font-size: 14px;
        width: 77px;
        cursor: pointer;
        text-align: center;
      }

      .selected {
        color: #4d7cfe;
        border-bottom: solid 2px #4d7cfe;
      }
    }

    .model-pane {
      padding-left: 130px;
      // padding-top: 20px;
      .tabs-title {
        font-size: 25px;
        margin: 20px 0;
        font-weight: bold;
      }

      .tabs-description {
        font-size: 13px;
        // margin-top: 10px;
      }

      .tabs-process {
        margin-top: 20px;
        display: flex;
        align-items: center;
      }

      .pr-item {
        height: 70px;
        margin-right: 30px;
        font-size: 13px;

        .pr-icon {
          //   width: 0px;
          font-size: 30px;
          margin-bottom: 15px;
        }

        .next-icon {
          margin-left: 20px;
        }
      }
    }
  }

  .qr-foot {
    margin-top: 20px;
    height: 156px;
    width: 100%;
    // background-color: #fff;
    display: flex;
    align-items: center;
    background-size: cover;
    justify-content: center;

    .slogan-content {
      //   height: 100px;
      // wi;
      text-align: center;

      .slogan {
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 10px;
      }

      .slogan-phone {
        font-size: 15px;
        color: #fff;

        .right-phone {
          margin-top: 4px;
          transform: rotate(90deg);
          margin-right: 8px;
        }
      }
    }

    .jump-btns {
      margin-left: 100px;
      // width: 240px;
      display: flex;
      justify-content: space-between;

      .jump-btn {
        width: 130px;
        font-weight: bold;
        height: 45px;
        margin: 0 10px;
      }

      .sell-btn {
        border-color: #ff6a34;
        background-color: #ff6a34;
        color: #fff;

        &:hover {
          background-color: #ff885d;
        }
      }
    }
  }
}
</style>
