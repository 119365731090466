<template>
  <div class="page-footer">
    <div class="contact-us">
      <div class="top">
        <div class="nav">
          <p class="title">导航栏</p>
          <ul>
            <li v-for="(item, index) in navigationData" :key="index + 'jj'">
              <router-link
                v-if="item.name !== 'Product'"
                style="color:#A0A6AB"
                :to="{ name: item.name }"
              >
                {{ item.text }}
              </router-link>
              <span style="color:#A0A6AB" @click="goToXiaoniuReceipt" v-else>
                产品
              </span>
            </li>
          </ul>
        </div>
        <div class="sources">
          <p class="title">数据源来源</p>
          <ul>
            <li>
              中华人民共和国住房和城乡建设部
            </li>
            <li>
              <p>各省级一体化平台</p>
            </li>
            <li>
              全国企业信用信息公示系统
            </li>
            <li>
              中国执行信息公开网
            </li>
          </ul>
        </div>
        <div class="contact">
          <p class="title">联系您的服务管家</p>
          <ul>
            <li>
              <img :src="BT" width="30" />
              <span>电话：</span><span>13438870357</span>
            </li>
            <li>
              <img :src="BE" width="30" />
              <span>邮箱：</span><span>xiaoniujianxun@163.com</span>
            </li>
            <li>
              <img :src="BL" width="30" />
              <span>地址：</span><span>四川省成都市成都高新区茂业中心B座</span>
            </li>
          </ul>
        </div>
        <div class="others">
          <div class="logo">
            <img :src="logoWhite" width="122" alt="小牛建讯" />
          </div>
          <div class="qr-code">
            <div class="OfficialAccounts">
              <img :src="OfficialAccounts" width="100px" alt="小牛建讯" />
              <p>公众号</p>
            </div>
            <div class="OfficialAccounts">
              <img :src="bottomApplet" width="100px" alt="小牛建讯" />
              <p>小程序</p>
            </div>
          </div>
        </div>
      </div>
      <div class="middle">
        <p>友情链接：</p>
        <p v-for="(item, index) in links" :key="index + 'ddd'">
          <a :href="item.url" target="_blank">{{ item.text }}</a>
        </p>
      </div>
      <div class="button">
        <span>
          ©最终解释权归仕硕科技有限公司所有
        </span>
        <a class="beian" href="https://beian.miit.gov.cn">
          蜀ICP备2020029605号-1</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/homePage/logo.png'
import logoWhite from '@/assets/footer/logo_white.png'

import buttomQr from '@/assets/footer/buttomQr.png'
import topQrCode from '@/assets/footer/topQrCode.png'
import bottomBanner from '@/assets/footer/bottomBanner.png'
import win7Blue from '@/assets/footer/win7Blue.png'
import winBlue from '@/assets/footer/winBlue.png'
import buttomLogo from '@/assets/footer/buttomLogo.png'
import bottomBannerLogo from '@/assets/footer/bottomBannerLogo.png'
import BE from '@/assets/footer/bottom_email.png'
import BL from '@/assets/footer/bottom_location.png'
import BT from '@/assets/footer/bottom_tel.png'
import OfficialAccounts from '@/assets/footer/OfficialAccounts.jpg'
import bottomApplet from '@/assets/footer/bottomApplet.png'

export default {
  name: 'page-footer',
  data() {
    return {
      BE,
      BL,
      BT,
      logoWhite,
      logo,
      buttomQr,
      topQrCode,
      bottomBanner,
      win7Blue,
      winBlue,
      buttomLogo,
      OfficialAccounts,
      bottomApplet,
      bottomBannerLogo,
      win7Url: '',
      win10Url: '',
      navigationData: [
        { text: '首页', name: 'Home' },
        { text: '产品', name: 'Product' },
        // { text: '解决方案', name: '' },
        { text: 'VIP会员', name: 'Vip' },
        { text: '探索小牛', name: 'HelpCenterDefault' },
        // { text: '帮助中心', name: '' },
        { text: '软件下载', name: 'Download' }
        // { text: '企业名录', name: 'iframeIndex' }
      ],
      sourceData: [
        {
          text: '中华人民共和国住房和城乡建设部',
          url: 'http://jzsc.mohurd.gov.cn/home'
        },
        { text: '各省级一体化平台' },
        {
          text: '全国企业信用信息公示系统',
          url: 'http://www.gsxt.gov.cn/index.html'
        },
        { text: '中国执行信息公开网', url: 'http://zxgk.court.gov.cn/' }
      ],
      links: [
        { text: '小牛建讯', url: 'http://www.xnjx666.com' },
        { text: '建筑资质查询', url: 'http://www.xnjx666.com' },
        { text: '建筑公司查询系统', url: 'http://www.xnjx666.com' }
      ]
    }
  },
  computed: {
    projectEnv() {
      if (process.env.VUE_APP_TYPE) {
        return process.env.VUE_APP_TYPE
      } else {
        return 'online'
      }
    }
  },
  methods: {
    goToPage(name) {
      if (this.$route.name === name) {
        return
      }
      this.$router.push({
        name
      })
    },
    goToXiaoniuReceipt() {
      // 收货
      if (this.projectEnv === 'dev') {
        window.open('http://localhost:8080/#/longna/receipt')
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open('http://www.xnjx666.com:9000/#/longna/receipt')
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open('http://192.168.0.108:8099/#/longna/receipt')
      }
    }
  },
  mounted() {
    this.$http.get('/sys/config/query/appaddr/win10').then(res => {
      this.win7Url = res
    })
    this.$http.get('/sys/config/query/appaddr/win7').then(res => {
      this.win10Url = res
    })
  }
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
ul,
li {
  list-style: none;
}
@media screen and (max-width: 2000px) {
  .contact-us {
    width: 1200px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1400px) {
  .contact-us {
    margin: 0 auto;
    width: 1200px;
  }
}

.page-footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #ffffff;
  background-color: #071b32;
  align-items: center;

  .slogan {
    height: 418px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 168px;

    .text {
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 33px;
    }

    .download {
      margin-top: 30px;

      .target {
        display: inline-block;
        width: 260px;
        height: 60px;
        margin: 0 20px;
        text-align: center;
        line-height: 60px;
        color: #0048d0;
        font-size: 28px;
        border-radius: 30px;
        position: relative;
        padding-left: 40px;
        background-color: #fff;

        img {
          position: absolute;
          left: 30px;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 40px;
        }
      }
    }
  }

  .contact-us {
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    // height: 419px;
    color: #eaeaea;
    .top {
      text-align: left;
      height: 230px;
      padding-top: 40px;
      display: flex;
      align-items: flex-start;
      .others {
        margin-left: 80px;
        padding-left: 80px;
        padding-bottom: 16px;
        border-left: 1px solid rgba(255, 255, 255, 0.1);
        .qr-code {
          display: flex;
          margin-top: 24px;
        }
      }
      .OfficialAccounts {
        margin-right: 24px;
        p {
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          margin-top: 10px;
          text-align: center;
        }
      }
      .nav {
        .title {
          font-size: 16px;
          // font-weight: bold;
          color: #ecf3fe;
        }
        ul {
          margin-top: 5px;
          li {
            cursor: pointer;
            line-height: 32px;
          }
        }
      }
      .sources {
        margin-left: 80px;
        .title {
          font-size: 16px;
          // font-weight: bold;
          color: #ecf3fe;
        }
        ul {
          margin-top: 5px;
          li {
            line-height: 34px;
            color: #a0a6ab;
            a {
              font-size: 14px;
              color: #a0a6ab;
              font-weight: 400;
            }
          }
        }
      }
      .contact {
        margin-left: 54px;
        .title {
          font-size: 16px;
          // font-weight: bold;
          color: #ecf3fe;
        }
        ul {
          margin-top: 5px;
          li {
            line-height: 34px;
            font-size: 16px;
            margin-top: 12px;
            font-weight: 400;
            color: #fff;
            img {
              margin-right: 12px;
            }
          }
        }
      }
    }
    .middle {
      align-items: flex-start;
      border-bottom: 1px solid #fff;
      border-color: rgba(#fff, 0.1);
      margin-top: 30px;
      padding-bottom: 24px;
      display: flex;
      flex-wrap: wrap;
      p {
        margin-right: 40px;

        color: #a0a6ab;
        a {
          font-size: 14px;
          font-weight: 400;
          color: #a0a6ab;
        }
      }
    }
    .button {
      width: 1200px;
      // line-height: 56px;
      text-align: center;
      font-size: 14px;
      margin-top: 24px;
      color: #a0a6ab;
      margin-bottom: 35px;
      .beian {
        color: #a0a6ab;
        font-size: 14px;
      }
    }
  }
}
</style>
