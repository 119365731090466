<template>
    <div class="image-upload">
        <div class="image-list">
            <div
                :style="item.style || { width: '100px', height: '100px' }"
                v-for="(url, index) in imageList"
                :key="index"
                class="image-items"
            >
                <div class="preview-image" :style="'background-image:url(' + url + ')'">
                    <div class="remove-content">
                        <a-button class="remove-btns" @click="removeThisImage(index)" type="danger"
                            >删除</a-button
                        >
                    </div>
                </div>
            </div>

            <div
                v-if="imageList.length < item.length"
                :style="item.style || { width: '100px', height: '100px' }"
                class="select-content"
            >
                <div class="loading" v-if="loading">
                    <a-icon type="loading" />
                    正在上传……
                </div>
                <div class="havebg-image" :style="'background-image:url(' + bgImage + ')'"></div>
                <input
                    class="image-select"
                    type="file"
                    :id="item.id"
                    :accept="item.accept || 'image/*'"
                    @change="e => uploadToServer(e, item)"
                />
                <div class="add-tips">
                    <a-icon class="add-icon" type="plus" />
                    <p>请选择图片</p>
                </div>
                <!-- <div class="operate-btns">
        <a-button type="primary" @click="uploadToServer(item)"
          >上传图片</a-button
        >
        <a-button @click="reSelectImage(item)" type="default"
          >取消上传</a-button
        >
      </div> -->
            </div>
        </div>
        <div class="tips" v-if="item.tips">{{ item.tips }}</div>
    </div>
</template>

<script>
export default {
    name: 'upload-image',
    data() {
        return {
            loading: false,
            bgImage: '',
            imageList: []
        }
    },
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
        value: {}
    },
    methods: {
        // setImageUrl(e) {
        //   const file = e.target.files[0]
        //   // console.log(file)
        //   const image = URL.createObjectURL(file)
        //   this.bgImage = image
        //   this.uploadToServer()
        //   //  urls.push(image)
        // },
        // reSelectImage(item) {
        //   this.bgImage = ''
        //   const input = document.getElementById(item.id)
        //   input.value = ''
        //   // input.click()
        // },
        removeThisImage(index) {
            this.imageList.splice(index, 1)
            this.$emit('removeImage', { index, name: this.item.name })
        },
        uploadToServer(e, item) {
            const file = e.target.files[0]
            if (!file) {
                return
            }
            const size = file.size
            if (size > item.size) {
                this.$message.warning('文件过大!',0.5)
                return
            }
            this.bgImage = URL.createObjectURL(file)
            const uploadForm = new FormData()
            uploadForm.append('file', file)

            // console.log(item)
            this.loading = true
            //  setTimeout(() => {

            // }, 500)
            this.$http
                .uploadFile('/cloud/upload', uploadForm)
                .then(r => {
                    this.loading = false
                    this.$message.success('上传成功',0.5)
                    this.imageList.push(URL.createObjectURL(file))
                    const input = document.getElementById(item.id)
                    input.value = ''
                    this.bgImage = ''
                    // console.log(this.imageList)
                    // console.log(this.bgImage)
                    this.$emit('uploadSuccess', { url: r, name: item.name })
                })
                .catch(() => {
                    this.loading = false
                    const input = document.getElementById(item.id)
                    input.value = ''
                    this.bgImage = ''
                })
        }
    },
    mounted() {
        this.$nextTick(() => {
            if (this.item.default.length) {
                this.imageList = this.$deepCopy(this.item.default)
            }
        })
    }
    // watch: {
    //   item: {
    //     handler() {
    //       console.log(this.value)
    //       // if (this.item.default.length) {
    //       //   this.imageList = this.$deepCopy(this.item.default)
    //       // }
    //     },
    //   },
    // },
}
</script>
<style lang="scss" scoped>
.image-upload {
    display: flex;
    margin-bottom: 5px;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    .select-content {
        position: relative;
        border: 1px dashed #999999;
        border-radius: 4px;
        margin-right: 15px;
        flex-shrink: 0;
        margin-bottom: 15px;
        // overflow: hidden;
        .havebg-image {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            z-index: 1000;
            position: relative;
        }
        .image-select {
            opacity: 0;
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1001;
            height: 100%;
        }
        .add-tips {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 999;
            width: 100%;
            text-align: center;
            height: 30px;
            margin: auto;
            .add-icon {
                font-size: 18px;
            }
            p {
                margin: 0 !important;
                font-size: 13px;
                height: 20px;
                line-height: 20px;
            }
        }
        .operate-btns {
            height: 50px;
            position: absolute;
            left: 100%;
            padding-left: 20px;
            top: 50%;
            transform: translateY(50%);
            margin: auto;
            // z-index: 1999;
        }
        .loading {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            text-align: center;
            top: 0;
            left: 0;
            color: #fafafa;
            padding-top: 40%;
            position: absolute;
            z-index: 1002;
        }
    }
    .image-list {
        display: flex;
        align-items: center;
        max-width: 100%;
        flex-wrap: wrap;
        flex-shrink: 0;
        .image-items {
            margin-right: 20px;
            margin-bottom: 15px;
            .preview-image {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                background-position: center;
                background-size: contain;
                border: 1px dashed #999;
                background-repeat: no-repeat;
                position: relative;

                .remove-content {
                    width: 100%;
                    height: 100%;
                    display: none;
                    background-color: rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                    .remove-btns {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        margin: auto;
                        // text-align: center;
                    }
                }
                &:hover {
                    .remove-content {
                        display: block;
                    }
                }
            }
        }
    }
    .tips {
        // position: absolute;
        font-size: 12px;
        width: 100%;
        // bottom: -10px;
    }
}
</style>
