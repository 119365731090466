<template>
    <div>
        <div class="follow-list">
            <div class="follow-item" v-for="(item, index) in followList" :key="index">
                <div class="time-label" v-for="(follow, fi) in item.selFollowRecordVO" :key="fi">
                    <div class="circle havebg"></div>
                    <div class="label">
                        <div class="tab"></div>
                        <div class="little-circle"></div>
                        {{ follow.timeLabel }}
                    </div>
                    <div
                        class="follow-detail"
                        v-for="(detail, di) in follow.followRecordList"
                        :key="di"
                    >
                        <div class="circle"></div>
                        <div class="follow-user">
                            <div class="name-type">
                                <span>{{ detail.memberName || '空' }}</span>
                                <span>{{ detail.followType }}</span>
                            </div>
                            <div class="detail-time">
                                {{ detail.createTime | time }}
                            </div>
                        </div>
                        <div class="detail-content">
                            <div v-if="detail.qualificationItems.length">
                                跟进资质：
                                <span v-text="qualificationText(detail.qualificationItems)"></span>
                            </div>
                            <div v-if="detail.reason">
                                跟进状态：{{ detail.reason }}
                                {{
                                    detail.reason === '未成交' || detail.reason === '无意向'
                                        ? '----资源退回公海'
                                        : ''
                                }}
                            </div>
                            <div>跟进来源：{{ clueSourcesText[detail.clueSources] || '-' }}</div>
                            <!-- <div v-if="detail.outcallOrderId">
                外呼单号：{{ detail.outcallOrderId }}
              </div> -->
                            <div v-if="detail.content">跟进备注：{{ detail.content }}</div>
                            <div v-if="detail.remarks">跟进备注：{{ detail.remarks }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!followList.length" class="no-data">暂无跟进记录</div>
    </div>
</template>
<script>
import day from 'dayjs'
export default {
    name: 'follow-list',
    filters: {
        time(val) {
            const dateTime = new Date(val)
            return day(dateTime).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    data() {
        return {
            clueSourcesText: ['', '收货查询', '出货查询']
        }
    },
    props: {
        followList: {
            type: Array,
            default() {
                return []
            }
        }
    },
    methods: {
        qualificationText(list) {
            return list
                .map(e => {
                    return e.qualificationItemName
                })
                .join('、')
        }
    }
}
</script>

<style lang="scss" scoped>
.no-data {
    margin-top: 10px;
    width: 100%;
    text-align: center;
}
.follow-list {
    margin-top: 40px;
    .follow-item {
        // padding-bottom: 0px;
        padding-top: 10px;
        padding-left: 48px;
        margin-left: 10px;
        border-left: solid 1px rgba($color: #cccccc, $alpha: 0.3);
        .time-label {
            width: 100%;
            position: relative;
            margin-top: -20px;
            margin-bottom: 40px;
            .havebg {
                background-color: #4d7cfe;
            }
            .label {
                position: relative;
                width: 100px;
                color: #fff;
                text-align: center;
                height: 32px;
                line-height: 32px;
                font-size: 14px;
                background-color: rgba($color: #4d7cfe, $alpha: 0.8);
                .tab {
                    border-color: rgba($color: #4d7cfe, $alpha: 0.8);
                    position: absolute;
                    height: 0;
                    width: 0;
                    border-style: solid;
                    border-width: 16px;
                    border-left-color: transparent;
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                    border-right-color: rgba($color: #4d7cfe, $alpha: 0.8);
                    left: -32px;
                }
                .little-circle {
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background-color: #fff;
                    position: absolute;
                    left: -3px;
                    top: 0;
                    bottom: 0;
                    margin: auto 0;
                }
            }
        }
        .circle {
            position: absolute;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            left: -56px;
            top: 8px;
            margin: auto;
            background-color: #fff;
            border: solid 1px #4d7cfe;
        }
        .follow-detail {
            position: relative;
            margin-top: 24px;
            background-color: #fcfcfc;
            border-radius: 4px;
            padding: 9px 16px 16px 16px;
            .follow-user {
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;
                .name-type {
                    span {
                        display: inline-block;
                        margin-right: 14px;
                        color: #333333;
                        font-size: 13px;
                    }
                }
            }
            .detail-content {
                font-size: 13px;
                color: #606366;
                div {
                    margin-bottom: 3px;
                }
            }
        }
    }
}
</style>
