<template>
    <div class="customer-table-componation">
        <table class="customer-table" border>
            <tr class="table-base th" v-if="th.length">
                <td
                    v-for="(h, index) in th"
                    :style="{
                        'max-width': h.maxWidth,
                        width: h.width
                    }"
                    :key="index"
                >
                    <div>{{ h.title }}</div>
                </td>
            </tr>
            <tr v-if="!td.length">
                <td class="table-base block-td" :colspan="th.length">暂无{{ blockText }}数据</td>
            </tr>
            <tr class="table-base" v-for="(d, index) in tableData" :key="index" v-else>
                <td v-for="(item, dIndex) in th" :width="item.width" :key="dIndex">
                    <span v-if="item.key !== 'index'">{{ d[item.key] }}</span>
                    <span v-else>{{ index + 1 }}</span>
                    <component
                        :enterpriseId="enterpriseId"
                        :brakeRow="d"
                        v-if="item.slot"
                        :is="item.slot"
                    ></component>
                </td>
            </tr>
        </table>

        <div class="table-pagination" v-if="total > 5">
            <a-pagination
                @change="changePage"
                :total="total"
                :pageSize="pageSize"
                :current="pageNum"
            ></a-pagination>
        </div>
    </div>
</template>

<script>
import { Pagination } from 'ant-design-vue'
export default {
    name: 'customer-table',
    components: {
        aPagination: Pagination
    },
    computed: {
        total() {
            return this.td.length
        },
        tableData() {
            const data = []
            const max = this.pageNum * this.pageSize
            const min = (this.pageNum - 1) * this.pageSize
            this.td.forEach((e, i) => {
                if (min <= i && i < max) {
                    data.push(e)
                }
            })
            return data
        }
    },
    props: {
        blockText: {
            type: String,
            default: ''
        },
        th: {
            type: Array,
            default() {
                return []
            }
        },
        td: {
            type: Array,
            default() {
                return []
            }
        },
        haveIndex: {
            type: Boolean,
            default: false
        },
        enterpriseId: {
            type: [String, Number],
            default: ''
        },
        brakeRow: {
            type: Object
        }
    },
    data() {
        return {
            pageNum: 1,
            pageSize: 5
        }
    },
    methods: {
        changePage(pageNum, pageSize) {
            this.pageNum = pageNum
            this.pageSize = pageSize
        }
    }
}
</script>
<style lang="scss" scoped>
.customer-table-componation {
    .customer-table {
        width: 100%;
        color: #606366;
        font-size: 13px;
        border: solid 1px #e3e3e3;
        .table-base {
            text-align: center;
            height: 36px;
        }
        .th {
            background-color: rgba($color: #4d7cfe, $alpha: 0.06);
        }
    }
    .table-pagination {
        width: 100%;
        margin-top: 15px;
        text-align: right;
    }
}
</style>
