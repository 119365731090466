<template>
  <div class="model-description">
    <div class="model-title-list">
      <div
        @mouseenter="changeIndex(key)"
        class="model-title"
        :class="{ 'model-title-selected': key === selectIndex }"
        v-for="(item, key) in tabs"
        :key="key"
      >
        {{ item.title }}
      </div>
    </div>

    <div class="model-item">
      <div class="model-des">
        {{ tabs[selectIndex].des }}
      </div>
      <img class="model-image" width="1200" :src="tabs[selectIndex].src" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'model-description',
  data() {
    return {
      selectIndex: 0,
      tabs: [
        {
          title: '收货查询',
          src:  'http://cdn.public.xnjx666.com//upload/gw/static/img/receipt.png',
          des: '全网施工资质数据，极速搜企业一手货源'
        },
        {
          title: '出货查询',
          src:  'http://cdn.public.xnjx666.com//upload/gw/static/img/shipment.png',
          des: '2种特定筛选条件，精准定位客户收购意向'
        },
        {
          title: '资质集市',
          src:  'http://cdn.public.xnjx666.com//upload/gw/static/img/market.png',
          des: '重塑代办工作流，达成资质出购的降本增效'
        },
        { title: '一键串货',
          src:  'http://cdn.public.xnjx666.com//upload/gw/static/img/find.png',
          des: '高效解码，一键串货' }
      ]
    }
  },
  methods: {
    changeIndex(key) {
      let timer = null
      if (timer !== null) {
        clearTimeout(timer)
        return
      } else {
        timer = setTimeout(() => {
          this.selectIndex = key
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.model-description {
  background-color: #faffff;
  padding: 40px 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .model-title-list {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    .model-title {
      font-size: 20px;
      cursor: pointer;
      font-weight: bold;
      //   height: 28px;
      line-height: 28px;
      margin-right: 120px;
      padding-bottom: 8px;
      margin-bottom: 60px;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        color: #00b29d;
        border-bottom: 4px solid #00b29d;
      }
    }
    .model-title-selected {
      color: #00b29d;
      border-bottom: 4px solid #00b29d;
    }
  }

  .model-item {
    .model-des {
      margin-bottom: 30px;
      font-size: 20px;
      color: #00b29d;
      font-weight: bold;
    }
    .model-image {
      box-shadow: 0px 0px 16px 0px rgba(0, 178, 157, 0.2);
    }
  }
}
</style>
