import { render, staticRenderFns } from "./CustomerTable.vue?vue&type=template&id=8661b5f6&scoped=true"
import script from "./CustomerTable.vue?vue&type=script&lang=js"
export * from "./CustomerTable.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./CustomerTable.vue?vue&type=style&index=0&id=8661b5f6&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "8661b5f6",
  "150f130b"
  
)

export default component.exports