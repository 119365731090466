<template>
  <div class="tree-checkbox" @mousedown="e => e.preventDefault()">
    <div v-for="(item, key) in tree" :key="key" class="tree-item">
      <div class="tree-title" @click="collapsePanel(item)">
        <a-icon v-if="!item.isExpend" type="caret-right"/>
        <a-icon v-if="item.isExpend" type="caret-down"/>
        {{ item.title }}
      </div>
      <div v-if="item.isExpend" class="checkbox-content">
        <div v-for="(child, index) in item.children" :key="index">
          <div v-if="child.onlyShow" class="check-subtitle">
            {{ child.title }}
          </div>
          <div v-else class="checkbox-item">
            <div class="checkbox-label">
              {{ child.title }}
            </div>
            <div class="checkbox-list">
              <div
                  v-for="(checkbox, checkKey) in child.children"
                  :key="checkKey"
                  :class="{ isDisabled: checkbox.disabled }"
                  class="item"
                  @click="checkThisItem(checkbox, child)"
                  @mousedown="e => e.preventDefault()"
              >
                <div
                    :class="{
                                        isChecked: checkbox.checked
                                    }"
                    class="box"
                >
                  <a-icon
                      v-show="checkbox.checked"
                      class="icon"
                      theme="filled"
                      type="check-square"
                  />
                </div>
                <div class="label">{{ checkbox.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import {Checkbox} from 'ant-design-vue'

export default {
  name: 'tree-checkbox',
  components: {
    aCheckboxGroup: Checkbox.Group,
    aCheckbox: Checkbox
  },
  data() {
    return {
      treeData: [],
      selected: [],
      searchValue: '',
      singleValue: undefined
    }
  },
  computed: {
    tree() {
      if (this.searchValue) {
        const copy = this.$deepCopy(this.treeData)
        const res = copy.filter(item => {
          return this.filterMethods(item)
        })
        res.forEach(e => {
          e.isExpend = true
        })
        return res
      } else {
        return this.treeData
      }
    }
  },
  props: {
    type: {
      type: String,
      default: 'default'
    },
    treeList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    filterMethods(item) {
      if (item.searchTitle.indexOf(this.searchValue) > -1) {
        return true
      }
      if (Object.prototype.hasOwnProperty.call(item, 'children')) {
        item.children = item.children.filter(child => {
          // console.log(child)
          if (child.searchTitle.indexOf(this.searchValue) > -1) {
            return true
          } else {
            return this.filterMethods(child)
          }
          // if (Object.prototype.hasOwnProperty.call(child, 'title')) {
          //
          // }
          // if (Object.prototype.hasOwnProperty.call(child, 'children')) {
          //   console.log('???', child)
          //   return this.filterMethods(child)
          // }
        })
        if (item.children.length > 0) {
          return true
        }
      } else {
        return item.searchTitle.indexOf(this.searchValue) > -1
      }
      // const  filterObj = function(item) {
      //   if (item.name.indexOf(query) > -1) return true
      //   if (item.hasOwnProperty('children')) {
      //     item.children = item.children.filter(function(child) {
      //       if (child.hasOwnProperty('type')) {
      //         return child.name.indexOf(query) > -1
      //       } else if (child.hasOwnProperty('children')) {
      //         return filterObj(child)
      //       }
      //     })
      //     if (item.children.length > 0) {
      //       return true
      //     }
      //   } else {
      //     return child.name.indexOf(query) > -1
      //   }
      // }
    },
    setDropStatus() {
      //   console.log('checkbox blur')
      this.$emit('setDropStatus')
    },
    collapsePanel(item) {
      this.treeData.forEach(e => {
        if (e.key === item.key) {
          e.isExpend = !e.isExpend
        } else {
          e.isExpend = false
        }
      })
      this.$emit('setTreeCollapse', item)
      this.treeData = this.$deepCopy(this.treeData)
    },
    checkThisItem(item, list) {
      if (list.value[0] === item.id) {
        list.value = []
      } else {
        list.value = [item.id]
      }
      this.searchValue = ''
      if (this.type === 'single') {
        // 单选模式特殊处理
        this.singleCheckBox(item.id)
      } else {
        this.selectCheckBox(list.value, list)
      }
    },
    // 单选模式
    singleCheckBox(value) {
      this.treeData.forEach(t => {
        t.children.forEach(c => {
          c.children.forEach(e => {
            if (this.singleValue === value) {
              e.disabled = false
              e.checked = false
            } else {
              if (e.id !== value) {
                e.disabled = true
                e.checked = false
              } else {
                e.disabled = false
                e.checked = true
              }
            }
          })
        })
      })
      if (!this.singleValue) {
        this.singleValue = this.singleValue === value ? undefined : value
      } else {
        this.singleValue = undefined
      }
      this.treeData = this.$deepCopy(this.treeData)
      this.$emit('setValue', this.singleValue)
    },
    setSearchValue(value) {
      this.searchValue = value
    },

    selectCheckBox(checked, list) {
      this.treeData.forEach(t => {
        t.children.forEach(c => {
          if (c.key === list.key) {
            c.children.forEach(e => {
              if (checked[0]) {
                if (e.id === checked[0]) {
                  e.checked = true
                  e.disabled = false
                  c.value = [e.id]
                } else {
                  e.checked = false
                  e.disabled = true
                }
              } else {
                e.checked = false
                e.disabled = false
              }
            })
          }
        })
      })
      this.treeData = this.$deepCopy(this.treeData)
      this.getChcekboxSelectValue()
    },
    getChcekboxSelectValue() {
      let value = []
      this.treeData.forEach(e => {
        e.children.forEach(c => {
          value = value.concat(c.value)
        })
      })
      // console.log(value)
      this.$emit('setValue', value)
    },
    getValue() {
      let value = []
      this.treeData.forEach(e => {
        e.children.forEach(c => {
          value = value.concat(c.value)
        })
      })
      return value
    },
    setValueFromSelect(value) {
      this.treeData.forEach(e => {
        e.children.forEach(c => {
          if (c.value.length) {
            // 如果当条数据有value，说明是选中状态
            if (
                !value.find(v => {
                  return v === c.value[0]
                })
            ) {
              // 如果在有value的里面找不到外面select的值，说明select已经把那个值给ccq了
              c.children.forEach(q => {
                q.checked = false
                q.disabled = false
              }) // 就把所有值都变成可选，因为只能选一个
            }
          }
        })
      })
    },
    setValueFromHistory(value) {
      this.searchValue = ''
      // 从历史记录里面来的
      this.treeData = this.$deepCopy(
          this.treeList.map((e, i) => {
            return {
              ...e,
              isExpend: i === 0
            }
          })
      ) // 默认展开第一个
      if (this.type === 'single') {
        this.singleValue = value
        if (value) {
          this.treeData.forEach(t => {
            t.children.forEach(c => {
              c.children.forEach(q => {
                if (q.id === value) {
                  q.checked = true
                  q.disabled = false
                } else {
                  q.checked = false
                  q.disabled = true
                }
              })
            })
          })
        }
      } else {
        this.treeData.forEach(t => {
          t.children.forEach(c => {
            c.children.forEach(q => {
              // 一层层找到历史记录里面的id
              const findValue = value.find(v => {
                return v === q.id
              })
              if (findValue) {
                c.value = [findValue]
              }
            })
          })
        })

        this.treeData.forEach(t => {
          t.children.forEach(c => {
            if (c.value.length) {
              c.children.forEach(q => {
                if (q.id === c.value[0]) {
                  q.disabled = false
                  q.checked = true
                } else {
                  q.disabled = true
                  q.checked = false
                }
              })
            } else {
              c.children.forEach(q => {
                q.disabled = false
                q.checked = false
              })
            }
          })
        })
      }

      this.treeData = this.$deepCopy(this.treeData)
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.tree-checkbox {
  background-color: #ffffff;
  width: 100%;
  overflow: auto;
  position: relative;
  z-index: 10001;

  .select-types {
    padding: 25px 30px;
    width: 100%;
  }

  .tree-item {
    .tree-title {
      width: 100%;
      margin-bottom: 2px;
      flex-shrink: 0;
      height: 30px;
      line-height: 30px;
      padding-left: 32px;
      background-color: rgba($color: #4a78f9, $alpha: 0.08);
      cursor: pointer;
    }

    .checkbox-content {
      flex-shrink: 0;
      padding-left: 52px;
      padding-top: 20px;

      .check-subtitle {
        color: #000000;
        font-size: 14px;
        margin: 20px 0;
        font-weight: bold;

        &:first-child {
          margin-top: 0 !important;
        }
      }

      .checkbox-item {
        display: flex;
        align-self: flex-start;
        margin-bottom: 20px;

        .checkbox-label {
          width: 150px;
          flex-grow: 1;
          margin-right: 25px;
        }

        .checkbox-list {
          display: flex;
          align-items: center;

          .item {
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: 10px;

            .box {
              width: 16px;
              height: 16px;
              font-size: 16px;
              line-height: 16px;
              border: solid 1px #ebedf0;
              position: relative;

              .icon {
                position: absolute;
                top: -1px;
                left: -1px;
                color: #4d7cfe;
              }
            }

            .label {
              margin: 0 8px;
            }
          }

          .isDisabled {
            pointer-events: none;
            cursor: not-allowed;

            .box {
              background-color: #ebebe4;
              border-color: #ebedf0;
            }

            .label {
              color: rgba(0, 0, 0, 0.25);
            }
          }
        }

        // .item {
        //   display: flex;
        // }
      }
    }
  }
}
</style>
