export const designList = [
    '工程设计煤炭行业',
    '工程设计化工石化医药行业',
    '工程设计石油天然气(海洋石油)行业',
    '工程设计电力行业',
    '工程设计冶金行业',
    '工程设计军工行业',
    '工程设计机械行业',
    '工程设计商物粮行业',
    '工程设计核工业行业',
    '工程设计电子通信广电行业',
    '工程设计轻纺行业',
    '工程设计建材行业',
    '工程设计铁道行业',
    '工程设计公路行业',
    '工程设计水运行业',
    '工程设计民航行业',
    '工程设计市政行业',
    '工程设计农林行业',
    '工程设计水利行业',
    '工程设计海洋行业',
    '工程设计建筑行业'
]
