import { render, staticRenderFns } from "./Qualification.vue?vue&type=template&id=4d1cb8b0&scoped=true"
import script from "./Qualification.vue?vue&type=script&lang=js"
export * from "./Qualification.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Qualification.vue?vue&type=style&index=0&id=4d1cb8b0&prod&scoped=true&lang=css")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./Qualification.vue?vue&type=style&index=1&id=4d1cb8b0&prod&lang=scss&scoped=true")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4d1cb8b0",
  "3b4076c6"
  
)

export default component.exports