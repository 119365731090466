<template>
  <div class="chart-warp">
    <div class="top-title">
      <div class="select-value" @click.stop="changeAreaListStatus">
        <img :src="locationPng" width="16px" />
        [{{ areaLabel | areaLabelFilter }}]
        <div class="area-list" v-show="showAreaList">
          <div
            class="area-item"
            @click="changeArea(item)"
            :class="{
              'no-select': item.disabled,
              'is-select': item.value === areaValue
            }"
            v-for="(item, key) in vxAreaOptions"
            :key="key"
          >
            {{ item.label | areaLabelFilter }}
          </div>
        </div>
      </div>
      <div class="main-title">建筑行业资质大数据</div>
    </div>
    <div class="chart-table">
      <div class="lf-charts">
        <div class="circular-charts">
          <div class="charts-title">施工资质</div>
          <div class="chart-detail">
            <div id="circular1" class="circular-image"></div>
            <div class="qua-list">
              <develop-table
                :colors="['#00BC87', '#F6BD16', '#7262FD']"
                :defaultExpand="0"
                :tableData="buildTable"
              ></develop-table>
            </div>
          </div>
        </div>
        <div class="circular-charts">
          <div class="charts-title">设计资质</div>
          <div class="chart-detail">
            <div id="circular2" class="circular-image"></div>
            <div class="qua-list">
              <develop-table
                :colors="[
                  '#00BC87',
                  '#F6BD16',
                  '#7262FD',
                  '#FF0087',
                  '#78D3F8'
                ]"
                :tableData="designTable"
              ></develop-table>
            </div>
          </div>
        </div>
      </div>
      <div class="rt-charts">
        <div class="rt-title">
          <span class="rt-area"> [{{ areaLabel | areaLabelFilter }}] </span>
          建筑企业资质增长趋势
        </div>
        <div class="line-charts">
          <div id="lineCahrt"></div>

          <div class="date-silder">
            <a-slider
              :step="null"
              range
              @change="changeDateRange"
              :marks="todayMarks"
              :value="defaultMarks"
              :tip-formatter="silderTipFormatter"
            >
            </a-slider>
            <!-- <div class=""></div> -->
          </div>
          <div class="current-data">
            <div class="current-item">
              <div>
                <div
                  class="current-point"
                  style="background-color:#00BC87"
                ></div>
                今日施工总承包增长量
              </div>
              <div>{{ todayGeneraRise }}</div>
            </div>
            <div class="current-item">
              <div>
                <div
                  class="current-point"
                  style="background-color:#1890FF"
                ></div>
                今日专业承包增长量
              </div>
              <div>{{ todaySpecialRise }}</div>
            </div>
          </div>
        </div>

        <div class="other-num">
          <span>持有安许证的企业数</span>
          <span>{{ securitytNum }}</span>
        </div>
        <div class="constructor-item">
          <div class="other-num">
            <span>全国注册建造师数</span>
            <span>
              {{ architectureNum.oneLevelNum + architectureNum.twoLevelNum }}
            </span>
          </div>
          <div class="sub-item">
            <span>一级注册建造师</span>
            <span>{{ architectureNum.oneLevelNum }}</span>
          </div>
          <div class="sub-item">
            <span>二级注册建造师</span>
            <span>{{ architectureNum.twoLevelNum }}</span>
          </div>
        </div>
        <div class="other-num">
          <span>全国项目业绩数量</span>
          <span>{{ achievementNum }}</span>
        </div>
        <div class="update-info">
          最后更新时间：{{ currentDate }}<br />
          <!-- 数据来源：中华人民共和国住房和城乡建设部<br /> -->
          <!-- 小牛建讯 -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import locationPng from '@/assets/home/home_location.png'
import { mapMutations, mapState } from 'vuex'
import echarts from '@/utils/echarts.js'
import developTable from './developTable.vue'

export default {
  name: 'chart-content',
  components: {
    developTable
  },
  filters: {
    areaLabelFilter(text) {
      if (text.indexOf('黑龙江') > -1) {
        return '黑龙江'
      } else if (text.indexOf('内蒙古') > -1) {
        return '内蒙古'
      } else {
        return text.substring(0, 2)
      }
    }
  },
  data() {
    return {
      areaValue: -1,
      areaLabel: '全国',
      locationPng,
      showAreaList: false,
      buildNum: 0,
      buildTable: [],
      designTable: [],
      todayGeneraRise: 0,
      todaySpecialRise: 0,
      todayMarks: {},
      defaultMarks: [],
      todayTableData: [],
      lineChart: null,
      achievementNum: '-',
      architectureNum: {
        oneLevelNum: 0,
        twoLevelNum: 0
      },
      securitytNum: '-'
    }
  },
  computed: {
    ...mapState({
      vxAreaOptions: state => state.areaOptions
    }),
    currentDate() {
      return this.$day().format('YYYY-MM-DD')
    }
  },
  methods: {
    ...mapMutations(['setAreaOptions']),
    silderTipFormatter(val) {
      return this.todayMarks[val]
    },
    changeArea(item) {
      if (item.disabled) {
        return
      }
      this.areaLabel = item.label
      this.areaValue = item.value
      this.setCircalurChartInfo()
      this.getSecurityTotal()
      this.getLineChartData()
    },
    changeAreaListStatus() {
      this.showAreaList = !this.showAreaList
    },
    close() {
      this.showAreaList = false
    },
    getAreaOptions() {
      this.$http.get('regionCode/selList').then(r => {
        this.areaOptions = []
        this.areaData = r
        const gangAoTai = [810000, 820000, 710000]
        // 去除港澳台
        r.forEach(option => {
          if (gangAoTai.includes(option.areaCode)) {
            this.areaOptions.push({
              label: option.areaName,
              value: option.areaCode,
              disabled: true
            })
          } else {
            this.areaOptions.push({
              label: option.areaName,
              value: option.areaCode,
              disabled: false
            })
          }
        })
        this.areaOptions.unshift({
          label: '全国',
          value: -1,
          disabled: false
        })
        this.setAreaOptions(this.areaOptions)
      })
    },
    setCircalurChartInfo() {
      const circular1 = document.getElementById('circular1')
      const circular2 = document.getElementById('circular2')
      const myChart1 = echarts.init(circular1)
      const myChart2 = echarts.init(circular2)
      const option = {
        title: {
          text: '',
          textStyle: {
            fontSize: '12',
            fontWeight: 400,
            color: '#fff'
          },
          subtext: '',
          subtextStyle: {
            fontSize: '20',
            color: '#FFFFFF',
            fontWeight: 500,
            lineHeight: 20,
            height: 20
          },
          top: '42%',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(255, 255, 255, 0.9);',
          borderColor: 'transparent',
          textStyle: {
            color: '#585858',
            fontSize: 12,
            fontWeight: 'noraml'
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['50%', '80%'],
            minAngle: 1,
            avoidLabelOverlap: true,
            label: {
              show: false,
              position: 'outside'
            },
            emphasis: {
              label: {
                show: false,
                color: '#FFFFFF'
                // formatter: '{b|{b}}\n{c|{c}}',
                // rich: {
                //   b: {
                //     fontSize: 12,
                //     fontWeight: '400',
                //     color: '#fff'
                //   },
                //   c: {
                //     fontSize: 20,
                //     fontWeight: '500',
                //     color: '#fff',
                //     lineHeight: 40
                //   }
                // }
                // fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: []
          }
        ]
      }
      const params = {
        areaCode: this.areaValue
      }
      // 施工资质
      this.$http
        .get('official/website/statistics/query/construction', params)
        .then(res => {
          // console.log(res)
          this.buildNum = res.totalNum
          option.title.text = '施工资质总量'
          option.title.subtext = res.totalNum
          const colors = ['#00BC87', '#F6BD16', '#7262FD']
          option.series[0].data =
            res.statisticsList &&
            res.statisticsList.map((e, i) => {
              return {
                value: e.num,
                name: e.name,
                itemStyle: {
                  color: colors[i]
                }
              }
            })
          myChart1.setOption(option)
          this.buildTable = []
          this.setBuildList(
            res.statisticsList,
            res.detailsList,
            this.buildTable
          )
        })
      // 设计资质
      this.$http
        .get('official/website/statistics/query/design', params)
        .then(res => {
          // console.log(res)
          // this.buildNum = res.totalNum
          option.title.text = '设计资质总量'
          option.title.subtext = res.totalNum
          const colors = ['#00BC87', '#F6BD16', '#7262FD', '#FF0087', '#78D3F8']
          option.series[0].data =
            res.statisticsList &&
            res.statisticsList.map((e, i) => {
              return {
                value: e.num,
                name: e.name,
                itemStyle: {
                  color: colors[i]
                }
              }
            })
          myChart2.setOption(option)
          this.designTable = []
          this.setBuildList(
            res.statisticsList,
            res.detailsList,
            this.designTable
          )
        })
    },
    setBuildList(statisticsList, list, tableData) {
      let newOb = {}
      list &&
        list.forEach(e => {
          newOb[e.qualificationType] = newOb[e.qualificationType] || []
          newOb[e.qualificationType].push(e)
        })
      const keyList = Object.keys(newOb)
      statisticsList &&
        statisticsList.forEach(s => {
          keyList.forEach(e => {
            if (s.name === e) {
              tableData.push({
                name: e,
                num: (function() {
                  let i = 0
                  newOb[e].forEach(n => {
                    i += n.num
                  })
                  return i
                })(),
                percentage: (function() {
                  let i = 0
                  newOb[e].forEach(n => {
                    i += parseFloat(n.percentage)
                  })
                  return i.toFixed(2)
                })(),
                detail: newOb[e].map(n => {
                  return {
                    qualificationLevel: n.qualificationLevel,
                    num: n.num,
                    percentage: n.percentage
                  }
                })
              })
            }
          })
        })

      // console.log(this.buildTable)
      // console.log(this.buildTable)
      // console.log(this.designTable)
    },
    getLineChartData() {
      const lineCahrt = document.getElementById('lineCahrt')
      this.lineChart = echarts.init(lineCahrt)
      const params = {
        areaCode: this.areaValue
      }
      this.$http
        .get('official/website/statistics/query/trend', params)
        .then(res => {
          this.todayTableData = res
          const numSplit = 100 / (res.length - 1)
          res.forEach((e, i) => {
            const keyName = this.$day(e.statisticsTime).format('MM-DD')
            this.todayMarks[parseInt(i * numSplit, 10)] = keyName
          })
          this.defaultMarks = [0, 100]
          this.todayGeneraRise = res[res.length - 1].generalNum
          this.todaySpecialRise = res[res.length - 1].specialNum
          this.setLineCharts(res)
        })
    },
    setLineCharts(res) {
      const option = {
        title: {
          show: false
        },
        tooltip: {
          trigger: 'axis',
          className: 'line-tooltip'
        },
        grid: {
          left: '0',
          right: '16',
          bottom: '0',
          top: '16',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [],
          axisTick: { inside: true },
          nameTextStyle: {
            color: 'rgba(255,255,255,0.8)'
          }
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(255,255,255,0.2)'
            }
          },
          // splitNumber: 5,
          max: function(value) {
            if (value.max > 0) {
              return value.max
            } else {
              return 5
            }
          },
          minInterval: 1
        },
        color: ['#00BC87', '#1890FF'],
        series: [
          {
            name: '今日施工总承包增长量',
            type: 'line',
            // stack: 'Total',
            symbol: 'none',
            lineStyle: {
              width: 1
            }
            // data: [120, 130, 110, 200]
          },
          {
            name: '今日专业承包增长量',
            type: 'line',
            // stack: 'Total',
            symbol: 'none',
            lineStyle: {
              width: 1
            }
            // data: [100, 90, 110, 200]
          }
        ]
      }

      // this.$http
      //   .get('official/website/statistics/query/trend', params)
      //   .then(res => {
      // console.log(res)
      const generalNum = []
      const specialNum = []
      const xData = []
      // const numSplit = 100 / (res.length - 1)
      res.forEach((e, i) => {
        generalNum.push(e.generalNum)
        specialNum.push(e.specialNum)
        const keyName = this.$day(e.statisticsTime).format('MM-DD')
        xData.push(keyName)
        // this.todayMarks[parseInt(i * numSplit, 10)] = keyName
      })

      // console.log(this.todayMarks)
      option.series[0].data = generalNum
      option.series[1].data = specialNum
      option.xAxis.data = xData
      // this.defaultMarks = [0, 100]
      this.lineChart.setOption(option)
      // })
    },
    getOtherInfo() {
      this.$http
        .get('official/website/statistics/query/achievement')
        .then(res => {
          this.achievementNum = res.achievementNum || 0
        })
      this.$http
        .get('official/website/statistics/query/architecture')
        .then(res => {
          this.architectureNum = res
        })
      this.getSecurityTotal()
    },
    getSecurityTotal() {
      const params = {
        areaCode: this.areaValue
      }
      this.$http
        .get('official/website/statistics/query/security', params)
        .then(res => {
          this.securitytNum = res.securityNum || 0
        })
    },
    changeDateRange(e) {
      // console.log(e)
      if (e[0] === e[1]) {
        return
      }
      this.defaultMarks = e
      const findStartIndex = this.todayTableData.findIndex(t => {
        return (
          this.$day(t.statisticsTime).format('MM-DD') === this.todayMarks[e[0]]
        )
      })
      const findEndIndex = this.todayTableData.findIndex(t => {
        return (
          this.$day(t.statisticsTime).format('MM-DD') === this.todayMarks[e[1]]
        )
      })
      const res = this.todayTableData.slice(findStartIndex, findEndIndex + 1)
      this.setLineCharts(res)
    }
  },
  mounted() {
    this.getAreaOptions()
    this.$nextTick(() => {
      this.setCircalurChartInfo()
      this.getLineChartData()
      this.getOtherInfo()
    })
  }
}
</script>
<style lang="scss">
.line-tooltip {
  font-size: 30px;
  text-align: left;
}
</style>

<style lang="scss" scoped>
.chart-warp {
  position: relative;
  z-index: 19;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .top-title {
    margin-bottom: 56px;
    margin-top: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    .select-value {
      color: #00ab94;
      font-size: 16px;
      line-height: 16px;
      cursor: pointer;
      position: relative;
      z-index: 12;
    }
    .main-title {
      font-size: 32px;
      line-height: 32px;
      font-weight: 500;
      color: #fff;
      margin-left: 8px;
    }
    .area-list {
      padding: 12px 12px 0px 24px;
      background-color: #fff;
      color: #181818;
      display: flex;
      position: absolute;
      left: 0;
      top: 18px;
      flex-wrap: wrap;
      align-items: center;
      width: 430px;
      border-radius: 4px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      .area-item {
        padding: 5px 0px;
        font-size: 14px;
        width: 65px;
        margin-bottom: 12px;
        line-height: 14px;
        // width: max-content;
        margin-right: 12px;
        cursor: pointer;
        &:hover {
          background-color: #f2f6f8;
        }
      }
      .no-select {
        color: #585858;
        &:hover {
          cursor: not-allowed;
        }
      }
      .is-select {
        background-color: #f2f6f8;
      }
    }
  }
  .chart-table {
    width: 1200px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .lf-charts {
      width: 776px;
      margin-right: 24px;
      .circular-charts {
        margin-bottom: 24px;
        border-radius: 4px;
        padding: 24px;
        width: 100%;
        min-height: 342px;
        background: rgba(251, 251, 255, 0.06);
        border: 1px solid rgba(0, 171, 148, 0.2);
        .charts-title {
          font-size: 16px;
          color: #fff;
          width: 100%;
          text-align: left;
        }
        .chart-detail {
          display: flex;
          align-items: flex-start;
          .circular-image {
            width: 272px;
            height: 272px;
          }
        }
      }
    }
    .rt-charts {
      border-radius: 4px;
      padding: 24px;
      width: 400px;
      height: 716px;
      margin-left: 24px;
      background: rgba(251, 251, 255, 0.06);
      border: 1px solid rgba(0, 171, 148, 0.2);
      .rt-title {
        font-weight: 500;
        color: #fff;
        width: 100%;
        text-align: left;
        .rt-area {
          color: #00ab94;
        }
      }
      .line-charts {
        .current-data {
          margin-top: 16px;
          font-size: 14px;
          color: #fff;
          .current-item {
            display: flex;
            width: 100%;
            margin-bottom: 16px;
            justify-content: space-between;
            .current-point {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 6px;
              display: inline-block;
            }
          }
        }
        #lineCahrt {
          width: 353px;
          height: 146px;
        }
        .date-silder {
          width: 352px;
          margin-bottom: 48px;
          .ant-slider {
            height: 16px;
            /deep/.ant-slider-step {
              display: none;
            }
            /deep/ .ant-slider-rail {
              height: 16px;
              background: rgba(255, 255, 255, 0.1);
            }
            /deep/ .ant-slider-track {
              height: 16px !important;
              background: rgba(0, 171, 148, 0.4);
            }
            /deep/.ant-slider-dot-active {
              display: none;
            }
            /deep/.ant-slider-handle {
              height: 24px;
              width: 12px;
              border-radius: 2px;
              border-color: transparent;
            }
            /deep/.ant-slider-mark {
              top: 30px;
              // .ant-slider-mark-text {
            }
            /deep/.ant-slider-mark-text {
              display: none;
              width: fit-content;
              &:first-child {
                display: inline-block;
                // }
              }
              &:last-child {
                display: inline-block;
              }
            }
          }
        }
      }
      .other-num {
        display: flex;
        width: 100%;
        justify-content: space-between;
        color: #fff;
        font-size: 16px;
        margin-top: 32px;
        font-weight: 500;
      }
      .sub-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 14px;
        margin: 16px 0;
        color: rgba($color: #fff, $alpha: 0.8);
      }
      .update-info {
        margin-top: 82px;
        font-size: 12px;
        width: 100%;
        text-align: right;
        line-height: 24px;
        color: rgba($color: #fff, $alpha: 0.6);
      }
    }
  }
}
</style>
