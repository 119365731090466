import axios from 'axios'
import qs from 'qs'

import Cookies from 'js-cookie'
// axios.defaults.baseURL = apiUrl
// import Store from '../store/index.js'
// import router from '../router/index.js'
import { message } from 'ant-design-vue'
import 'ant-design-vue/lib/date-picker/style/css'

let apiUrl = ''
if (process.env.VUE_APP_TYPE === 'test') {
  // 本地测试
  apiUrl = 'http://192.168.0.108:9005/api/'
} else if (process.env.VUE_APP_TYPE === 'preview') {
  // 线上测试
  apiUrl = 'https://www.xnjx666.com/api/'
} else if (process.env.VUE_APP_TYPE === 'online') {
  apiUrl = 'https://www.xnjx666.com/api/'
} else if (process.env.VUE_APP_TYPE === 'dev') {
  apiUrl = 'http://192.168.0.108:9005/api/'
}

console.log(apiUrl)
// 下载的文件类型
const MimeTypeConfig = {
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  XLS: 'application/vnd.ms-excel application/x-excel',
  DOC: 'application/msword',
  DOCX:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPT: 'application/vnd.ms-powerpoint',
  GIF: 'application/gif',
  PNG: 'application/png',
  JPG: 'application/jpeg',
  MP3: 'audio/mpeg',
  ZIP: 'application/x-zip-compressed',
  TAR: 'application/x-tar',
  TXT: 'text/plain'
}
// 展示过期弹框
// let  = false
const httpRequestor = {
  // 默认的异常处理方法，会传入完整的data对象，可以在这里弹提示框
  defaultErrorHandler: null,
  baseURL: apiUrl,
  // 跨域请求时把cookie带过去
  withCredentials: true
}
// 后端默认超时时间必须比这里短
const DEFAULT_TIME_OUT = 35000
// 上传文件的默认超时时间
const DEFAULT_UPLOAD_TIME_OUT = 120000

const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: DEFAULT_TIME_OUT
})
//响应拦截器
axiosInstance.interceptors.response.use(
  handleResponseSuccess,
  handleResponseFail
)

/**
 * 通过post发送数据，使后端直接收到json格式的数据。并统一处理常见的错误
 * @param url
 * @param data
 * @param ContentType
 * @param params
 * @param throwError throwError 是否不使用默认的异常处理方法，而把异常抛出来
 * @param timeout 超时时间，默认10秒
 * @returns {Promise} 返回一个promise对象。其中then方法传递回包中的data数据；catch事件则传递整个回包，其参数为{data:{},status{code:123,message:'xxx'}}
 */
httpRequestor.post = function postJson(
  url,
  data = {},
  ContentType = 'application/json',
  params = {},
  throwError,
  timeout
) {
  return commonAjax({
    method: 'POST',
    url,
    params,
    data: setContentTypeData(ContentType, data),
    errorHandler: (!throwError && httpRequestor.defaultErrorHandler) || null,
    timeout: timeout || DEFAULT_TIME_OUT,
    withCredentials: httpRequestor.withCredentials,
    headers: { 'Content-Type': ContentType }
  })
}
httpRequestor.delete = function postJson(
  url,
  data = {},
  ContentType = 'application/json',
  params = {},
  throwError,
  timeout
) {
  return commonAjax({
    method: 'DELETE',
    url,
    params,
    data: setContentTypeData(ContentType, data),
    errorHandler: (!throwError && httpRequestor.defaultErrorHandler) || null,
    timeout: timeout || DEFAULT_TIME_OUT,
    withCredentials: httpRequestor.withCredentials,
    headers: { 'Content-Type': ContentType }
  })
}
/**
 * 通过表单发送同步的post请求，服务器端可以在回包时重定向或下发文件
 * @param {string} url
 * @param {object?} data={} 要发送数据的键值对，值不可以是对象，必须序列化成字符串
 */
httpRequestor.postAndDownload = function postJsonSync(url, data = {}) {
  const postForm = document.createElement('form') // 表单对象
  postForm.method = 'POST'
  // postForm.enctype = 'application/json';
  Object.entries(data).forEach(([key, value]) => {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', key)
    input.setAttribute(
      'value',
      typeof value === 'object' ? JSON.stringify(value) : String(value)
    )
    postForm.appendChild(input)
  })
  document.body.appendChild(postForm)
  postForm.submit()
  document.body.removeChild(postForm)
}
httpRequestor.download = function postJsonSync(
  url,
  params = {},
  throwError,
  timeout
) {
  return commonAjax({
    method: 'GET',
    url,
    params,
    headers: { 'Content-Type': 'application/json' },
    responseType: 'blob',
    errorHandler: (!throwError && httpRequestor.defaultErrorHandler) || null,
    timeout: timeout || DEFAULT_TIME_OUT,
    withCredentials: httpRequestor.withCredentials
  })
}

// 下载文件 通过get请求获取返回二进制
httpRequestor.downloadFile = function postJson(url, params) {
  // 参数
  const {
    method,
    fileName,
    data = {},
    successHandler = () => {},
    throwError,
    timeout,
    MimeType = MimeTypeConfig.XLSX
  } = params
  // console.log(params)
  return commonAjax({
    method: method || 'GET',
    url,
    responseType: 'arraybuffer',
    data: method ? data : JSON.stringify(data),
    errorHandler: (!throwError && httpRequestor.defaultErrorHandler) || null,
    timeout: timeout || DEFAULT_TIME_OUT,
    withCredentials: httpRequestor.withCredentials
    // headers: {'Content-Type': 'application/json'} // 会变成复杂请求
  }).then(response => {
    const blob = new Blob([response], { type: MimeType }) // 不兼容type
    // 利用a标签实现下载
    const link = document.createElement('a')
    link.style.display = 'none'
    link.setAttribute('type', MimeType) // 并不支持
    const downUrl = window.URL.createObjectURL(blob)
    // 成功回调
    successHandler(downUrl)
    link.href = downUrl
    // 添加到浏览器为了兼容 firefox
    document.body.appendChild(link)
    // 为了兼容qq浏览器，fileName中必须加上文件后缀
    link.download = fileName
    link.click()
    document.body.removeChild(link)
    // window.URL.revokeObjectURL(downUrl); 暂不销毁
  })
}

/**
 * 通过表单post上传文件并接收json格式的数据。并统一处理常见的错误
 * @param {string} url
 * @param {FormData|object} formElem FormData对象，或form Dom元素，其中需要含有一个name为files的选择文件的input元素
 * @param {Function?} onUploadProgress 上传进度回调，参数为event
 * @param {boolean?} throwError 是否不使用默认的异常处理方法，而把异常抛出来
 * @param {int?} timeout 超时时间，默认10秒
 * @return {Promise} 返回一个promise对象。其中then方法传递回包中的data数据；catch事件则传递整个回包，其参数为{data:{},status{code:123,message:'xxx'}}
 */
httpRequestor.uploadFile = function uploadFile(
  url,
  formElem,
  onUploadProgress,
  throwError,
  timeout
) {
  // $(formElem).attr('enctype', 'multipart/form-data');
  return commonAjax({
    method: 'POST',
    url,
    data: formElem instanceof FormData ? formElem : new FormData(formElem),
    onUploadProgress,
    errorHandler: (!throwError && httpRequestor.defaultErrorHandler) || null,
    timeout: timeout || DEFAULT_UPLOAD_TIME_OUT,
    disableQueue: true,
    withCredentials: httpRequestor.withCredentials,
    headers: { 'Content-Type': 'application/form-data' }
  })
}

/**
 * 通过get发送并接收json格式的数据（get发的本来就是json格式）。并统一处理常见的错误
 * @param {string} url
 * @param {object?} params={}
 * @param {boolean?} throwError 是否不使用默认的异常处理方法，而把异常抛出来
 * @param {int?} timeout 超时时间，默认10秒
 * @return {Promise} 返回一个promise对象。其中then方法传递回包中的data数据；catch事件则传递整个回包，其参数为{data:{},status{code:123,message:'xxx'}}
 */
httpRequestor.get = function get(url, params = {}, throwError, timeout) {
  return commonAjax({
    method: 'GET',
    url,
    params,
    errorHandler: (!throwError && httpRequestor.defaultErrorHandler) || null,
    timeout: timeout || DEFAULT_TIME_OUT,
    withCredentials: httpRequestor.withCredentials
  })
}

httpRequestor.put = function postJson(
  url,
  data = {},
  ContentType = 'application/json',
  params = {},
  throwError,
  timeout
) {
  return commonAjax({
    method: 'PUT',
    url,
    params,
    data: setContentTypeData(ContentType, data),
    errorHandler: (!throwError && httpRequestor.defaultErrorHandler) || null,
    timeout: timeout || DEFAULT_TIME_OUT,
    withCredentials: httpRequestor.withCredentials,
    headers: { 'Content-Type': ContentType }
  })
}

/**
 * 通用的发包和回包处理逻辑。会将成功获取到的带有错误码的数据转换为异常通过catch返回出来，并会将所有error对象封装成统一的形式
 * @param config
 * @return {Promise} 返回一个promise对象。其中then方法传递回包中的data数据；catch事件则传递整个回包，其参数为{data:{},status{code:123,message:'xxx'}}
 */
async function commonAjax(config) {
  try {
    return axiosInstance(config)
  } catch (err) {
    // console.log("err",err)
    // throw err
    if (err.status.code === 'DUPLICATE_REQUEST') {
      return ''
    } else {
      throw err
    }
  }
}

//请求的响应成功
function handleResponseSuccess(response) {
  if (response.data.type === 'application/json') {
    const reader = new FileReader()
    reader.readAsText(response.data, 'utf-8')
    reader.onload = function() {
      const result = JSON.parse(reader.result)
      if (result.code === '3008') {
        window.setVerification(true)
        return Promise.reject({ result })
      }
    }
  }

  const result = response.data
  const successCode = ['200', undefined]
  // 收货出货需要vip : 5008 5013
  // 领取 5004 5003 5005
  // 求购发布次数 5010
  // 免费次数用完 5012
  // 账号过期 2005
  // 微信登录相关 2007 2006
  const spcicalCode = [
    '5003',
    '5004',
    '5005',
    '5008',
    '5010',
    '5012',
    '5013',
    '2007',
    '2005',
    '2006',
    '3008',
    '5017',
    '5015',
    '5014',
    '5025',
    '5026',
    '5018',
  ]
  // && successCode.indexOf(result.code) > -1  && successCode.indexOf(result.code) > -1
  if (response.status === 200 && successCode.indexOf(result.code) > -1) {
    // 成功的状态
    return Promise.resolve(result.data || result)
  } else if (parseInt(result.code, 10) === 2001) {
    // message.warning('您还未登录，请先登录注册后再体验功能')
    // router.push({
    //     name: 'Login'
    // })
    // console.log('未登录')
    Cookies.remove('memberName')
    Cookies.remove('userId')
    Cookies.remove('userArea')
    return Promise.reject({ result })

    // router.push('/login')
  } else {
    // console.log(result.c /ode)
    if (spcicalCode.indexOf(result.code) === -1) {
      message.error(result.msg ? result.msg : '服务器连接错误')
      return Promise.reject(result)
    } else if (result.code === '2005') {
      window.setExpriedModalShow(true)
      // localStorage.setItem('expriedModalShow', 1)
      // Store().commit('setExpriedModalShow', true)
      return Promise.reject(result)
    } else if (result.code === '3008') {
      window.setVerification(true)
      return Promise.reject(result)
    } else {
      // console.log(result)
      return Promise.reject(result)
    }
  }
}

//请求响应失败
function handleResponseFail(error) {
  let result
  if (error.response) {
    // 请求已发送，响应中返回了非2xx的错误码，包括304等
    const codeMap = {
      401: '请求未授权',
      403: '请求被拒绝',
      404: '请求网址没有找到'
    }
    const code = codeMap[error.response.status] || '网络连接错误'
    result = fillErrorMessage(
      code,
      `${error.response.status} ${error.response.statusText}`,
      error.response.data
    )
  } else if (error.message.startsWith('timeout of ')) {
    // 请求没有发出去，本地产生的错误
    result = fillErrorMessage(1001, '后端没有回包')
  } else if (error instanceof Error) {
    error.status = {
      code: 1001,
      debugMessage: error.message,
      message: '网络连接错误'
    }
    result = error
  } else {
    result = fillErrorMessage(1001, error.message)
  }

  return handleError(error.config, result)
}

/**
 * 根据错误码生成标准格式的错误信息对象
 * @param {number} code 定义在errorCode中的错误码
 * @param {string?} debugMessage 调试用的错误信息
 * @param {*?} data 请求回包中的数据
 * @returns {{data: null, status: {code: number, message: string, debugMessage: string}}}
 */
function fillErrorMessage(code, debugMessage, data = null) {
  return {
    data,
    status: {
      code,
      message: '网络请求出错',
      debugMessage
    }
  }
}

/**
 * 统一的异常对象封装逻辑，在这里抛出异常
 */
function handleError(response, result) {
  message.error(
    result.status.message ? result.status.message : '服务器开小差啦~'
  )
  return Promise.reject(result)
}

/*
判断contenttype，返回不同的数据类型
 */
function setContentTypeData(contentType, data) {
  if (contentType.indexOf('form') > -1) {
    return qs.stringify(data)
  } else {
    return JSON.stringify(data)
  }
}

export default httpRequestor
