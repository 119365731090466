<template>
    <div class="follow-form">
        <a-form-model
            ref="followForm"
            :model="followModel"
            :rules="rules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ offset: 5 }"
        >
            <a-form-model-item label="跟进资质" prop="qualificationItemIds">
                <tree-checkbox-select
                    :fakeQualification="fakeQualification"
                    :qualificationOptions="vxTypeQualificationOptions"
                    :designQualificationOptions="vxDesignQualificationOptions"
                    v-model="followModel.qualificationItemIds"
                ></tree-checkbox-select>
            </a-form-model-item>
            <a-form-model-item label="跟进状态" prop="followStatus">
                <a-select placeholder="请选择跟进状态" v-model="followModel.followStatus">
                    <a-select-option
                        v-for="item in followStatusOptions"
                        :key="item.vlaue"
                        :value="item.value"
                        :disabled="item.disabled"
                        >{{ item.label }}</a-select-option
                    >
                </a-select>
            </a-form-model-item>

            <a-form-model-item label="联系电话" prop="phone">
                <a-input placeholder="请输入联系电话" v-model="followModel.phone"></a-input>
            </a-form-model-item>
            <a-form-model-item label="联系人" prop="contacts">
                <a-input placeholder="请输入联系人" v-model="followModel.contacts"></a-input>
            </a-form-model-item>
            <a-form-model-item label="跟进备注" prop="remarks">
                <a-textarea placeholder="请输入跟进备注" v-model="followModel.remarks"></a-textarea>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TreeCheckboxSelect from '@/components/TreeCheckboxSelect.vue'

export default {
    name: 'follow',
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            fakeQualification: state => state.fakeQualificationOptions,
            typeQualificationOptions: state => state.typeQualificationOptions,
            designQualificationOptions: state => state.designQualificationOptions,
            rowQuaStatus: state => state.rowQuaStatus
        }),
        vxTypeQualificationOptions() {
            if (this.rowQuaStatus.onlyConstruction) {
                return this.typeQualificationOptions
            } else {
                return []
            }
        },
        vxDesignQualificationOptions() {
            if (this.followModel.clueSources === 1) {
                if (this.rowQuaStatus.onlyDesign) {
                    return this.designQualificationOptions
                } else {
                    return []
                }
            } else {
                return []
            }
        }
    },
    components: {
        TreeCheckboxSelect
    },
    props: {
        followQualication: {
            type: Array,
            default() {
                return []
            }
        }
    },
    data() {
        return {
            followStatusOptions: [
                // { label: '无意向', value: 2, disabled: false },
                { label: '有意向', value: 1, disabled: false },
                { label: '未成交', value: 4, disabled: false },
                { label: '已成交', value: 3, disabled: false }
            ],
            phoneStatusOptions: [
                { value: 0, label: '无效' },
                { value: 1, label: '有效' }
            ],
            followModel: {
                remarks: '',
                contacts: '',
                followStatus: 1,
                outcallOrderId: '',
                phone: '',
                phoneStatus: 1,
                qualificationItemIds: []
            },
            rules: {
                followStatus: [{ required: true, message: '请选择跟进状态', trigger: 'blur' }],
                contacts: [{ required: true, message: '请输入跟进联系人', trigger: 'blur' }],
                phone: [{ validator: this.validMobile, required: true, trigger: 'blur' }],
                remarks: [{ required: true, message: '请输入跟进记录', trigger: 'blur' }],
                qualificationItemIds: [
                    { required: true, message: '请选择跟进的资质', trigger: 'change' }
                ]
            }
        }
    },
    methods: {
        validMobile(rule, value, callback) {
            const isTelephone = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/
            const isCellPhone = /^1(3|4|5|6|7|8|9)\d{9}$/
            if (!isCellPhone.test(value) && !isTelephone.test(value)) {
                callback(new Error('请输入正确的固话或者手机号码'))
            } else {
                callback()
            }
        },
        getFollowModal() {
            let data = null
            this.$refs.followForm.validate(val => {
                if (val) {
                    data = Object.assign({}, this.followModel)
                } else {
                    data = null
                }
            })
            return data
        },
        setFollowDefault(defaultVal) {
            const lastFollow = defaultVal.lastFollow
                ? defaultVal.lastFollow.followRecordList[0]
                : null

            const defaultQua = []
            let defaultPhone = ''
            let defaultContacts = ''
            if (lastFollow) {
                defaultPhone = lastFollow.phone
                defaultContacts = lastFollow.contacts

                lastFollow.qualificationItems.forEach(d => {
                    const find = this.fakeQualification.find(c => {
                        return parseInt(c.value, 10) === parseInt(d.qualificationItemId, 10)
                    })
                    if (find) {
                        defaultQua.push(find.value)
                    }
                })
            }
            this.followModel = {
                content: '',
                contacts: defaultContacts,
                followStatus: [],
                outcallOrderId: '',
                phone: defaultPhone,
                remarks: '',
                clueSources: defaultVal.clueSources,
                phoneStatus: 1,
                qualificationItemIds: defaultQua
            }
            this.$refs.followForm.clearValidate()
        }
    }

    //   get fakeQualification() {
    //     return userModule.fakeQualificationList
    //   }
    //   get qualificationOptions() {
    //     return userModule.qualificationTypeList
    //   }

    //   allQualiOptions = userModule.qualificationTreeList
}
</script>

<style lang="scss" scoped>
.follow-form {
    width: 100%;
}
</style>
