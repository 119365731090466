import { render, staticRenderFns } from "./History.vue?vue&type=template&id=52cec1f1&scoped=true"
import script from "./History.vue?vue&type=script&lang=js"
export * from "./History.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./History.vue?vue&type=style&index=0&id=52cec1f1&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "52cec1f1",
  "60f9648c"
  
)

export default component.exports