<template>
    <div class="buying-table-item">
        <div class="lf">
            <div class="is-new" v-if="row.freshResources">新上架</div>
            <div class="qua-image">
                <div class="image" :style="'background-image:url(' + qrBg + ')'"></div>
                <!-- <p>{{ index + 1 }}</p> -->
            </div>
            <div class="info">
                <div class="qualification">{{ row.qualification }}</div>
                <div class="other">
                    <div class="area-date">求购地区：{{ row.wantAreaName || '不限' }}</div>
                    <div class="area-date">落地地区：{{ row.destinationAreaName || '不限' }}</div>
                    <div class="area-date">发布时间：{{ row.releaseTime }}</div>
                </div>
            </div>
        </div>
        <div class="rt">
            <a-button
                @click="watchThisPhone"
                class="btns"
                size="small"
                v-if="!row.checkState"
                type="primary"
                >查看电话</a-button
            >

            <div class="contact" v-else>
                <div class="person line">联系人：{{ row.contactsPerson }}</div>
                <a-tooltip title="双击可复制电话号码" trigger="hover">
                    <span class="point-phone"
                        >电话： <span @dblclick="dblCopy" v-html="row.contactsPhoneText"></span
                    ></span>
                </a-tooltip>
                <!-- <div class="phone line">电话：{{ row.contactsPhone }}</div> -->
            </div>
            <div class="phone-tips">
                手机号经短信校验属实
            </div>
        </div>
    </div>
</template>

<script>
import { Tooltip } from 'ant-design-vue'
import { mapMutations } from 'vuex'
import qrBg from '@/assets/image/qr/qr_bg.png'

export default {
    name: 'table-item',
    components: {
        aTooltip: Tooltip
    },
    props: {
        freeCount: {
            type: Number,
            default: 0
        },
        row: {
            type: Object,
            default() {
                return {}
            }
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            qrBg
        }
    },
    methods: {
        ...mapMutations(['setWatchPhoneModalShow', 'setShowActivityModal']),
        dblCopy() {
            this.$message.success('电话号码已复制到粘贴板', 0.5)
        },
        watchThisPhone() {
            // if (this.freeCount === 0) {
            //   this.$message.warning('您的免费次数已使用完毕，请联系客服人员增加次数')
            //   return
            // }
            this.$http
                .get('buy/viewContactInfo', { id: this.row.id })
                .then(() => {
                    this.$emit('refreshList')
                })
                .catch(err => {
                    if (err.code === '5010') {
                      this.$message.warn('出售与求购相关使用次数已用完', 0.5)
                        // detailModule.setWatchPhoneModalShow(true)
                    }else  if (err.code === '5013') {
                      this.setWatchPhoneModalShow(true)
                    }else  if (err.code === '5014') {
                      this.setShowActivityModal(true)
                    }
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.buying-table-item {
    height: 200px;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: solid 1px #f2f2f2;
    border-left: solid 1px #f2f2f2;
    border-right: solid 1px #f2f2f2;
    .lf {
        position: relative;
        align-items: flex-start;
        display: flex;
        .is-new {
            width: 70px;
            height: 25px;
            background-color: #4ebf56;
            text-align: center;
            line-height: 25px;
            color: #fff;
            position: absolute;
            left: -20px;
            top: -20px;
            z-index: 100;
        }
        .qua-image {
            z-index: 99;
            width: 140px;
            height: 160px;
            background-color: #4d7cfe;
            position: relative;
            margin-right: 20px;
            overflow: hidden;
            border-radius: 3px;
            .image {
                width: 175px;
                height: 218px;
                background-size: cover;
                position: absolute;
                right: -55px;
                bottom: -70px;
            }
        }
        .info {
            .qualification {
                font-size: 16px;
                color: #4d7cfe;
                position: relative;
                display: flex;
                align-items: center;
                //   cursor: pointer;
                margin-bottom: 45px;
            }
            .area-date {
                font-size: 13px;
                margin-bottom: 10px;
                color: #8e8e90;
            }
        }
    }
    .rt {
        width: auto;
        .line {
            font-size: 13px;
            margin-bottom: 5px;
        }
        .btns {
            width: 100%;
            text-align: right;
            width: 70px;
            margin-bottom: 8px;
        }
        .phone-tips {
            font-size: 12px;
            color: #8e8e90;
        }
    }
}
</style>
