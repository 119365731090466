<template>
  <div class="detail">
    <div :style="'background-image:url(' + detailBg + ')'" class="bg">
      <div class="text">
        <p>小牛建讯大数据营销系统</p>
        <p>
          <span class="line" style="margin-right: 15px;"></span
          >让资质服务更简单!
          <span class="line" style="margin-left: 15px;"></span>
        </p>
      </div>
    </div>
    <div class="box">
      <!--  面包屑-->
      <!--      <div class="breadcrumb">
              <a-breadcrumb class="item" separator=">" v-if="breadcrumbShow">
                <a-breadcrumb-item style="cursor: pointer;">
                  <router-link to="/home">
                    首页
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item style="cursor: pointer;">
                  <router-link to="/dynamic">
                    小牛动态
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item
                  ><span style="color: #333333;">专题详情</span></a-breadcrumb-item
                >
              </a-breadcrumb>
              <a-breadcrumb class="item" separator=">" v-else>
                <a-breadcrumb-item style="cursor: pointer;">
                  <router-link to="/home">
                    首页
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item style="cursor: pointer;">
                  <router-link to="/caseCenter">
                    案例中心
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item
                  ><span style="color: #333333;">专题详情</span></a-breadcrumb-item
                >
              </a-breadcrumb>
            </div>-->
      <!--    主体内容-->
      <div class="main">
        <div class="particulars">
          <div v-show="dynamicDataShow" class="dynamicMain">
            <div class="particularsTitle">
              <p class="h3">
                {{ particulars.title }}
              </p>
              <p class="time">
                <span style="margin-right: 30px"
                  >时间:{{ particulars.dynamicTime }}</span
                >
                <span>阅读量:{{ particulars.readCount }}</span>
              </p>
            </div>
            <div class="context" v-html="particulars.context"></div>
          </div>
          <div v-show="!dynamicDataShow" class="caseMain">
            <p class="title">
              {{ detailData.name }}
            </p>
            <div class="describe" v-html="detailData.describe"></div>
            <div v-if="detailData.itemBg">
              <p class="effectTitle">
                {{ detailData.itemBg.itemBgTitle }}
              </p>
              <p>{{ detailData.itemBg.itemBgContent }}</p>
            </div>
            <div v-if="detailData.effect">
              <p class="effectTitle">
                {{ detailData.effect.effectTitle }}
              </p>
              <p>{{ detailData.effect.effectContent }}</p>
            </div>
            <div v-if="detailData.worth">
              <p class="effectTitle">
                {{ detailData.worth.worthTitle }}
              </p>
              <p>{{ detailData.worth.info }}</p>
              <ul>
                <li
                  v-for="(d, i) in detailData.worth.worthContent"
                  :key="i + 'ddd'"
                >
                  <p class="worthContentTitle">
                    {{ d.title }}
                  </p>
                  <p>{{ d.text }}</p>
                </li>
              </ul>
            </div>
          </div>
          <!--      上一条-->
          <div>
            <div v-show="dynamicDataShow" class="previous">
              <div class="place">
                <div v-if="prePageDataShow">
                  <p @click="goDynamicPage(prePageData.dynamicId)">
                    <span class="text">上一篇文章</span>
                    <a
                      ><span>《</span><span> {{ prePageData.title }}</span
                      ><span>》</span></a
                    >
                  </p>
                </div>
                <div v-if="nextPageDataShow" class="nextPage">
                  <p @click="goDynamicPage(nextPageData.dynamicId)">
                    <span class="text">下一篇文章</span>
                    <a>
                      <span>《</span><span> {{ nextPageData.title }}</span
                      ><span>》</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div v-show="!dynamicDataShow" class="previous">
              <div class="place">
                <div v-if="casePrePageDataShow">
                  <p @click="goCasePage(casePrePageData.index)">
                    <span class="text">上一篇文章</span>
                    <a
                      ><span>《</span><span> {{ casePrePageData.name }}</span
                      ><span>》</span></a
                    >
                  </p>
                </div>
                <div v-if="caseNextPageDataShow" class="nextPage">
                  <p @click="goCasePage(caseNextPageData.index)">
                    <span class="text">下一篇文章</span>
                    <a>
                      <span>《</span><span> {{ caseNextPageData.name }}</span
                      ><span>》</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="rightModular">
          <div>
            <div v-if="isShowReg" class="reg">
              <div class="form-title">
                <div class="title">
                  <span class="line"></span>
                  <div class="text">立即注册</div>
                  <span class="line"></span>
                </div>
                <p class="tips">即可领取3天VIP查询更多</p>
              </div>
              <a-form-model
                ref="ruleForm"
                :label-col="{ span: 0 }"
                :model="formValue"
                :rules="rules"
                :wrapper-col="{ span: 23 }"
                class="form"
              >
                <a-form-model-item label="" prop="phone">
                  <a-input
                    v-model="formValue.phone"
                    placeholder="请输入手机号"
                  />
                </a-form-model-item>
                <a-form-model-item label="" prop="verifyCode">
                  <a-input-search
                    v-model="formValue.verifyCode"
                    placeholder="请输入短信验证码"
                  >
                    <a-button
                      slot="enterButton"
                      :disabled="isSend"
                      style="margin-left: 17px"
                      @click.native="beforeSendCode"
                    >
                      {{ verifyText }}
                    </a-button>
                  </a-input-search>
                </a-form-model-item>
              </a-form-model>
              <a-button
                id="freeRegister"
                class="main-btns"
                size="large"
                type="primary"
                @click="login"
                >免费注册
              </a-button>
            </div>
            <div v-else class="detailRegImg" @click="detailReg">
              <img :src="detailRegImg" alt="" />
            </div>
          </div>

          <div class="information">
            <div
              :style="'background-image:url(' + clickSearch + ')'"
              class="query"
            >
              <div class="left">
                <p class="top">立即加入吧</p>
                <p class="text">查询行业内的资质信息</p>
                <a-button
                  class="left-btn"
                  size="large"
                  type="primary"
                  @click="goToXiaoniuReceipt"
                  >点击查询
                </a-button>
              </div>
              <div></div>
            </div>
            <div class="informationMain">
              <div class="text">
                <div class="InfoTitle">推荐企业资质信息</div>
              </div>
              <ul>
                <li
                  v-for="(item, index) in recommendQualificationData"
                  :key="index"
                  style="cursor: pointer;"
                  @click="goToFindQua(item)"
                >
                  {{ item.qualificationNameLevel }}
                </li>
              </ul>
            </div>
          </div>
          <div class="special">
            <div class="InfoTitle">推荐专题</div>
            <ul>
              <li
                v-for="(item, i) in recommendedTopicsData"
                :key="i"
                @click="goDynamicPage(item.dynamicId)"
              >
                <img :src="item.homepagePic" alt="小牛建讯" />
                <p>{{ item.title }}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!--      相关推荐-->
      <div v-if="isRecommend" class="relevant">
        <p class="title">相关推荐</p>
        <ul class="relevantItem">
          <li
            v-for="(item, index) in dynamicRecommendData"
            :key="index + 'index'"
            @click="goDynamicPage(item.dynamicId)"
          >
            <img :src="item.homepagePic" alt="" />
            <p class="itemTitle">{{ item.title }}</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import clickSearch from '@/assets/dynamic/clickSearch.png'
import detailBg from '@/assets/dynamic/detail_bg.png'
import detailRegImg from '@/assets/dynamic/detailRegImg.jpeg'

import { mapMutations, mapState, mapActions } from 'vuex'

export default {
  name: 'Details.vue',
  inject: ['reload'],
  // asyncData({ store, route }) {
  //   const dynamicId = route.params.dynamicId
  //   let params = dynamicId ? { dynamicId: parseInt(dynamicId, 10) } : null
  //   return store.dispatch('getDynamicDetail', params)
  // },
  computed: {
    ...mapState(['dynamicDetail']),
    prePageData() {
      return this.dynamicDetail[0]
    },
    particulars() {
      return this.dynamicDetail[1] || {}
    },
    nextPageData() {
      return this.dynamicDetail[2]
    },
    prePageDataShow() {
      return this.prePageData ? this.prePageData.dynamicId : false
    },
    nextPageDataShow() {
      return this.nextPageData ? this.nextPageData.dynamicId : false
    },
    projectEnv() {
      if (process.env.VUE_APP_TYPE) {
        return process.env.VUE_APP_TYPE
      } else {
        return 'online'
      }
    }
  },
  data() {
    return {
      userIpArea: null,
      detailsName: '',
      detailRegImg,
      detailBg,
      isShowReg: false,
      clickSearch: clickSearch,
      formValue: {
        phone: '',
        verifyCode: ''
      },
      rules: {
        phone: [
          {
            validator: this.validatePhoneNumber,
            required: true,
            message: '请输入正确的手机号',
            trigger: 'blur'
          }
        ],
        verifyCode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'change'
          }
        ]
      },
      verifyText: '获取验证码',
      isSend: false,
      //推荐资质信息
      recommendQualificationData: [],
      //详情
      //particulars: {},

      //相关推荐
      dynamicRecommendData: [],
      // 推荐专题
      recommendedTopicsData: [],
      // prePageData: {},
      // nextPageData: {},
      isRecommend: false,
      detailData: {},
      dynamicDataShow: false,
      breadcrumbShow: false,
      casePrePageDataShow: false,
      caseNextPageDataShow: false,
      casePrePageData: {},
      caseNextPageData: {},
      caseRes: [
        {
          index: 1,
          name: '成都六六建筑工程咨询有限公司',
          describe:
            '<p>时间维度：2个月</p><p>线索量：提升70%</p><p>成交量：提升25%</p><p>业绩量：提升120%</p>',
          itemBg: {
            itemBgTitle: '项目背景',
            itemBgContent:
              '成都六六建筑工程咨询有限公司拥有40余名业务人员，年产值达1000万，实际上，在获客方面，六六依然困难重重，公司业务员找客户需要花费大量的时间多方查找层层筛选，这样的方式不仅耗时长且不精准，效率极低。有时候一个业务员打100个电话也未必能找到一个意向客户。随着市场竞争白热化，获客渠道成本增加，企业如果还像以往过分依靠老板个人人脉，花费大量时间查找线索却收效甚微，生意只会越做越难。基于以上背景六六老板为寻求企业盈利的突破口，在多方对比之下选择了小牛建讯'
          },
          effect: {
            effectTitle: '应用效果',
            effectContent:
              '业务员查询企业资质信息由半个小时缩短为3分钟，筛选关键决策人联系方式从100次无效沟通到现在1次成功。2个月内，线索量提升70%，成交量提升25%，业绩量提升120%。作为大数据应用的先行者之一，成都六六建筑工程咨询有限公司深刻体验到大数据给企业业务带来的"智慧升级"。后续小牛建讯会给予六六对数据和功能需求的定制开发，为企业提供更好的营销获客及服务支持。'
          },
          worth: {
            worthTitle: '价值体现',
            info:
              '客户可以通过小牛建讯找货查询/出货查询/资质集市等功能，提高销售效率，获取有效的潜在线索。',
            worthContent: [
              {
                title: '1、提高效率',
                text:
                  '360°企业资质信息图谱，无需多方查找层层筛选即可全面获取企业资质信息，节省销售时间。'
              },
              {
                title: '2、获取潜客',
                text:
                  '通过多维标签组合筛选，快速从亿级数据中获取关键决策人联系方式，识别客户意向，迅速抓住商机。'
              },
              {
                title: '3、资源共享',
                text:
                  '提供专属于建筑资质服务的买卖社区，最便捷的资质信息供需平台，让资质交易快速、高效又放心。'
              }
            ]
          }
        },
        {
          index: 2,
          name: '华建博文管理咨询（北京）有限公司',
          describe:
            '<p>时间维度：5个月</p><p>线索量：提升120%</p><p>成交量：提升60%</p><p>业绩量：提升70%</p>',
          itemBg: {
            itemBgTitle: '项目背景',
            itemBgContent:
              '华建博文管理咨询（北京）有限公司是一家从事企业并购重组和资质代理服务的专业机构，隶属于华建博文集团，集团总部在北京，公司业务遍及全国。\n' +
              '自2007年成立以来，公司致力于向不同类型、不同成长阶段的企业提供并购重组、资质代理，结合多年的专业经验和对行业市场的深刻理解和体验，华建博文为客户提供整体而全面的系统解决方案和量身定做的顾问式服务来满足客户独一无二的业务需求，公司拥有一支高素质、多元化的业务团队和来自不同行业的高级资深专家以专长集合的方式与客户密切合作，提供资源优化配置的增值服务，得到了客户的广泛认可。公司在企业并购和资质代理领域有着独特的资源，与众多的财务顾问公司、律师事务所、投资机构和资质代理机构保持战略合作，通过挖掘各类优质项目，助力企业转型成长，实现企业和投资者的共赢合作。\n' +
              '多年来，华建博文在资质服务行业一直属于行业头部企业，口碑与专业都非常好，小牛建讯初期公测之时有幸邀请到华建博文为产品进行内测，试用期间华建博文提出了很多关于资质服务常见的行业需求及拓客难题。'
          },
          effect: {
            effectTitle: '应用效果',
            effectContent:
              '华建博文自使用小牛建讯以来，5个月内累计获得120%有效线索，70%业绩增长，资质流转效率提升60%以上，公司表示会一直使用小牛建讯，实现更好的业务增长。小牛建讯覆盖全国建筑企业的资质信息资源，拥有小牛建讯，你就是资质界行走的资源库，没有100次无效沟通只有一次成功！'
          },
          worthTitle: {
            title: '价值体现',
            info:
              '客户可以通过小牛建讯找货查询/出货查询/资质集市等功能，提高销售效率，获取有效的潜在线索。',
            worthContent: [
              {
                title: '1、提高效率',
                text:
                  '360°企业资质信息图谱，无需多方查找层层筛选即可全面获取企业资质信息，节省销售时间。'
              },
              {
                title: '2、获取潜客',
                text:
                  '通过多维标签组合筛选，快速从亿级数据中获取关键决策人联系方式，识别客户意向，迅速抓住商机。'
              },
              {
                title: '3、资源共享',
                text:
                  '提供专属于建筑资质服务的买卖社区，最便捷的资质信息供需平台，让资质交易快速、高效又放心。'
              }
            ]
          }
        },
        {
          index: 3,
          name: '江苏名俊企业管理有限公司',
          describe:
            '<p>时间维度：3个月</p><p>线索量：提升80%</p><p>成交量：提升50%</p><p>业绩量：提升70%</p>',
          itemBg: {
            itemBgTitle: '项目背景',
            itemBgContent:
              '江苏名俊企业管理有限公司在江苏地区是行业知名建筑资质代办企业，随着市场竞争压力加大，公司业绩一直不上不下，为了拓展企业的宣传获客渠道，也曾在抖音上投放广告，但是获客成本太高。公司老总一直在寻找一个成本低且转化高的渠道来促进业绩稳步增长，通过圈内人的推荐，接触到小牛建讯，初期只是让业务员试用，通过业务人员的不断测试和反馈之后，发现小牛建讯数据确实精准，同时更新速度很快，不仅节约时间，对挖掘意向客户也有很大的帮助。公司老总很满意试用效果，并且非常期待软件的后续优化版本，当下就购买了小牛建讯的企业定制版会员。'
          },
          effect: {
            effectTitle: '应用效果',
            effectContent:
              '初期名俊老总不太相信小牛建讯真的有那么好用，事实证明，小牛建讯数据有效率已经能达到90%以上，三个月内，公司的业绩和线索量明显提升，线索量提升80%，资质流转效率提升50%，业绩量提升70%。VIP专属客服全程协助名俊公司更好的使用小牛建讯，时刻接收反馈，及时处理问题。'
          },
          worth: {
            worthTitle: '价值体现',
            info:
              '客户可以通过小牛建讯找货查询/出货查询/资质集市等功能，提高销售效率，获取有效的潜在线索。',
            worthContent: [
              {
                title: '1、提高效率',
                text:
                  '360°企业资质信息图谱，无需多方查找层层筛选即可全面获取企业资质信息，节省销售时间。'
              },
              {
                title: '2、获取潜客',
                text:
                  '通过多维标签组合筛选，快速从亿级数据中获取关键决策人联系方式，识别客户意向，迅速抓住商机。'
              },
              {
                title: '3、资源共享',
                text:
                  '提供专属于建筑资质服务的买卖社区，最便捷的资质信息供需平台，让资质交易快速、高效又放心。'
              }
            ]
          }
        },
        {
          index: 4,
          name: '江苏唯诺企业管理有限公司',
          describe:
            '<p>时间维度：3个月</p><p>线索量：提升50%</p><p>成交量：提升35%</p><p>业绩量：提升60%</p>',
          itemBg: {
            itemBgTitle: '项目背景',
            itemBgContent:
              '江苏唯诺企业管理有限公司，成立于2018年7月，是一家专注于建筑领域资质咨询服务的专业代理机构。从成立之初，企业一直飞速发展，如今已成为行业知名资质服务。现下由于政策及市场环境，公司增长速度放缓，公司吴总因与江苏元臣集团龚总是好友，经龚总推荐，为旗下员工申请了小牛建讯的7天体验时间。在体验期间，经吴总反馈小牛建讯数据量多而全，精准度以及时效性也超出预期，虽然有时一些数据查询不出来，但是反馈给小牛建讯的专业客服，一般2个小时左右就能更新出来，为了更好更高效的提升企业营销获客效率，吴总决定与小牛建讯达成长期合作，购买了小牛建讯的集团定制版。'
          },
          effect: {
            effectTitle: '应用效果',
            effectContent:
              '3个月内，企业线索量提升50%，成交量提升35%，业绩量提升60%。小牛建讯云平台，对企业的销售起着显而易见的加速效果，总体使用效果比NACC更好。'
          },
          worth: {
            worthTitle: '价值体现',
            info:
              '客户可以通过小牛建讯找货查询/出货查询/资质集市等功能，提高销售效率，获取有效的潜在线索。',
            worthContent: [
              {
                title: '1、提高效率',
                text:
                  '360°企业资质信息图谱，无需多方查找层层筛选即可全面获取企业资质信息，节省销售时间。'
              },
              {
                title: '2、获取潜客',
                text:
                  '通过多维标签组合筛选，快速从亿级数据中获取关键决策人联系方式，识别客户意向，迅速抓住商机。'
              },
              {
                title: '3、资源共享',
                text:
                  '提供专属于建筑资质服务的买卖社区，最便捷的资质信息供需平台，让资质交易快速、高效又放心。'
              }
            ]
          }
        },
        {
          index: 5,
          name: '江苏元臣企业管理有限公司',
          describe:
            '<p>时间维度：5个月</p><p>线索量：提升75%</p><p>成交量：提升35%</p><p>业绩量：提升90%</p>',
          itemBg: {
            itemBgTitle: '项目背景',
            itemBgContent:
              '江苏元臣集团成立于2006年09月28日，是一家在资质服务行业耕耘15年，全国领先的综合性企业服务机构，建筑类成长型和创新型企业的孵化器。拥有众多相关领域的高级专家：建筑资质咨询，人力资源猎头，知识产权，建筑人才培训等，并下设成都研发中心、四川营销中心、河南、云南等多家分公司，拥有员工452人。\n' +
              '面临国家政策、市场竞争及需求的变化，具有行业领军地位的元臣集体广泛聚集业内优秀的技术创新人才，携手江苏唯诺企业管理有限公司，广州鸿运企业咨询有限公司，书豪科技集团有限公司等数百家业内知名企业，展开前沿技术的孵化和应用创新，赋能产业数字化转型和数字经济发展。集团CEO龚总是一个非常有魄力的人，为建筑资质服务业积极探寻更高效的营销获客方式，整合资质行业内外资源，专注于解决资质服务获客难、进出货效率低，渠道单一等一系列问题。历经多年，不断收集行业痛点，深挖用户需求，经元臣集团1000+业务员测试验证，12次优化迭代，才有如今涵盖856.24万建筑企业数据、235.7万建筑资质信息、5300+资质服务商的小牛建讯资质服务平台。'
          },
          effect: {
            effectTitle: '应用效果',
            effectContent:
              '经过验证，小牛建讯确实能够显著降低销售成本，提升销售业绩。以元臣集团使用小牛建讯的4个月时间维度统计，总体线索量环比提升75%，成交量提升35%，业绩量提升90%以上。通过内部真实的数据反馈，小牛建讯也看清了未来的方向，成立专门的用户需求调研部门，继续收集整理用户需求，深挖用户痛点，不断进行产品的优化迭代，解决用户问题，提供更加高效的营销获客方式！'
          },
          worth: {
            worthTitle: '价值体现',
            info:
              '客户可以通过小牛建讯找货查询/出货查询/资质集市等功能，提高销售效率，获取有效的潜在线索。',
            worthContent: [
              {
                title: '1、提高效率',
                text:
                  '360°企业资质信息图谱，无需多方查找层层筛选即可全面获取企业资质信息，节省销售时间。'
              },
              {
                title: '2、获取潜客',
                text:
                  '通过多维标签组合筛选，快速从亿级数据中获取关键决策人联系方式，识别客户意向，迅速抓住商机。'
              },
              {
                title: '3、资源共享',
                text:
                  '提供专属于建筑资质服务的买卖社区，最便捷的资质信息供需平台，让资质交易快速、高效又放心。'
              }
            ]
          }
        },
        {
          index: 6,
          name: '江苏智臣教育咨询有限公司',
          describe:
            '<p>时间维度：1个月</p><p>线索量：提升30%</p><p>成交量：提升15%</p><p>业绩量：提升40%</p>',
          itemBg: {
            itemBgTitle: '项目背景',
            itemBgContent:
              '江苏智臣教育咨询有限公司成立于2011年，是一家专业从事建筑领域资质代理服务的公司，总部位于江苏扬州，另设云南分部，四川分部等。公司拥有精通建筑资质领域的咨询顾问团队，专业的资质申报材料编制团队，以及业务娴熟的市场业务拓展团队，为全国多个省份建筑领域的企业解决资质办理、资质升级、资质增项、资质维护、资质换证等繁琐问题。\n' +
              '智臣在行业内虽然综合实力强，作为行业老牌资质服务，以往的获客渠道也很多样性，业务人员专业素养极高，但是线索有效率及接通率始终还是不高。每个业务人员每日必须保证有200+的电话量才能收获一到两个意向客户，为了公司能占领更多的市场份额，公司老总通过多方渠道了解到小牛建讯大数据资质查询平台，联系了我们的VIP客服申请试用，希望通过小牛建讯解决企业获客难的问题。'
          },
          effect: {
            effectTitle: '应用效果',
            effectContent:
              '通过一个月的持续使用，在小牛建讯专属客服的共同协作之下，智臣的线索量以及接通率明显提升，其中线索量提升30%，成交量提升15%，业绩量提升40%。\n' +
              '通过小牛建讯，改善企业不稳定的销售线索获取渠道，有效销售线索获取成本降低30%。\n' +
              '小牛建讯也会不忘初心，以资质人的核心需求为出发点，持续深耕资质智能服务，为更多的资质服务提供完美的拓客体验。'
          },
          worth: {
            worthTitle: '价值体现',
            info:
              '客户可以通过小牛建讯找货查询/出货查询/资质集市等功能，提高销售效率，获取有效的潜在线索。',
            worthContent: [
              {
                title: '1、提高效率',
                text:
                  '360°企业资质信息图谱，无需多方查找层层筛选即可全面获取企业资质信息，节省销售时间。'
              },
              {
                title: '2、获取潜客',
                text:
                  '通过多维标签组合筛选，快速从亿级数据中获取关键决策人联系方式，识别客户意向，迅速抓住商机。'
              },
              {
                title: '3、资源共享',
                text:
                  '提供专属于建筑资质服务的买卖社区，最便捷的资质信息供需平台，让资质交易快速、高效又放心。'
              }
            ]
          }
        },
        {
          index: 7,
          name: '四川辰轩企业服务有限公司',
          describe:
            '<p>时间维度：3个月</p><p>线索量：提升80%</p><p>成交量：提升50%</p><p>业绩量：提升70%</p>',
          itemBg: {
            itemBgTitle: '项目背景',
            itemBgContent:
              '四川辰轩企业服务有限公司（以下称“四川辰轩”）是在2017年11月6日成立的全国性建筑行业资质领域的服务平台，总部设在成都，位于成都市锦江区锦华路汇融广场(锦华)f座2502，是四川省优质的建筑企业资质办理公司。\n' +
              '四川辰轩以“专业与性价比是永恒的追求”为愿景，牢牢把握高品质服务为主线，专注成为更专业、更高效、更便捷、更低价的服务型企业。2019年，四川辰轩凭借高水平企业管理，优化业务结构和转换发展动能，开启了新征程。为了更好的保持公司业务的飞速发展，势必需要开拓更多的客源渠道支撑公司的年度营收目标。公司老总通过好友了解到小牛建讯是一款专为资质服务量身打造的营销利器时，对小牛建讯非常感兴趣申请了试用体验服务。'
          },
          effect: {
            effectTitle: '应用效果',
            effectContent:
              '短短几天的体验期，四川辰轩资质流转效率就提升了20%以上，通过小牛建讯的出货查询及资质集市等板块转让了公司囤积很久的一些冷门资质。\n' +
              '因承轩老总对小牛建讯的试用效果非常满意，随即开通了小牛建讯的企业专用版，3个月时间，公司线索量提升80%，资质流转效率提升50%，业绩量提升70%。小牛建讯作为资质服务庞大的客户资源池，将会源源不断地提供精准的建筑资质客户资源！'
          },
          worth: {
            worthTitle: '价值体现',
            info:
              '客户可以通过小牛建讯找货查询/出货查询/资质社区等功能，提高销售效率，获取有效的潜在线索。',
            worthContent: [
              {
                title: '1、提高效率',
                text:
                  '360°企业资质信息图谱，无需多方查找层层筛选即可全面获取企业资质信息，节省销售时间。'
              },
              {
                title: '2、获取潜客',
                text:
                  '通过多维标签组合筛选，快速从亿级数据中获取关键决策人联系方式，识别客户意向，迅速抓住商机。'
              },
              {
                title: '3、资源共享',
                text:
                  '提供专属于建筑资质服务的买卖社区，最便捷的资质信息供需平台，让资质交易快速、高效又放心。'
              }
            ]
          }
        },
        {
          index: 8,
          name: '四川中象融创企业管理有限公司',
          describe:
            '<p>时间维度：2个月</p><p>线索量：提升70%</p><p>成交量：提升25%</p><p>业绩量：提升120%</p>',
          itemBg: {
            itemBgTitle: '项目背景',
            itemBgContent:
              '四川中象融创企业管理有限公司于2018年10月25日成立，立足川内，面向西南，经过近两年的快速发展，中象融创已经成为行业的“增长新星”，随着互联网的迅猛发展，TOB产业的传统获客模式已进入瓶颈期，中象融创的老总表示如今是互联网时代，新的经营模式和渠道层出不穷，企业要发展，必须具有前瞻性，敢于尝试突破，或将不被淘汰。中象融创以前曾用过NACC，对于大数据获客系统带来的便利及盈利相当满意，所以当了解到小牛建讯是一款专为建筑资质服务打造的大数据营销系统时，立即向小牛建讯官方客服申请试用。'
          },
          effect: {
            effectTitle: '应用效果',
            effectContent:
              '小牛建讯目前的版本比NACC更全面、精准，如今全网没有任何一款资质服务获客系统能与小牛建讯匹敌。中象融创公司在使用小牛建讯的2个月内，线索量同比提升70%，成交量提升25%，业绩量的提升更是惊人，已达120%，效果显而易见，如今的小牛建讯，在四川、江苏、云南、三大省份数据更新精准率已高达95%。'
          },
          worth: {
            worthTitle: '价值体现',
            info:
              '客户可以通过小牛建讯找货查询/出货查询/资质社区等功能，提高销售效率，获取有效的潜在线索。',
            worthContent: [
              {
                title: '1、提高效率',
                text:
                  '360°企业资质信息图谱，无需多方查找层层筛选即可全面获取企业资质信息，节省销售时间。'
              },
              {
                title: '2、获取潜客',
                text:
                  '通过多维标签组合筛选，快速从亿级数据中获取关键决策人联系方式，识别客户意向，迅速抓住商机。'
              },
              {
                title: '3、资源共享',
                text:
                  '提供专属于建筑资质服务的买卖社区，最便捷的资质信息供需平台，让资质交易快速、高效又放心。'
              }
            ]
          }
        },
        {
          index: 9,
          name: '江苏君致建设管理有限公司',
          describe:
            '<p>时间维度：3个月</p><p>线索量：提升65%</p><p>成交量：提升60%</p><p>业绩量：提升45%</p>',
          itemBg: {
            itemBgTitle: '项目背景',
            itemBgContent:
              '江苏君致建设管理有限公司于2019年11月11日成立，是江苏资质行业典型的“后起之秀”凭借专业的服务团队以及科学合理的管理方法，在2019年以及2020年业务增长速度惊人。但是面临2021年资质政策改革，行业的发展趋势不明，想要保持足够的竞争力，维持预期的业绩增长，寻求更好更高效的获客之法刻不容缓。\n' +
              '公司负责人看到很多同行都在用小牛建讯开发客户，经过深入了解，发现使用客户对小牛建讯的评价都很不错，公司负责人立即申请试用小牛建讯。'
          },
          effect: {
            effectTitle: '应用效果',
            effectContent:
              '初期由于对小牛建讯的使用技巧不是太熟悉，经过小牛建讯专属客服1对1的讲解和协助之后，从开始业务的毫无起色到后来一天能开发出5-10个意向客户，之后开通了企业版，每天的查询量更多，销售效率提升，客户转化率更高了。\n' +
              '3个月内，线索量提升65%，资质流转效率提升60%，业绩量提升45%。公司负责人表示小牛建讯未来一定是建筑资质服务的”超级引擎”！'
          },
          worth: {
            worthTitle: '价值体现',
            info:
              '客户可以通过小牛建讯找货查询/出货查询/资质社区等功能，提高销售效率，获取有效的潜在线索。',
            worthContent: [
              {
                title: '1、提高效率',
                text:
                  '360°企业资质信息图谱，无需多方查找层层筛选即可全面获取企业资质信息，节省销售时间。'
              },
              {
                title: '2、获取潜客',
                text:
                  '通过多维标签组合筛选，快速从亿级数据中获取关键决策人联系方式，识别客户意向，迅速抓住商机。'
              },
              {
                title: '3、资源共享',
                text:
                  '提供专属于建筑资质服务的买卖社区，最便捷的资质信息供需平台，让资质交易快速、高效又放心。'
              }
            ]
          }
        }
      ]
    }
  },
  methods: {
    ...mapMutations(['setUserInfo']),
    ...mapActions(['getDynamicDetail']),
    refresh() {
      window.location.reload()
      setTimeout(refresh, 5000)
    },
    // 在新页面打开新的小牛收货
    goToXiaoniuReceipt() {
      // 开发环境
      if (this.projectEnv === 'dev') {
        window.open('http://localhost:8080/#/longna/receipt')
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open('http://www.xnjx666.com:9000/#/longna/receipt')
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open('http://192.168.0.108:8099/#/longna/receipt')
      }
    },
    getAreaIp() {
      this.$http.get('areaIp/get').then(res => {
        this.userIpArea = res
      })
    },
    goToFindQua(item) {
      let url = ''
      let xiaoniuPage = ''
      if (this.projectEnv === 'dev') {
        url = 'http://localhost:8080/#/'
      } else if (
          this.projectEnv === 'online' ||
          this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        url = 'http://www.xnjx666.com:9000/#/'
      } else if (this.projectEnv === 'test') {
        // 测试环境
        url = 'http://192.168.0.108:8099/#/'
      }
      xiaoniuPage =
          'longna/receipt?qualificationId=' +
          parseInt(item.qualificationId)+
          '&userArea=' +
          JSON.stringify(this.userIpArea)

      window.open(url + xiaoniuPage)
    },
    goDynamicPage(id) {
      this.$router.push({
        path: `/details/${id}`
      })
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      // this.$store.dispatch('getDynamicDetail', { dynamicId: id })
      this.detail(id)
    },

    goCasePage(id) {
      this.$router.push({
        path: `/caseDetails/${id}`
      })
      this.getPageData(id)
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    // nextPage(id) {
    //   this.detail(id)
    //   // this.dynamicRecommend(id);
    //   document.body.scrollTop = 0
    //   document.documentElement.scrollTop = 0
    // },
    beforeSendCode() {
      this.$refs.ruleForm.validateField('phone', val => {
        if (!val) {
          this.sendCode()
        }
      })
      // console.log(validate)
      // return
    },
    sendCode() {
      // console.log('?')
      this.$http
        .post(
          'user/sms/verificte/send',
          { cellPhone: this.formValue.phone },
          'application/x-www-form-urlencoded'
        )
        .then(() => {
          this.isSend = true
          let time = 120
          const interVal = setInterval(() => {
            if (time === 0) {
              this.verifyText = '重新发送'
              clearInterval(interVal)
              this.isSend = false
            } else {
              this.verifyText = '重新发送(' + time + ')'
            }
            time -= 1
          }, 1000)
        })
        .catch(() => {
          this.isSend = false
        })
    },
    userInfo(userId) {
      this.$http.get('member/get', { id: userId }).then(r => {
        this.setUserInfo(r)
      })
    },
    login() {
      const hvaeUserId = Cookie.get('userId')
      if (!hvaeUserId) {
        this.$refs.ruleForm.validate(val => {
          if (val) {
            const params = {
              username: this.formValue.phone,
              verifycode: this.formValue.verifyCode,
              loginSource: 0
            }
            this.$http
              .post('user/login', params, 'application/x-www-form-urlencoded')
              .then(r => {
                this.$message.success('登录成功', 0.5)
                Cookie.set('JSESSIONID', r.tokeId, { expires: 7 })
                Cookie.set('memberName', r.memberName, { expires: 7 })
                Cookie.set('userId', r.id, { expires: 7 })
                this.userInfo(r.id)
                this.reload()
                // this.$router.push('/')
              })
          }
        })
      } else {
        this.$message.success('您已登录!', 0.5)
      }
    },
    //推荐资质
    recommendQualification() {
      this.$http.get('recommend/qualification/query', {}).then(res => {
        // console.log(res)
        this.recommendQualificationData = res
      })
    },
    //推荐专题
    recommendedTopics() {
      const params = {
        current: 1,
        dynamicTypeEnum: 'RECOMMEND',
        size: 2
      }
      this.$https.post('dynamic/selList', params).then(res => {
        this.recommendedTopicsData = res.list
      })
    },
    //相关推荐
    dynamicRecommend(id) {
      const params = {
        current: 1,
        size: 3,
        dynamicId: parseInt(id)
      }
      this.$http.get('dynamic/recommend', params).then(res => {
        this.dynamicRecommendData = res
        this.isRecommend = this.dynamicRecommendData.length > 1
      })
    },
    // pageParameter

    detail(id) {
      this.dynamicDataShow = true
      this.dynamicRecommend(id)
    },
    goToProduct(item) {
      if (!item.link) {
        this.$message.warning(
          '该功能暂未开放，如需体验请下载小牛建讯客户端',
          0.5
        )
        return
      } else {
        this.$router.push(item.link)
      }
    },
    recommendQualificationSearch(item, url) {
      if (!url.link) {
        this.$message.warning(
          '该功能暂未开放，如需体验请下载小牛建讯客户端',
          0.5
        )
        return
      } else {
        localStorage.setItem(
          'receiptQua',
          JSON.stringify({ key: item.qualificationId })
        )
        this.$router.push(url.link)
        //  Cookie.set('qualificationId', item.qualificationId, { expires: 7 })
      }
    },
    getPageData(index) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      // localStorage.setItem("caseId",index);
      this.dynamicDataShow = false
      // console.log('当前数据的 index',index)
      this.caseRes.forEach(item => {
        if (item.index === index) {
          this.detailData = item
          // console.log('当前数据',this.detailData);
        }
      })
      if (index === 1) {
        this.casePrePageDataShow = false
        this.caseNextPageDataShow = true
        this.caseRes.forEach(item => {
          if (item.index === index + 1) {
            this.caseNextPageData = item
          }
        })
      } else if (index === 9) {
        this.casePrePageDataShow = true
        this.caseNextPageDataShow = false
        this.caseRes.forEach(item => {
          if (item.index === index - 1) {
            this.casePrePageData = item
          }
        })
      } else {
        this.casePrePageDataShow = true
        this.caseNextPageDataShow = true
        this.caseRes.forEach(item => {
          if (item.index === index + 1) {
            this.caseNextPageData = item
          }
          if (item.index === index - 1) {
            this.casePrePageData = item
          }
        })
      }
    },
    detailReg() {
      window.open(
        'http://p.qiao.baidu.com/cps/chat?siteId=16126914&userId=29006515&siteToken=021ffcc1cc48017af59aa14863832a01'
      )
    }
  },
  mounted() {
    this.getAreaIp()
    // const dynamicId = this.$route.params.dynamicId
    // if (dynamicId) {
    //   this.isRecommend = Boolean(dynamicId)
    //   this.dataPromise.then(() => {
    //     this.breadcrumbShow = true
    //     this.detail(dynamicId)
    //   })
    // }
  },
  created() {
    const caseId = this.$route.params.caseId
    const dynamicId = this.$route.params.dynamicId
    if (!this.$isServer && this.$metaInfo) {
      this.$watch('$metaInfo', () => {
        if (this.$metaInfo.title) {
          this.$meta().refresh()
        }
      })
      console.log(dynamicId)
      if (dynamicId){
        this.getDynamicDetail({ dynamicId:parseInt(dynamicId, 10 )})
      }
    }
    if (dynamicId) {
      this.isRecommend = Boolean(dynamicId)
      this.breadcrumbShow = true
      this.detail(dynamicId)
      // if (this.$isServer) {
      //   this.breadcrumbShow = true
      //   this.detail(dynamicId)
      // } else {
      //   this.dataPromise.then(() => {
      //     this.breadcrumbShow = true
      //     this.detail(dynamicId)
      //   })
      // }
    }
    if (caseId) {
      this.breadcrumbShow = false
      this.getPageData(parseInt(caseId))
    }
    this.recommendedTopics()
    this.recommendQualification()

    const hvaeUserId = Cookie.get('userId')
    if (!hvaeUserId) {
      this.isShowReg = true
    } else {
      this.isShowReg = false
    }
    this.$meta().refresh()
  },
  serverPrefetch() {
    const dynamicId = this.$route.params.dynamicId
    if (dynamicId) {
    return this.getDynamicDetail({ dynamicId: parseInt(dynamicId, 10) })
    }
  },
  metaInfo() {
    // console.log(this.particulars, this.dynamicDetail)
    const title = this.dynamicDataShow
      ? this.particulars.title
      : this.detailData.name + '-案例中心-小牛建讯'
    let meta = []
    if (!this.dynamicDataShow) {
      meta = [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: `企业案例-${this.detailData.name}-小牛建讯`
        },
        {
          vmid: 'description',
          name: 'description',
          content: `小牛建讯建筑资质大数据查询系统, 为您提供${this.detailData.name}轻松查,面向资质服务企业,通过智能AI数据算法分析,构建全面的信息资源库.为资质服务企业提供建筑资质,建筑资质查询,建筑资质服务,建筑企业查询,施工资质查询,全国建筑资质,资质分立,建筑企业资质查询,资质代办,资质转让,资质剥离,资质办理等服务`
        }
      ]
    } else {
      meta = [
        {
          vmid: 'keywords',
          name: 'keywords',
          content: this.dynamicDetail[1].keywords
            ? `${this.dynamicDetail[1].keywords.join(',')}`
            : ''
        },
        {
          vmid: 'description',
          name: 'description',
          content: this.dynamicDetail[1].description
            ? `${this.dynamicDetail[1].description}`
            : ''
        }
      ]
    }
    return {
      title,
      meta
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-input {
  width: 328px;
  text-indent: 10px;
  height: 32px;
  border: 1px solid #e6e9f0;
  border-radius: 2px;
}

::v-deep .ant-form-item {
  margin-bottom: 16px;
}

.ant-input-search /deep/ .ant-input {
  width: 194px;
  text-indent: 10px;
  height: 32px;
  border: 1px solid #e6e9f0;
  border-radius: 2px;
}

.ant-input-group-addon .ant-input-search-button {
  width: 118px;
  height: 32px;
  background: #00ab94;
  border-radius: 2px;
  color: #ffffff;
  font-size: 14px;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.detail {
  background-color: #f5f5f5;
  width: 100%;

  .box {
    width: 1200px;
    margin: 0 auto;

    .breadcrumb {
      margin: 30px 0;

      .item {
        width: 220px;
        margin: 20px 0;
      }
    }

    .main {
      padding-top: 32px;
      display: flex;
      justify-content: space-between;

      .particulars {
        width: 784px;
        height: 100%;
        padding: 48px 32px 24px 32px;
        background-color: #ffffff;

        .dynamicMain {
          .context /deep/ img {
            max-width: 730px !important;
          }

          .particularsTitle {
            width: 100%;
            text-align: center;
            //display: flex;
            //justify-content: space-between;

            .time {
              font-size: 15px;
              line-height: 50px;
              font-weight: 400;
              color: #1f1f1f;
              opacity: 0.49;
            }

            .h3 {
              font-size: 20px;
              font-weight: 500;
              color: #181818;
              line-height: 28px;
            }
          }

          .context {
            margin-top: 61px;
          }
        }

        .caseMain {
          font-size: 16px;
          font-weight: 400;
          color: #585858;
          line-height: 24px;

          .title {
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: #181818;
            line-height: 28px;
          }

          .describe {
            font-size: 16px;
            font-weight: 400;
            color: #585858;
            line-height: 24px;
          }

          .effectTitle {
            margin-top: 24px;
            margin-bottom: 16px;
            font-size: 20px;
            font-weight: 500;
            color: #181818;
            line-height: 28px;
          }

          .worthContentTitle {
            margin-top: 12px;
            margin-bottom: 8px;
            font-size: 16px;
            font-weight: 500;
            color: #181818;
            line-height: 24px;
          }
        }

        .previous {
          border-top: 1px solid #e6e9f0;
          padding-top: 24px;
          margin-top: 32px;
          position: relative;
          height: 86px;

          .place {
            position: absolute;
            right: 0;
          }

          .nextPage {
            margin-top: 15px;
          }

          p {
            cursor: pointer;
            display: flex;

            .text {
              font-size: 16px;
              font-weight: 500;
              color: #00ab94;
              line-height: 22px;
            }

            a {
              margin-left: 12px;
              font-size: 16px;
              font-weight: 400;
              color: #585858;
              line-height: 24px;
              display: flex;

              span:nth-child(2) {
                display: inline-block;
                max-width: 400px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .rightModular {
        width: 392px;

        .reg {
          box-sizing: border-box;
          border-radius: 5px;
          margin-bottom: 24px;
          width: 100%;
          background-color: #ffffff;
          padding: 24px 32px;
          text-align: center;

          .form-title {
            .title {
              font-size: 20px;
              font-weight: 500;
              color: #00ab94;
              line-height: 28px;
              display: flex;
              justify-content: center;
              align-items: center;

              .text {
                margin: 0 12px;
              }

              .line {
                display: inline-block;
                width: 54px;
                height: 1px;
                background: #00ab94;
              }
            }

            .tips {
              font-size: 16px;
              font-weight: 400;
              color: #ff7f28;
              line-height: 22px;
              margin-top: 8px;
            }
          }

          .form {
            margin-top: 24px;
            width: 100%;
          }

          .main-btns {
            width: 100%;
            background: #00ab94;
            border-color: #00ab94;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
            border-radius: 2px;
          }
        }

        .detailRegImg {
          cursor: pointer;
          margin-bottom: 24px;
          width: 100%;
          box-shadow: 0 0 21px 6px rgba(207, 207, 207, 0.18);

          img {
            width: 100%;
          }
        }

        .information {
          width: 100%;
          margin-bottom: 24px;
          background: #fff;
          border-radius: 4px;

          .query {
            height: 188px;
            width: 100%;
            border-radius: 4px 4px 0 0;
            display: flex;
            justify-content: space-between;

            .left {
              margin-top: 50px;
              margin-left: 35px;
              text-align: left;

              .top {
                font-size: 16px;
                font-weight: 400;
                color: #ffffff;
                line-height: 29px;
                opacity: 0.91;
              }

              .text {
                font-size: 19px;
                font-weight: 400;
                color: #ffffff;
                line-height: 29px;
                opacity: 0.91;
              }

              .left-btn {
                border: none;
                border-radius: 25px;
                width: 98px;
                margin-top: 15px;
                height: 30px;
                line-height: 30px;
                color: #805a12;
                background-color: #ffe138;
              }
            }
          }

          .informationMain {
            padding: 16px 30px;

            .text {
              margin-top: 24px;
              padding-bottom: 24px;
              border-bottom: 1px solid #e6e9f0;

              .InfoTitle {
                width: 220px;
                padding-left: 8px;
                border-left: 4px solid #00ab94;
                font-size: 24px;
                font-weight: bold;
                color: #00ab94;
                line-height: 29px;
              }
            }

            li {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 16px;
              font-weight: 400;
              color: #181818;
              text-align: left;
              line-height: 54px;
              border-bottom: 1px solid #e6e9f0;

              &:hover {
                color: #00ab94;
              }
            }

            li:last-child {
              border-bottom: none;
            }
          }
        }

        .special {
          width: 100%;
          border-radius: 4px;
          background-color: #fff;
          margin-bottom: 20px;
          padding: 24px;

          .InfoTitle {
            width: 120px;
            padding-left: 8px;
            border-left: 4px solid #00ab94;
            font-size: 24px;
            font-weight: bold;
            color: #00ab94;
            line-height: 29px;
          }

          ul {
            margin-top: 30px;
            margin-bottom: 56px;
          }

          li {
            border-top: 1px solid #e6e9f0;
            padding-top: 16px;

            img {
              width: 342px;
              height: 158px;
              background-color: #dddddd;
              border-radius: 4px;
            }

            p {
              margin-top: 12px;
              margin-bottom: 16px;
              font-size: 16px;
              font-weight: 400;
              color: #181818;
              line-height: 28px;
              width: 328px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .relevant {
      margin: 40px 0;
      width: 100%;

      .title {
        width: 144px;
        height: 36px;
        font-size: 36px;
        font-weight: bold;
        color: #00ab94;
        line-height: 28px;
      }

      .relevantItem {
        display: flex;
        //justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        flex-wrap: wrap;

        li {
          width: 32%;
          margin-right: 16px;
          background-color: #ffffff;
          margin-bottom: 20px;

          img {
            width: 378px;
            height: 300px;
          }

          p {
            padding: 30px 86px 30px 71px;
            font-size: 20px;
            font-weight: 400;
            color: #373737;
            line-height: 30px;
          }
        }
      }
    }
  }

  .bg {
    height: 240px;

    .text {
      padding-top: 70px;
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      position: relative;

      p:first-child {
        font-size: 40px;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
      }

      p:last-child {
        font-size: 20px;
        font-weight: 400;
        color: #00ab94;
        line-height: 28px;

        .line {
          display: inline-block;
          width: 56px;
          height: 1px;
          background: #00ab94;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
