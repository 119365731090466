<template>
  <div class="product-list">
    <div @click="goLeft" class="arrow lf" :class="isHaveLeft ? 'isHave' : ''">
      <a-icon type="left" />
    </div>
    <div class="item-list" ref="exList">
      <div
        class="item"
        @click="goToXiaoNiu(item.link)"
        v-for="(item, key) in list"
        :key="key"
      >
        <img :src="hotTag" width="34" class="hot-tag" v-if="item.isHot" />
        <img :src="item.src" width="40" class="item-img" />
        <span>{{ item.title }}</span>
      </div>
    </div>
    <div @click="goRight" class="arrow rt" :class="isHaveRight ? 'isHave' : ''">
      <a-icon type="right" />
    </div>
  </div>
</template>

<script>
import business from '@/assets/home/business.png'
import manage from '@/assets/home/manage.png'
import notice from '@/assets/home/notice.png'
import rencai from '@/assets/home/rencai.png'
import guquan from '@/assets/home/guquan.png'
import qualifications from '@/assets/home/qualifications.png'
import service from '@/assets/home/service.png'
import hotTag from '@/assets/home/hot_tag.png'
import tel from '@/assets/home/tel.png'
export default {
  name: 'product-list',
  computed: {
    projectEnv() {
      if (process.env.VUE_APP_TYPE) {
        return process.env.VUE_APP_TYPE
      } else {
        return 'online'
      }
    }
  },
  data() {
    return {
      hotTag,
      isHaveLeft: false,
      isHaveRight: false,
      list: [
        { title: '查电话', src: tel, isHot: true, link: 'receipt' },
        { title: '查资质', src: qualifications, isHot: true, link: 'receipt' },
        { title: '查企业', src: business, link: 'receipt' },
        { title: '资质服务', src: service, link: 'qr' },
        { title: '小牛人才', src: rencai, link: 'rencai' },
        { title: '小牛股权', src: guquan, link: 'guquan' },
        { title: '客户管理', src: manage, link: 'myclient' },
        { title: '知识库', src: notice, link: 'knowledgeWarehouse' }
      ]
    }
  },
  methods: {
    goLeft() {
      if (!this.isHaveLeft) {
        return
      }
      const dom = this.$refs['exList']
      dom.scrollLeft -= 182
      this.isHaveRight = true
      if (dom.scrollLeft === 0) {
        this.isHaveLeft = false
      }
    },
    goRight() {
      if (!this.isHaveRight) {
        return
      }
      const dom = this.$refs['exList']
      dom.scrollLeft += 180
      const currentScroll = dom.scrollLeft
      dom.scrollLeft += 1
      this.isHaveLeft = true
      if (dom.scrollLeft === currentScroll) {
        this.isHaveRight = false
      }
    },
    goToXiaoNiu(link) {
      if (link === 'receipt') {
        // 收货
        if (this.projectEnv === 'dev') {
          window.open('http://localhost:8080/#/longna/receipt')
        } else if (
          this.projectEnv === 'online' ||
          this.projectEnv === 'preview'
        ) {
          // 预发  正式环境
          window.open('http://www.xnjx666.com:9000/#/longna/receipt')
        } else if (this.projectEnv === 'test') {
          // 测试环境
          window.open('http://192.168.0.108:8099/#/longna/receipt')
        }
      } else if (link === 'qr') {
        // 资质集市
        if (this.projectEnv === 'dev') {
          window.open(
            'http://localhost:8080/#/longna/qualificationResources/main'
          )
        } else if (
          this.projectEnv === 'online' ||
          this.projectEnv === 'preview'
        ) {
          // 预发  正式环境
          window.open(
            'http://www.xnjx666.com:9000/#/longna/qualificationResources/main'
          )
        } else if (this.projectEnv === 'test') {
          // 测试环境
          window.open(
            'http://192.168.0.108:8099/#/longna/qualificationResources/main'
          )
        }
      } else if (link === 'knowledgeWarehouse') {
        // 知识库
        if (this.projectEnv === 'dev') {
          window.open(
            'http://localhost:8080/#/longna/knowledgeWarehouse/knowledgeWarehouseHomePage'
          )
        } else if (
          this.projectEnv === 'online' ||
          this.projectEnv === 'preview'
        ) {
          // 预发  正式环境
          window.open(
            'http://www.xnjx666.com:9000/#/longna/knowledgeWarehouse/knowledgeWarehouseHomePage'
          )
        } else if (this.projectEnv === 'test') {
          // 测试环境
          window.open(
            'http://192.168.0.108:8099/#/longna/knowledgeWarehouse/knowledgeWarehouseHomePage'
          )
        }
      } else if (link === 'myclient') {
        // 我的客户
        if (this.projectEnv === 'dev') {
          window.open('http://localhost:8080/#/longna/client/processing')
        } else if (
          this.projectEnv === 'online' ||
          this.projectEnv === 'preview'
        ) {
          // 预发  正式环境
          window.open('http://www.xnjx666.com:9000/#/longna/client/processing')
        } else if (this.projectEnv === 'test') {
          // 测试环境
          window.open('http://192.168.0.108:8099/#/longna/client/processing')
        }
      } else if (link === 'rencai') {
        // 小牛人才
        if (this.projectEnv === 'dev') {
          window.open('http://localhost:8080/#/talents/gaps')
        } else if (
          this.projectEnv === 'online' ||
          this.projectEnv === 'preview'
        ) {
          // 预发  正式环境
          window.open('http://www.xnjx666.com:9000/#/talents/gaps')
        } else if (this.projectEnv === 'test') {
          // 测试环境
          window.open('http://192.168.0.108:8099/#/talents/gaps')
        }
      } else if (link === 'guquan') {
        // 小牛股权
        if (this.projectEnv === 'dev') {
          window.open('http://localhost:8080/#/shareholder/sell')
        } else if (
          this.projectEnv === 'online' ||
          this.projectEnv === 'preview'
        ) {
          // 预发  正式环境
          window.open('http://www.xnjx666.com:9000/#/shareholder/sell')
        } else if (this.projectEnv === 'test') {
          // 测试环境
          window.open('http://192.168.0.108:8099/#/shareholder/sell')
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const dom = this.$refs['exList']
      dom.scrollLeft += 1
      if (dom.scrollLeft > 0) {
        this.isHaveRight = true
      }
      //   console.log(dom.scrollLeft)
      //   console.log(dom.offsetWidth)
    })
  }
}
</script>

<style lang="scss">
.product-list {
  width: 1200px;
  user-select: none;
  //   overflow-x: auto;
  margin-bottom: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .item-list {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 1056px;
    overflow: hidden;
    margin: 0 12px;
    z-index: 9;
    justify-content: space-between;
  }
  .item {
    width: 154px;
    height: 70px;
    flex-shrink: 0;
    cursor: pointer;
    background-color: #fff;
    text-align: center;
    line-height: 70px;
    color: #181818;
    margin: 0 12px;
    border: 0.5px solid #e6e9f0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    .hot-tag {
      position: absolute;
      right: 0;
      top: 1px;
    }
    &:hover {
      box-shadow: 0px 0px 12px 0px rgba(0, 178, 157, 0.2);
      border-color: #00ab94;
    }
    .item-img {
      margin-right: 12px;
    }
  }
  .arrow {
    color: #585858;
    cursor: pointer;
    height: 70px;
    width: 48px;
    line-height: 70px;
    flex-shrink: 0;
    background: #fff;
    border-radius: 4px;
    z-index: 10;
    border: 0.5px solid #e6e9f0;
    &:hover {
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
      border-color: #00ab94;
    }
  }
  .lf {
    margin-right: 12px;
  }
  .rt {
    margin-left: 12px;
  }
  .isHave {
    color: #00ab94;
  }
}
</style>
