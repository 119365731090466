<template>
    <div class="business-info">
        <!-- <div class="info-item">
      <div class="sub-title">公司简介</div>
      <p class="profile content">
        {{ businessInfo.companyProfile || '暂无简介' }}
      </p>
    </div> -->
        <div class="info-item">
            <div class="sub-title">股东信息({{ shareholderTd.length }})</div>
            <div class="content">
                <customer-table
                    blockText="股东信息"
                    :th="shareholderTh"
                    :td="shareholderTd"
                ></customer-table>
            </div>
        </div>

        <div class="info-item">
            <div class="sub-title">施工资质企业对外投资({{ investmentTd.length }})</div>
            <div class="content">
                <customer-table
                    blockText="对外投资"
                    :th="investmentTh"
                    :td="investmentTd"
                ></customer-table>
            </div>
            <div class="tips">
                施工企业对外投资：指有资质的施工企业的对外投资，被投资企业也有施工资质。
            </div>
        </div>
        <div class="info-item">
            <div class="sub-title">工商信息</div>
            <table class="content content-table" border>
                <tr v-for="(tableData, index) in bussinessTable" :key="index">
                    <td
                        :colspan="td.colspan || 1"
                        :class="{ isTitle: !!!(tdKey % 2), isValue: tdKey % 2 }"
                        v-for="(td, tdKey) in tableData.td"
                        :key="tdKey"
                    >
                        {{ td.title }}
                    </td>
                </tr>
            </table>
        </div>
        <div class="info-item">
            <div class="sub-title">主要人员({{ mainPersonTd.length }})</div>
            <div class="content">
                <customer-table
                    blockText="主要人员"
                    :th="mainPersonTh"
                    :td="mainPersonTd"
                ></customer-table>
            </div>
        </div>

        <div class="info-item">
            <div class="sub-title">分支机构({{ branchList.length }})</div>
            <table class="content content-table branch-table" border v-if="branchList.length">
                <tr v-for="(item, index) in branchList" :key="index">
                    <td class="bg-td">{{ index + 1 }}</td>
                    <td>{{ item.branchName }}</td>
                </tr>
            </table>

            <div class="content block-table" v-else>暂无分支机构数据</div>
        </div>
        <div class="info-item">
            <div class="sub-title">工商变更({{ manageChangeTd.length }})</div>
            <div class="content">
                <customer-table
                    blockText="工商变更"
                    :th="manageChangeTh"
                    :td="manageChangeTd"
                ></customer-table>
            </div>
        </div>
        <div class="tips">
            数据来源：该数据源于国家企业信用信息公示系统
        </div>
    </div>
</template>
<script>
import CustomerTable from '@/components/CustomerTable.vue'

export default {
    name: 'detail-business',
    components: {
        CustomerTable
    },
    props: {
        enterpriseId: {},
        businessInfo: {}
    },
    data() {
        return {
            bussinessTable: [{ td: [] }],
            shareholderTh: [
                {
                    title: '股东',
                    key: 'shareholderName'
                },
                {
                    title: '股东类型',
                    key: 'shareholderType'
                },
                // {
                //   title: '持股比例',
                //   key: 'shareholdingRatio',
                // },
                {
                    title: '认缴金额',
                    key: 'subscribedAmount'
                },
                {
                    title: '认缴出资日期',
                    key: 'contributionDate'
                }
            ],
            shareholderTd: [],
            mainPersonTh: [
                {
                    title: '序号',
                    key: 'index'
                },
                {
                    title: '姓名',
                    key: 'name'
                },
                {
                    title: '职位',
                    key: 'position'
                }
            ],
            mainPersonTd: [],
            investmentTh: [
                { title: '被投资企业名', key: 'investmentEnterpriseName' },
                { title: '法定代表人', key: 'legalPerson' },
                { title: '注册资本', key: 'capital' },
                // { title: '出资比例', key: 'proportion' },
                { title: '成立时间', key: 'establishmentDate' },
                { title: '经营状态', key: 'businessStatus' }
            ],
            investmentTd: [],
            branchList: [],
            manageChangeTh: [
                {
                    title: '序号',
                    key: 'index',
                    width: '40px'
                },
                {
                    title: '变更日期',
                    key: 'changeDate',
                    width: '100px'
                },
                {
                    title: '变更事项',
                    key: 'changeItem',
                    width: '120px'
                },
                {
                    title: '变更前',
                    key: 'changePre'
                },
                {
                    title: '变更后',
                    key: 'changeAfter'
                }
            ],
            manageChangeTd: []
        }
    },
    methods: {
        getEnterpriseDetail() {
            // console.log('11', this.enterpriseId)
        },
        getManageChangeList() {
            const params = {
                currentPage: 1,
                pageSize: 200,
                enterpriseId: this.enterpriseId
            }
            this.$http.get('business/baseInfo/selManageChangeByEnterpriseId', params).then(r => {
                this.manageChangeTd = r.data || []
            })
        },
        getBranchOriganizationList() {
            const params = {
                enterpriseId: this.enterpriseId
            }
            this.$http.get('business/baseInfo/selBranchOrganizeByEnterpriseId', params).then(r => {
                this.branchList = r || []
                // this.branchList = [{ branchName: 'お疲れ様です。' }, { branchName: 'また明日' }]
            })
        },
        getInvestment() {
            const params = {
                currentPage: 1,
                pageSize: 200,
                enterpriseId: this.enterpriseId
            }
            this.$http
                .get('business/baseInfo/selForeignInvestmentByEnterpriseId', params)
                .then(r => {
                    this.investmentTd = r.data || []
                })
        },
        getMainPersonList() {
            const params = {
                currentPage: 1,
                pageSize: 200,
                enterpriseId: this.enterpriseId
            }
            this.$http.get('business/baseInfo/selMainPersonByEnterpriseId', params).then(r => {
                this.mainPersonTd = r.data || []
            })
        },
        getShareholderList() {
            const params = {
                currentPage: 1,
                pageSize: 200,
                enterpriseId: this.enterpriseId
            }
            this.$http.get('business/baseInfo/selShareholderByEnterpriseId', params).then(r => {
                this.shareholderTd = r.data || []
                // this.shareholderTd = [{ shareholderName: 'zege', shareholdingRatio: '100%', subscribedAmount: '100000w', contributionDate: '2019-03-04' }]
            })
        },
        setBusiness() {
            // const params = {
            //   id: this.enterpriseId,
            // }
            // this.$http
            //   .get('business/baseInfo/selBusinessInfoById', params)
            //   .then((r  ) => {
            const r = this.businessInfo
            this.bussinessTable = [
                {
                    td: [
                        { title: '统一社会信用代码' },
                        { title: r.socialCreditCode || '暂无数据' },
                        { title: '经营状态' },
                        { title: r.businessStatus || '暂无数据' }
                        // { title: '注册号' }, { title: r.registrationNo || '暂无数据' }
                    ]
                },
                // {
                //     td: [
                //         // { title: '组织机构代码' }, { title: r.organizationCode || '暂无数据' },
                //         { title: '经营状态' }, { title: r.businessStatus || '暂无数据' }]
                // },
                {
                    td: [
                        { title: '法定代表人' },
                        { title: r.legalPerson || '暂无数据' },
                        { title: '注册资本' },
                        { title: r.registeredCapital || '暂无数据' }
                    ]
                },
                {
                    td: [
                        { title: '公司类型' },
                        { title: r.companyType || '暂无数据' },
                        { title: '成立日期' },
                        { title: r.establishmentDate || '暂无数据' }
                    ]
                },
                {
                    td: [
                        { title: '营业期限' },
                        { title: r.businessTerm || '--' },
                        { title: '登记机关' },
                        { title: r.registrationAuthority || '暂无数据' }
                    ]
                },
                {
                    td: [
                        { title: '核准日期' },
                        { title: r.approvalDate || '暂无数据' },
                        { title: '公司规模' },
                        { title: r.companySize || '暂无数据' }
                    ]
                },
                {
                    td: [{ title: '所属行业' }, { title: r.industry || '暂无数据', colspan: 3 }]
                },
                {
                    td: [
                        { title: '企业地址' },
                        { title: r.businessAddress || '暂无数据', colspan: 3 }
                    ]
                },
                {
                    td: [
                        { title: '经营范围' },
                        { title: r.businessScope || '暂无数据', colspan: 3 }
                    ]
                }
            ]
            //  })
        }
    },
    //   @Prop() enterpriseId?: ''
    //   @Prop() businessInfo?: {}

    mounted() {
        this.$nextTick(() => {
            this.getEnterpriseDetail()
            this.setBusiness() // 工商信息 公司简介
            this.getShareholderList() // 股东信息
            this.getMainPersonList() // 获取主要员工
            this.getInvestment() // 获取对外投资
            this.getBranchOriganizationList() // 获取分支机构
            this.getManageChangeList() // 工商变更
        })
    }
}
</script>

<style lang="scss" scoped>
.business-info {
    .info-item {
        margin-bottom: 50px;
        .sub-title {
            font-size: 18px;
            color: #2a7be7;
            width: 100%;
            text-align: center;
            font-weight: 600;
            height: 60px;
            line-height: 60px;
            border: #e3e3e3 solid 1px;
            border-bottom: transparent;
            background-color: rgba(#eeeeee, 0.3);
        }
        .content {
            //  margin-top: 16px;
        }
        .block-table {
            height: 36px;
            text-align: center;
            font-size: 13px;
            color: #606366;
            line-height: 36px;
            border: #e3e3e3 solid 1px;
        }
        .content-table {
            width: 100%;
            letter-spacing: 1px;
            border: #e3e3e3 solid 1px;
            line-height: 20px;
            .isTitle {
                width: 101px;
                height: 52px;
                text-align: center;
                font-size: 13px;
                color: #606366;
                background-color: rgba($color: #4d7cfe, $alpha: 0.06);
            }
            .isValue {
                width: 203px;
                text-align: left;
                padding: 5px 10px;
                box-sizing: border-box;
                font-size: 13px;
                color: #606366;
            }
        }
        .branch-table {
            tr {
                height: 36px;
            }
            .bg-td {
                text-align: center;
                background-color: rgba($color: #4d7cfe, $alpha: 0.06);
            }
        }
        .profile {
            color: #606366;
            font-size: 13px;
            line-height: 18px;
        }
    }
}
</style>
