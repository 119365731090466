<template>
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ic_close</title>
    <g
      id="小牛股权"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="出货信息-资源锁定弹窗"
        transform="translate(-914.000000, -676.000000)"
      >
        <g id="编组-2" transform="translate(496.000000, 660.000000)">
          <g id="ic_close" transform="translate(418.000000, 16.000000)">
            <rect
              id="矩形"
              fill-opacity="0"
              fill="#FFFFFF"
              fill-rule="nonzero"
              x="0"
              y="0"
              width="14"
              height="14"
            ></rect>
            <line
              x1="11.6666667"
              y1="11.6666667"
              x2="2.33333333"
              y2="2.33333333"
              id="路径-13"
              stroke="currentColor"
              stroke-width="1.5"
            ></line>
            <line
              x1="11.6666667"
              y1="2.33333333"
              x2="2.33333333"
              y2="11.6666667"
              id="路径-16"
              stroke="currentColor"
              stroke-width="1.5"
            ></line>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'close-icon',
  data() {
    return {}
  },
  mounted() {}
}
</script>
