<template>
  <div class="intermediary-page" ref="showBox">
    <div class="filter-box">
      <div class="tips">
        <p>说明:</p>
        <p v-for="(item, index) in instructions" :key="index + 'r'">
          {{ index + 1 }}.{{ item.info }}
        </p>
      </div>
      <div class="filter-list" id="intermediaryFilter">
        <div class="filter-item">
          <div class="label-title">时间范围：</div>
          <a-select
            size="default"
            :getPopupContainer="getPopupContainer"
            style="width: 306px"
            v-model="filterOptions.monthNum"
          >
            <a-select-option
              v-for="item in timeRangeList"
              :key="item.value + 'sss'"
              :value="item.value"
              >{{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="filter-item">
          <div class="label-title">地区：</div>
          <a-tree-select
            :getPopupContainer="getPopupContainer"
            placeholder="请选择地区"
            allow-clear
            id="areaTree"
            treeCheckable
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            v-model="filterOptions.codeList"
            style="width: 306px"
            aria-readonly
            :filterTreeNode="notFilter"
            allowClear
            :tree-data="codeData"
            tree-checkable
            :show-checked-strategy="SHOW_PARENT"
          />
        </div>
        <div class="filter-item">
          <div class="label-title">资质级别：</div>
          <a-select
            size="default"
            :getPopupContainer="getPopupContainer"
            style="width: 306px"
            v-model="filterOptions.category"
            @change="handleChangeQualifications"
          >
            <a-select-option
              v-for="item in qualificationsList"
              :key="item.value + 'ef'"
              :value="item.value"
              >{{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="operate-btns">
          <a-button class="btns rt" @click="getClientList" type="primary">
            查询
          </a-button>
          <a-button class="btns" @click="renewFilter">重置</a-button>
        </div>
      </div>
    </div>
    <div class="main">
      <div v-if="hideNotFind" class="table-content">
        <div class="table-left">
          <div id="myHomeChart" ref="homeEcharts"></div>
          <div class="explainText">
            <p style="color: rgba(51, 51, 51, 0.65);font-size: 13px;">
              点击柱状或者资质类别名称可以切换查看详细情况
            </p>
            <div style="font-size: 15px;margin: 30px 0 ;">
              为您找到<span style="color:#3398DB;">
                {{ qualificationNum }} </span
              >个<span style="color:#3398DB;">
                {{ qualificationDesignation }}
              </span>
            </div>
          </div>
        </div>
        <div class="table-right" id="tableRight" ref="heightBox">
          <a-table
            bordered
            :pagination="false"
            :columns="Columns"
            rowKey="index"
            :data-source="detailsListData"
          >
          </a-table>
          <div class="page" v-if="totalSize > 10">
            <a-pagination
              v-model="detailsData.pageNum"
              class="pagination"
              :defaultPageSize="detailsData.pageSize"
              :total="totalSize"
              :item-render="itemRender"
              @change="onChange"
            />
          </div>
        </div>
      </div>
      <div v-if="showNotFind">
        <img style="width: 100%;height: 100%;" :src="notFind" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { TreeSelect } from 'ant-design-vue'
import notFind from '@/assets/image/no_date_block.png'
import echarts from '@/utils/echarts.js'
// const echarts = require('echarts')

export default {
  name: 'qualification-service',
  data() {
    return {
      notFind,
      hideNotFind: false,
      showNotFind: false,
      instructions: [
        {
          info: '资质服务分析地区目前只开放江苏省，其他省份的数据正在分析研究中'
        },
        { info: '现资质服务数据不包含发证机关为住房和城乡建设部' },
        { info: '时间范围是指资质取得的时间' }
      ],
      filterOptions: { monthNum: 3, category: 1, codeList: [] },
      detailsData: { pageNum: 1, pageSize: 10, qualificationName: '' },
      qualificationCategory: '总承包二级资质',
      qualificationsList: [
        { value: 1, label: '总承包二级资质' },
        { value: 2, label: '总承包三级资质' },
        {
          value: 3,
          label: '专业承包一级资质'
        },
        { value: 4, label: '专业承包其他等级资质' }
      ],
      qualificationsListMap: new Map([
        [1, '总承包二级资质'],
        [2, '总承包三级资质'],
        [3, '专业承包一级资质'],
        [4, '专业承包其他等级资质']
      ]),
      timeRangeList: [
        { value: 3, label: '3个月以内' },
        { value: 6, label: '6个月以内' }
      ],
      codeData: [],
      codeDefault: [],
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      qualificationsColumns: [
        {
          title: '序号',
          dataIndex: 'index'
        },
        {
          dataIndex: 'qualificationName',
          slots: { title: 'customTitle' },
          scopedSlots: { customRender: 'name' }
        },
        // {  dataIndex: 'title', width: 120, scopedSlots: { customRender: 'title' }, },
        {
          title: '数量',
          dataIndex: 'num'
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operate' },
          width: 80
        }
      ],
      Columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: '50px'
        },
        {
          title: '地区',
          dataIndex: 'areaName',
          width: '60px'
        },
        {
          title: '公司名称',
          dataIndex: 'companyName',
          width: '250px'
        },
        {
          title: '法定代表人',
          dataIndex: 'legalPerson',
          width: '90px'
        },
        {
          title: '资质类别及等级',
          dataIndex: 'qualification',
          width: '250px'
        },
        {
          title: '取得时间',
          dataIndex: 'approvalTime',
          width: '100px'
        }
      ],
      regionCode: [],
      qualificationsData: [],
      detailsListData: [],
      prevDisabled: true,
      nextDisabled: false,
      show: false,
      totalSize: 0,
      qualificationNum: '',
      qualificationDesignation: '',
      isClickFindBtn: false,
      myChart: null,
      options: {
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'line'
            // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          width: 'auto',
          height: 'auto',
          left: 50,
          right: 50,
          bottom: 30,
          top: 30,
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            axisTick: {
              alignWithLabel: true
            },
            axisLabel: {
              interval: 0,
              rotate: 40
            },
            triggerEvent: true
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              interval: 0
            },
            min: 0,
            scale: true /*按比例显示*/,
            triggerEvent: true
          }
        ],
        series: [
          {
            name: '资质总数',
            type: 'bar',
            barWidth: '25%',
            data: [],
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  position: 'top',
                  textStyle: {
                    color: '#000000'
                  }
                }
              }
            }
          }
        ]
      }
    }
  },
  methods: {
    itemRender(current, type, originalElement) {
      if (type === 'prev') {
        originalElement.children = undefined
        originalElement.text = ' 上一页 '
        // originalElement.data.class = originalElement.data.class + " red"
        return originalElement
      } else if (type === 'next') {
        originalElement.children = undefined
        originalElement.text = ' 下一页 '
        // originalElement.data.class = originalElement.data.class + " red"
        return originalElement
      }
      return originalElement
    },

    onChange(pageNum) {
      const current = this.$refs['heightBox']
      if (current != null) {
        current.scrollIntoView()
      }
      const params = {
        category: this.filterOptions.category,
        codeList: this.regionCode,
        monthNum: this.filterOptions.monthNum,
        pageNum: pageNum,
        pageSize: this.detailsData.pageSize,
        qualificationName: localStorage.getItem('qualificationName')
      }
      this.detailsList(params)
    },

    listDataChange() {
      if (this.filterOptions.codeList[0] === this.codeDefault[0]) {
        const region = []
        this.codeData.forEach(item => {
          region.push(item.value)
          item.children.forEach(d => {
            region.push(d.value)
          })
        })
        this.regionCode = region
      } else {
        this.regionCode = this.filterOptions.codeList
      }
      const params = {
        monthNum: this.filterOptions.monthNum,
        category: this.filterOptions.category,
        codeList: this.regionCode
      }
      this.$http
        .post('intermediary/pool/query/statistics', params)
        .then(res => {
          if (JSON.stringify(res) === '{}') {
            this.showNotFind = true
            this.hideNotFind = false
            this.qualificationsData = []
            this.detailsListData = []
            return
          } else {
            this.showNotFind = false
            this.hideNotFind = true
            this.options.xAxis[0].data = res.xAxisData
            this.options.series[0].data = res.seriesData
            this.viewDetails(res.xAxisData[0])
            localStorage.setItem('qualificationName', res.xAxisData[0])
            this.qualificationNum = res.seriesData[0]
            this.qualificationDesignation = res.xAxisData[0]
          }
        })
    },

    notFilter() {
      return false
    },

    detailsList(params) {
      this.$http.post('intermediary/pool/query/details', params).then(res => {
        const list = res.records
        list.forEach((item, index) => {
          item.index = index + 1
          item.legalPerson =
            item.legalPerson === null ? '---' : item.legalPerson
          item.approvalTime = this.$day(item.approvalTime).format('YYYY-MM-DD')
        })
        this.detailsListData = list
        this.qualificationNum = res.total
        const length = res.total
        this.totalSize = res.total
        if (length > 10) {
          this.show = true
          const pageNum = res.current
          const totalNum = res.pages
          this.detailsData.pageNum = pageNum
          if (pageNum === 1) {
            this.prevDisabled = true
            this.nextDisabled = false
          } else if (pageNum === totalNum) {
            this.prevDisabled = false
            this.nextDisabled = true
          } else {
            this.prevDisabled = false
            this.nextDisabled = false
          }
        } else {
          this.show = false
        }
        this.isClickFindBtn = false
      })
    },

    handleChangeQualifications(val) {
      this.qualificationCategory = this.qualificationsListMap.get(val)
    },

    getClientList() {
      this.listDataChange()
    },

    renewFilter() {
      this.filterOptions.monthNum = 3
      this.filterOptions.category = 1
      this.filterOptions.codeList = this.codeDefault
    },

    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('intermediaryFilter')
    },

    viewDetails(item) {
      // const current = this.$refs['heightBox'] as any
      // if (current != null && !this.isClickFindBtn) {
      //     current.scrollIntoView()
      // }
      this.detailsData.pageNum = 1
      this.detailsData.pageSize = 10
      const params = {
        category: this.filterOptions.category,
        codeList: this.regionCode,
        monthNum: this.filterOptions.monthNum,
        pageNum: this.detailsData.pageNum,
        pageSize: this.detailsData.pageSize,
        qualificationName: item
      }

      this.detailsList(params)
    },

    code() {
      this.$http.get('regionCode/query', {}).then(res => {
        res.forEach(item => {
          if (item.level === 1) {
            this.filterOptions.codeList.push(item.areaCode)
            this.codeDefault = this.filterOptions.codeList
            const obj = {
              title: item.areaName,
              value: item.areaCode,
              key: item.areaCode,
              children: []
            }
            this.codeData.push(obj)
            this.$nextTick(() => {
              this.listDataChange()
            })
          } else {
            const arr = {
              title: item.areaName,
              value: item.areaCode,
              key: item.areaCode
            }
            this.codeData.forEach(d => {
              d.children.push(arr)
            })
          }
        })
      })
    },
    drawLine() {
      //基于准本好的DOM，初始化echarts实例
      this.myChart = echarts.init(document.getElementById('myHomeChart'))
      this.myChart.setOption(this.options)
      const _that = this
      this.myChart.on('click', function(params) {
        if (params.componentType == 'xAxis') {
          // console.log("单击了"+params.value+"x轴标签");
          _that.viewDetails(params.value)
          localStorage.setItem('qualificationName', params.value)
          _that.qualificationDesignation = params.value
        } else {
          _that.viewDetails(params.name)
          localStorage.setItem('qualificationName', params.name)
          _that.qualificationNum = params.value
          _that.qualificationDesignation = params.name
        }
      })
    }
  },

  // @Watch('options', {immediate: true, deep: true})
  // handler(newVal , oldVal ) {
  //     if (this.myChart) {
  //         if (newVal) {
  //             this.myChart.setOption(newVal)
  //         } else {
  //             this.myChart.setOption(oldVal)
  //         }
  //     }
  // }

  // @Watch('hideNotFind', {deep: true})
  // hideHandler(newVal ) {
  //     if (newVal) {
  //         this.$nextTick(() => {
  //             this.drawLine()
  //         })
  //     }
  // }

  mounted() {
    this.code()
    this.$nextTick(() => {
      const dom = document.getElementById('areaTree')
      const areaTreeInput = dom.getElementsByTagName('input')[0]
      const areaTreeFather = areaTreeInput.parentElement
      areaTreeFather.removeChild(areaTreeInput)
    })
  },
  destroyed() {
    localStorage.removeItem('qualificationName')
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.myChart.setOption(newVal)
          } else {
            this.myChart.setOption(oldVal)
          }
        }
      }
    },
    hideNotFind: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.drawLine()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn {
  height: 50px;
  float: right;
  margin-top: 15px;
}
.intermediary-page {
  width: 1200px;
  position: relative;

  .filter-box {
    background-color: #ffffff;
    width: 100%;
    border-radius: 0 0 4px 4px;
    padding: 16px 25px;

    .tips {
      padding: 15px;
      font-size: 13px;
      background-color: #fbfbfb;
      margin-bottom: 15px !important;
      p {
        line-height: 28px;
        margin: 0;
        color: rgba(51, 51, 51, 0.65);
      }
    }

    .filter-title {
      font-size: 16px;
      color: #909399;
    }

    .filter-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .filter-item {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        flex-grow: 0;
        margin-bottom: 16px;

        .label-title {
          text-align: left;
          font-size: 14px;
          color: #666666;
        }

        .filter-input {
          width: 306px;
        }

        .filter-btn {
          width: 86px;
          height: 36px;
          line-height: 36px;
        }

        .isSelect {
          background-color: rgba($color: #3b68e7, $alpha: 0.1);
          color: #3b68e7;
          border-color: #3b68e7;
        }

        .have-margin {
          margin-right: 24px;
        }
      }
    }

    .operate-btns {
      margin-top: 24px;
      margin-bottom: 16px;

      .btns {
        height: 40px;
        width: 126px;
        font-size: 16px;
        line-height: 40px;
      }

      .rt {
        margin-right: 24px;
      }
    }
  }

  .main {
    width: 100%;
    background-color: #ffffff;
    margin-top: 24px;
    padding: 0 24px 60px 24px;
    .table-content {
      //width: 100%;
      //background-color: #ffffff;
      //padding: 24px 24px 60px 24px;
      //display: flex;
      //justify-content: space-between;
      border-radius: 4px;

      .table-left {
        width: 35%;
        #myHomeChart {
          width: 1100px;
          height: 600px;
        }
        .explainText {
          margin-left: 50px;
          width: 100%;
        }
      }
      .table-right {
        width: calc(100% - 100px);
        margin-left: 50px;
        margin-right: 50px;
        .page {
          margin-top: 25px;
          height: 100px;

          .pagination {
            float: right;
          }
        }
      }

      .rt {
        margin-right: 24px;
      }
    }
  }

  .select-status {
    width: 100%;
  }
}
</style>
