import { render, staticRenderFns } from "./wxLogin.vue?vue&type=template&id=751a6d46&scoped=true"
import script from "./wxLogin.vue?vue&type=script&lang=js"
export * from "./wxLogin.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./wxLogin.vue?vue&type=style&index=0&id=751a6d46&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "751a6d46",
  "0fa053a4"
  
)

export default component.exports