<template>
  <div class="download-page">
    <div
        style="background-image:url('http://cdn.public.xnjx666.com//upload/gw/static/img/download_bg.png')"
        class="topBanner">
      <div class="container">
        <!--            logo-->
        <!--                <img :src="topLogo" alt="" style="width: 350px;" />-->
        <div class="downloadButton">
          <!--                        标语-->
          <div class="sloganBox">
            <p>数据创造价值</p>
            <p>让资质服务更简单</p>
            <p>MAKE QUALIFICATION SERVICE EASIER</p>
            <div class="slogan">
              <span>精准</span>
              <div class="slogan-line"></div>
              <span>全面</span>
              <div class="slogan-line"></div>
              <span>实时</span>
            </div>
          </div>

          <!--                        二维码-->
          <div class="qrCode">
            <div class="topQrCode">
              <div class="Qrcode">
                <img :src="QrCode" alt="小牛建讯"/>
                <img :src="icApplet" alt="小牛建讯"/>
              </div>
              <div class="text">
                <p>联系您的服务管家 : 13438870357</p>
              </div>

            </div>
          </div>

          <div class="down-icon">
            <div class="downLoadLink">
              <a id="win7first" :href="win7Url" class="down">
                <img :src="win" alt=""/><span class="linkText">Win7下载</span>
              </a>
              <a
                  id="win10first"
                  :href="win10Url"
                  class="down"
                  style="margin-left: 20px;"
              >
                <img :src="win" alt=""/><span class="linkText">Win10下载</span>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="download">
      <!--        介绍-->
      <div class="introduceText">
        <p>专注赋能资质服务企业</p>
        <p>
          通过智能AI数据算法分析，为资质服务企业提供精准的数据结果；帮助企业解决拓客、资源共享问题，提升业务人员工作效能
        </p>
      </div>
      <!--        关键词-->
      <div class="keyword">
        <div class="keywordText">
          <ul>
            <li >
              <img :src="one" alt="小牛建讯"/>
              <p>客户精准</p>
            </li>
            <li>
              <img :src="two" alt="小牛建讯"/>
              <p>海量资源</p>
            </li>
            <li>
              <img :src="three" alt="小牛建讯"/>
              <p>数据实时</p>
            </li>
            <li >
              <img :src="four" alt="小牛建讯"/>
              <p>资源共享</p>
            </li>
          </ul>
        </div>
        <!--        <div class="keywordLine" :style="'background-image:url(' + line + ')'"></div>-->
      </div>
      <div class="product">
        <img src="http://cdn.public.xnjx666.com//upload/gw/static/img/productOne.png" alt="小牛建讯"/>
        <div class="text">
          <p class="index">
            <span class="num">01</span><span class="indexText">收货查询</span>
          </p>
          <p class="textInfo" style="margin-top: 30px;">
            基于建筑资质行业属性，自定义多维度筛选，即可全方位多角度查企业信息（企业资质、联系电话、工商信息、风险信息），从而极大的节省销售时间提升获客效率
          </p>
        </div>
      </div>
      <div class="product">
        <div class="text">
          <p class="index">
            <span class="num">02</span><span class="indexText">出货查询</span>
          </p>
          <p class="textInfo">
            设定查询条件,一键甄选出全网所有符合条件的企业,基于AI算法智能精准营销
          </p>
        </div>
        <img src="http://cdn.public.xnjx666.com//upload/gw/static/img/productTwo.png" alt="小牛建讯"/>
      </div>
      <div class="product">
        <img src="http://cdn.public.xnjx666.com//upload/gw/static/img/productThree.png" alt="小牛建讯"/>
        <div class="text">
          <p class="index">
            <span class="num">03</span><span class="indexText">资质服务</span>
          </p>
          <p class="textInfo">
            专属资质服务社区,整合全品类资质服务，资源共享，加速企业经营效率
          </p>
        </div>
      </div>
      <div class="product lastProduct">
        <div class="text">
          <p class="index">
            <span class="num">04</span><span class="indexText">客户管理</span>
          </p>
          <p class="textInfo">
            一站式客户跟踪管理系统,资源和业务人员线上管理,有意向的客户进行实时跟进，简单清楚记录业务进程,客户转化更高效
          </p>
        </div>
        <img src="http://cdn.public.xnjx666.com//upload/gw/static/img/productFive.png" alt="小牛建讯"/>
      </div>

    </div>
  </div>
</template>

<script>
import topLogo from '@/assets/download/topLogo.png'
import win from '@/assets/download/ic_windows.png'
import topQrCode from '@/assets/download/topQrCode.png'
import QrCode from '@/assets/contactUs/service.png'
import one from '@/assets/download/one.png'
import two from '@/assets/download/two.png'
import three from '@/assets/download/three.png'
import four from '@/assets/download/four.png'
import line from '@/assets/download/line.png'
import query from '@/assets/download/query.png'
import transfer from '@/assets/download/transfer.png'
import resource from '@/assets/download/resource.png'
import icApplet from '@/assets/download/pc_applet.png'

export default {
  name: 'download',
  data() {
    return {
      icApplet,
      QrCode,
      topLogo,
      win,
      topQrCode,
      one,
      two,
      three,
      four,
      line,
      query,
      transfer,
      resource,
      win7Url: '',
      win10Url: ''
    }
  },
  methods: {
    getDownloadUrl() {
      this.$http.get('/sys/config/query/appaddr/win10').then(res => {
        this.win10Url = res
      })
      this.$http.get('/sys/config/query/appaddr/win7').then(res => {
        this.win7Url = res
      })
    }
  },
  mounted() {
    document.title = '全国建筑企业资质查询-软件下载_小牛建讯官网'
    this.$nextTick(() => {
      this.getDownloadUrl()
    })
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

button {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

/*@media screen and (max-width: 2000px) {
  .query {
    height: 576px !important;
  }
  .transfer {
    height: 659px !important;
  }
  .resource {
    height: 516px !important;
  }
}

@media screen and (max-width: 1400px) {
  .query {
    height: 495px !important;
  }
  .transfer {
    height: 565px !important;
  }
  .resource {
    height: 442px !important;
  }
}*/

.download-page {
  background-color: #ffffff;
  width: 100%;

  .topBanner {
    width: 100%;
    height: 668px;
    box-sizing: border-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .container {
      width: 1200px;
      margin: 0 auto;

      .downloadButton {
        color: #ffffff;
        padding-top: 80px;
        max-width: 636px;

        .sloganBox {
          box-sizing: border-box;
          width: 613px;
          text-align: left;

          p:first-child {
            font-size: 24px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 33px;
          }

          p:nth-child(2) {
            margin-top: 12px;
            font-size: 40px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 56px;
          }

          p:nth-child(3) {
            font-size: 14px;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.6);
            line-height: 20px;
          }

          .slogan {
            margin-top: 32px;
            width: 260px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
              font-size: 24px;
              font-weight: 400;
              display: inline-block;
              text-align: left;
            }

            .slogan-line {
              width: 1px;
              height: 24px;
              background-color: #FFFFFF;
              opacity: 0.4;
            }
          }
        }

        .qrCode {
          margin-top: 32px;

          .topQrCode {
            .Qrcode {
              display: flex;
              align-items: flex-end;
              text-align: left;

              img {
                width: 120px;
              }

              img:last-child {
                margin-left: 24px;

              }

            }

            .text {
              margin-top: 16px;
              font-size: 16px;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 22px;
            }


          }
        }

        .down-icon {
          margin-top: 80px;
          width: 1200px;

          .downLoadLink {
            width: 362px;
            margin: 0 auto;
            display: flex;


          }


          .down {
            width: 160px;
            height: 48px;
            border-radius: 4px;
            border: 1px solid #FFFFFF;
            padding: 13px 0 13px 31px;

            .linkText {
              margin-left: 8px;
              font-size: 16px;
              font-weight: 500;
              color: #FFFFFF;
              line-height: 22px;

            }

          }
        }
      }
    }
  }

  .download {
    width: 1200px;
    margin: 0 auto;

    .introduceText {
      padding-top: 80px;
      text-align: center;
      color: #181818;


      p:first-child {
        font-size: 32px;
        font-weight: 500;
        line-height: 45px;
      }

      p:nth-child(2) {
        margin-top: 32px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }


    }

    .keyword {
      .keywordText {
        width: 1200px;
        margin: 80px auto 0;

        ul {
          display: flex;
          font-size: 16px;
          justify-content: space-around;
          li{
            text-align: center;
          }
          p{
            margin-top: 8px;
          }

        }
      }
    }

    .product {
      width: 1200px;
      margin: 80px auto 0;
      display: flex;
      justify-content: space-between;

      img {
        width: 480px;
        height: 280px;
      }

      .text {
        margin-top: 84px;
        .index {

          text-align: left;

          .num {
            font-size: 32px;
            font-weight: 500;
            color: #00AB94;
            line-height: 45px;
            margin-right: 12px;
          }

          .indexText {
            font-size: 32px;
            font-weight: 500;
            color: #00AB94;
            line-height: 45px;
          }
        }

        .textInfo {
          margin-top: 24px;
          text-align: left;
          width: 576px;
          height: 44px;
          font-size: 16px;
          font-weight: 400;
          color: #181818;
          line-height: 22px;
        }
      }
    }
    .lastProduct{
      margin-bottom: 80px;
    }
  }
}
</style>
