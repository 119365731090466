<template>
  <div class="receipt-page">
    <table-filter
        id="tableFilter"
        ref="tableFilterREF"
        @clickFindBtns="clickFindBtns"
        @getFilterList="setFilterOption"
    ></table-filter>
    <div class="table-content">
      <div v-show="dataLoading" class="loading">
        <a-spin size="large" tip="加载中......"/>
      </div>
      <div id="tips" class="tips">
        小牛建讯为您查询出
        <span class="primary-text">{{ tipText }}</span>
        家符合条件的企业（电话数据经大数据实时演算解析，并经启发式算法优化排序显示）
      </div>
      <div class="filter-btns">
        <a-select
            v-model="riskLevelFilter"
            allowClear
            class="risk-filter-btn"
            placeholder="风险等级"
            @change="setRiskLevelFilter"
        >
          <a-select-option
              v-for="(item, key) in riskOption"
              :key="key"
              :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <a-button
            :class="{
            noRisk: establishmentDateSort !== 2,
            haveRisk: establishmentDateSort === 2
          }"
            class="have-rt-margin"
            @click="setEstablishmentDateSort"
        >
          成立时间
          <a-icon
              v-if="establishmentDateSort"
              :type="establishmentDateSort === 2 ? 'arrow-down' : 'arrow-up'"
          />
        </a-button>
        <a-button
            :class="
            safeCertificationValue[safeCertificationIndex].value !== undefined
              ? 'haveRisk'
              : 'noRisk'
          "
            @click="changeSafeCertificate"
        >
          {{ safeCertificationValue[safeCertificationIndex].label }}
        </a-button>
        <a-button
            v-if="showWatchBtn"
            :disabled="isSuperAdmin"
            class="special-btn"
            icon="eye"
            @click="receiveAllList"
        >本页电话
        </a-button>
      </div>
      <template v-if="total">
        <table-card
            v-for="(item, index) in tableData"
            :key="index"
            :index="index"
            :isChecked="item.isChecked"
            :isClicked="findClickedItem(item.enterpriseId)"
            :isSpecialProvince="!isNotSpecialProvince"
            :row="item"
            :showCkeckBox="false"
            @goToCompanyDetail="goToCompanyDetail"
            @goToCompanyRisk="goToCompanyRisk"
            @phoneIsNotTurnOn="phoneIsNotTurnOn"
            @phoneIsTurnOn="phoneIsTurnOn"
            @receiveThisItem="receiveThisItem"
            @setRiskLevelFilter="setRiskLevelFilter"
            @showSharQrCode="showSharQrCode"
        ></table-card>
        <div class="card-pagination">
          <a-pagination
              v-model="pageNum"
              :pageSize.sync="pageSize"
              :showQuickJumper="true"
              :showSizeChanger="false"
              :showTotal="
              () => {
                return false
              }
            "
              :total="total"
              @change="tableChange"
          ></a-pagination>
          <a-button v-if="total > pageSize" class="fake-btn">确定</a-button>
        </div>
        <div
            v-if="softVersion === 'V_FREE_TEMP'"
            :style="'background-image:url(' + freeBg + ')'"
            class="free-vip-item"
        >
          <div class="free-main-title">如需查看更多数据，请升级至VIP版本</div>
          <div class="free-sub-title">
            如有疑问，请联系客服，免费咨询热线：13438870357
          </div>
          <div class="free-vip-btn" @click="goToVipPage">去升级</div>
        </div>
      </template>
      <block-data
          v-else
          :imageSrc="notFind"
          :text="''"
          :titleText="''"
      ></block-data>
      <p class="tips">
        客户查询说明：<br/>
        1.
        数据来源：该数据源于中国住房和城乡建设部、各省级一体化平台、国家企业信用信息公示系统、中国执行网<br/>
        2.相同查询条件，同一公司所有业务人员查询到的结果，列表展示一致。
      </p>
    </div>

    <qr-modal ref="qr" :url="qrCode"></qr-modal>
  </div>
</template>

<script>
import freeBg from '@/assets/tableItem/free_bg.png'
import notFind from '@/assets/image/no_date_block.png'
import BlockData from '@/components/BlockData.vue'
import {mapState, mapMutations} from 'vuex'
import qrModal from '@/components/qrCode.vue'

import tableFilter from './ReceiptComponents/Filter.vue'
import tableCard from './ReceiptComponents/TableCard.vue'
import {Pagination, Drawer, Spin, Modal, Select} from 'ant-design-vue'
import Cookie from 'js-cookie'

export default {
  name: 'shipment',
  components: {
    tableFilter,
    tableCard,
    BlockData,
    qrModal,
    aDrawer: Drawer,
    aSpin: Spin,
    aPagination: Pagination,
    aSelect: Select,
    aSelectOption: Select.Option
  },
  watch: {
    isListQuery: {
      handler(val) {
        if (val) {
          this.$refs.tableFilterREF.getFilterGroup()
          this.setListQuery(false)
        }
      },
    }
  },
  computed: {
    ...mapState({
      isListQuery: state => state.isListQuery,
      userInfo: state => state.userInfo
    }),
    softVersion() {
      return this.userInfo ? this.userInfo.buyVersion : 'V_FREE_TEMP'
    },
    userEnable(){
     return  this.userInfo ? this.userInfo.enable : -1
    }
  },
  data() {
    return {
      riskOption: [
        {label: '无风险', value: '0'},
        {label: '低风险', value: '1'},
        {label: '中风险', value: '2'},
        {label: '高风险', value: '3'}
      ],
      riskLevelFilter: undefined,
      freeBg,
      qrCode: '',
      establishmentDateSort: 0,
      isSuperAdmin: false,
      showWatchBtn: true,
      filters: null,
      pageSize: 10,
      notFind,
      pageNum: 1,
      riskSort: 1,
      haveSafeCertificate: false,
      dataLoading: false,
      gangAoTai: [810000, 820000, 710000],
      isNotSpecialProvince: false,
      tableData: [],
      tipText: '',
      total: 0,
      totalNum:0,
      clickItems: [],
      companyDetailTitle: '',
      companyDetailDrawer: false,
      safeCertificationIndex: 0,
      safeCertificationValue: [
        {value: undefined, label: '有/无安许证'},
        {value: true, label: '有安许证'},
        {value: false, label: '无安许证'}
      ],
      companyDetail: {}
    }
  },
  methods: {
    ...mapMutations([
      'setWatchPhoneModalShow',
      'setListQuery',
      'setQualificationOptions',
      'setEnterpriseInfo',
      'changeTurnPhoneStatus',
      'setATurnOnDefaultQua',
      'changeNotTurnPhoneStatus',
      'setPageHandleFunction',
      'setFreeOverDateModalShow',
      'setNotPhoneCountModalShow',
      'setExpriedModalShow',
      'setShowLoginModal',
      'setRowQuaStatus',
      'setShowActivityModal'
    ]),
    // 接通跟进
    phoneIsTurnOn(row) {
      // console.log(item)
      this.setEnterpriseInfo({...row, clueSources: 1})
      // homeModule.setTurnOnItem(row)
      this.setTurnDetaultQua(row)
      this.changeTurnPhoneStatus(true)
      this.setPageHandleFunction(this.afterTurnOnHandle)
    },
    // 拨打未接通
    phoneIsNotTurnOn(row) {
      this.setEnterpriseInfo({...row, clueSources: 1})
      this.changeNotTurnPhoneStatus(true)
      this.setPageHandleFunction(this.afterTurnOnHandle)
    },
    afterTurnOnHandle() {
      this.getComponyList()
    },
    setLegalPersonToFilter(person) {
      // console.log(person)
      this.$refs.tableFilterREF.onlyKeyword(person)
      this.companyDetailDrawer = false
    },
    setPhoneToFilter(phone) {
      this.$refs.tableFilterREF.onlyPhone(phone)
      //  this.companyDetailDrawer = false
    },
    setEstablishmentDateSort() {
      const hvaeUserId = Cookie.get('userId')

      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)
          // this.$router.push({
          //   path: "/login?from=Product"
          // });
        }, 800)
      } else {
        switch (this.establishmentDateSort) {
          case 0:
            this.establishmentDateSort = 2
            break
          case 2:
            this.establishmentDateSort = 1
            break
          case 1:
            this.establishmentDateSort = 0
            break
        }
        this.pageNum = 1
        // this.establishmentDateSort += 1
        // if (this.establishmentDateSort > 2) {
        //   this.establishmentDateSort = 0
        // }
        this.getComponyList()
      }
    },
    receiveThisItem(item) {
      if (this.isSuperAdmin) {
        this.$message.warning('超级管理员无法查看电话', 0.5)
        return
      }
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)

          // this.$router.push({
          //   path:'/login?from=Product'
          // })
        }, 800)
      } else {
        const params = [
          {
            clueSources: 1,
            enterpriseName: item.companyName || item.enterpriseName,
            enterpriseId: item.enterpriseId
          }
        ]
        this.$http
            .post('enterpriseClue/addReceiveCount', params)
            .then(() => {
              this.getComponyList()
            })
            .catch(err => {
              console.log('18989456712',err.code)
              if (err.code === '5003') {
                Modal.info({
                  title: '温馨提示',
                  content: err.msg,
                  okText: '确定',
                  onOk: () => {
                    console.log('is ok')
                  }
                })
              }
              if (err.code === '5008') {
                this.setNotPhoneCountModalShow(true)
              } else if (err.code === '2005') {
                this.setExpriedModalShow(true)
              } else if (err.code === '5013') {
                this.setWatchPhoneModalShow(true)
              } else if (err.code==='5014'){
                this.setShowActivityModal(true) // 活动期间开启该弹窗 活动结束后关闭
              }
            })
      }
    },
    receiveAllList() {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)

          // this.$router.push({
          //   path:'/login?from=Product'
          // })
        }, 800)
      } else {
        const params = this.tableData.map(e => {
          return {
            clueSources: 1,
            enterpriseName: e.companyName,
            enterpriseId: e.enterpriseId
          }
        })
        // console.log(params)
        this.$http
            .post('enterpriseClue/addReceiveCount', params)
            .then(res => {
              this.setReceiveDataInTable(res)
              this.$message.success('操作成功', 0.5)
            })
            .catch(err => {
              //  console.log(err)
              if (err.code === '5004') {
                // 本页全都被领取光了
                Modal.confirm({
                  title: '温馨提示',
                  content: err.msg,
                  okText: '下一页',
                  cancelText: '取消',
                  onOk: () => {
                    if (this.total / this.pageSize > this.pageNum) {
                      this.pageNum += 1
                      this.tableChange(this.pageNum, this.pageSize)
                    } else {
                      this.$message.warning('已经是最后一页了！', 0.5)
                      this.showWatchBtn = false
                    }
                  },
                  onCancel: () => {
                    console.log('on cancel')
                  }
                })
              } else if (err.code === '5005') {
                Modal.info({
                  title: '温馨提示',
                  content: err.msg,
                  okText: '确定',
                  onOk: () => {
                    this.setReceiveDataInTable(err.data)
                    console.log('is ok')
                  }
                })
              } else if (err.code === '5008') {
                this.setNotPhoneCountModalShow(true)
              } else if (err.code === '2005') {
                this.setExpriedModalShow(true)
              } else if (err.code === '5013') {
                this.setWatchPhoneModalShow(true)
              } else if (err.code==='5014'){
                this.setShowActivityModal(true) // 活动期间开启该弹窗 活动结束后关闭
              }
            })
      }
    },
    setReceiveDataInTable() {
      this.showWatchBtn = false
      this.getComponyList()
    },
    setRiskLevelFilterFromDetail(val) {
      this.closeCompanyDetailDrawer()
      this.setRiskLevelFilter(val)
    },
    closeCompanyDetailDrawer() {
      this.$refs.companyDrawer.setBasicInfoReback()
      this.companyDetailDrawer = false
    },
    findClickedItem(enterpriseId) {
      // 判断有没有点过
      const have = this.clickItems.find(c => c === enterpriseId)
      return have
    },
    getContainer() {
      return document.getElementById('mainContent')
    },
    goToCompanyDetail(item) {
      localStorage.removeItem('enterpriseItem')
      const isNotHavePhone = item.phoneNumDisplay.indexOf('暂无') > -1
      // console.log(isNotHavePhone)
      if (!this.isNotSpecialProvince) {
        return
      }
      if (!this.findClickedItem(item.enterpriseId)) {
        this.clickItems.push(item.enterpriseId)
      }
      this.setTurnDetaultQua(item)
      this.setEnterpriseInfo({
        ...item,
        clueSources: 1,
        isNotHavePhone
      })
      this.$router.push({
        path: `/enterpriseDetail/${item.enterpriseId}/1/0/0`
        // query: {
        //   id: item.enterpriseId,
        //   clueSources: 1,
        //   isFromClient: 0
        // }
      })
    },
    goToCompanyRisk(item) {
      localStorage.removeItem('enterpriseItem')
      const isNotHavePhone = item.phoneNumDisplay.indexOf('暂无') > -1
      if (!this.isNotSpecialProvince) {
        return
      }
      if (!this.findClickedItem(item.enterpriseId)) {
        this.clickItems.push(item.enterpriseId)
      }
      this.setTurnDetaultQua(item)
      this.setEnterpriseInfo({
        ...item,
        clueSources: 1,
        isNotHavePhone
      })
      this.$router.push({
        path: `/enterpriseDetail/${item.enterpriseId}/1/0/1`
        // query: {
        //   id: item.enterpriseId,
        //   clueSources: 1,
        //   isFromClient: 0,
        //   risk: 1
        // }
      })
    },
    setTurnDetaultQua(row) {
      let designQua = []
      let tableItemDefauletQua = []
      // console.log(this.filters)
      const onlyDesign = row.designQualificationListLocal.length > 0
      const onlyConstruction = row.qualificationsListLocal.length > 0
      if (this.filters.qualificationIds.length) {
        // console.log(this.filters.must.qualification.data)
        this.filters.qualificationIds.forEach(e => {
          const findQua = row.qualificationsListLocal.find(q => {
            return parseInt(q.id, 10) === e
          })
          if (findQua) {
            tableItemDefauletQua.push({
              value: findQua.id,
              label: findQua.qualificationName
            })
          }
          const findDesign = row.designQualificationListLocal.find(q => {
            return parseInt(q.id, 10) === e
          })
          if (findDesign) {
            tableItemDefauletQua.push({
              value: findDesign.id,
              label: findDesign.qualificationName
            })
          }
        })
        //  this.tableItemDefauletQua = this.filters.must.qualification.data
      } else {
        tableItemDefauletQua = row.qualificationsListLocal.map(e => {
          return {
            value: e.id,
            label: e.qualificationName
          }
        })
        designQua = row.designQualificationListLocal.map(e => {
          return {
            value: e.id,
            label: e.qualificationName
          }
        })
      }
      this.setATurnOnDefaultQua(tableItemDefauletQua.concat(designQua))
      this.setRowQuaStatus({onlyDesign, onlyConstruction})
    },
    showSharQrCode(item) {
      if (this.isSuperAdmin) {
        return
      }
      this.$http
          .get('enterpriseClue/shareEnterpriseTime', {
            enterpriseId: item.enterpriseId,
            userId: this.userInfo.id
          })
          .then(res => {
            const href = window.location.href
            const find = href.indexOf('#')
            const host = href.substr(0, find + 1)
            const currentDate = new Date().getTime()
            const link = 'http://www.xnjx666.com:9000/index.html#'
            // process.env.VUE_APP_TYPE  === 'online'
            //? 'http://www.xnjx666.com:9000/index.html#'
            // : host
            const pageUrl = `${link}/enterprise/share?enterpriseId=${item.enterpriseId}&dateTime=${currentDate}&uuid=${res}`
            // )
            const url =
                'http://www.xnjx666.com/shareIframe.html?src=' +
                encodeURIComponent(pageUrl)
            this.qrCode = url
            this.$refs.qr.showModal()
          })
    },
    showSizeChange(page, pageSize) {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)
        }, 800)
      } else {
        this.pageSize = pageSize
        this.pageNum = 1
        this.$refs.tableFilterREF.saveCurrentPageSizeAndPageNum(
            this.pageNum,
            this.pageSize
        )
        this.$emit('goBackToTop')
        // console.log('///')
        const pageDom = document.getElementsByClassName(
            'ant-pagination-options-quick-jumper'
        )[0]
        const pageInput = pageDom.getElementsByTagName('input')
        this.$nextTick(() => {
          pageInput[0].value = ''
        })
      }
    },
    tableChange(page, pageSize) {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)
        }, 800)
      } else {
        this.pageSize = pageSize
        this.pageNum = page
        this.$emit('goBackToTop')
        // console.log('///')
        // const pageDom = document.getElementsByClassName(
        //     'ant-pagination-options-quick-jumper'
        // )[0]
        // const pageInput = pageDom.getElementsByTagName('input')
        // this.$nextTick(() => {
        //   pageInput[0].value = ''
        // })
        this.getComponyList()
      }
    },
    changeSafeCertificate() {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)
        }, 800)
      } else {
        this.safeCertificationIndex += 1
        if (this.safeCertificationIndex > 2) {
          this.safeCertificationIndex = 0
        }
        this.pageNum = 1
        this.getComponyList()
      }
    },
    filterIsRisk() {
      this.riskSort = this.riskSort === 2 ? 1 : 2
      this.getComponyList()
    },
    clickFindBtns(info) {
      this.riskSort = 1
      this.riskLevelFilter = undefined
      this.safeCertificationIndex = 0
      this.establishmentDateSort = 0
      this.$emit('goBackToTop')
      this.setFilterOption(info)
    },
    setFilterOption(info) {
      this.filters = info.options
      this.pageNum = info.pageNum
      this.pageSize = info.pageSize
      this.getComponyList()
    },
    setRiskLevelFilter(val) {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)
        }, 800)
      } else {
        this.pageNum = 1
        this.riskLevelFilter = val
        this.getComponyList()
      }
    },
    getComponyList() {
      const hvaeUserId = Cookie.get('userId')
      if (!hvaeUserId) {
        this.getDefaultList()
      } else {
        let params = {
          ...this.filters,
          haveSafeCertificate: this.safeCertificationValue[
              this.safeCertificationIndex
              ].value,
          riskSort: this.riskSort,
          establishmentSort: this.establishmentDateSort,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          riskLevel: this.riskLevelFilter
        }
        if (params.telephone) {
          params = {
            telephone: params.telephone,
            haveSafeCertificate: this.safeCertificationValue[
                this.safeCertificationIndex
                ].value,
            riskSort: this.riskSort,
            establishmentSort: this.establishmentDateSort,
            pageSize: this.pageSize,
            pageNum: this.pageNum
          }
        }
        const keyWords = this.filters.keyWord
        this.dataLoading = true
        const areaCode = this.filters.areaCodes || []
        this.$http
            .post('enterpriseClue/list', params)
            .then(r => {
              if (areaCode.length) {
                areaCode.forEach(area => {
                  if (this.gangAoTai.includes(area)) {
                    this.isNotSpecialProvince = false
                  } else {
                    this.isNotSpecialProvince = true
                  }
                })
              } else {
                this.isNotSpecialProvince = true
              }
              const filtersQlificationId = this.filters
                  ? this.filters.qualificationIds
                  : []
              r.data.forEach((e, i) => {
                e.number = i + 1
                const qualificationsList = []
                e.buildQualificationsList.forEach(q => {
                  qualificationsList.push(...q.qualificationItems)
                })
                const designQualificationList = []
                e.designQualificationList.forEach(q => {
                  designQualificationList.push(...q.qualificationItems)
                })
                /// (this.pageNum - 1) * this.pageSize + i + 1
                e.qualificationsListLocal = qualificationsList.filter(q => {
                  return q.id
                })
                e.designQualificationListLocal = designQualificationList.filter(
                    q => {
                      return q.id
                    }
                )
                // 匹配关键字正则
                const replaceReg = new RegExp(keyWords, 'g')
                // 高亮替换v-html值
                const replaceString =
                    '<span class="hightlight">' + keyWords + '</span>'
                e.companyNameHTML = keyWords
                    ? e.companyName.replace(replaceReg, replaceString)
                    : e.companyName // 开始替换
                e.qualificationsListText = e.qualificationsListLocal
                    .map(q => {
                      return q.qualificationName
                    })
                    .join('、') // 悬浮窗的资质列表
                e.designQualificationListText = e.designQualificationListLocal
                    .map(q => {
                      return q.qualificationName
                    })
                    .join('、') // 设计资质
                // if (e.qualificationsList.length > 5) {
                if (filtersQlificationId.length > 0) {
                  const findDisplay = []
                  const findDesignDisplay = []
                  const newList = e.qualificationsListLocal.concat([])
                  const newDesignList = e.designQualificationListLocal.concat([])

                  filtersQlificationId.forEach(f => {
                    const index = newList.findIndex(
                        q => parseInt(q.id, 10) === parseInt(f, 10)
                    )
                    const designIndex = newDesignList.findIndex(
                        q => parseInt(q.id, 10) === parseInt(f, 10)
                    )
                    newList[index] = {}
                    newDesignList[designIndex] = {}
                    if (index > -1) {
                      findDisplay.push(
                          '<span class="qua-hightlight">' +
                          e.qualificationsListLocal[index].qualificationName +
                          '</span>' //找到所有筛选的资质
                      )
                    }
                    if (designIndex > -1) {
                      findDesignDisplay.push(
                          '<span class="qua-hightlight">' +
                          e.designQualificationListLocal[designIndex]
                              .qualificationName +
                          '</span>' //设计资质
                      )
                    }
                  })
                  const otherList = newList.filter(n => {
                    return n.qualificationName
                  }) // 避免显示混乱 重新取一个数组
                  const otherDesignList = newDesignList.filter(n => {
                    return n.qualificationName
                  })
                  const display =
                      findDisplay.length > 3
                          ? findDisplay.slice(0, 3).join('、')
                          : findDisplay.join('、') // 取前三个
                  const designDisplay =
                      findDesignDisplay.length > 2 // 设计只取两个
                          ? findDesignDisplay.slice(0, 2).join('、')
                          : findDesignDisplay.join('、')
                  const text =
                      findDisplay.length > 3
                          ? ''
                          : otherList
                              .slice(0, 3 - findDisplay.length)
                              .map(q => {
                                return q.qualificationName
                              })
                              .join('、')
                  const disignText =
                      findDesignDisplay.length > 2
                          ? ''
                          : otherDesignList
                              .slice(0, 2 - findDesignDisplay.length)
                              .map(q => {
                                return q.qualificationName
                              })
                              .join('、')
                  const tooltipText = otherList
                      .map(q => {
                        return q.qualificationName
                      })
                      .join('、') // 悬浮窗也要高亮

                  const desginTooltipText = otherDesignList
                      .map(q => {
                        return q.qualificationName
                      })
                      .join('、')
                  // e.qualificationsListText =
                  //     display + (tooltipText ? '、' + tooltipText : '') // 施工
                  // e.designQualificationListText =
                  //     designDisplay +
                  //     (desginTooltipText ? '、' + desginTooltipText : '') // 设计
                  if (display) {
                    e.qualificationsListText =
                        display + (tooltipText ? '、' + tooltipText : '')
                    e.qualificationsText =
                        e.qualificationsListLocal.length > 3
                            ? `${display}${text ? '、' + text : ''}等${
                                e.qualificationsListLocal.length
                            }项`
                            : `${display}${text ? '、' + text : ''}` // 施工
                  } else {
                    e.qualificationsListText = tooltipText
                    e.qualificationsText =
                        e.qualificationsListLocal.length > 3
                            ? `${text}等${e.qualificationsListLocal.length}项`
                            : text
                  }
                  if (designDisplay) {
                    e.desginQualificationText =
                        e.designQualificationListLocal.length > 2
                            ? `${designDisplay}${
                                disignText ? '、' + disignText : ''
                            }等${e.designQualificationListLocal.length}项`
                            : `${designDisplay}${disignText ? '、' + disignText : ''}` // 设计
                    e.designQualificationListText =
                        designDisplay +
                        (desginTooltipText ? '、' + desginTooltipText : '')
                  } else {
                    e.designQualificationListText = desginTooltipText
                    e.desginQualificationText =
                        e.designQualificationListLocal.length > 2
                            ? `${disignText}等${e.designQualificationListLocal.length}项`
                            : disignText
                  }
                } else {
                  const text = e.qualificationsListLocal
                      .slice(0, 3)
                      .map(q => {
                        return q.qualificationName
                      })
                      .join('、')
                  const disignText = e.designQualificationListLocal
                      .slice(0, 2)
                      .map(q => {
                        return q.qualificationName
                      })
                      .join('、')
                  e.qualificationsText =
                      e.qualificationsListLocal.length > 3
                          ? `${text}等${e.qualificationsListLocal.length}项`
                          : text
                  e.desginQualificationText =
                      e.designQualificationListLocal.length > 2
                          ? `${disignText}等${e.designQualificationListLocal.length}项`
                          : disignText
                }
                if (e.phoneList && e.phoneList.length) {
                  if (e.haveReceive) {
                    e.phoneNumDisplay = e.phoneList
                        .map(c => {
                          return (
                              '<span ondblclick="copyThisText(' +
                              c +
                              ')">' +
                              c +
                              '</span>'
                          )
                        })
                        .join('、')
                  } else {
                    e.phoneNumDisplay = e.phoneList.join('、')
                  }
                  //  : '***********'
                } else {
                  e.phoneNumDisplay = '暂无联系方式'
                }

                e.receiveCount = e.receiveCount || 0
                e.dealCount = e.dealCount || 0
                // e.haveReceive
                e.checked = false
              })
              this.tableData = r.data
              const totalLength = r.totalSize.toString().length
              const totalNum = Math.pow(10, totalLength - 1)
              const tipText = Math.floor(r.totalSize / totalNum) // 去除零头
              this.tipText =
                  r.totalSize > 1000 ? tipText * totalNum + '+' : r.totalSize
              this.total = r.totalSize
              this.dataLoading = false
              this.$nextTick(() => {
                this.totalNum = Math.ceil(this.total / 10)
                window.document.querySelector('.ant-pagination-jump-next') ? window.document.querySelector('.ant-pagination-jump-next').style.display = 'none' : ''
                if (this.totalNum >= 10) {
                  window.document.querySelector(`.ant-pagination-item-${6}`) ? window.document.querySelector(`.ant-pagination-item-${6}`).style.display = 'inline-block' : ''
                  window.document.querySelector(`.ant-pagination-item-${7}`) ? window.document.querySelector(`.ant-pagination-item-${7}`).style.display = 'inline-block' : ''
                  window.document.querySelector(`.ant-pagination-item-${8}`) ? window.document.querySelector(`.ant-pagination-item-${8}`).style.display = 'inline-block' : ''
                  window.document.querySelector(`.ant-pagination-item-${9}`) ? window.document.querySelector(`.ant-pagination-item-${9}`).style.display = 'inline-block' : ''
                  if (r.pageNum>=this.totalNum-3 ) {
                    window.document.querySelector(`.ant-pagination-item-${this.totalNum}`) ? window.document.querySelector(`.ant-pagination-item-${this.totalNum}`).style.display = 'inline-block' : ''
                  } else {
                    window.document.querySelector(`.ant-pagination-item-${this.totalNum}`) ? window.document.querySelector(`.ant-pagination-item-${this.totalNum}`).style.display = 'none' : ''
                  }
                } else {
                  if (r.pageNum>5&&r.pageNum <= this.totalNum) {
                    window.document.querySelector(`.ant-pagination-item-${r.pageNum}`) ? window.document.querySelector(`.ant-pagination-item-${r.pageNum}`).style.display = 'inline-block' : ''
                  } else {
                    window.document.querySelector(`.ant-pagination-item-${6}`) ? window.document.querySelector(`.ant-pagination-item-${6}`).style.display = 'none' : ''
                    window.document.querySelector(`.ant-pagination-item-${7}`) ? window.document.querySelector(`.ant-pagination-item-${7}`).style.display = 'none' : ''
                    window.document.querySelector(`.ant-pagination-item-${8}`) ? window.document.querySelector(`.ant-pagination-item-${8}`).style.display = 'none' : ''
                    window.document.querySelector(`.ant-pagination-item-${9}`) ? window.document.querySelector(`.ant-pagination-item-${9}`).style.display = 'none' : ''
                  }
                }
              })
              this.isNeedShowWatchBtn()
              this.webPortalToThisLookPhone()
            })
            .catch(err => {
              this.dataLoading = false
              if (err.code === '5012') {
                this.setFreeOverDateModalShow(true)
              }
            })
      }
    },
    // 处理从门户网站跳转到本项目查看电话
    webPortalToThisLookPhone(){
      let company = this.$route.query.company
      if (company) {
        let item = this.tableData[0]
        const params = [
          {
            clueSources: 1,
            enterpriseName: item.companyName || item.enterpriseName,
            enterpriseId: item.enterpriseId
          }
        ]
        this.$http
          .post('enterpriseClue/addReceiveCount', params)
          .then(() => {
            this.getComponyList()
            if (company) {
              this.$router.replace({ query: {} });
            }
          })
          .catch((err) => {
            if (err.code == '5013') {
              this.$message.error(err.msg)
            }
            if (company) {
              this.$router.replace({ query: {} });
            }
          })
      }
    },
    isNeedShowWatchBtn() {
      const receiveLength = this.tableData.filter(e => {
        return e.haveReceive
      }).length
      this.showWatchBtn = receiveLength !== this.tableData.length
    },
    goToVipPage() {
      this.$router.push({
        name: 'Vip',
        query: {
          fromUrl: this.$route.name
        }
      })
    },
    setTableValue(result, areaCode, filtersQlificationId, keyWords) {
      if (areaCode.length) {
        areaCode.forEach(area => {
          if (this.gangAoTai.includes(area)) {
            this.isNotSpecialProvince = false
          } else {
            this.isNotSpecialProvince = true
          }
        })
      } else {
        this.isNotSpecialProvince = true
      }
      const r = result
      r.data.forEach((e, i) => {
        e.number = i + 1
        const qualificationsList = []
        e.qualificationsList.forEach(q => {
          if (q.qualificationItems) {
            qualificationsList.push(...q.qualificationItems)
          } else {
            qualificationsList.push(q)
          }
        })
        e.qualificationsListLocal = qualificationsList.filter(q => {
          return q.id
        })
        // 匹配关键字正则
        e.designQualificationListLocal = []
        const replaceReg = new RegExp(keyWords, 'g')
        // 高亮替换v-html值
        const replaceString = '<span class="hightlight">' + keyWords + '</span>'
        e.companyNameHTML = keyWords
            ? e.companyName.replace(replaceReg, replaceString)
            : e.companyName // 开始替换
        e.qualificationsListText = e.qualificationsListLocal
            .map(q => {
              return q.qualificationName
            })
            .join('、') // 悬浮窗的资质列表
        // if (e.qualificationsList.length > 5) {
        if (filtersQlificationId.length > 0) {
          const findDisplay = []
          const newList = e.qualificationsListLocal.concat([])

          filtersQlificationId.forEach(f => {
            const index = newList.findIndex(
                q => parseInt(q.id, 10) === parseInt(f, 10)
            )
            newList[index] = {}
            if (index > -1) {
              findDisplay.push(
                  '<span class="qua-hightlight">' +
                  e.qualificationsListLocal[index].qualificationName +
                  '</span>' //找到所有筛选的资质
              )
              //  newList.splice(index, 1)不用slice保证数组长度
            }
          })
          const otherList = newList.filter(n => {
            return n.qualificationName
          }) // 避免显示混乱 重新取一个数组
          const display =
              findDisplay.length > 5
                  ? findDisplay.slice(0, 5).join('、')
                  : findDisplay.join('、') // 取前五个
          const text =
              findDisplay.length > 5
                  ? ''
                  : otherList
                      .slice(0, 5 - findDisplay.length)
                      .map(q => {
                        return q.qualificationName
                      })
                      .join('、')
          const tooltipText = otherList
              .map(q => {
                return q.qualificationName
              })
              .join('、') // 悬浮窗也要高亮
          e.qualificationsListText =
              display + (tooltipText ? '、' + tooltipText : '')
          e.qualificationsText =
              e.qualificationsListLocal.length > 5
                  ? `${display}${text ? '、' + text : ''}等${
                      e.qualificationsListLocal.length
                  }项`
                  : `${display}${text ? '、' + text : ''}`
        } else {
          const text = e.qualificationsListLocal
              .slice(0, 5)
              .map(q => {
                return q.qualificationName
              })
              .join('、')
          e.qualificationsText =
              e.qualificationsListLocal.length > 5
                  ? `${text}等${e.qualificationsListLocal.length}项`
                  : text
        }
        if (e.phoneList && e.phoneList.length) {
          if (e.haveReceive) {
            e.phoneNumDisplay = e.phoneList
                .map(c => {
                  return (
                      '<span ondblclick="copyThisText(' + c + ')">' + c + '</span>'
                  )
                })
                .join('、')
            // console.log(e.phoneNumDisplay);
          } else {
            e.phoneNumDisplay = e.phoneList.join('、')
          }
          //  : '***********'
        } else {
          e.phoneNumDisplay = '暂无联系方式'
        }
        // e.contactInformationListText = e.contactInformationList.join('、')
        e.receiveCount = e.receiveCount || 0
        e.dealCount = e.dealCount || 0
        // e.haveReceive
        e.checked = false
      })
      this.tableData = r.data
      const totalLength = r.totalSize.toString().length
      const totalNum = Math.pow(10, totalLength - 1)
      const tipText = Math.floor(r.totalSize / totalNum) // 去除零头
      this.tipText = r.totalSize > 1000 ? tipText * totalNum + '+' : r.totalSize
      this.total = r.totalSize
      this.dataLoading = false
    },
    getDefaultList() {
      this.$http.get('enterpriseClue/defaultList').then(res => {
        this.setTableValue(res, [], [], '')
      })
    }
  },
  mounted() {
    const hvaeUserId = Cookie.get('userId')
    if (!hvaeUserId) {
      this.getDefaultList()
    }
  }
}
</script>

<style lang="scss" src="../../../utils/table.scss"></style>
<style lang="scss" scoped>
.receipt-page {
  width: 100%;
  min-width: 1200px;

  .filter-btns {
    margin-top: 12px;
    padding: 6px 21px;
    border: solid 1px #f2f2f2;
    background-color: #f2f2f2;
    position: relative;

    .noRisk {
      background-color: transparent;
      border-color: #999999;

      &:hover {
        border-color: #759fff;
      }

      &:focus {
        border-color: #759fff;
      }
    }

    .haveRisk {
      background-color: #4d7cfe;
      color: #ffffff;

      &:hover {
        background-color: #759fff;
        border-color: #759fff;
      }
    }

    .special-btn {
      color: #fff;
      background-color: #65b3fe;
      position: absolute;
      right: 21px;

      &:hover {
        background-color: #84baef;
        border-color: #84baef;
      }
    }
  }

  .card-pagination {
    width: 100%;
    // text-align: right;
    margin-top: 35px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .fake-btn {
      margin-left: 8px;
    }
  }
}
</style>
