<template>
  <div class="buying-table-item">
    <div class="lf">
      <div v-if="row.isNewest" class="is-new">新上架</div>
      <div :style="'background-image:url(' + quaImage + ')'" class="qua-image"></div>
      <div class="info">
        <div class="qualification">
          {{ row.qualificationNameAndLevel }}
          <a-tag v-if="row.channel === 1" class="channel-tag" color="cyan"
          >小牛优选
          </a-tag
          >
          <a-tag v-if="row.channel === 2" class="channel-tag" color="purple"
          >平台自营
          </a-tag
          >
          <a-tag v-if="row.quelificationStatus === 0" class="channel-tag" color="blue"
          >现货
          </a-tag
          >
          <a-tag v-if="row.quelificationStatus === 1" class="channel-tag" color="green"
          >预售
          </a-tag
          >
        </div>
        <div class="area-effect">
          <div class="info-item">地区：{{ row.areaName || '不限' }}</div>
          <div v-if="row.effectiveDate" class="line"></div>
          <div v-if="row.effectiveDate" class="info-item">
            有效期至{{ row.effectiveDate }}
          </div>
        </div>
        <div class="other-list">
          <div v-for="(item, key) in otherList" :key="key" class="other-item">
            <div class="label">{{ item.label }}</div>
            <div
                v-if="item.value !== 'isHoldingCompany' && item.value !== 'price'"
                class="value"
            >
              {{ row.authenFeatureVO[item.value] ? '有' : '无' }}
            </div>
            <div v-if="item.value === 'isHoldingCompany'" class="value">
              {{ row.authenFeatureVO[item.value] ? '有' : '无' }}
            </div>
            <div v-if="item.value === 'price'" class="value" style="color: #dc0000;">
              面议
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rt">
      <div class="submit-time">发布日期：{{ row.releaseTime }}</div>
      <a-button
          v-if="!row.checkState"
          class="btns"
          size="small"
          type="primary"
          @click="watchThisPhone"
      >查看电话
      </a-button
      >

      <div v-else class="contact">
        <div class="person line">联系人：{{ row.contactsPerson }}</div>
        <a-tooltip title="双击可复制电话号码" trigger="hover">
          <div class="phone line">
            电话：
            <span @dblclick="dblCopy" v-html="row.phoneNumDisplay"></span>
          </div>
        </a-tooltip>
      </div>
      <div class="phone-tips">
        手机号经短信校验属实
      </div>
    </div>
  </div>
</template>

<script>
import {Tooltip, Tag} from 'ant-design-vue'
import quaImage from '@/assets/image/qr/qua_image.png'
import {mapMutations} from 'vuex'

export default {
  name: 'table-item',
  components: {
    aTooltip: Tooltip,
    aTag: Tag
  },
  data() {
    return {
      quaImage,
      otherList: [
        {label: '财务纠纷', value: 'isFinanceDispute'},
        {label: '法律纠纷', value: 'isLawDispute'},
        {label: '行政处罚记录', value: 'isPoliticsPenalize'},
        {label: '在建工程', value: 'isBuildingProject'},
        {label: '挂靠人员', value: 'isHangingClose'},
        {label: '安全生产许可证', value: 'isSecurityLicence'},
        {label: '包函', value: 'isNeedLetter'},
        {label: '不可控股', value: 'isHoldingCompany'},
        {label: '价格', value: 'price'}
      ]
    }
  },
  props: {
    freeCount: {
      type: Number,
      default: 0
    },
    row: {
      type: Object,
      default() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  methods: {
    ...mapMutations(['setWatchPhoneModalShow', 'setShowActivityModal']),

    dblCopy() {
      this.$message.success('电话号码已复制到粘贴板', 0.5)
    },
    watchThisPhone() {
      this.$http
          .get('shared/phone/view', {id: this.row.id})
          .then(() => {
            this.$emit('refreshList')
          })
          .catch(err => {
            if (err.code === '5010') {
              this.$message.warn('出售与求购相关使用次数已用完', 0.5)
            } else if (err.code === '5013') {
              this.setWatchPhoneModalShow(true)
            } else if (err.code === '5014') {
              this.setShowActivityModal(true)
            }
          })
    }
  }
}
</script>
<style lang="scss" scoped>
.buying-table-item {
  height: 200px;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: solid 1px #f2f2f2;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;

  .lf {
    position: relative;
    align-items: flex-start;
    display: flex;

    .is-new {
      width: 70px;
      height: 25px;
      background-color: #4ebf56;
      text-align: center;
      line-height: 25px;
      color: #fff;
      position: absolute;
      left: -20px;
      top: -20px;
      z-index: 100;
    }

    .qua-image {
      z-index: 99;
      width: 112px;
      height: 160px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      // background-color: #4d7cfe;
      position: relative;
      margin-right: 20px;
      overflow: hidden;
      border-radius: 3px;

      .image {
        width: 175px;
        height: 218px;
        background-size: cover;
        position: absolute;
        right: -55px;
        bottom: -70px;
      }
    }

    .info {
      .qualification {
        font-size: 17px;
        color: #4d7cfe;
        position: relative;
        display: flex;
        align-items: center;
        align-items: center;
        //   cursor: pointer;
        margin-bottom: 20px;

        .channel-tag {
          margin-left: 10px;
        }

        // .excellent {
        // }
        // .platform {
        // }
      }

      .area-effect {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        flex-shrink: 0;
        font-size: 14px;
        color: #8e8e90;
        justify-content: flex-start;

        div {
          width: fit-content;
          margin-right: 20px;
        }

        .line {
          width: 1px;
          height: 16px;
          flex-shrink: 0;
          background-color: #ddd;
        }
      }

      .other-list {
        margin-top: 40px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        justify-content: space-between;

        .other-item {
          width: fit-content;
          padding: 0 15px 0 10px;
          font-size: 15px;
          margin-right: 5px;
          text-align: center;
          border-right: solid 1px #ddd;

          .value {
            margin-top: 10px;
            font-size: 13px;
          }

          &:last-child {
            border-right: none;
          }
        }
      }

      .area-date {
        font-size: 13px;
        margin-bottom: 4px;
        color: #8e8e90;
      }
    }
  }

  .rt {
    // margin-bottom: 20px;
    width: 130px;

    position: relative;

    .submit-time {
      position: absolute;
      color: #8e8e90;
      text-align: right;
      top: -80px;
      right: 0;
      width: 200px;
    }

    .line {
      font-size: 13px;
      margin-bottom: 5px;
    }

    .btns {
      width: 100%;
      text-align: right;
      width: 70px;
      margin-bottom: 8px;
    }

    .phone-tips {
      font-size: 12px;
      color: #8e8e90;
    }
  }
}
</style>
