<template>
    <div class="qr-page">
        <component :is="qrComponents" @fastSend="showFastModal"></component>
    </div>
</template>

<script>
import main from './QrPage/main.vue'
// import { universalModule } from '@/store/modules/universal'

export default {
    name: 'qualification-resources',

    computed: {},
    data() {
        return {
            qrComponents: main
        }
    },

    methods: {
        goBackToTop() {
            this.$emit('goBackToTop')
        },
        showFastModal() {
            this.$emit('showFastModal')
        }
    }
}
</script>

<style lang="scss">
// .page-tabs {
//   border-radius: 4px;
//   height: 50px;
//   background-color: #ffffff;
//   .ant-tabs-nav-container {
//     height: 50px;
//   }
// }
.quick-bottom {
    text-align: center;
    .quick-btns {
        width: 50%;
        margin-bottom: 5px;
    }
    .service-phone {
        margin-top: 10px;
    }
}
</style>
<style lang="scss" scoped>
.qr-page {
    width: 1200px;
    .page-tabs {
        border-radius: 4px 4px 0 0;
        height: 28px;
        padding: 25px;
        background-color: #ffffff;
        // .ant-tabs-nav-container {
        //   height: 50px;
        // }
    }
}
</style>
