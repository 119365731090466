<template>
    <div class="certifi-detail">
        <div
            v-if="statusImage"
            class="check-status"
            :style="'background-image:url(' + statusImage + ')'"
        ></div>
        <div class="back">
            <a-button icon="left" type="link" @click="backFrom">返回</a-button>
        </div>
        <div class="re-checkout" v-if="detail.status === 2 && !isSuperAdmin">
            <a-button @click="reSend" type="primary">重新提审</a-button>
        </div>
        <div class="info-content">
            <div
                class="check-remarks"
                v-if="(detail.status === 2 || detail.status === 3) && !isSuperAdmin"
            >
                <div>审核失败原因：</div>
                <div class="have-color">{{ detail.remarks }}</div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">资质状态:</div>
                    <div class="detail">
                        {{ statusText[detail.quelificationStatus] }}
                    </div>
                </div>
            </div>

            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">资质类别及等级:</div>
                    <div class="detail">
                        <div v-for="(item, index) in detail.qualificationTextList" :key="index">
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">企业名称:</div>
                    <div class="detail">{{ detail.enterpriseName }}</div>
                </div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">注册地区:</div>
                    <div class="detail">{{ detail.area }}</div>
                </div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">营业执照:</div>
                    <div class="detail">
                        <div class="image-list" v-if="detail.businessLicenseUrl">
                            <div
                                class="image-item"
                                title="点击查看大图"
                                @click="showImagePreview(detail.businessLicenseUrl)"
                                :style="'background-image:url(' + detail.businessLicenseUrl + ')'"
                            >
                                <!-- <img :src="detail.legalImageUrl" /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">有效期:</div>
                    <div class="detail">{{ effectiveDate }}</div>
                </div>
            </div>
            <div class="list-item">
                <div class="item-title ">资质证书:</div>
                <div class="detail">
                    <div class="image-list">
                        <div
                            v-for="(item, key) in detail.itemsImageUrlList"
                            :key="key"
                            class="image-item"
                            title="点击查看大图"
                            @click="showImagePreview(item.upLoadImageUrl)"
                            :style="'background-image:url(' + item.upLoadImageUrl + ')'"
                        >
                            <!-- <img :src="detail.legalImageUrl" /> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">更多特性:</div>
                    <div class="detail">
                        <a-checkbox-group :value="other">
                            <a-checkbox
                                class="checkbox"
                                v-for="(item, key) in otherOptions"
                                :key="key"
                                :value="item.value"
                                >{{ item.label }}</a-checkbox
                            >
                        </a-checkbox-group>
                    </div>
                </div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">备注说明:</div>
                    <div class="detail">{{ detail.showDescribe || '--' }}</div>
                </div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">手机号:</div>
                    <div class="detail">{{ detail.contactsPhone }}</div>
                </div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">联系人:</div>
                    <div class="detail">{{ detail.contactsPerson || '-' }}</div>
                </div>
            </div>
            <div class="item-list">
                <div class="list-item">
                    <div class="item-title ">发布时间:</div>
                    <div class="detail">{{ detail.createTime }}</div>
                </div>
            </div>

            <!-- <div
      class="check-remarks success"
      v-if="detail.status === 1 && detail.remarks"
    >
      审核成功原因：{{ detail.remarks }}
    </div>
    <div class="resubmit-btn">
      <a-button v-if="detail.status === 2" @click="editThisInfo" type="primary"
        >重新审核</a-button
      >
    </div> -->
            <div class="item-list" v-if="isCertification && !detail.status">
                <div class="list-item">
                    <div class="item-title "></div>
                    <a-button class="have-rt-margin" type="primary" @click="confirmPass">
                        通过
                    </a-button>
                    <a-button type="danger" @click="confirmRefuse">不通过</a-button>
                </div>
            </div>

            <div v-if="isSuperAdmin">
                <div v-if="detail.status === 2" class="failure-info">
                    <!-- <div class="item-list">
            <div class="list-item">
              <div class="item-title ">审核状态:</div>
              <div class="detail">审核失败</div>
            </div>
          </div> -->
                    <div class="item-list">
                        <div class="list-item">
                            <div class="item-title ">审核人员:</div>
                            <div class="detail">{{ detail.memberName }}</div>
                        </div>
                    </div>
                    <div class="item-list">
                        <div class="list-item">
                            <div class="item-title ">审核时间:</div>
                            <div class="detail">{{ detail.auditTime }}</div>
                        </div>
                    </div>
                    <div class="item-list">
                        <div class="list-item">
                            <div class="item-title ">备注原因:</div>
                            <div class="detail">{{ detail.remarks }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="detail.status === 1" class="success-info">
                    <!-- <div class="item-list">
            <div class="list-item">
              <div class="item-title ">审核状态:</div>
              <div class="detail">审核成功</div>
            </div>
          </div> -->
                    <div class="item-list">
                        <div class="list-item">
                            <div class="item-title ">审核人员:</div>
                            <div class="detail">
                                {{ detail.memberName || '平台自营自动审核' }}
                            </div>
                        </div>
                    </div>
                    <div class="item-list">
                        <div class="list-item">
                            <div class="item-title ">审核时间:</div>
                            <div class="detail">
                                {{ detail.auditTime || '平台自营自动审核' }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="detail.status === 0" class="ing-info">
          <div class="item-list">
            <div class="list-item">
              <div class="title">审核状态:</div>
              <div class="detail">审核中</div>
            </div>
          </div>
        </div> -->
            </div>
        </div>

        <a-modal
            width="500px"
            title="审核失败"
            :destroyOnClose="true"
            :visible="showRefuse"
            @cancel="closeModal"
            @ok="refuseCertificaion"
        >
            <form-index ref="formIndex" :labelCol="{ span: 4 }" :formItems="formItems"></form-index>
        </a-modal>
        <image-preview
            @closePrview="closePrview"
            :imageUrl="priviewUrl"
            v-if="showPriview"
        ></image-preview>
    </div>
</template>

<script>
// import { universalModule } from '@/store/modules/universal'
// import { userModule } from '@/store/modules/user'
import { Checkbox, Modal } from 'ant-design-vue'
import imagePreview from '@/components/ImagePreview.vue'
import formType from '@/components/Form/formType'
import formIndex from '@/components/Form/index.vue'
import failure from '@/assets/image/check/failure.png'
import pass from '@/assets/image/check/pass.png'
import ing from '@/assets/image/check/ing.png'

export default {
    name: 'detail',
    components: {
        aCheckbox: Checkbox,
        aCheckboxGroup: Checkbox.Group,
        imagePreview,
        aModal: Modal,
        formIndex
    },
    data() {
        return {
            statusImage: '',
            failure,
            pass,
            ing,
            isCertification: false,
            detail: {},
            priviewUrl: '',
            showPriview: false,
            showRefuse: false,
            effectiveDate: '',
            refuseRemark: '',
            other: [],
            formItems: [
                {
                    type: formType.TEXTAREA,
                    value: '',
                    label: '备注原因',
                    name: 'remarks',
                    placeholder: '请填写审核不通过原因',
                    validate: [
                        {
                            required: true,
                            trigger: 'blur',
                            message: '请填写审核不通过的原因'
                        }
                    ]
                }
            ],
            statusText: ['现货', '预售'],
            otherOptions: [
                { label: '财务纠纷', value: 'isFinanceDispute' },
                { label: '法律纠纷', value: 'isLawDispute' },
                { label: '行政处罚记录', value: 'isPoliticsPenalize' },
                { label: '在建工程', value: 'isBuildingProject' },
                { label: '挂靠人员', value: 'isHangingClose' },
                { label: '安全生产许可证', value: 'isSecurityLicence' },
                { label: '包函', value: 'isNeedLetter' },
                { label: '不可控股', value: 'isHoldingCompany' }
            ]
        }
    },
    computed: {
        isSuperAdmin() {
            return userModule.roleLevel === 1
        },
        areaOptions() {
            return universalModule.areaOptions
        }
    },
    methods: {
        reSend() {
            this.$router.push({
                name: 'excellentShare',
                query: {
                    channel: '1',
                    reSendId: this.id
                }
            })
        },
        refuseCertificaion() {
            const remarks = this.$refs.formIndex.formValidator()
            if (!remarks) {
                return
            }

            const params = {
                status: 2,
                authenId: this.id,
                ...remarks
            }
            this.$http.post('authen/inspect', params).then(() => {
                this.closeModal()
                this.getCompanyCertificationDetail()
                this.$message.success('操作成功', 0.5)
                // this.getCertificationList()
                // this.closeDraw()
            })
        },
        confirmRefuse() {
            this.refuseRemark = ''
            this.showRefuse = true
        },
        closeModal() {
            this.showRefuse = false
        },
        confirmPass() {
            Modal.confirm({
                title: '审核成功',
                content:
                    '审核成功之后，该企业添加的资质信息会展示在我的发布中，一旦审核通过不可撤销，请仔细审核用户上传的资质信息。',
                onOk: () => {
                    this.passCertification()
                }
            })
        },
        passCertification() {
            const params = {
                status: 1,
                authenId: this.id
            }
            this.$http.post('authen/inspect', params).then(() => {
                this.$message.success('操作成功', 0.5)
                //  this.getCertificationList()
                this.getCompanyCertificationDetail()
                // this.closeDraw()
            })
        },
        closePrview() {
            this.showPriview = false
            this.priviewUrl = ''
        },
        showImagePreview(url) {
            this.showPriview = true
            this.priviewUrl = url
        },
        backFrom() {
            if (this.$route.query.from) {
                this.$router.push({
                    name: this.$route.query.from,
                    query: {
                        pId: '4',
                        historyPage: this.$route.query.historyPage || '',
                        mySellPage: this.$route.query.mySellPage || '',
                        cePage: this.$route.query.cePage
                    }
                })
            } else {
                this.$router.push({
                    name: 'mySell',
                    query: {
                        pId: '4'
                    }
                })
            }
        },
        getCompanyCertificationDetail() {
            // console.log('getCompanyCertificationDetail')
            this.$http.get('authen/get/deteils', { id: this.id }).then(res => {
                this.detail = res
                this.detail.qualificationTextList = res.itemsInfoList.map(e => {
                    return e.authenItemName
                })
                const findArea = this.areaOptions.find(e => {
                    return e.value === this.detail.areaCode
                })
                if (findArea) {
                    this.detail.area = findArea.label
                }
                this.otherOptions.forEach(o => {
                    if (res.authenFeatureVO[o.value]) {
                        this.other.push(o.value)
                    }
                })
                if (res.itemsImageUrlList) {
                    res.itemsImageUrlList.forEach(i => {
                        if (i.effectiveDate) this.effectiveDate = i.effectiveDate
                    })
                }
                switch (res.status) {
                    case 1:
                        this.statusImage = pass
                        break
                    case 2:
                        this.statusImage = failure
                        break
                    case 3:
                        this.statusImage = failure
                        break
                    default:
                        this.statusImage = ing
                }
            })
            //   console.log(this.detail)
        }
    },
    async mounted() {
        await universalModule.getAreaOptions()
        this.id = this.$route.query.id
        this.isCertification = this.$route.query.isCertification === '1'
        // this.$nextTick(() => {
        //   this.getCompanyCertificationDetail()
        // })
    },
    watch: {
        areaOptions() {
            this.getCompanyCertificationDetail()
        }
    }
}
</script>

<style lang="scss" scoped>
.certifi-detail {
    // margin-top: 15px;
    // margin-top: 50px;
    padding-bottom: 10px;
    background-color: #ffffff;
    width: 100%;
    .back {
        padding: 20px 10px 10px 10px;
    }
    .re-checkout {
        position: absolute;
        right: 10px;
        top: 0;
        padding: 20px 10px 10px 10px;
    }
    .check-status {
        width: 114px;
        height: 114px;
        background-size: cover;
        position: absolute;
        background-repeat: no-repeat;
        background-position: center;
        right: 200px;
        top: 70px;
        z-index: 888;
    }
    .item-list {
        display: flex;
        width: 750px;
        // justify-content: space-between;
    }
    .info-content {
        width: 100%;
        position: relative;
        margin-top: 10px;
        // padding: 62px 22px 2px 22px;
        // border: solid 1px #f2f2f2;
        margin-bottom: 20px;
        // border-radius: 4px;
    }
    .info-maintitle {
        height: 32px;
        position: absolute;
        width: 100%;
        line-height: 32px;
        padding-left: 10px;
        border-left: 10px solid #4d7cfe;
        top: 0;
        left: 0;
        box-sizing: border-box;
        color: #8e8e90;
        background-color: #f2f2f2;
    }
    .list-item {
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        .item-title {
            // font-size: 13px;
            width: 110px;
            text-align: right;
            margin-right: 35px;
            flex-shrink: 0;
            color: #8e8e90;
            // height: 40px;
            // line-height: 40px;
        }
        .detail {
            // .image-list {
            //   display: flex;
            //   align-items: center;
            //   flex-wrap: wrap;
            // }
            .checkbox {
                width: 140px;
                margin-left: 0;
            }
            .image-item {
                width: 240px;
                height: 240px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                margin: 0 15px 15px 0;
                cursor: pointer;
                img {
                    width: 300px;
                    height: 225px;
                }
            }
        }
        //  line-height: 60px;
    }
    .failure-info {
        padding-top: 10px;
        background-color: #f2f2f2;
        color: #dc0000;
    }
    .success-info {
        padding-top: 10px;
        background-color: #f2f2f2;
        color: #4ebf56;
    }
    .ing-info {
        padding-top: 10px;
        background-color: #f2f2f2;
        color: #ff8800;
    }
    .check-remarks {
        width: 100%;
        // height: 40px;
        //line-height: 40px;
        display: flex;
        align-items: flex-start;
        font-size: 15px;
        padding: 15px;
        // background: #f2f2f2;
        // border-radius: 11px;
        margin-bottom: 20px;
        .have-color {
            font-weight: bold;
            width: 70%;
            color: #ff8800;
        }
        // margin-left: 22px;
    }
}
</style>
