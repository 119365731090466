<template>
  <div class="index">
    <div :style="'background-image:url(' + detailBg + ')'" class="bg">
      <div class="text">
        <p>联系我们</p>
        <p>Get in touch with us</p>
      </div>
    </div>
    <div class="content">
      <div class="contentMain">
        <div style="margin-top: 54px;">
          <div class="contentTitle">
            <span>产品咨询</span>
          </div>
          <p class="contentInfo">
            如果你想深入了解、体验产品，请联系我们的产品咨询团队：
          </p>
          <div class="center">
            <img :src="phone" alt="小牛建讯" />
            <div class="phone" style="vertical-align:bottom;">13438870357</div>
          </div>
        </div>
        <div style="margin-top: 126px;">
          <div class="contentTitle">
            <span>代理合作咨询</span>
          </div>
          <p class="contentInfo">
            如果你想成为我们的合作伙伴，请联系我们的渠道团队：
          </p>
          <div class="center">
            <img :src="phone" alt="小牛建讯" style="vertical-align:bottom;" />
            <div class="phone" style="vertical-align:bottom;">13438870357</div>
          </div>
          <div class="center">
            <img :src="mailbox" alt="小牛建讯" style="vertical-align:bottom;" />
            <div class="phone" style="vertical-align:bottom;">
              xiaoniujianxun@163.com
            </div>
          </div>
        </div>
        <div style="margin-top: 126px;">
          <div class="contentTitle">
            <span>售前服务咨询</span>
          </div>
          <p class="contentInfo">
            如果你在使用产品过程中有任何疑问、建议或意见，欢迎向我们提出：
          </p>
          <div class="center">
            <img :src="phone" alt="小牛建讯" style="vertical-align:bottom;" />
            <div class="phone" style="vertical-align:bottom;">13438870357</div>
          </div>
          <div class="center">
            <img :src="mailbox" alt="小牛建讯" style="vertical-align:bottom;" />
            <div class="phone" style="vertical-align:bottom;">
              xiaoniujianxun@163.com
            </div>
          </div>
        </div>
      </div>
      <div class="qrCode">
        <div>
          <img :src="officialAccount" alt="小牛建讯" />
          <p class="qrcodeText">关注公众号</p>
        </div>
        <div>
          <img :src="service" alt="小牛建讯" />
          <p class="qrcodeText">加客服微信</p>
        </div>
        <div>
          <img :src="icApplet" alt="小牛建讯" />
          <p class="qrcodeText">小程序</p>
        </div>
      </div>

      <div class="route">
        <p class="lookRouter">
          查看路线
        </p>
        <p class="location">
          <img :src="location" alt="小牛建讯" />
          <span class="city">四川省成都市成都高新区天府大道北段28号1栋1单元23层6号</span>
        </p>
        <img class="mapImg" :src="mapImg" alt="小牛建讯" />
      </div>
    </div>
  </div>
</template>

<script>
import detailBg from '@/assets/contactUs/contactUs.png'
import star from '@/assets/contactUs/star.png'
import service from '@/assets/contactUs/service.png'
import officialAccount from '@/assets/contactUs/officialAccount.png'
import mapImg from '@/assets/contactUs/Map.png'
import line from '@/assets/contactUs/line.png'
import phone from '@/assets/contactUs/phone.png'
import location from '@/assets/contactUs/location.png'
import mailbox from '@/assets/contactUs/mailbox.png'
import icApplet from '@/assets/contactUs/pc_applet.png'

export default {
  name: 'ContactUs',
  data() {
    return {
      detailBg,
      icApplet,
      officialAccount,
      star,
      service,
      mapImg,
      line,
      phone,
      location,
      mailbox
    }
  },
  mounted(){
    document.title = '全国建筑企业资质查询-联系我们_小牛建讯官网'
  }
}
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

* {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.icApplet {
  width: 178px;
  height: 178px;
}

.index {
  width: 100%;
  background-color: #fff;

  .bg {
    width: 100%;
    text-align: center;
    height: 360px;
    display: flex;

    .text {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      position: relative;
      padding-top: 120px;

      p:first-child {
        font-size: 40px;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
      }

      p:nth-child(2) {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }

      .line {
        position: absolute;
        left: 50%;
        top: 70%;
        transform: translate(-50%, -50%);
        width: 89px;
        height: 3px;
        background: #ffffff;
      }
    }
  }

  .content {
    .contentMain {
      width: 700px;
      box-sizing: border-box;
      margin: 0 auto;
      text-align: center;

      .contentTitle {
        span {
          font-size: 32px;
          font-weight: 500;
          color: #181818;
          line-height: 45px;
        }
      }

      .contentInfo {
        margin-top: 56px;
        font-size: 16px;
        font-weight: 400;
        color: #181818;
        line-height: 22px;
      }

      .phone {
        font-size: 24px;
        font-weight: 500;
        color: #00ab94;
        line-height: 33px;
        margin-left: 10px;
      }
    }
  }

  .qrCode {
    width: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 48px;

    img {
      width: 120px;
    }

    .qrcodeText {
      font-size: 14px;
      font-weight: 400;
      color: #181818;
      line-height: 20px;
      text-align: center;
    }
  }
  .route {
    width: 1200px;
    margin: 60px auto 0;
    text-align: center;
    .lookRouter {
      font-size: 32px;
      font-weight: 500;
      color: #181818;
      line-height: 45px;
      margin-bottom: 56px;
    }
    .location {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
      .city {
        font-size: 24px;
        font-weight: 500;
        color: #00ab94;
        line-height: 33px;
        margin-left: 10px;
      }
    }
    .mapImg {
      margin-bottom: 80px;
    }
  }
}
</style>
