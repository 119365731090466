<template>
  <div class="product-root">
    <div class="product-bg"></div>
    <div class="product-main">
      <div class="product-menu">
        <div
          class="menu-item"
          @click="showComponents(key)"
          :class="{ isCurrent: currentMenu === key }"
          v-for="(item, key) in menuList"
          :key="key"
        >
          <div class="little-trangle" v-if="item.title"></div>
          {{ item.title }}
        </div>
      </div>
      <div class="product-component" v-if="$route.name === 'Product'">
        <component
          :is="menuList[currentMenu].components"
          @goBackToTop="goBackTop"
          @showFastModal="showFastModal"
        ></component>
      </div>
      <router-view
        @showFastModal="showFastModal"
        @goBackToTop="goBackTop"
        class="product-component"
        v-else
      />
    </div>
    <a-modal
      v-model="fastModal"
      :destroyOnClose="true"
      width="620px"
      :footer="null"
    >
      <div slot="title" style="text-align:center">只需1步,买家快速找上门</div>
      <form-index
        v-model="formValue"
        ref="quickForm"
        :labelCol="{ span: 5 }"
        @setVerifyCode="setVerifyCode"
        @validatePhone="validatePhone"
        singleId="quickQuaSelect"
        :designQualificationOptions="designQualificationOptions"
        :fakeQualification="fakeQualificationOptions"
        :qualificationOptions="qualificationOptions"
        :formItems="formItems"
      ></form-index>
      <div class="quick-bottom">
        <a-button
          class="quick-btns"
          @click="beforeSubmit"
          type="primary"
          size="large"
          >快速提交
        </a-button>
        <div class="tips">
          您的专属客服稍后将会和您取得联系，以验证出售信息的真实及完整性
        </div>
        <div class="service-phone">
          免费咨询热线：13438870357
        </div>
      </div>
    </a-modal>
    <a-modal
      :visible="expriedModalShow"
      @cancel="closeExpiredModal"
      title="温馨提示"
    >
      <p style="text-align:center">
        当前使用版本已过期，请您升级成VIP版后继续使用
      </p>
      <div class="goto-tag"><a @click="goToVip">我要升级>></a></div>
      <div slot="footer">
        <a-button @click="closeExpiredModal" type="primary">确认</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import formType from '@/components/Form/formType'
import formIndex from '@/components/Form/index.vue'
import VerifyCode from '@/components/verifyCode.vue'
import receipt from './components/receipt.vue'
import shipment from './components/shipment.vue'
import { mapMutations, mapState } from 'vuex'
import client from './components/client.vue'
import qualificationService from './components/qualificationService.vue'
import qr from './components/qR.vue'
import Cookie from 'js-cookie'
import { message } from 'ant-design-vue'

export default {
  name: 'product-root',
  components: {
    formIndex
  },
  data() {
    return {
      expriedModalShow: false,
      currentMenu: 0,
      menuList: [
        { title: '收货查询', components: receipt },
        { title: '出货查询', components: shipment },
        { title: '我的客户', components: client },
        { title: '资质社区', components: qr },
        // { title: '资质库', components: qualificationService }
      ],
      fakeQualificationOptions: [],
      // typeQualificationOptions: [],
      gangAoTai: [810000, 820000, 710000],
      // fakeQualification: [],
      formQualification: [],
      formValue: {
        other: [],
        itemsInfoList: undefined,
        quelificationStatus: 0,
        verifyCode: '',
        contactsPhone: '',
        contactsPerson: '',
        enterpriseName: ''
      },
      fastModal: false
    }
  },
  computed: {
    ...mapState({
      designQualificationOptions:state => state.designQualificationOptions,
      userInfo: state => state.userInfo,
      // fakeQualification: state => state.fakeQualificationOptions,
      qualificationOptions: state => state.typeQualificationOptions,
      vxAreaOptions: state => state.areaOptions
    }),
    formItems() {
      return [
        {
          label: '资质状态',
          type: formType.RADIO,
          value: this.formValue.quelificationStatus,
          name: 'quelificationStatus',
          options: [
            { value: 0, label: '现货' },
            { value: 1, label: '预售' }
          ],
          validate: [
            {
              required: true,
              trigger: 'blur',
              message: '请选择资质状态'
            }
          ]
        },
        {
          label: '企业名称',
          type: formType.INPUT,
          placeholder: '请输入企业名称全称',
          name: 'enterpriseName',
          value: this.formValue.enterpriseName,
          validate: [
            {
              required: true,
              validator: this.isNotHaveNumber
            }
          ]
        },
        {
          label: '资质类别及等级:',
          name: 'itemsInfoList',
          value: this.formValue.itemsInfoList,
          // fakeQualification: this.fakeQualification,
          // qualificationOptions: this.qualificationOptions,
          // component: TreeCheckboxSingleSelect,
          // component: TreeCheckboxSingleSelect,

          type: formType.SINGLECHECKBOKTREE,
          validate: [
            {
              required: true,
              message: '请选择资质类别及等级',
              trigger: 'change'
            }
          ]
        },
        {
          label: '地区',
          type: formType.SELECT,
          placeholder: '请选择注册地区，如四川省',
          name: 'areaCode',
          options: this.areaOptions,
          value: this.formValue.areaCode,
          validate: [
            {
              required: true,
              trigger: 'change',
              message: '请选择注册地区'
            }
          ]
        },
        {
          label: '其他特性',
          type: formType.CHECKBOX,
          name: 'other',
          options: [
            { label: '不可控股', value: 'isHoldingCompany' },
            { label: '包函', value: 'isNeedLetter' }
          ],
          value: this.formValue.other
        },
        {
          label: '联系人',
          type: formType.INPUT,
          value: this.formValue.contactsPerson,
          name: 'contactsPerson',
          validate: [
            {
              required: true,
              message: '请输入联系人姓名',
              trigger: 'blur'
            }
          ],
          placeholder: '请输入联系人姓名'
        },
        {
          label: '手机',
          type: formType.INPUT,
          value: this.formValue.contactsPhone,
          name: 'contactsPhone',
          validate: [
            {
              required: true,
              validator: this.validatePhoneNumber,
              trigger: 'blur'
            }
          ],
          placeholder: '请输入联系人电话'
        },
        {
          label: '验证码',
          type: formType.COMPONENT,
          value: this.formValue.verifyCode,
          name: 'verifyCode',
          component: VerifyCode,
          validate: [
            {
              required: true,
              message: '请输入验证码',
              trigger: 'change'
            }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setFakerQualification',
      'setQualification',
      'setAreaOptions',
      'setShowLoginModal'
    ]),

    isNotHaveNumber(rule, value, callback) {
      const notNum = /^[\u4e00-\u9fa5a-zA-Z]+$/
      if (!value) {
        callback(new Error('请输入企业名称'))
      } else if (notNum.test(value)) {
        callback()
      } else {
        callback(new Error('企业名称仅支持中英文'))
      }
    },
    goToMain() {
      if (this.$route.name === 'qrMain') return
      this.$router.push({
        name: 'qrMain'
      })
    },
    beforeSubmit() {
      const validate = this.$refs.quickForm.formValidator()
      if (validate) {
        this.quickSubmit()
      }
    },
    quickSubmit() {
      const other = {}
      this.formValue.other.forEach(o => {
        other[o] = true
      })
      // console.log(this.fakeQualification)
      const params = {
        ...this.formValue,
        authenFeatureVO: {
          ...other
        },
        channel: 0,
        itemsInfoList: [
          {
            authenItemId: this.formValue.itemsInfoList
            // authenItemName: this.formValue.itemsInfoList.label,
          }
        ]
      }
      this.$http.post('authen/add', params).then(() => {
        this.fastModal = false
        this.$message.success('发布成功', 0.5)
      })
    },
    setVerifyCode(value) {
      this.formValue.verifyCode = value
    },
    showFastModal() {
      this.formValue = {
        other: [],
        itemsInfoList: undefined,
        quelificationStatus: 0,
        verifyCode: '',
        contactsPhone: '',
        contactsPerson: ''
      }
      this.fastModal = true
    },
    changeRouter(key) {
      this.$router.push({
        name: key
      })
    },
    validatePhone(promise) {
      const isPhone = this.$refs.quickForm.formItemValidator('contactsPhone')
      promise.resolve({
        validate: isPhone,
        phone: this.formValue.contactsPhone
      })
    },
    validatePhoneNumber(rule, value, callback) {
      //  const isTelephone = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/
      const isCellPhone = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (!isCellPhone.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    },
    getTypeQualification() {
      this.$http.get('qualification/selQualificationAllByIndustry').then(r => {
        this.setQualification(r)
      })
    },
    getQualification() {
      this.$http.get('qualification/selQualificationAll').then(r => {
        this.fakeQualificationOptions = []
        r.forEach(e => {
          e.qualificationLevels.forEach(q => {
            this.fakeQualificationOptions.push({
              label: e.qualificationName + q.level,
              value: q.id
            })
          })
        })
        this.setFakerQualification(this.fakeQualificationOptions)
      })
    },
    showComponents(key) {
      const hvaeUserId = Cookie.get('userId')
      // const downTime = this.userInfo
      //   ? this.$day(this.userInfo.failureTime).valueOf()
      //   : 0
      // const currentTime = this.userInfo ? this.$day().valueOf() : 0
      const enable = this.userInfo ? this.userInfo.enable : -1
      //过期状态
      // if (currentTime > downTime) {
      //   this.expriedModalShow = true
      //   //  停用
      // } else
      if (!enable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      } else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)
          // this.$router.push({
          //     path: '/login?from=Product'
          // })
        }, 800)
      } else {
        if (this.$route.name === 'Product') {
          this.currentMenu = key
          this.$router.push({
            path: `/product/${key}`
          })
          // this.$addQuery(this.$route, { current: key }, this.$router)
        } else {
          this.$nextTick(() => {
            this.$router.push({
              path: `/product/${key}`
              // query: {
              //   current: key
              // }
            })
            this.currentMenu = key
          })
        }
      }
    },
    showSharePage() {
      this.currentMenu = 5
    },
    showSellPage() {
      this.currentMenu = 6
    },
    getAreaOptions() {
      this.$http.get('regionCode/selList').then(r => {
        this.areaOptions = []
        this.areaData = r
        const gangAoTai = [810000, 820000, 710000]
        // 去除港澳台
        r.forEach(option => {
          if (gangAoTai.includes(option.areaCode)) {
            this.areaOptions.push({
              label: option.areaName,
              value: option.areaCode,
              disabled: true
            })
          } else {
            this.areaOptions.push({
              label: option.areaName,
              value: option.areaCode,
              disabled: false
            })
          }
        })
        this.setAreaOptions(this.areaOptions)
      })
    },
    goBackTop() {
      this.$emit('goBackTop')
    },
    closeExpiredModal() {
      this.expriedModalShow = false
    },
    goToVip() {
      this.goToVipModalShow = false
      this.$router.push({
        name: 'Vip'
      })
    }
  },
  created() {
    // if (!this.$isServer) {
    const currentName = this.$route.name
    if (currentName === 'Product') {
      const currnetKey = this.$route.params.current
      if (currnetKey) {
        this.currentMenu = parseInt(currnetKey, 10)
      }
      // else {
      //   this.$addQuery(this.$route, { current: this.currentMenu }, this.$router)
      // }
    } else {
      this.currentMenu = 3
    }
    // }/
  },
  mounted() {
    const hvaeUserId = Cookie.get('userId')
    if (hvaeUserId) {
      this.getTypeQualification()
      this.getQualification()
      this.getAreaOptions()
      this.$emit('goBackTop')
    }
  }
}
</script>
<style lang="scss" scoped>
.goto-tag {
  width: 100%;
  text-align: center;
}
.product-root {
  height: 100%;
  position: relative;
  min-width: 1200px;

  .product-bg {
    width: 100%;
    height: 530px;
    background-color: #2a7be7;
    padding-top: 215px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .product-main {
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 215px;
    justify-content: flex-start;

    .product-menu {
      display: flex;
      width: 1200px;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      z-index: 1000;

      .menu-item {
        font-size: 22px;
        font-weight: bold;
        color: #f4f5ff;
        opacity: 0.6;
        margin-right: 50px;
        cursor: pointer;
        position: relative;

        &:hover {
          opacity: 1;

          .little-trangle {
            //   display: block;
          }
        }
      }

      .isCurrent {
        opacity: 1;

        .little-trangle {
          display: block;
        }
      }

      .little-trangle {
        display: none;
        position: absolute;
        width: 0px;
        height: 0px;
        border: 15px solid #fff;
        border-left-color: transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        bottom: -30px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }

    .product-component {
      position: relative;
      z-index: 100;
      margin-top: 30px;
    }
  }
}
</style>
