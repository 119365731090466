<template>
  <a-modal
    class="indexPage"
    :visible="isShowActivity"
    :footer="null"
    :width="800"
    :maskClosable="false"
    :centered="true"
    @cancel="setModalVisible"
  >
    <!-- 取消按钮 -->
    <div slot="closeIcon"><img :src="icPopupClose" alt="" /></div>

    <!-- ---------------------------参加活动 进行中----------------------------- -->

    <div v-if="isJoinActive && !activeProgress.isFinish && !activeProgress.doneGrade" class="main-content main-content-two" :style="'background-image:url(' + activityBgcTow + '),linear-gradient(180deg, #F66E35 0%, #F65F52 100%)'">
      <div>
        <h4 class="main-content-title">邀好友助力领<span>30</span>天VIP</h4>
        <!-- 次标题 -->
        <p :style="'background-image:url(' + bgcSubTitle + ')'" class="main-content-subTitle">
          得<span>1500</span>家建企联系电话
        </p>
        <div class="main-content-main">
          <!-- 进度 -->
          <div class="plan-detail">
            <img :src="icJd" alt="">
            <ul>
              <li>已有{{ (activeProgress && activeProgress.doneCount) || 0 }}人为你助力</li>
              <li>还差{{ (activeProgress && activeProgress.badCount) || 0 }}人助力</li>
              <li>得{{ (activeProgress && activeProgress.grade.practical) || 0 }}天VIP会员</li>
            </ul>
          </div>
          <!-- 二维码 -->
          <div :style="'background-image:url(' + bgcCode + ')'" class="code">
            <img :src="wxAppletCode" alt="">
          </div>
        </div>
        <!-- 按钮 -->
        <div class="main-code-text" @click="toVipPage">
          <p>立即升级VIP，解锁更多权益</p>
        </div>
      </div>
    </div>

    <!-- ---------------------------活动中 - 活动到账----------------------------- -->

    <div v-else-if="activityInfo && !activeProgress.isFinish && activeProgress.doneGrade" class="main-content main-content-two" :style="'background-image:url(' + activityBgcTow + '),linear-gradient(180deg, #F66E35 0%, #F65F52 100%)'">
      <div>
        <h4 class="main-content-title">邀好友助力领<span>30</span>天VIP</h4>
        <!-- 次标题 -->
        <p :style="'background-image:url(' + bgcSubTitle + ')'" class="main-content-subTitle">
          得<span>1500</span>家建企联系电话
        </p>
        <div class="main-content-main">
          <!-- 进度 -->
          <div class="plan-detail">
            <img :src="icJd" alt="">
            <ul>
              <li v-if="activeProgress && activeProgress.doneGrade.day == 5">{{ (activeProgress && activeProgress.doneGrade.day) || 0 }}天VIP已到账</li>
              <li v-else>累计{{ (activeProgress && activeProgress.doneGrade.day) || 0 }}天VIP已到账</li>
              <li>只需{{ (activeProgress && activeProgress.badCount) || 0 }}人助力</li>
              <li>再得{{ (activeProgress && activeProgress.grade.practical) || 0 }}天VIP会员</li>
            </ul>
          </div>
          <!-- 二维码 -->
          <div :style="'background-image:url(' + bgcCode + ')'" class="code">
            <img :src="wxAppletCode" alt="">
          </div>
        </div>
        <!-- 按钮 -->
        <div class="main-code-text" @click="toVipPage">
          <p>立即升级VIP，解锁更多权益</p>
        </div>
      </div>
    </div>

    <!-- ---------------------------活动完成----------------------------- -->

    <div v-else-if="isJoinActive && activeProgress.isFinish" class="main-content main-content-two" :style="'background-image:url(' + activityBgcTow + '),linear-gradient(180deg, #F66E35 0%, #F65F52 100%)'">
      <div class="main-content-main">
        <h4 class="main-content-title">邀好友助力领<span>30</span>天VIP</h4>
        <!-- 次标题 -->
        <p :style="'background-image:url(' + bgcSubTitle + ')'" class="main-content-subTitle">
          得<span>1500</span>家建企联系电话
        </p>
        <!-- 进度 -->
        <div class="plan-detail finish">
          <p>恭喜你成功完成本次助力活动</p>
          <p>累计<span>30天VIP</span>已全部到账</p>
        </div>
        <!-- 二维码 -->
        <div :style="'background-image:url(' + bgcCode + ')'" class="code">
          <img :src="wxAppletCode" alt="">
        </div>
      </div>
      <div class="main-code-text" @click="toVipPage">
        <p>立即升级VIP，解锁更多权益</p>
      </div>
    </div>

    <!-- ---------------------------未参加活动----------------------------- -->

    <div v-else class="main-content" :style="'background-image:url(' + activityBgc + '),linear-gradient(180deg, #F66E35 0%, #F65F52 100%)'">
      <h4 class="main-content-title">邀好友助力领<span>30</span>天VIP</h4>
      <p :style="'background-image:url(' + bgcSubTitle + ')'" class="main-content-subTitle">
        得<span>1500</span>家建企联系电话
      </p>
      <div class="main-content-main">
        <div :style="'background-image:url(' + bgcCode + ')'" class="code">
          <img :src="wxAppletCode" alt="">
        </div>
      </div>
      <div class="main-code-text" @click="toVipPage">
        <p>立即升级VIP，解锁更多权益</p>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import activityBgc from '@/assets/activity/activity_bgc.png'
import activityBgcTow from '@/assets/activity/activity_bgc_tow.png'
import icPopupClose from '@/assets/activity/ic_popup_close.png'
import icJd from '@/assets/activity/ic_jd.png'
import bgcSubTitle from '@/assets/activity/bgc_sub_title.png'
import bgcCode from '@/assets/activity/bgc_code.png'
import wxAppletCode from '@/assets/activity/wxApplet_code.png'
export default {
  name: 'ActivityModalShow',
  computed: {
    ...mapState({
      isShowActivity: state => state.isShowActivity
    }),

    // 是否参加了活动
    isJoinActive(){
      return this.activityInfo
    },

    // 活动弹窗所需要的数据
    activeProgress(){
      const doneCount = this.activityInfo && this.activityInfo.totalClick || 0  // 已完成的点击量
      // const doneCount = 60  // 已完成的点击量
      const result = this.activityInfo && this.progressDetailData(doneCount)

      return result
    }

  },
  data() {
    return {
        wxAppletCode,
        bgcSubTitle,
        bgcCode,
        activityInfo: {},
        icPopupClose,
        icJd,
        activityBgc,
        activityBgcTow,
        activityContent: {},
        gradeData: [
            {
              count: 10,  // 点击量
              day: 5, // 会员天数
              practical: 5 // 实际天数
            },
            {
              count: 20,  // 点击量
              day: 10, // 会员天数
              practical: 5 // 实际天数
            },
            {
              count: 30,  // 点击量
              day: 15, // 会员天数
              practical: 5 // 实际天数
            },
            {
              count: 40,  // 点击量
              day: 20, // 会员天数
              practical: 5 // 实际天数
            },
            {
              count: 50,
              day: 25,
              practical: 5 // 实际天数
            },
            {
              count: 60,
              day: 30,
              practical: 5 // 实际天数
            },
        ]
    }
  },
  methods: {
    ...mapMutations(['setShowActivityModal']),
    setModalVisible() {
      this.setShowActivityModal(false)
    },
    // 获取分享活动
    getActivityInfo(){
        this.$http.get('wx/activity/get').then((res) => {
            this.activityInfo = res || null
        })
        .catch(err => {
            this.activityInfo = null
        })
    },

    // 跳转vip
    toVipPage(){
      this.$router.push('/vip')
      this.setModalVisible()
    },

    progressDetailData(count = 20){
      const doneCount = count > 60 ? 60 : count
      let grade = {} // 等级信息
      let doneGrade = {} // 已完成的等级信息
      let isFinish = false // 是否完成了活动
      let badCount = 0 // 差多少点击量到下一等级
      const gradeData = this.gradeData
      if (doneCount >= gradeData[gradeData.length - 1].count) isFinish = true
      if (isFinish) {
          grade = gradeData[gradeData.length - 1]
      } else {
          grade = gradeData.find(item => doneCount < item.count)
          doneGrade = gradeData.find(item => doneCount == item.count) || null
          badCount = grade.count - doneCount
      }

      return {
        grade,
        isFinish,
        badCount,
        doneCount,
        doneGrade
      }
    },
  },
/*  watch: {
    isShowActivity(n){
      if (n) {
        this.getActivityInfo()
      }
    }
  },*/
  created() {
    // this.getActivityInfo()
  },
}
</script>

<style scoped lang="scss">
img{
  max-width: 100%;
  max-height: 100%;
}

ul, li, p{
  margin: 0;
  padding: 0;
}

.indexPage /deep/ .ant-modal-body {
  padding: 0 !important;
}

.indexPage /deep/ .ant-modal-content {
    width: 480px;
    height: 520px;
    margin: 0 auto;
    background: rgba($color: #fff, $alpha: 0);
    box-shadow: none;
}

.indexPage /deep/ .ant-modal-close {
    position: absolute;
    top: -30px;
    right: -40px;
}

.indexPage /deep/ .ant-modal-close-x {
    display: block;
    width: 31px;
    height: 31px;
    font-size: 16px;
    font-style: normal;
    line-height: 27px;
    text-align: center;
    text-transform: none;
    text-rendering: auto;
}

.indexPage /deep/ .ant-modal-mask{
    z-index: 1007 !important;
}
.indexPage /deep/ .ant-modal-wrap{
    z-index: 1007 !important;
}

.indexPage{
  // font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 500;
}

.main-content {
  width: 480px;
  height: 468px;
  background-color: linear-gradient(180deg, #F66E35 0%, #F65F52 100%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 10px;

  .main-content-title,
  .main-content-subTitle {
    font-size: 32px;
    color: #FFFFFF;
    margin: 17px auto 18px;
    text-align: center;
    letter-spacing: 4px;

    span{
      font-size: 48px;
      font-weight: 600;
    }
  }

  .main-content-subTitle {
    width: 360px;
    height: 64px;
    line-height: 64px;
    font-size: 24px;
    margin: 0 auto;
    color: #FFFFFF;
    letter-spacing: unset;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-weight: 400;

    span{
      font-size: 32px;
      font-weight: 500;
    }
  }

  .main-content-subTitle2{
    font-size: 18px;
    color: #FFFFFF;
    text-align: center;
    margin: 23px 0;
  }

  .main-content-main {
    width: 100%;

    .plan-detail{
      width: 420px;
      height: 88px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 4px;
      border: 1px dashed #F67847;
      margin: 23px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      >img{
        width: 273px;
        height: 18px;
      }

      >ul{
        display: flex;

        li{
          width: 145px;
          margin-top: 8px;
          font-size: 16px;
          color: #D67300;
          text-align: center;
          &:nth-child(1){
            color: #F67847;
          }
          &:nth-child(2){
            width: 120px;
          }
        }
      }
    }

    .finish{
      margin-top: 20px;
      >p{
        font-size: 20px;
        color: #D67300;

        span{
          color: #FF4040;
          font-size: 24px;
          font-weight: 600;
        }
      }
    }

    .code{
      width: 176px;
      height: 176px;
      border-radius: 50%;
      margin: 20px auto 0;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;

      img{
        width: 120px;
        height: 120px;
        overflow: hidden;
        border-radius: 50%;
      }
    }
  }

  .main-code-text {
    margin: 10px auto 32px;
    width: 416px;
    height: 56px;
    line-height: 56px;
    background: linear-gradient(180deg, #FBF379 0%, #FEC335 94%, #FEC234 96%, #FEC031 100%);
    box-shadow: 0px 0px 10px 0px #FB3201;
    border-radius: 28px;
    font-size: 16px;
    color: #700909;
    text-align: center;
    cursor: pointer;
  }
}

.main-content-two{
    height: 588px;
}
</style>