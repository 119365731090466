<template>
    <div class="block-page">
        <img :src="imageSrc" :width="imageWidth" :height="imageHeight" class="block-image" />
        <p v-if="titleText" class="block-title">{{ titleText }}</p>
        <p v-if="text" class="block-text">{{ text }}</p>
        <slot name="btSlot"></slot>
    </div>
</template>
<script lang="ts">
import Vue from 'vue'
import noData from '@/assets/no_data.png'
export default Vue.extend({
    name: 'block-data',
    data() {
        return {
            noData
        }
    },
    props: {
        imageSrc: {
            type: String,
            default: noData
        },
        imageWidth: {
            type: [Number, String],
            default: '100%'
        },
        imageHeight: {
            type: [Number, String],
            default: '100%'
        },
        text: {
            type: String,
            default: '默认文字'
        },
        titleText: {
            type: String,
            default: '默认标题'
        }
    }
})
</script>
<style lang="scss" scoped>
.block-page {
    background-color: #ffffff;
    text-align: center;
    .block-image {
    }
    .block-title {
        margin-top: 10px;
        font-size: 20px;
        color: rgba($color: #000000, $alpha: 0.85);
        font-weight: bold;
    }
    .block-text {
        margin-top: 13px;
        padding-bottom: 20px;
        color: rgba($color: #000000, $alpha: 0.8);
        font-size: 14px;
    }
}
</style>
