<template>
    <div class="certification-page">
        <div>
            <a-button @click="backFrom" v-if="channel === '2'" type="link" icon="left">
                返回
            </a-button>
        </div>
        <div class="second-title" v-if="channel === '1'">
            <p class="isActive"><a-icon type="form" class="have-rt-margin" />填写出售资质信息</p>
            <span class="title-tips"> （信息填写越完整，转让速度越快，成功率越大！）</span>
        </div>
        <!-- <div class="sub-title">企业认证信息</div> -->
        <!-- <div class="back-btn">
      <a-button icon="left" @click="goBack" type="link">返回</a-button>
    </div> -->
        <div>
            <div class="certifi-form" v-if="!isSubmit">
                <div class="warning">
                    请注意：上传出售该资质的相关资料，平台会进行资源的真实性审核。审核通过后，该资质会通过小牛优选发布，以此来提高产品的曝光并增加购买客户的信任度。
                </div>
                <form-index
                    ref="formIndex"
                    :labelCol="{ span: 4 }"
                    v-model="formValue"
                    @uploadSuccess="uploadSuccess"
                    @addMoreQualification="addMoreQualification"
                    @setVerifyCode="setVerifyCode"
                    @validatePhone="validatePhone"
                    @removeImage="removeImage"
                    @removeThisQualification="removeThisQualification"
                    :formItems="formItems"
                ></form-index>
                <a-row type="flex">
                    <a-col :span="4"> </a-col>
                    <div class="submit-certification">
                        <a-button
                            @click="submitCertification"
                            class="have-rt-margin"
                            type="primary"
                            >{{ id ? '重新提审' : '立即发布' }}</a-button
                        >
                        <a-button @click="backToMySell">取消</a-button>
                        <div class="tips" v-if="channel === '1'">
                            提示：发布审核成功之后即为小牛优选发布，默认展示时间为30天。中途不需要出售该资质，可在个人中心
                            - 我出售的资质，操作下架资源
                        </div>
                    </div>
                </a-row>
            </div>
            <!-- <div v-else>
        <block-data
          :imageWidth="300"
          text="企业认证审核一般耗时1-3个工作日"
          titleText="企业认证已提交，请等待审核"
        ></block-data>
      </div> -->
        </div>

        <image-preview
            @closePrview="closePrview"
            :imageUrl="priviewUrl"
            v-if="showPriview"
        ></image-preview>
    </div>
</template>

<script>
import { Row, Col } from 'ant-design-vue'
import formType from '@/components/Form/formType'
import formIndex from '@/components/Form/index.vue'
import uploadImage from '@/components/Form/ImageUpload'
import BlockData from '@/components/BlockData'
import failure from '@/assets/image/check/failure.png'
import pass from '@/assets/image/check/pass.png'
import ing from '@/assets/image/check/ing.png'
import imagePreview from '@/components/ImagePreview'
import qualificationSelect from '@/components/QualificationSelect'
// import { universalModule } from '@/store/modules/universal'
import VerifyCode from '@/components/verifyCode.vue'
import { mapState } from 'vuex'

export default {
    name: 'certification',
    components: {
        formIndex,
        aRow: Row,
        aCol: Col,
        BlockData,
        imagePreview
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            fakeQualification: state => state.fakeQualificationOptions,
            qualificationOptions: state => state.typeQualificationOptions,
            vxAreaOptions: state => state.areaOptions
        }),
        pageTitle() {
            return this.isEdit ? '企业认证' : '企业认证详情'
        },
        formItems() {
            const items = [
                {
                    label: '资质状态',
                    type: formType.RADIO,
                    options: [
                        {
                            label: '现货',
                            value: 0
                        },
                        {
                            label: '预售',
                            value: 1
                        }
                    ],
                    name: 'quelificationStatus',
                    value: this.formValue.quelificationStatus,
                    validate: [
                        {
                            required: true,
                            trigger: 'change',
                            message: '请选择资质状态'
                        }
                    ]
                },
                {
                    label: '资质类别及等级',
                    type: formType.COMPONENT,
                    value: this.formValue.itemsInfoList,
                    name: 'itemsInfoList',
                    component: qualificationSelect,
                    list: this.qualificationList,
                    validate: [
                        {
                            required: true,
                            trigger: 'change',
                            message: '请选择资质类别及等级'
                        }
                    ]
                },
                {
                    label: '企业名称',
                    type: formType.INPUT,
                    placeholder: '请输入企业名称全称',
                    name: 'enterpriseName',
                    value: this.formValue.enterpriseName,
                    validate: [
                        {
                            required: true,
                            validator: this.isNotHaveNumber
                        }
                    ]
                },
                {
                    label: '注册地区',
                    type: formType.SELECT,
                    placeholder: '请选择注册地区，如四川省',
                    name: 'areaCode',
                    options: this.areaOptions,
                    value: this.formValue.areaCode,
                    validate: [
                        {
                            required: true,
                            trigger: 'change',
                            message: '请选择注册地区'
                        }
                    ]
                },
                {
                    label: '营业执照',
                    component: uploadImage,
                    type: formType.COMPONENT,
                    style: { width: '200px', height: '200px' },
                    id: 'businessLicenseUrl',
                    name: 'businessLicenseUrl',
                    value: this.formValue.businessLicenseUrl,
                    default: this.businessLicenseUrl,
                    show: this.businessPhotoShow,
                    size: 5 * 1024 * 1024,
                    tips:
                        '仅支持JPG、JPEG和PNG格式，大小不超过5M，文字清晰可辩，企业名称必须与您填写的企业名称一致',
                    length: 1,
                    validate: [
                        {
                            required: true,
                            trigger: 'blur',
                            message: '请上传营业执照'
                        }
                    ]
                },
                {
                    label: '证书有效期',
                    name: 'effectiveDate',
                    type: formType.MONTHRANGE,
                    value: this.formValue.effectiveDate,
                    mode: 'month',
                    placeholder: '请选择证书有效期',
                    validate: [
                        {
                            required: true,
                            trigger: 'change',
                            message: '请选择证书有效期'
                        }
                    ]
                },
                {
                    label: '资质证书',
                    component: uploadImage,
                    type: formType.COMPONENT,
                    style: { width: '200px', height: '200px' },
                    id: 'itemsImageUrlList',
                    length: 10,
                    size: 5 * 1024 * 1024,
                    name: 'itemsImageUrlList',
                    value: this.formValue.itemsImageUrlList,
                    default: this.itemsImageUrlList,
                    show: this.quaPhotoShow,
                    validate: [
                        {
                            required: true,
                            trigger: 'blur',
                            message: '请上传企业资质证书'
                        }
                    ],
                    tips: '为增加信息可信度，可上传资质证书图片（马赛克图片）'
                },
                {
                    label: '更多特性',
                    type: formType.CHECKBOX,
                    name: 'other',
                    options: this.otherOptions,
                    value: this.formValue.other
                },
                {
                    label: '备注说明',
                    type: formType.TEXTAREA,
                    name: 'showDescribe',
                    value: this.formValue.showDescribe,
                    placeholder: ' 请输入详细备注说明，不能出现手机号及完整的公司名称'
                },
                {
                    label: '联系人',
                    type: formType.INPUT,
                    value: this.formValue.contactsPerson,
                    name: 'contactsPerson',
                    validate: [
                        {
                            required: true,
                            message: '请输入联系人姓名',
                            trigger: 'blur'
                        }
                    ],
                    placeholder: '请输入联系人姓名'
                },
                {
                    label: '手机',
                    type: formType.INPUT,
                    value: this.formValue.contactsPhone,
                    name: 'contactsPhone',
                    validate: [
                        {
                            required: true,
                            validator: this.validatePhoneNumber,
                            trigger: 'blur'
                        }
                    ],
                    placeholder: '请输入联系人电话'
                }
            ]
            if (this.channel === '1') {
                items.push({
                    label: '验证码',
                    type: formType.COMPONENT,
                    value: this.formValue.verifyCode,
                    name: 'verifyCode',
                    component: VerifyCode,
                    validate: [
                        {
                            required: true,
                            message: '请输入验证码',
                            trigger: 'change'
                        }
                    ]
                })
            }
            //       const contactsPerson =
            // items.splice(items.length - 2, 0, contactsPerson)
            return items
        },
        areaOptions() {
            const options = []
            this.vxAreaOptions.forEach(e => {
                if (this.gangAoTai.includes(e.value)) {
                    e.disabled = true
                }
                options.push(e)
            })
            return options
        }
    },
    data() {
        return {
            businessPhotoShow: false,
            quaPhotoShow: false,
            gangAoTai: [810000, 820000, 710000],
            formValue: {
                itemsInfoList: undefined,
                verifyCode: '',
                other: [],
                showDescribe: '',
                quelificationStatus: '',
                areaCode: [],
                enterpriseName: '',
                businessLicenseUrl: '',
                socialCreditCode: '',
                legalPerson: '',
                contactsPhone: '',
                contactsPerson: '',
                itemsIdList: '',
                itemsImageUrlList: [],
                effectiveDate: ''
            },
            otherOptions: [
                { label: '财务纠纷', value: 'isFinanceDispute' },
                { label: '法律纠纷', value: 'isLawDispute' },
                { label: '行政处罚记录', value: 'isPoliticsPenalize' },
                { label: '在建工程', value: 'isBuildingProject' },
                { label: '挂靠人员', value: 'isHangingClose' },
                { label: '安全生产许可证', value: 'isSecurityLicence' },
                { label: '包函', value: 'isNeedLetter' },
                { label: '不可控股', value: 'isHoldingCompany' }
            ],
            reSendId: 0,
            channel: '1',
            statusText: ['现货', '预售'],
            qualificationList: [{ qualificationId: undefined }],
            companyName: '',
            priviewUrl: '',
            showPriview: false,
            statusImage: '',
            detail: {},
            id: '',
            isSubmit: false,
            isEdit: true,
            businessLicenseUrl: [],
            itemsImageUrlList: [],
            legalImageUrl: ''
        }
    },
    methods: {
        isNotHaveNumber(rule, value, callback) {
            const notNum = /^[\u4e00-\u9fa5a-zA-Z]+$/
            if (!value) {
                callback(new Error('请输入企业名称'))
            } else if (notNum.test(value)) {
                callback()
            } else {
                callback(new Error('企业名称仅支持中英文'))
            }
        },
        backToMySell() {
            if (this.$route.query.from) {
                this.$router.replace({
                    name: this.$route.query.from,
                    query: {
                        current: 3
                    }
                })
            } else {
                this.$router.go(-1)
                // this.$router.push({
                //     name: 'Product',
                //     query: {
                //         current: '3'
                //     }
                // })
            }
        },
        backFrom() {
            this.$router.push({
                name: this.$route.query.from
            })
        },
        removeThisQualification(index) {
            this.qualificationList.splice(index, 1)
        },
        validatePhone(promise) {
            const isPhone = this.$refs.formIndex.formItemValidator('contactsPhone')
            promise.resolve({
                validate: isPhone,
                phone: this.formValue.contactsPhone
            })
        },
        validatePhoneNumber(rule, value, callback) {
            //  const isTelephone = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/
            const isCellPhone = /^1(3|4|5|6|7|8|9)\d{9}$/
            if (!isCellPhone.test(value)) {
                callback(new Error('请输入正确的手机号码'))
            } else {
                callback()
            }
        },
        addMoreQualification() {
            this.qualificationList.push({ qualificationId: undefined })
        },
        editThisInfo() {
            this.isEdit = true
            this.formValue.quelificationStatus = this.detail.quelificationStatus // 资质状态
            this.formValue.areaCode = this.detail.areaCode // 地区
            this.formValue.enterpriseName = this.detail.enterpriseName // 企业名称
            this.formValue.socialCreditCode = this.detail.socialCreditCode // 信用代码
            this.formValue.legalPerson = this.detail.legalPerson // 法人

            // this.businessLicenseUrl = this.detail.businessLicenseUrl.split()
            this.itemsImageUrlList = this.detail.itemsImageUrlList.map(e => {
                return e.upLoadImageUrl
            })
            this.qualificationList = this.detail.itemsInfoList.map(e => {
                return { qualificationId: e.authenItemId }
            })
        },
        closePrview() {
            this.showPriview = false
            this.priviewUrl = ''
        },
        showImagePreview(url) {
            this.showPriview = true
            this.priviewUrl = url
        },
        validatorIdCardNo(rule, value, callback) {
            const isIdNumber = /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/
            if (!isIdNumber.test(value)) {
                callback(new Error('请输入正确的企业法定代表人身份证号码'))
            } else {
                callback()
            }
        },
        setVerifyCode(code) {
            this.formValue.verifyCode = code
        },
        uploadSuccess(image) {
            // console.log(image)
            this[image.name].push(image.url)
        },
        removeImage(image) {
            this[image.name].splice(image.index, 1)
        },
        goBack() {
            // if (window.ipcRenderer) {
            //   const ipcRenderer = window.ipcRenderer
            //   // console.log(ipcRenderer)
            //   ipcRenderer.send('goBack')
            // }
            const isFromTable = !!parseInt(this.$route.query.isFromTable, 10)
            const isFromHomepage = !!parseInt(this.$route.query.isFromHomepage, 10)
            if (isFromTable) {
                this.$router.push({
                    name: 'shared'
                })
            } else if (isFromHomepage) {
                this.$router.replace({
                    name: 'receipt'
                })
            } else {
                this.$router.replace({
                    name: 'resources'
                })
            }

            //  this.$router.go(-1)
        },
        submitCertification() {
            // console.log(this.qualificationList)
            this.formValue.itemsImageUrlList = this.itemsImageUrlList.join()
            if (this.qualificationList[0].qualificationId) {
                this.formValue.itemsInfoList = this.qualificationList.map(e => {
                    return {
                        authenItemId: e.qualificationId,
                        authenItemName: ''
                    }
                })
            } else {
                this.formValue.itemsInfoList = undefined
            }
            this.formValue.businessLicenseUrl = this.businessLicenseUrl
                .map(e => {
                    return e
                })
                .join()

            const itemsImageUrlList = this.itemsImageUrlList.map(e => {
                return {
                    effectiveDate: this.formValue.effectiveDate,
                    imageFileName: '',
                    upLoadImageUrl: e
                }
            })
            const others = {}
            this.formValue.other.forEach(o => {
                others[o] = true
            })
            const validate = this.$refs.formIndex.formValidator()

            const params = {
                quelificationStatus: this.formValue.quelificationStatus,
                itemsInfoList: this.formValue.itemsInfoList,
                enterpriseName: this.formValue.enterpriseName,
                areaCode: this.formValue.areaCode,
                businessLicenseUrl: this.formValue.businessLicenseUrl,
                itemsImageUrlList,
                authenFeatureVO: {
                    ...others
                },
                showDescribe: this.formValue.showDescribe,
                contactsPhone: this.formValue.contactsPhone,
                contactsPerson: this.formValue.contactsPerson,
                verifyCode: this.formValue.verifyCode,
                channel: this.channel
            }

            // const validate = this.$refs.formIndex.formValidator()
            if (this.id) {
                params.id = this.id
            }

            if (validate) {
                this.$http
                    .post('/authen/add', params)
                    .then(() => {
                        this.$message.success('发布成功', 0.5)
                        this.backToMySell()
                        //  this.$router.go(-1)
                        // if (this.channel === '2') {
                        //     this.$router.push({
                        //         name: 'enterpriseCertification'
                        //     })
                        // } else {
                        //     this.$router.push({
                        //         name: 'mySell',
                        //         query: {
                        //             pId: '4'
                        //         }
                        //     })
                        // }
                    })
                    .catch(err => {
                        if (err.code === '5010') {
                          this.$message.warn('出售与求购相关使用次数已用完', 0.5)
                        }
                    })
            }
        },
        getCompanyCertificationDetail() {
            // console.log('getCompanyCertificationDetail')
            this.$http
                .get('authen/get/deteils', { id: this.id })
                .then(res => {
                    // console.log(res)
                    this.formValue.enterpriseName = res.enterpriseName
                    this.formValue.areaCode = res.areaCode
                    this.formValue.contactsPerson = res.contactsPerson
                    this.formValue.quelificationStatus = res.quelificationStatus
                    this.formValue.itemsInfoList = res.itemsInfoList.map(e => {
                        return { label: e.authenItemName, value: e.authenItemId }
                    })
                    this.qualificationList = res.itemsInfoList.map(e => {
                        return { qualificationId: e.authenItemId }
                    })

                    this.formValue.contactsPhone = res.contactsPhone

                    this.formValue.showDescribe = res.showDescribe
                    this.otherOptions.forEach(o => {
                        if (res.authenFeatureVO[o.value]) {
                            this.formValue.other.push(o.value)
                        }
                    })
                    this.formValue.effectiveDate = res.itemsImageUrlList[0]
                        ? res.itemsImageUrlList[0].effectiveDate
                        : ''
                    this.itemsImageUrlList = res.itemsImageUrlList.map(img => {
                        return img.upLoadImageUrl
                    })
                    this.businessLicenseUrl = [res.businessLicenseUrl]
                    this.businessPhotoShow = true
                    this.quaPhotoShow = true
                })
                .catch(() => {
                    this.quaPhotoShow = true
                    this.businessPhotoShow = true
                })
        }
    },
    async mounted() {
        this.channel = this.$route.query.channel
        this.id = this.$route.query.reSendId
        this.$nextTick(() => {
            if (this.id) {
                this.getCompanyCertificationDetail()
            } else {
                this.quaPhotoShow = true
                this.businessPhotoShow = true
            }
        })
    }
}
</script>

<style lang="scss" scoped>
.certification-page {
    width: 1200px;
    // border-radius: 0 4p;
    // padding-top: 18px;
    background-color: #ffffff !important;
    padding: 25px;
    overflow: hidden;
    position: relative;
    .title-tips {
        margin-left: 10px;
        align-self: flex-end;
        height: 40px;
        line-height: 40px;
        font-size: 12px;
    }
    .back-btn {
        position: absolute;
        left: 0;
        top: 65px;
        font-size: 18px;
    }
    .certification-title {
        font-size: 18px;
        height: 50px;
        line-height: 50px;
        margin: 0 -24px;
        padding-left: 24px;
        background-color: #f2f2f2;
    }
    .sub-title {
        font-size: 14px;
        margin-top: 16px;
    }
    .certifi-form {
        margin-top: 20px;
        padding-top: 40px;
        padding-bottom: 40px;
        width: 1000px;
        position: relative;
        .warning {
            top: 0;
            left: 30px;
            width: 1200px;
            position: absolute;
            font-weight: bold;
            color: #ff8800;
        }
    }
    .submit-certification {
        margin-top: 20px;

        .tips {
            font-size: 13px;
            margin-top: 10px;
        }
    }

    .check-remarks {
        width: fit-content;
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        padding: 0 10px;
        color: #ff8800;
        background: #f2f2f2;
        border-radius: 11px;
        margin-bottom: 20px;
        margin-left: 22px;
    }
    .success {
        color: #4ebf56;
    }
    .resubmit-btn {
        margin: 20px 0 20px 22px;
    }
}
</style>
