<template>
  <div class="shipment-page">
    <shipment-filter
        ref="filter"
        @clickFindBtns="clickFindBtns"
        @setFilter="getFilter"
    ></shipment-filter>
    <div class="table-content">
      <div v-show="dataLoading" class="loading">
        <a-spin size="large" tip="加载中......"/>
      </div>
      <div id="tips" class="tips">
        小牛建讯为您查询出
        <span class="primary-text">{{ tipText }}</span>
        家符合条件的企业（电话数据经大数据实时演算解析，并经启发式算法优化排序显示）
      </div>
      <div class="filter-btns">
        <a-select
            v-model="riskLevelFilter"
            allowClear
            class="risk-filter-btn"
            placeholder="风险等级"
            @change="setRiskLevelFilter"
        >
          <a-select-option
              v-for="(item, key) in riskOption"
              :key="key"
              :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
        <a-button
            :class="{
            noRisk: establishmentDateSort !== 2,
            haveRisk: establishmentDateSort === 2
          }"
            class="have-rt-margin"
            @click="setEstablishmentDateSort"
        >
          成立时间
          <a-icon
              v-if="establishmentDateSort"
              :type="establishmentDateSort === 2 ? 'arrow-down' : 'arrow-up'"
          />
        </a-button>
        <a-button
            :class="
            safeCertificationValue[safeCertificationIndex].value !== undefined
              ? 'haveRisk'
              : 'noRisk'
          "
            @click="changeSafeCertificate"
        >
          {{ safeCertificationValue[safeCertificationIndex].label }}
        </a-button>
        <a-button
            v-if="showWatchBtn"
            :disabled="isSuperAdmin"
            class="special-btn"
            icon="eye"
            @click="receiveAllList"
        >本页电话
        </a-button>
      </div>
      <div v-if="total > 0">
        <div v-for="(row, index) in tableData" :key="index">
          <table-card
              v-if="row.isHaveQualification"
              :key="index"
              :isClicked="findClickedItem(row.enterpriseId)"
              :row="row"
              @goToCompanyDetail="goToCompanyDetail"
              @goToCompanyRisk="goToCompanyRisk"
              @phoneIsNotTurnOn="phoneIsNotTurnOn"
              @phoneIsTurnOn="phoneIsTurnOn"
              @receiveThisItem="receiveThisItem"
              @showSharQrCode="showSharQrCode"
          ></table-card>
          <simple-card
              v-if="!row.isHaveQualification"
              :key="index"
              :isClicked="findClickedItem(row.enterpriseId)"
              :row="row"
              @goToCompanyDetail="goToCompanyDetail"
              @goToCompanyRisk="goToCompanyRisk"
              @phoneIsNotTurnOn="phoneIsNotTurnOn"
              @phoneIsTurnOn="phoneIsTurnOn"
              @receiveThisItem="receiveThisItem"
              @showSharQrCode="showSharQrCode"
          ></simple-card>
        </div>
        <div class="card-pagination">
          <a-pagination
              v-model="pageNum"
              :pageSize.sync="pageSize"
              :showQuickJumper="true"
              :showSizeChanger="false"
              :showTotal="
              () => {
                return false
              }
            "
              :total="total"
              @change="tableChange"
              @showSizeChange="showSizeChange"
          ></a-pagination>
          <a-button v-if="total > pageSize" class="fake-btn">确定</a-button>
        </div>
        <div
            v-if="softVersion === 'V_FREE_TEMP'"
            :style="'background-image:url(' + freeBg + ')'"
            class="free-vip-item"
        >
          <div class="free-main-title">如需查看更多数据，请升级至VIP版本</div>
          <div class="free-sub-title">
            如有疑问，请联系客服，免费咨询热线：13438870357
          </div>
          <div class="free-vip-btn" @click="goToVipPage">去升级</div>
        </div>
      </div>

      <block-data
          v-else
          :imageSrc="notFind"
          :text="''"
          :titleText="''"
      ></block-data>
      <div class="tips">
        出货查询说明：<br/>
        1.
        来源：该数据源于中国住房和城乡建设部、各省级一体化平台、国家企业信用信息公示系统、中国执行网<br/>
        2.
        出货查询，企业有资质的无资质的查询方式不一样，有资质的企业可以选择相关性推荐的资质，有这类资质的企业购买意向度更高<br/>
        3. 相同查询条件，同一公司所有业务人员查询到的结果，列表展示一致
      </div>
    </div>

    <qr-modal ref="qr" :url="qrCode"></qr-modal>
  </div>
</template>

<script>
import freeBg from '@/assets/tableItem/free_bg.png'
import {Pagination, Drawer, Spin, Modal, Select} from 'ant-design-vue'
import notFind from '@/assets/image/no_date_block.png'
import BlockData from '@/components/BlockData.vue'
import qrModal from '@/components/qrCode.vue'
import {mapState, mapMutations} from 'vuex'

import shipmentFilter from './ShipmentComponents/Filter.vue'
import TableCard from './ShipmentComponents/TableCard.vue'
import SimpleCard from './ShipmentComponents/SimpleCard.vue'

export default {
  name: 'receipt',
  components: {
    shipmentFilter,
    TableCard,
    aDrawer: Drawer,
    aSpin: Spin,
    aPagination: Pagination,
    SimpleCard,
    BlockData,
    qrModal,
    aSelect: Select,
    aSelectOption: Select.Option
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      isListQuery: state => state.isListQuery
    }),
    softVersion() {
      return this.userInfo.buyVersion
    },

  },
  watch: {
    isListQuery: {
      handler(val) {
        if (val) {
          this.$refs.filter.getFilterHistory()
          this.setListQuery(false)
        }
      },
    }
  },
  data() {
    return {
      freeBg,
      dataLoading: false,
      showWatchBtn: true,
      companyDetailDrawer: false,
      qrCode: '',
      notFind,
      totalNum:0,
      total: 0,
      tipText: '',
      isSuperAdmin: false,
      haveSafeCertificate: false,
      tableData: [],
      filter: {},
      establishmentDateSort: 0,
      riskSort: 1,
      pageNum: 1,
      haveQualification: false,
      safeCertificationIndex: 0,
      safeCertificationValue: [
        {value: undefined, label: '有/无安许证'},
        {value: true, label: '有安许证'},
        {value: false, label: '无安许证'}
      ],
      pageSize: 10,
      clickItems: [],
      riskLevelFilter: undefined,
      riskOption: [
        {label: '无风险', value: '0'},
        {label: '低风险', value: '1'},
        {label: '中风险', value: '2'},
        {label: '高风险', value: '3'}
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setListQuery',
      'setQualificationOptions',
      'setEnterpriseInfo',
      'changeTurnPhoneStatus',
      'setATurnOnDefaultQua',
      'changeNotTurnPhoneStatus',
      'setPageHandleFunction',
      'setNotPhoneCountModalShow',
      'setWatchPhoneModalShow',
      'setFreeOverDateModalShow',
      'setRowQuaStatus',
      'setShowActivityModal'
    ]),
    setPhoneToFilter(phone) {
      this.$refs.filter.onlyPhone(phone)
      this.companyDetailDrawer = false
    },
    setLegalPersonToFilter(person) {
      this.$refs.filter.onlyKeyword(person)
      this.companyDetailDrawer = false
    },
    clickFindBtns() {
      // this.pageNum = 1
      this.riskSort = 1
      this.riskLevelFilter = undefined
      this.establishmentDateSort = 0
      this.safeCertificationIndex = 0
      this.tableChange(1, 10)
    },
    closeCompanyDetailDrawer() {
      this.$refs.companyDrawer.setBasicInfoReback()
      this.companyDetailDrawer = false
    },
    showSharQrCode(item) {
      if (this.isSuperAdmin) {
        return
      }
      this.$http
          .get('enterpriseClue/shareEnterpriseTime', {
            enterpriseId: item.enterpriseId,
            userId: this.userInfo.id
          })
          .then(res => {
            const href = window.location.href
            const find = href.indexOf('#')
            const host = href.substr(0, find + 1)
            const currentDate = new Date().getTime()
            // encodeURIComponent(
            // const link =
            //   VUE_APP_TYPE === 'online'
            //     ? 'http://www.xnjx666.com:9000/index.html#'
            //     : host
            const link = 'http://www.xnjx666.com:9000/index.html#'

            const pageUrl = `${link}/enterprise/share?enterpriseId=${item.enterpriseId}&dateTime=${currentDate}&uuid=${res}`
            // )
            const url =
                'http://www.xnjx666.com/shareIframe.html?src=' +
                encodeURIComponent(pageUrl)
            this.qrCode = url
            console.log(link)
            this.$refs.qr.showModal()
          })
    },
    // 接通跟进
    phoneIsTurnOn(row) {
      this.setEnterpriseInfo({...row, clueSources: 2})
      this.setTurnDetaultQua(row)
      this.changeTurnPhoneStatus(true)
      this.setPageHandleFunction(this.afterTurnOnHandle)
    },
    // 拨打未接通
    phoneIsNotTurnOn(row) {
      this.setEnterpriseInfo({...row, clueSources: 2})
      this.changeNotTurnPhoneStatus(true)
      this.setPageHandleFunction(this.afterTurnOnHandle)
    },
    afterTurnOnHandle() {
      this.getSalesList()
    },
    findClickedItem(enterpriseId) {
      // 判断有没有点过
      const have = this.clickItems.find(c => c === enterpriseId)
      return have
    },
    goToCompanyDetail(item) {
      localStorage.removeItem('enterpriseItem')
      const isNotHavePhone = item.phoneNumDisplay.indexOf('暂无') > -1
      // console.log(isNotHavePhone)
      // if (!this.isNotSpecialProvince) {
      //     return
      // }
      // if (!this.findClickedItem(item.enterpriseId)) {
      //     this.clickItems.push(item.enterpriseId)
      // }
      this.setTurnDetaultQua(item)
      this.setEnterpriseInfo({
        ...item,
        clueSources: 2,
        isNotHavePhone
      })
      this.$router.push({
        path: `/enterpriseDetail/${item.enterpriseId}/2/0/0`
        // query: {
        //   id: item.enterpriseId,
        //   clueSources: 2,
        //   isFromClient: 0
        // }
      })
    },
    goToCompanyRisk(item) {
      localStorage.removeItem('enterpriseItem')
      const isNotHavePhone = item.phoneNumDisplay.indexOf('暂无') > -1
      // console.log(isNotHavePhone)
      // if (!this.isNotSpecialProvince) {
      //     return
      // }
      // if (!this.findClickedItem(item.enterpriseId)) {
      //     this.clickItems.push(item.enterpriseId)
      // }
      this.setTurnDetaultQua(item)
      this.setEnterpriseInfo({
        ...item,
        clueSources: 2,
        isNotHavePhone
      })
      this.$router.push({
        path: `/enterpriseDetail/${item.enterpriseId}/2/0/1`
        // query: {
        //   id: item.enterpriseId,
        //   clueSources: 2,
        //   isFromClient: 0,
        //   risk: 1
        // }
      })
    },
    setTurnDetaultQua(row) {
      let tableItemDefauletQua = []
      if (this.haveQualification) {
        if (this.filter.qualificationIdList) {
          // console.log(this.filters.must.qualification.data)
          this.filter.qualificationIdList.forEach(e => {
            const findQua = row.qualificationsListLocal.find(q => {
              return parseInt(q.id, 10) === e
            })
            if (findQua) {
              tableItemDefauletQua.push({
                value: findQua.id,
                label: findQua.qualificationName
              })
            }
          })
          //  this.tableItemDefauletQua = this.filters.must.qualification.data
        } else {
          tableItemDefauletQua = row.qualificationsListLocal.map(e => {
            return {
              value: e.id,
              label: e.qualificationName
            }
          })
        }
      }
      this.setATurnOnDefaultQua(tableItemDefauletQua)
      this.setRowQuaStatus({onlyDesign: false, onlyConstruction: true})
    },
    receiveThisItem(item) {
      if (this.isSuperAdmin) {
        this.$message.warning('超级管理员无法查看电话', 0.5)
        return
      }
      const params = [
        {
          clueSources: 2,
          enterpriseName: item.companyName || item.enterpriseName,
          enterpriseId: item.enterpriseId
        }
      ]
      this.$http
          .post('enterpriseClue/addReceiveCount', params)
          .then(() => {
            this.getSalesList()
          })
          .catch(err => {
            if (err.code === '5003') {
              Modal.info({
                title: '温馨提示',
                content: err.msg,
                okText: '确定',
                onOk: () => {
                  console.log('is ok')
                }
              })
            } else if (err.code === '5008') {
              this.setNotPhoneCountModalShow(true)
            } else if (err.code === '5013') {
              this.setWatchPhoneModalShow(true)
            } else if (err.code==='5014'){
              this.setShowActivityModal(true) // 活动期间开启该弹窗 活动结束后关闭
            }
          })
    },
    receiveAllList() {
      console.log('点击了本业电话')
      const params = this.tableData.map(e => {
        return {
          clueSources: 2,
          enterpriseName: e.enterpriseName,
          enterpriseId: e.enterpriseId
        }
      })
      this.$http
          .post('enterpriseClue/addReceiveCount', params)
          .then(res => {
            this.setReceiveDataInTable(res)
            this.$message.success('操作成功', 0.5)
          })
          .catch(err => {
            if (err.code === '5004') {
              // 本页全都被领取光了
              Modal.confirm({
                title: '温馨提示',
                content: err.msg,
                okText: '下一页',
                cancelText: '取消',
                onOk: () => {
                  if (this.total / this.pageSize > this.pageNum) {
                    this.pageNum += 1
                    this.tableChange(this.pageNum, this.pageSize)
                  } else {
                    this.$message.warning('已经是最后一页了！', 0.5)
                    this.showWatchBtn = false
                  }
                },
                onCancel: () => {
                  console.log('on cancel')
                }
              })
            } else if (err.code === '5005') {
              Modal.info({
                title: '温馨提示',
                content: err.msg,
                okText: '确定',
                onOk: () => {
                  this.setReceiveDataInTable(err.data)
                  console.log('is ok')
                }
              })
            } else if (err.code === '5008') {
              this.setNotPhoneCountModalShow(true)
            } else if (err.code === '5013') {
              this.setWatchPhoneModalShow(true)
            } else if (err.code==='5014'){
              this.setShowActivityModal(true) // 活动期间开启该弹窗 活动结束后关闭
            }
          })
    },
    setReceiveDataInTable() {
      this.showWatchBtn = false
      this.getSalesList()
    },
    async tableChange(pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      // this.$setQuery.addQuery(this.$route, {
      //     pageNum: this.pageNum,
      //     pageSize: this.pageSize
      // })
      this.$refs.filter.setFilterToFindList(this.pageNum, this.pageSize)
      this.$emit('goBackToTop')
    },
    async showSizeChange(pageNum, pageSize) {
      this.pageNum = 1
      this.pageSize = pageSize
      // this.$setQuery.addQuery(this.$route, {
      //     pageNum: this.pageNum,
      //     pageSize: this.pageSize
      // })
      this.$refs.filter.setFilterToFindList(this.pageNum, this.pageSize)
      this.$emit('goBackToTop')
    },

    filterIsRisk() {
      this.riskSort = this.riskSort === 2 ? 1 : 2
      this.getSalesList()
    },
    changeSafeCertificate() {
      this.safeCertificationIndex += 1
      if (this.safeCertificationIndex > 2) {
        this.safeCertificationIndex = 0
      }
      this.pageNum = 1
      this.getSalesList()
    },
    setEstablishmentDateSort() {
      switch (this.establishmentDateSort) {
        case 0:
          this.establishmentDateSort = 2
          break
        case 2:
          this.establishmentDateSort = 1
          break
        case 1:
          this.establishmentDateSort = 0
          break
      }
      this.pageNum = 1
      this.getSalesList()
    },
    getFilter(option) {
      this.filter = option.filter
      this.pageNum = option.pageNum ? parseInt(option.pageNum, 10) : 1
      this.pageSize = option.pageSize ? parseInt(option.pageSize, 10) : 10
      this.haveQualification = this.filter.haveQualification
      this.getSalesList()
    },
    setRiskLevelFilter() {
      this.pageNum = 1
      this.getSalesList()
    },
    getSalesList() {
      let params = {
        ...this.filter,
        establishmentDateSort: this.establishmentDateSort,
        riskSort: this.riskSort,
        haveSafeCertificate: this.safeCertificationValue[
            this.safeCertificationIndex
            ].value,
        riskLevel: this.riskLevelFilter,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (params.phoneNum) {
        params = {
          phoneNum: params.phoneNum,
          establishmentDateSort: this.establishmentDateSort,
          riskSort: this.riskSort,
          haveSafeCertificate: this.safeCertificationValue[
              this.safeCertificationIndex
              ].value,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
      this.dataLoading = true
      this.$http
          .post('precise', params)
          .then(res => {
            this.dataLoading = false
            const keyWords = this.filter.keyWord
            const filtersQlificationId = this.filter.qualificationIds || []
            // console.log(filtersQlificationId)
            res.data.forEach((e, i) => {
              e.number = i + 1

              const qualificationsList = []
              e.qualificationsList.forEach(q => {
                // console.log(q)
                qualificationsList.push(q)
              })
              // console.log(e.qualificationsList)
              /// (this.pageNum - 1) * this.pageSize + i + 1
              e.qualificationsListLocal = qualificationsList.filter(q => {
                return q.id
              })
              e.isHaveQualification = !!qualificationsList.length
              // console.log(e.qualificationsListLocal)
              // 匹配关键字正则

              const replaceReg = new RegExp(keyWords, 'g')
              // 高亮替换v-html值
              const replaceString =
                  '<span class="hightlight">' + keyWords + '</span>'
              e.companyNameHTML = keyWords
                  ? e.enterpriseName.replace(replaceReg, replaceString)
                  : e.enterpriseName // 开始替换
              e.qualificationsListText = e.qualificationsListLocal
                  .map(q => {
                    return q.qualificationName
                  })
                  .join('、') // 悬浮窗的资质列表
              // if (e.qualificationsList.length > 5) {
              if (filtersQlificationId.length > 0) {
                const findDisplay = []
                const newList = e.qualificationsListLocal.concat([])

                filtersQlificationId.forEach(f => {
                  const index = newList.findIndex(
                      q => parseInt(q.id, 10) === parseInt(f, 10)
                  )
                  newList[index] = {}
                  if (index > -1) {
                    findDisplay.push(
                        '<span class="qua-hightlight">' +
                        e.qualificationsListLocal[index].qualificationName +
                        '</span>' //找到所有筛选的资质
                    )
                    //  newList.splice(index, 1)不用slice保证数组长度
                  }
                })
                const otherList = newList.filter(n => {
                  return n.qualificationName
                }) // 避免显示混乱 重新取一个数组
                const display =
                    findDisplay.length > 3
                        ? findDisplay.slice(0, 3).join('、')
                        : findDisplay.join('、') // 取前三个
                const text =
                    findDisplay.length > 3
                        ? ''
                        : otherList
                            .slice(0, 3 - findDisplay.length)
                            .map(q => {
                              return q.qualificationName
                            })
                            .join('、')
                const tooltipText = otherList
                    .map(q => {
                      return q.qualificationName
                    })
                    .join('、') // 悬浮窗也要高亮
                e.qualificationsListText =
                    display + (tooltipText ? '、' + tooltipText : '')
                e.qualificationsText =
                    e.qualificationsListLocal.length > 3
                        ? `${display}${text ? '、' + text : ''}等${
                            e.qualificationsListLocal.length
                        }项`
                        : `${display}${text ? '、' + text : ''}`
              } else {
                const text = e.qualificationsListLocal
                    .slice(0, 3)
                    .map(q => {
                      return q.qualificationName
                    })
                    .join('、')
                e.qualificationsText =
                    e.qualificationsListLocal.length > 3
                        ? `${text}等${e.qualificationsListLocal.length}项`
                        : text
              }
              if (e.phoneList && e.phoneList.length) {
                if (e.haveReceive) {
                  e.phoneNumDisplay = e.phoneList
                      .map(c => {
                        return (
                            '<span ondblclick="copyThisText(' +
                            c +
                            ')">' +
                            c +
                            '</span>'
                        )
                      })
                      .join('、')
                } else {
                  e.phoneNumDisplay = e.phoneList.join('、')
                }
                //  : '***********'
              } else {
                e.phoneNumDisplay = '暂无联系方式'
              }

              // e.contactInformationListText = e.contactInformationList.join('、')
              e.receiveCount = e.receiveCount || 0
              e.dealCount = e.dealCount || 0
              // e.haveReceive
              e.checked = false
            })
            this.tableData = res.data
            // console.log(this.tableData)
            const totalLength = res.totalSize.toString().length
            const totalNum = Math.pow(10, totalLength - 1)
            const tipText = Math.floor(res.totalSize / totalNum) // 去除零头
            this.tipText =
                res.totalSize > 1000 ? tipText * totalNum + '+' : res.totalSize
            this.total = res.totalSize
            this.dataLoading = false
            this.$nextTick(() => {
              this.totalNum = Math.ceil(this.total / 10)
              window.document.querySelector('.ant-pagination-jump-next') ? window.document.querySelector('.ant-pagination-jump-next').style.display = 'none' : ''
              if (this.totalNum >= 10) {
                window.document.querySelector(`.ant-pagination-item-${6}`) ? window.document.querySelector(`.ant-pagination-item-${6}`).style.display = 'inline-block' : ''
                window.document.querySelector(`.ant-pagination-item-${7}`) ? window.document.querySelector(`.ant-pagination-item-${7}`).style.display = 'inline-block' : ''
                window.document.querySelector(`.ant-pagination-item-${8}`) ? window.document.querySelector(`.ant-pagination-item-${8}`).style.display = 'inline-block' : ''
                window.document.querySelector(`.ant-pagination-item-${9}`) ? window.document.querySelector(`.ant-pagination-item-${9}`).style.display = 'inline-block' : ''
                if (res.pageNum>=this.totalNum-3 ) {
                  window.document.querySelector(`.ant-pagination-item-${this.totalNum}`) ? window.document.querySelector(`.ant-pagination-item-${this.totalNum}`).style.display = 'inline-block' : ''
                } else {
                  window.document.querySelector(`.ant-pagination-item-${this.totalNum}`) ? window.document.querySelector(`.ant-pagination-item-${this.totalNum}`).style.display = 'none' : ''
                }
              } else {
                if (res.pageNum>5&&res.pageNum <= this.totalNum) {
                  window.document.querySelector(`.ant-pagination-item-${res.pageNum}`) ? window.document.querySelector(`.ant-pagination-item-${res.pageNum}`).style.display = 'inline-block' : ''
                } else {
                  window.document.querySelector(`.ant-pagination-item-${6}`) ? window.document.querySelector(`.ant-pagination-item-${6}`).style.display = 'none' : ''
                  window.document.querySelector(`.ant-pagination-item-${7}`) ? window.document.querySelector(`.ant-pagination-item-${7}`).style.display = 'none' : ''
                  window.document.querySelector(`.ant-pagination-item-${8}`) ? window.document.querySelector(`.ant-pagination-item-${8}`).style.display = 'none' : ''
                  window.document.querySelector(`.ant-pagination-item-${9}`) ? window.document.querySelector(`.ant-pagination-item-${9}`).style.display = 'none' : ''
                }
              }
            })
            this.isNeedShowWatchBtn()

          })
          .catch(err => {
            this.dataLoading = false
            if (err.code === '5012') {
              this.setFreeOverDateModalShow(true)
            }
          })
    },
    isNeedShowWatchBtn() {
      const receiveLength = this.tableData.filter(e => {
        return e.haveReceive
      }).length
      this.showWatchBtn = receiveLength !== this.tableData.length
    },
    goToVipPage() {
      this.$router.push({
        name: 'Vip',
        query: {
          fromUrl: this.$route.name
        }
      })
    }
  }
}
</script>
<style lang="scss" src="../../../utils/table.scss"></style>

<style lang="scss" scoped>

.shipment-page {
  min-width: 1200px;

  .filter-btns {
    margin-top: 12px;
    padding: 6px 21px;
    border: solid 1px #f2f2f2;
    background-color: #f2f2f2;
    position: relative;

    .noRisk {
      background-color: transparent;
      border-color: #999999;

      &:hover {
        border-color: #759fff;
      }

      &:focus {
        border-color: #759fff;
      }
    }

    .haveRisk {
      background-color: #4d7cfe;
      color: #ffffff;

      &:hover {
        background-color: #759fff;
        border-color: #759fff;
      }
    }

    .special-btn {
      color: #fff;
      background-color: #65b3fe;
      position: absolute;
      right: 21px;

      &:hover {
        background-color: #84baef;
        border-color: #84baef;
      }
    }
  }

  .card-pagination {
    width: 100%;
    // text-align: right;
    margin-top: 35px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .fake-btn {
      margin-left: 8px;
    }
  }
}
</style>
