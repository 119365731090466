<template>
  <div>
    <div class="qualification-type-list">
      <div v-for="(item, index) in qualifications" :key="index" class="detail-item">
        <div
            :class="{ selected: typeIndex === index }"
            class="type-item"
            @click="selectQualicationType(index)"
        >
          {{ item.industryName }}({{ item.num }})
          <div v-if="index + 1 < qualifications.length" class="slice-point"></div>
        </div>
      </div>
    </div>
    <div v-if="qualificationItems.length">
      <div v-for="(item, key) in qualificationItems" :key="key" class="qualification-table">
        <div class="title-band">
          <div :class="{ overtime: item.beOverdue }" class="qualification-type">
            {{ item.certificateType }}
            <div :class="{ originColor: item.beOverdue }" class="right-triangle"></div>
            <div class="right-triangle white-color"></div>
          </div>
          <div :class="{ originBg: item.beOverdue }" class="type-info">
            <a-button v-if="item.certificateType.indexOf('D')!== -1" class="not-turn-on"
                      style="margin-top: 5px;margin-left: 30px;float: left"
                      @click="selChangePreview(item.certificateNo)">换证预览
            </a-button>
            <div>
              {{ item.certificateNo }}
              <span class="block"></span>
              有效：{{ item.effectiveDate }}
              <span class="block"></span>
              <span v-if="item.certificateType !== '安许'">
                            {{ item.items.length }}项
                        </span>
              <span class="block"></span>
              <span v-if="item.beOverdue" class="overtime-text">过期</span>
            </div>

          </div>
        </div>
        <div v-if="item.certificateType !== '安许'" class="content-table">
          <div class="item-row title">资质项</div>
          <div v-for="(qua, qIndex) in item.items" :key="qIndex" class="item-row">
            {{ qua.qualificationName }}
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="qualification-table">
        <div class="title-band">
          <div class="qualification-type">
            暂无
            <div class="right-triangle"></div>
            <div class="right-triangle white-color"></div>
          </div>
          <div class="type-info">
            暂无{{
              qualifications[typeIndex] ? qualifications[typeIndex].industryName : ''
            }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="!qualifications.length" class="no-data">暂无企业资质信息</div>
    <div class="tips">
      数据来源：企业资质数据，部级资质项来源于“中华人民共和国住房和城乡建设部”，其他资质项来源于企业所在省份最高建设主管部门官方网站。具体资质项来源于发证部门，数据更精准！
    </div>
    <a-modal
        :destroyOnClose="true"
        :footer="null"
        :centered="true"
        :visible="visible"
        class="preview"
        title="换证预览"
        width="550px"
        @cancel="cancelBtn"
    >
      <preview :certificateNoId="certificateNoId"></preview>
      <div class="explain">
        <div class="explainText">
          <p>
            <span class="tips">换证说明</span>
            <span class="tipsLink" @click="certificateDetail">查看详情 &gt;</span>
          </p>
        </div>
        <div v-if="isShowDetail" class="tipsText">
          施工资质新标准变化内容：<br/>
          1. 特级资质不再区分行业，统一改为综合资质<br/>
          2. 钢结构专包合并到建筑总包<br/>
          3. 铁路铺轨架梁专包合并到铁路总包<br/>
          4. 河湖整治专包合并到水利水电总包<br/>
          5. 海洋石油专包合并到石油化工总包<br/>
          6. 机场场道专包、空管及机场弱电专包、机场目视助航专包合并为民航总包<br/>
          7. 环保工程和特种工程合并为通用专包<br/>
          8. 建筑幕墙专包合并到建筑装修装饰专包<br/>
          9. 电子与智能化专包、城市及道路照明专包合并到建筑机电安装专包<br/>
          10. 公路路基专包、公路路面专包、公路交通专包合并为公路工程类专包<br/>
          11. 港口与海岸专包、航道专包、通航建筑物专包、港航设备及水上交管专包合并为港口与航道工程类专包<br/>
          12. 水工金属专包、水利水电机电专包合并为水利水电工程类专包<br/>
          13. 铁路电务专包、铁路电气化专包合并为铁路工程类专包<br/>
          14. 施工劳务部分等级改为专业作业制作不分等级<br/>
        </div>


      </div>
    </a-modal>

  </div>
</template>

<script>
import {Popover} from 'ant-design-vue'
import preview from '../SelChangePreview.vue'

export default {
  name: 'conpany-detail-qualification',
  components: {
    preview,
    aPopover: Popover
  },
  data() {
    return {
      isShowDetail: false,
      visible: false,
      certificateNoId: null,
      typeIndex: 0
    }
  },
  props: {
    qualifications: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    isHaveAnXu() {
      const anxu = this.qualifications.filter(e => {
        return e.certificateType === '安许'
      })
      return !!anxu.length
    },
    qualificationItems() {
      return this.qualifications[this.typeIndex]
          ? this.qualifications[this.typeIndex].certificates
          : []
    }
  },
  methods: {
    certificateDetail() {
      this.isShowDetail = !this.isShowDetail
    },
    selChangePreview(certificateNo) {
      this.visible = true
      this.certificateNoId = certificateNo
    },
    cancelBtn() {
      this.isShowDetail = false
      this.visible = false
    },
    selectQualicationType(index) {
      this.typeIndex = index
    }
    // haveWithTemplate(have) {
    //   if (have) {
    //     return 1
    //   } else {
    //     return 0
    //   }
    // },
    // haveWithLabor(have) {
    //   if (have) {
    //     return 1
    //   } else {
    //     return 0
    //   }
    // },
  }
}
</script>

<style scoped>
.preview /deep/ .ant-modal-body {
  padding: 0 !important;
}
</style>


<style lang="scss" scoped>
.not-turn-on {
  background-color: #ffffff;
  color: #4d7cfe;
  border: 1px solid #4d7cfe;

}

.explain {
  border-top: 1px solid #D9D9D9;

  .explainText {
    min-height: 52px;
    position: relative;

    p {
      position: absolute;
      right: 42px;

      .tips {
        font-size: 14px;
        color: #595959;
        line-height: 52px;

      }

      .tipsLink {
        font-size: 14px;
        color: #4D7CFE;
        line-height: 52px;
        cursor: pointer;

      }
    }


  }

  .tipsText {
    padding: 0 20px 20px 20px;
    font-size: 13px;
    color: #909399;
    min-height: 300px;
  }
}

.qualification-type-list {
  display: flex;
  width: center;
  align-items: center;

  .detail-item {
    margin-bottom: 24px;
  }

  .type-item {
    color: #d2d2d2;
    margin-right: 40px;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    cursor: pointer;

    &:hover {
      color: #4a78f9;

      .slice-point {
        background-color: #4a78f9;
      }
    }
  }

  .selected {
    color: #4a78f9;
  }

  .slice-point {
    width: 6px;
    height: 6px;
    position: absolute;
    background-color: #d2d2d2;
    top: 0;
    bottom: 0;
    right: -20px;
    margin: auto;
    border-radius: 50%;
  }
}

.no-data {
  width: 100%;
  text-align: center;
}

.qualification-table {
  margin-bottom: 20px;
}

.title-band {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  line-height: 40px;
  font-size: 14px;
  width: 100%;

  .qualification-type {
    position: relative;
    color: #ffffff;
    width: 90px;
    text-align: center;
    background-color: rgba($color: #4d7cfe, $alpha: 0.8);

    .right-triangle {
      position: absolute;
      top: 0;
      right: -20px;
      height: 0;
      width: 0;
      border: solid 20px rgba($color: #4d7cfe, $alpha: 0.8);
      border-bottom-color: transparent;
      border-right: transparent;
      border-top-color: transparent;
    }

    .white-color {
      right: -40px;
      border: solid 20px rgba($color: #fff, $alpha: 0.8);
      border-bottom-color: transparent;
      border-right: transparent;
      border-top-color: transparent;
    }

    .originColor {
      border: solid 20px rgba($color: #fa8f00, $alpha: 0.8);
      border-bottom-color: transparent;
      border-right: transparent;
      border-top-color: transparent;
    }
  }

  .overtime {
    background-color: rgba($color: #fa8f00, $alpha: 0.8);
  }

  .type-info {
    text-align: right;
    color: #606366;
    flex-grow: 1;
    font-size: 13px;
    padding-right: 16px;
    margin-left: 20px;
    background-color: rgba($color: #4d7cfe, $alpha: 0.06);

    .overtime-text {
      background-color: rgba($color: #fa8f00, $alpha: 0.1);
      width: 42px;
      height: 28px;
      border-radius: 4px;
      margin-left: 5px;
      display: inline-block;
      text-align: center;
      line-height: 28px;
    }

    .block {
      width: 5px;
      display: inline-block;
    }
  }

  .originBg {
    background-color: rgba($color: #fa8f00, $alpha: 0.06);
    color: #fa8f00;
  }
}

.content-table {
  margin-top: 4px;
  // margin-bottom: 24px;
  width: 100%;
  display: flex;
  align-self: center;
  flex-wrap: wrap;
  overflow: hidden;

  .item-row {
    position: relative;
    background-color: #fff;
    height: 36px;
    line-height: 36px;
    width: 50%;
    flex-shrink: 1;
    // flex-grow: 1;
    text-align: center;

    &:nth-child(2n) {
      border-right: 1px solid rgba(204, 204, 204, 0.3);
    }

    &:after {
      content: '';
      position: absolute;
      width: 200%;
      height: 1px;
      left: 0;
      bottom: 0;
      border-bottom: 1px solid rgba(204, 204, 204, 0.3);
    }

    //
  }

  .title {
    width: 100%;
    padding-left: 23px;
    text-align: left;
    color: #909399;
  }
}
</style>
