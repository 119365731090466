<template>
  <div id="app">
    <config-provider :locale="locale">
      <router-view v-if="isRouterAlive" />
    </config-provider>
  </div>
</template>

<script>
import { ConfigProvider } from 'ant-design-vue'
import { mapMutations } from 'vuex'
import Cookies from 'js-cookie'
import httpRequestor from './utils/http'
import beforeLogin from './beforeLogin.js'
// import router from './router/index'

import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN.js'

export default {
  name: 'app',
  provide() {
    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
    return {
      reload: this.reload
    }
  },
  asyncData({ store, route }) {
    // console.log(store, route)
    const userId = Cookies.get('userId')

    // 触发 action 后，会返回 Promise
    // if (userId) {
    return store.dispatch('getUserInfo', userId)
  },
  components: {
    ConfigProvider
  },
  data() {
    return {
      locale: zhCN,
      isRouterAlive: true
    }
  },
  methods: {
    ...mapMutations(['clearUserInfo']),
    reload() {
      this.isRouterAlive = false //先关闭，
      this.$nextTick(function() {
        this.isRouterAlive = true //再打开
      })
    }
  },
  metaInfo() {
    // 如果子component中没有定义 metaInfo.title ，会默认使用这个title
    return {
      title: '全国建筑企业资质查询-施工资质办理服务_小牛建讯官网',
      meta: [
        {
          vmid: 'keywords',
          name: 'keywords',
          content:
            '建筑资质,建筑资质查询,建筑资质服务,建筑企业查询,施工资质查询,全国建筑资质,资质分立,建筑企业资质查询,资质代办,资质转让,资质剥离,资质办理'
        },
        {
          vmid: 'description',
          name: 'description',
          content:
            '小牛建讯建筑资质大数据查询系统,全国建筑资质信息轻松查,面向资质服务企业,通过智能AI数据算法分析,构建全面的信息资源库.为资质服务企业提供建筑资质,建筑资质查询,建筑资质服务,建筑企业查询,施工资质查询,全国建筑资质,资质分立,建筑企业资质查询,资质代办,资质转让,资质剥离,资质办理等服务'
        }
      ]
    }
  },
  mounted() {
    window.clearUserInfo = this.clearUserInfo
    // console.log(this.dataPromise)
  }
  // beforeRouteEnter(to, from, next) {
  //   beforeLogin(to, from, next)
  //   // next()
  // }
  // beforeRouteEnter(to, from, next) {
  //   const id = Cookies.get('userId')
  //   if (id) {
  //     httpRequestor
  //       .get('member/get', { id })
  //       .then(r => {
  //         Cookies.set('memberName', r.memberName, { expires: 7 })
  //         Cookies.set('userId', r.id, { expires: 7 })
  //         Cookies.set('userArea', r.useAreaName, { expires: 7 })
  //         next()
  //       })
  //       .catch(() => {
  //         Cookies.remove('memberName')
  //         Cookies.remove('userId')
  //         Cookies.remove('userArea')
  //         next()
  //       })
  //   } else {
  //     next()
  //   }
  // <style src="./utils/tailwind.css"></style>
  //
  // }
}
</script>
<style lang="scss">
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
#app {
  // font-family: MicrosoftYaHe, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  /* text-align: center; */
  color: #2c3e50;
  min-width: 1200px;
  width: 100vw;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.transpant-dom {
  opacity: 0;
  position: absolute;
}

.primary-text {
  color: #4d7cfe;
}

.tips {
  font-size: 13px;
  color: #909399;
  line-height: 2;
}

.warning-tips {
  color: #dc0000;
  font-weight: bold;
}

.second-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #f2f2f2;
  color: #8e8e90;
  font-size: 20px;
  height: 48px;
  position: relative;

  p {
    margin: 0 0 0 34px;
    height: 48px;
    width: fit-content;
    line-height: 48px;
    cursor: pointer;
    border-bottom: solid 1px transparent;
  }

  .isActive {
    border-bottom: solid 1px #4d7cfe;
  }
}
</style>
