<template>
  <!--  <div class="index">
      <div class="img">
        <img v-if="previewImg"  :src="previewImg" alt="">
        <div class="example" v-else >
          <a-spin tip="加载中...">
          </a-spin>
        </div>
      </div>
      <div v-if="previewImg" class="download" @click="download(previewImg)">
        <img :src="previewDownload" alt="">
        <span class="downText">预览下载</span>
      </div>
    </div>-->
  <div :style="'background-image:url(' + bgPreview + ')'" class="index">
    <div class="main">
      <div class="line"><p class="nameText">企业名称：</p><span class="detail">{{ itemData.companyName }}</span></div>
      <div class="line"><p class="nameText">详细地址：</p><span class="detail">{{ itemData.businessAddress }}</span></div>
      <div class="line"><p class="nameText"><span style="letter-spacing: 1px;">统一社会信用代码</span><br/><span
          style="letter-spacing: 0;">(或营业执照注册码)</span><span style="position: relative;bottom: 12px;">：</span></p>
        <span class="detail" style="position: relative;bottom: 12px;">{{ itemData.socialCreditCode }}</span></div>
      <div class="line">
        <div class="flex flexLeft">
          <p class="nameText ">注册资本：</p><span class="detail">{{ itemData.registeredCapital }}</span>
        </div>
        <div class="flex">
          <p class="nameText">法定代表人：</p><span class="detail">{{ itemData.legalPerson }}</span>
        </div>
      </div>
      <div class="line"><p class="nameText">经济性质：</p><span class="detail">{{ itemData.companyType }}</span></div>
      <div class="line">
        <div class="flex flexLeft">
          <p class="nameText ">证书编号：</p><span class="detail">{{ itemData.certificateNo }}</span>
        </div>
        <div class="flex">
          <p class="nameText">有效期：</p><span class="detail">{{ itemData.effectiveDate }}</span>
        </div>
      </div>
      <div class="qua">
        <p class="nameText">资质类别及名称：</p>
        <div class="detail">
          <div v-if="itemData.qualificationItems" :class="{ maxQua: itemData.qualificationItems.length >12 }">
            <p v-for="(item,index) in itemData.qualificationItems"
               :key="index"> {{ item }}</p>
          </div>

        </div>

      </div>

    </div>
    <div class="qr">
      <div class="qrcode">
        <img :src="qrcodePath" alt="">
      </div>
      <div class="qrText">
        <p class="name">小牛建讯·仕硕科技</p>
        <p class="qua">(施工资质换证预览)</p>
      </div>

    </div>
    <div class="bottom">
      小牛建讯 · 建筑行业资质AI云智一体化平台：http://www.xnjx666.com
    </div>
    <div :style="'background-image:url(' + bgWatermark + ')'" class="mark">

    </div>

  </div>
</template>

<script>
import previewDownload from '@/assets/image/ic_preview_download.png'
import bgPreview from '@/assets/image/bg_preview.png'
import bgWatermark from '@/assets/image/bg_watermark.png'
import {Spin} from 'ant-design-vue'

export default {
  name: "SelChangePreview",
  components: {
    aSpin: Spin
  },
  props: {
    certificateNoId: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      bgPreview,
      bgWatermark,
      previewDownload,
      qrcodePath: '',
      itemData: {},
      previewImg: '',
      apiUrl: ''
    }
  },
  methods: {
    getList(certificate) {
      this.$http.get('/qualification/getChangePreview', {certificateNo: certificate}).then((res) => {
        const url = res.qrcodePath.substring(res.qrcodePath.indexOf("upload/") + 7);
        this.qrcodePath = `${this.apiUrl}/upload/file/get?path=` + url
        this.itemData = res
      })
    },
    download(url) {
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = '换证预览'
        document.body.appendChild(link)
        link.click()
      })
    }
  },
  mounted() {
    let apiUrl = ''
    if (process.env.VUE_APP_TYPE === 'test') {
      // 本地测试
      apiUrl = 'http://192.168.0.108:9005/api/'
    } else if (process.env.VUE_APP_TYPE === 'preview') {
      // 线上测试
      apiUrl = 'http://www.xnjx666.com/api/'
    } else if (process.env.VUE_APP_TYPE === 'online') {
      apiUrl = 'http://www.xnjx666.com/api/'
    } else if (process.env.VUE_APP_TYPE === 'dev') {
      apiUrl = 'http://192.168.0.108:8005/api/'
    }
    this.apiUrl = apiUrl
    this.getList(this.certificateNoId)

  }

}
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}


.index {
  position: relative;
  margin: 10px 20px;
  height: 720px;
  width: 510px;
  background-size: 100% 100%;

  .mark {
    background-size: 100% 100%;
    height: 720px;
    width: 510px;
    position: absolute;
    top: 0;

  }

  .main {
    padding-top: 175px;
    padding-left: 65px;
    padding-right: 65px;

    .flexLeft {
      width: 240px;
    }

    .flex {
      display: flex;
    }

    .qua {
      .nameText {
        font-size: 14px;
        font-family: "heiti";
        font-weight: 500;
        color: #4C403E;
        letter-spacing: 3px;
      }

      .detail {
        font-size: 12px;
        font-family: "songti";
        font-weight: 400;
        color: #181818;
        margin-bottom: 2px;
      }

      .maxQua {
        display: flex;
        flex-wrap: wrap;

        p:nth-child(2n+1) {
          width: 240px;
          text-align: left;
        }

        p:nth-child(2n-1) {
          width: 210px;
          text-align: left;

        }
      }
    }


    .line {
      margin-bottom: 6px;
      display: flex;
      align-items: flex-end;

      .nameText {
        font-size: 14px;
        font-family: "heiti";
        font-weight: 500;
        color: #4C403E;
        letter-spacing: 3px;
      }

      .detail {
        font-size: 12px;
        font-family: "songti";
        font-weight: 400;
        color: #181818;
        margin-bottom: 2px;
      }

    }

  }

  .qr {


    .qrcode {
      position: absolute;
      bottom: 60px;
      left: 65px;
      width: 70px;
      height: 70px;
    }

    .qrText {
      position: absolute;
      bottom: 60px;
      right: 65px;
      text-align: right;
      font-weight: 500;

      .name {
        font-size: 14px;
        font-family: "heiti";
        margin-bottom: 4px;
      }

      .qua {
        font-size: 12px;
        font-family: "songti";
      }
    }

  }

  .bottom {
    position: absolute;
    left: 22px;
    bottom: 7px;
    font-family: 'songti';
    font-weight: 400;
    color: #4C403E;
    line-height: 15px;
    font-size: 10px;


  }
}

/*.example {
  display: flex;
  align-items: center;
  justify-content:center;
  width: 600px;
  min-height: 520px;
  text-align: center;
}

.index {
  .img {
    text-align: center;
    img{
      padding-top:28px;
    }

  }

  .download {
    cursor: pointer;
    position: relative;
    right: -455px;
    top: -28px;
    padding: 4px 8px;
    width: 100px;
    height: 28px;
    background: rgba(0, 0, 0, 0.4);

    .downText {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      vertical-align: bottom;
      margin-left: 4px;
    }

    img {
      vertical-align: bottom;

    }
  }


  .title-band {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    line-height: 40px;
    font-size: 14px;
    width: 100%;

    .qualification-type {
      position: relative;
      color: #ffffff;
      width: 90px;
      text-align: center;
      background-color: rgba($color: #4d7cfe, $alpha: 0.8);

      .right-triangle {
        position: absolute;
        top: 0;
        right: -20px;
        height: 0;
        width: 0;
        border: solid 20px rgba($color: #4d7cfe, $alpha: 0.8);
        border-bottom-color: transparent;
        border-right: transparent;
        border-top-color: transparent;
      }

      .white-color {
        right: -40px;
        border: solid 20px rgba($color: #fff, $alpha: 0.8);
        border-bottom-color: transparent;
        border-right: transparent;
        border-top-color: transparent;
      }

      .originColor {
        border: solid 20px rgba($color: #fa8f00, $alpha: 0.8);
        border-bottom-color: transparent;
        border-right: transparent;
        border-top-color: transparent;
      }
    }

    .overtime {
      background-color: rgba($color: #fa8f00, $alpha: 0.8);
    }

    .type-info {
      text-align: right;
      color: #606366;
      flex-grow: 1;
      font-size: 13px;
      padding-right: 16px;
      margin-left: 20px;
      background-color: rgba($color: #4d7cfe, $alpha: 0.06);

      .overtime-text {
        background-color: rgba($color: #fa8f00, $alpha: 0.1);
        width: 42px;
        height: 28px;
        border-radius: 4px;
        margin-left: 5px;
        display: inline-block;
        text-align: center;
        line-height: 28px;
      }

      .block {
        width: 5px;
        display: inline-block;
      }
    }

    .originBg {
      background-color: rgba($color: #fa8f00, $alpha: 0.06);
      color: #fa8f00;
    }
  }

  .content-table {
    margin-top: 4px;
    // margin-bottom: 24px;
    width: 100%;
    display: flex;
    align-self: center;
    flex-wrap: wrap;
    overflow: hidden;

    .item-row {
      position: relative;
      background-color: #fff;
      height: 36px;
      line-height: 36px;
      width: 50%;
      flex-shrink: 1;
      // flex-grow: 1;
      text-align: center;

      &:nth-child(2n) {
        border-right: 1px solid rgba(204, 204, 204, 0.3);
      }

      &:after {
        content: '';
        position: absolute;
        width: 200%;
        height: 1px;
        left: 0;
        bottom: 0;
        border-bottom: 1px solid rgba(204, 204, 204, 0.3);
      }

      //
    }

    .title {
      width: 100%;
      padding-left: 23px;
      text-align: left;
      color: #909399;
    }
  }
}*/


</style>