var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"AboutUs"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"cost"},[_c('div',{staticClass:"content"},[_vm._m(2),_c('div',{staticClass:"costMain"},[_c('ul',_vm._l((_vm.costList),function(item){return _c('li',{key:item.index},[_c('img',{attrs:{"src":item.url,"alt":"小牛建讯"}}),_c('p',{staticClass:"costNum"},[_c('span',[_vm._v(_vm._s(item.num)+"万")]),_c('span',{staticClass:"sup"},[_vm._v("+")])]),_c('p',{staticClass:"costText"},[_vm._v(_vm._s(item.text))])])}),0)])])]),_c('div',{staticClass:"aboutCompany"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"idea"},[_vm._m(3),_c('div',{staticClass:"ideaMain"},[_c('ul',_vm._l((_vm.ideaList),function(item){return _c('li',{key:item.index,style:('background-image:url(' + item.imgUrl + ')')},[_c('div',[_c('p',{staticClass:"ideaMainTitle"},[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.text))])])])}),0)])]),_c('div',{staticClass:"companyDevelopment"},[_vm._m(4),_c('div',{staticClass:"timeLine"},[_c('div',{staticClass:"timeLineText"},_vm._l((_vm.timeLineList),function(item,index){return _c('div',{key:index,staticClass:"content",style:({ left: item.left+ 'px' ,top:item.top+ 'px'})},[_c('div',{staticClass:"my_timeline_item_content"},[_vm._v("\n                "+_vm._s(item.timestamp)+"\n              ")]),_c('p',{staticClass:"tit"},[_vm._v("\n                "+_vm._s(item.year)+"\n              ")]),_c('ul',{staticClass:"infoText",domProps:{"innerHTML":_vm._s(item.info)}})])}),0),_c('div',{staticClass:"ul_box"},[_c('ul',{staticClass:"my_timeline"},_vm._l((_vm.timeLineList),function(item,index){return _c('li',{key:index,staticClass:"my_timeline_item"},[_c('div',{staticClass:"my_timeline_item_line"}),_c('div',{staticClass:"my_timeline_node",style:({
                    backgroundColor: item.bgColor,
                    width: item.size + 'px',
                    height: item.size + 'px'
                  })})])}),0),_c('div',{staticClass:"my_timeline_item_line"})])])]),(false)?_c('div',{staticClass:"team"},[_vm._m(5),_vm._m(6)]):_vm._e(),_c('div',{staticClass:"honor"},[_vm._m(7),_c('div',{staticClass:"threeImg"},[_c('div',{staticClass:"Containt"},[_c('ul',{staticClass:"imgBoxoul",style:({ left: _vm.calleft + 'px', width: _vm.widthData + 'px' }),on:{"mouseout":function($event){return _vm.move()},"mouseover":function($event){return _vm.stopmove()}}},_vm._l((_vm.honorList),function(item){return _c('li',{key:item.index},[_c('img',{attrs:{"src":item.imgUrl,"alt":""}}),_vm._v("\n                "+_vm._s(item.id)+"\n              ")])}),0)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg",staticStyle:{"background-image":"url('http://cdn.public.xnjx666.com//upload/gw/static/img/explore.png')"}},[_c('div',{staticClass:"text"},[_c('p',[_vm._v("关于我们")]),_c('p',[_vm._v("Explore the calf")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introduce"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"titTop"},[_c('div',{staticClass:"titleText"},[_vm._v("公司简介")])])]),_c('div',{staticClass:"introduceMain"},[_c('div',{staticClass:"introduceText"},[_c('p',[_vm._v("\n            仕硕科技有限公司是一家智能数据产品与服务提供商。以建筑资质服务行业诉求为引领，专注建筑施工资质智能数据处理系统研发及应用，致力于帮助建筑资质服务解决业务增长难题，推动资质代办服务智能化升级，实现人工智能与行业需求深度融合。\n          ")]),_c('p',{staticStyle:{"margin-top":"24px"}},[_vm._v("\n            小牛建讯是仕硕科技自主研发的资质服务营销平台，依托数据挖掘、云计算、人工智能等核心技术，为建筑资质服务提供资源查询、资源互通、资源整合等一系列信息智能化解决方案。\n          ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"titTop"},[_c('div',{staticClass:"titleText"},[_vm._v("投资成本")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"titTop"},[_c('div',{staticClass:"titleText"},[_vm._v("企业理念")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"titTop"},[_c('div',{staticClass:"titleText"},[_vm._v("发展历程")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"titTop"},[_c('div',{staticClass:"titleLine"}),_c('div',{staticClass:"titleText"},[_vm._v("核心团队")]),_c('div',{staticClass:"titleLine"})]),_c('div',{staticClass:"titleDescribe"},[_vm._v("Corporate Dilemma")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("图")]),_c('div',[_vm._v("文")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"titTop"},[_c('div',{staticClass:"titleLine"}),_c('div',{staticClass:"titleText"},[_vm._v("荣誉证书")]),_c('div',{staticClass:"titleLine"})]),_c('div',{staticClass:"titleDescribe"},[_vm._v("Corporate Dilemma")])])
}]

export { render, staticRenderFns }