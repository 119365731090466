<template>
    <a-modal
        title="小牛建讯用户协议"
        :visible="show"
        :zIndex="4001"
        @ok="() => closeModal()"
        @cancel="() => closeModal()"
    >
        <div class="agreement-detail">
            <h1>
                <strong>用户协议</strong>
            </h1>
            <p>&nbsp;</p>
            <p>欢迎您使用小牛建讯的服务！</p>
            <p>
                <strong>在您使用</strong>
                <strong>小牛建讯</strong>
                <strong>提供的各项VIP会员及非会员服务（以下简称&ldquo;</strong>
                <strong>小牛建讯</strong>
                <strong
                    >服务&rdquo;，
                    具体形式包括但不限于您获取并使用的数据查询、信息发布等服务，亦包括未来增加或完善现有服务的任何新功能）之前，
                    请务必仔细阅读并透彻理解本协议。您在使用</strong
                >
                <strong>小牛建讯</strong>
                <strong
                    >服务前，
                    应当仔细认真阅读本《用户协议》(以下简称&ldquo;本协议&rdquo;)中的全部条款、规则及内容（特别是其中以加粗标示出的关于</strong
                >
                <strong>小牛建讯</strong>
                <strong
                    >服务及用户重大权益的规则，
                    该等规则可能涉及相关方的责任免除或限制、法律适用与争议解决条款，请您重点阅读），确认对本协议全部条款、规则及内容均已知晓、理解并接受，
                    并已同意将其作为确定双方权利义务的依据。如您使用</strong
                >
                <strong>小牛建讯</strong>
                <strong>账号访问</strong>
                <strong>小牛建讯</strong>
                <strong>或以其他方式使用或接受任何</strong>
                <strong>小牛建讯</strong>
                <strong>服务，即视为您已阅读并与</strong>
                <strong>小牛建讯</strong>
                <strong
                    >达成协议，
                    自愿接受本协议全部条款、规则及内容的约束。所有服务规则视为本条款不可分割的一部分，与本条款具有同等法律效力。</strong
                >
            </p>
            <p>
                <strong>本协议的订立双方为</strong>
                <strong>小牛建讯</strong>
                <strong>的实际运营商</strong>
                <strong>仕硕科技</strong>
                <strong>有限公司（下称&ldquo;</strong>
                <strong>小牛建讯</strong>
                <strong>&rdquo;或&ldquo;我们&rdquo;）与使用</strong>
                <strong>小牛建讯</strong>
                <strong
                    >相关服务的使用人（下称&ldquo;用户&rdquo;或&ldquo;您&rdquo;），
                    本协议是您与</strong
                >
                <strong>小牛建讯</strong>
                <strong>之间关于您使用</strong>
                <strong>小牛建讯</strong>
                <strong>提供的各项服务所订立的服务条款及规则，与正式书面合同具有同等效力。</strong>
            </p>
            <h2>
                <strong>一、用户注册及服务使用</strong>
            </h2>
            <p>
                1.1 &ldquo;
                <strong>小牛建讯</strong>
                <strong>账号</strong
                >&rdquo;是指您通过小牛建讯官方网页端注册的账号。小牛建讯服务，需要您在登录您的小牛建讯账号之后方可使用。您在注册时所填写的内容与个人资料必须真实、准确、有效。在完成账号注册手续后，您将有权通过该账号使用小牛建讯服务。
            </p>
            <p>
                1.2
                小牛建讯特别提醒您应妥善保管您的账号及密码信息，当您使用完完毕后，应当及时退出。由于您自身原因导致的账户或密码的泄露、遗忘等情形或其他原因所带来的损失，
                小牛建讯将配合采取必要措施以降低相关损失，但因此造成的不利后果，需由您自行承担。
            </p>
            <p>
                1.3
                您在使用小牛建讯服务时，所发布的信息（包括用户操作数据）及用户行为必须遵守国家有关法律规定以及小牛建讯服务规则，
                并承担一切因自己发布信息或其他行为导致的民事、行政、或刑事法律责任或其他不利后果。
            </p>
            <p>
                <strong>1.4</strong>
                <strong>小牛建讯</strong>
                <strong>不会将您的个人信息转移或披露给任何非关联的第三方，除非：</strong>
            </p>
            <p>
                <strong>（1）相关法律法规或法院、政府机关要求；</strong>
            </p>
            <p>
                <strong>（2）为完成合并、分立、收购或资产转让而转移；</strong>
            </p>
            <p>
                <strong>（3）为提供您要求的服务所必需。</strong>
            </p>
            <p>
                1.5 您在小牛建讯所发布或展示的信息，不得含有以下内容或用于以下目的：
            </p>
            <p>
                （1）违反宪法确定的基本原则的；
                <br />（2）危害国家安全，泄露国家机密，颠覆国家政权，破坏国家统一，损害国家荣誉和利益的；
                <br />（3）煽动民族仇恨、民族歧视，破坏民族团结的；
                <br />（4）破坏国家、地区间友好关系的；
                <br />（5）违背中华民族传统美德、社会公德、伦理道德、以及社会主义精神文明的；
                <br />（6）破坏国家宗教政策，宣扬邪教和封建迷信的；
                <br />（7）散布谣言或不实消息，扰乱社会秩序，破坏社会稳定的；
                <br />（8）煽动、组织、教唆恐怖活动、非法集会、结社、游行、示威、聚众扰乱社会秩序的；
                <br />（9）散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；
                <br />（10）侮辱或诽谤他人，侵犯他人肖像权、姓名权、名誉权、隐私权或其他人身权利的；
                <br />（11）以非法组织名义活动的；
                <br />（12）侵犯他人知识产权、商业秘密等合法权益的；
                <br />（13）含有违反法律、法规、国家政策及公序良俗的法律、行政法规禁止的其他内容的。
            </p>
            <p>
                1.6
                您不得为任何非法目的使用小牛建讯服务，且不得在未经小牛建讯事先书面允许的情况下，以任何形式利用或借助小牛建讯从事以下活动：
            </p>
            <p>
                （1）进入小牛建讯或其他计算机信息网络或者使用计算机信息网络资源的；
                <br />（2）对小牛建讯或其他计算机信息网络功能进行删除、修改或者增加的；
                <br />（3）进入计算机信息网络中对存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；
                <br />（4）制作、传播计算机病毒（包括木马）等破坏性程序；
                <br />（5）其他危害计算机信息网络安全的行为。
            </p>
            <p>
                1.7
                如果您在使用小牛建讯服务时，未能遵守本协议或其他服务规则的规定，为了维护良好服务环境，保障其他用户的合法权益，
                小牛建讯有权删除您发布的相关信息，暂时或永久禁止您在小牛建讯发布信息，直至封停您的账号，同时保留依法追究您的法律责任的权利。
                为配合上述目标实现，我们可能将系统内的相关记录，作为您违反法律和本协议的证据，加以保存或向监管部门提供。请您知悉并同意，
                小牛建讯采取前述措施时，无须通知您或事先获得您的同意。
            </p>
            <p>
                1.8
                您在使用小牛建讯服务时存在违反法律、法规、规章或监管部门规范性文件规定的情形，或使用小牛建讯服务时侵犯第三方的合法权益，
                或利用小牛建讯服务从事任何侵犯第三方合法权益的行为，从而导致小牛建讯承担任何性质的赔偿、补偿或罚款而遭受损失的，
                您应当赔偿小牛建讯因此遭受的全部损失（为避免疑义，包括间接损失）。
            </p>
            <p>
                1.9
                依照监管要求，对于小牛建讯在向您提供服务时向您提供的及/或您在使用小牛建讯服务时获取的数据及服务信息等，
                您应当仅在中华人民共和国大陆范围内储存及/或使用，同时您不得利用小牛建讯提供的数据及服务信息从事任何违反法律、法规或其他监管政策的行为。
                否则，由此产生的一切不利法律后果由您自行承担，且您应全额赔偿小牛建讯因此遭受的商誉、企业形象等损失。
            </p>
            <p>
                1.10
                您不得以营利为目的，将您在使用小牛建讯服务时获得的服务信息或该服务自身向第三方出售或以其他形式提供。
                否则，小牛建讯有权停止向您提供服务，且您应支付相当于您因前述转售行为所获利益的违约金。
            </p>
            <p>
                1.11 您在使用小牛建讯服务时，
                与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由您自行负担。
            </p>
            <h2>
                <strong>二、VIP会员服务条款</strong>
            </h2>
            <p>
                当您通过小牛建讯官方途径注册成为小牛建讯VIP会员（以下简称&ldquo;
                <strong>VIP会员</strong>&rdquo;）时，
                您在使用VIP会员服务的同时，须一并遵守本条的以下规定。为避免疑义，本条所指&ldquo;VIP会员账号&rdquo;或&ldquo;账号&rdquo;，
                包括企业套餐项下的子账号。
            </p>
            <h3>
                <strong>（一）</strong>
                <strong>成为VIP会员的方式</strong>
            </h3>
            <p>
                2.1.1 在成为VIP会员之前，您应先根据真实、准确信息注册成为小牛建讯用户。
                当您的个人资料发生变化，应及时修改，否则由此造成的VIP会员权利不能全面有效行使的责任，
                由您自身承担，小牛建讯有权因此取消您VIP会员资格，无须退还VIP会员费或给予其他任何形式的赔偿或补偿。
            </p>
            <p>
                2.1.2 您应通过小牛建讯指定的购买渠道成为小牛建讯VIP会员，获取小牛建讯VIP服务。
            </p>
            <p>
                2.1.3
                您应通过小牛建讯指定支付渠道支付小牛建讯VIP会员服务费用（包括网银支付或第三方支付等方式），
                您在支付VIP会员服务费用之前，须认可该项服务标明之价格，确认接受相关的服务条款及支付条款，
                严格按照支付流程操作，并在支付时注意及确保支付环境安全，否则应自行承担全部不利后果。
            </p>
            <p>
                2.1.4
                VIP会员的服务价格以小牛建讯网站上标注的详细资费标价为准，小牛建讯对会员的服务有修改价格、升级服务、增减服务内容等权利。
            </p>
            <p>
                2.1.5
                当您通过小牛建讯指定或明示认可的购买途径及支付途径并按照服务标准完成服务费用支付，
                且小牛建讯通过后台完成VIP会员服务激活时，您即成为小牛建讯VIP会员，小牛建讯会员服务即时生效并开始计算服务期限。
            </p>
            <h3>
                <strong>（二）</strong>
                <strong>VIP会员服务的使用</strong>
            </h3>
            <p>
                2.2.1 小牛建讯向VIP会员提供的全部服务，均仅限于该VIP会员在小牛建讯平台内使用，
                任何以恶意破解等不法手段将小牛建讯服务内容与小牛建讯平台分离的行为（例如恶意修改小牛建讯展示信息），
                均不属于小牛建讯服务。由此引起的一切法律后果由行为人负责，小牛建讯将依法追究行为人的法律责任。
            </p>
            <p>
                2.2.2
                当您开始实际使用小牛建讯各单项服务时，视为您已同意该单项服务的服务条款以及小牛建讯在该单项服务中发出的各类公告的规定（如有）。
            </p>
            <p>
                2.2.3&nbsp;您应自行妥善保管、使用、维护您在小牛建讯申请取得的账号、账号信息及密码，
                并采取必要和有效的保密措施，避免与第三人共享账号及相关服务信息。
                非小牛建讯原因致使您账户密码泄漏以及因您保管、使用、维护不当造成损失的，小牛建讯无须承担与此有关的任何责任。
            </p>
            <p>
                2.2.4&nbsp;VIP会员服务资费标准以小牛建讯网站上标注的详细资费标价为准，
                小牛建讯享有修改价格、升级服务、增减功能等权利。
            </p>
            <p>
                2.2.5&nbsp;VIP会员服务具有固定的使用期限，您一旦成为VIP会员，即视为同意该服务使用期限的相关规定。
            </p>
            <p>
                2.2.6&nbsp;成为VIP会员后，您有权停止或放弃接受小牛建讯服务，并有权注销小牛建讯用户账户，但小牛建讯无须向您退还已支付的任何费用。
            </p>
            <h3>
                <strong>（三）</strong>
                <strong>VIP会员使用规范</strong>
            </h3>
            <p>
                2.3.1 您知悉并同意：VIP会员账号同一时间同一账号仅可在1台移动通信设备上登录使用，
                超出上述范围使用的，将被强制下线。用户应当自行承担超范围使用而导致的任何损失，同时小牛建讯保留追究上述行为人法律责任的权利。
            </p>
            <p>
                2.3.2&nbsp;您知悉并同意：您VIP会员账号内的行为（包括但不限于信息查询、信息发布等）均代表您本人行为，
                您应妥善保管您的账号及密码，通过小牛建讯官方渠道获取和使用VIP会员账号（亦包含通过该账号能够享受的小牛建讯会员服务，下同），
                并对该账号下的所有行为承担责任。
            </p>
            <p>
                <strong>为保障您的账号安全，确保您的账号不被他人违规使用， 您不应通过</strong>
                <strong>小牛建讯</strong>
                <strong>官方渠道之外的其他途径获取或使用</strong>
                <strong>小牛建讯</strong>
                <strong>VIP会员账号， 或为他人通过其他途径获取或使用</strong>
                <strong>小牛建讯</strong>
                <strong>VIP会员账号提供条件。 否则，</strong>
                <strong>小牛建讯</strong>
                <strong
                    >有权视具体违规情节，采取强制修改密码直至封禁相关VIP会员账号等措施，
                    暂停或终止为您提供服务，并无须退还任何费用。违规使用情形包括：</strong
                >
            </p>
            <p>
                (1)
                未经小牛建讯事先明示同意，转让、借用、出租、赠与或以其他方式，为他人使用您的VIP会员账号提供条件；
                <br />(2)
                未经小牛建讯事先明示同意，通过爬虫工具、机器人软件等手段获取VIP会员服务或有关数据信息；
                <br />(3)
                通过系统漏洞、黑色产业、滥用会员身份等不正当手段，侵犯小牛建讯或他人合法权益；
                <br />(4) 其他违反法律、法规或相关监管政策的行为，或违反本协议及其他服务规则的行为。
            </p>
            <p>我们会基于如下标准判断您的账号是否存在前述违规使用情形：</p>
            <p>
                (1) 同一账号近期登录使用设备数量是否异常；
                <br />(2) 同一账号单日查询次数是否异常； <br />(3)
                是否存在其他足以被认定为违规使用的具体行为。
            </p>
            <p>
                如您发现您的账号遭到未经授权的使用时，请您立即通知小牛建讯，我们将协助您找回。
            </p>
            <h2>
                <strong>三、</strong>
                <strong>收费服务</strong>
            </h2>
            <p>
                3.1 小牛建讯的部分服务是以收费方式提供的，如您使用收费服务，请遵守相关的协议。
            </p>
            <p>
                3.2
                小牛建讯可能根据实际需要对收费服务的收费标准、方式进行修改和变更，小牛建讯也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，小牛建讯将在相应服务页面进行通知或公告。如果您不同意上述修改、变更或付费内容，则应停止使用该服务。
            </p>
            <h2>
                <strong>四、</strong>
                <strong>数据来源与责任限制</strong>
            </h2>
            <p>
                4.1&nbsp;&ldquo;小牛建讯&rdquo;是由仕硕科技有限公司开发的一款企业相关数据等公开信息查询工具。根据用户查询条件，
                小牛建讯的搜索引擎系统会以非人工检索方式生成用户检索的已经依法公开的企业信息。小牛建讯目前网站数据来源主要包括：
            </p>
            <p>全国企业信用信息公示系统（https://gsxt.saic.gov.cn）</p>
            <p>中国裁判文书网（https://wenshu.court.gov.cn/Index）</p>
            <p>中国执行信息公开网（https://zhixing.court.gov.cn/search）</p>
            <p>
                全国建筑市场监管公共服务平台（
                <a href="http://jzsc.mohurd.gov.cn/home">http://jzsc.mohurd.gov.cn/home</a>）
            </p>
            <p>
                <strong>小牛建讯</strong>
                <strong>平台所登载的信息均来自于网络公开信息，</strong>
                <strong>小牛建讯</strong>
                <strong
                    >仅对相关网站依法公示的信息向用户如实展示，
                    并不主动编辑或修改被所公示网站上的信息的内容或其表现形式。受限于现有技术水平、各信息来源网站更新不同步等原因，
                    对此类信息的展示，并不视为</strong
                >
                <strong>小牛建讯</strong>
                <strong
                    >对其内容的真实性、准确性、完整性、时效性作出任何形式的确认或担保。
                    请您在依据</strong
                >
                <strong>小牛建讯</strong>
                <strong
                    >服务相关信息作出判断或决策前，自行进一步核实此类信息的完整或准确性，并自行承担使用后果。
                    同时，</strong
                >
                <strong>小牛建讯</strong>
                <strong
                    >承诺将不断提升技术水平，逐步完善服务信息来源质量与更新频次，为您提供更高质量的服务。</strong
                >
            </p>
            <p>
                4.2&nbsp;由于政府禁令、现行生效的适用法律或法规的变更、火灾、地震、瘟疫、战争、停电、通讯线路中断、电信部门技术调整、
                因政府管制而造成网站的暂时性关闭等其他任何影响网络正常运营的不可预见、不可避免、不可克服和不可控制的事件（&ldquo;
                <strong>不可抗力事件</strong>&rdquo;）；
                以及他人蓄意破坏，黑客攻击、计算机病毒，系统维护、系统升级，
                网络过载等技术问题（&ldquo;
                <strong>技术事件</strong
                >&rdquo;）而导致本网站不能访问而造成的本站所提供的信息及数据的延误、停滞或错误，小牛建讯无须承担责任。
                同时，小牛建讯承诺将不断完善技术设施与条件，尽最大努力避免技术事件的发生，在不可抗力事件或技术事件发生时，
                在条件允许的前提下尽最大努力修复，以确保您继续正常使用，最大程度地避免您的损失。
            </p>
            <p>
                4.3&nbsp;请您知悉并同意，由于小牛建讯服务的互联网服务性质，
                因此在服务的各个环节中不可避免地会受到相关不确定因素的影响（如电信运营商、网络质量、您使用的设备型号及系统、您的地理位置等），
                由于前述不确定因素造成您无法正常使用小牛建讯服务的，小牛建讯无须承担任何责任。同时，小牛建讯承诺将在技术条件允许范围内尽最大努力为您解决，
                以确保您能够正常使用。
            </p>
            <h2>
                <strong>五</strong>
                <strong>、</strong>
                <strong>广告</strong>
            </h2>
            <p>
                5.1
                您同意小牛建讯可以在提供服务的过程中自行或由第三方广告商向您发送广告、推广或宣传信息（包括商业与非商业信息），其方式和范围可不经向您特别通知而变更。
            </p>
            <p>
                5.2
                小牛建讯可能为您提供选择关闭广告信息的功能，但任何时候您都不得以本协议未明确约定或小牛建讯未书面许可的方式屏蔽、过滤广告信息。
            </p>
            <p>
                5.3
                小牛建讯依照法律的规定对广告商履行相关义务，您应当自行判断广告信息的真实性并为自己的判断行为负责，
                <strong
                    >除法律明确规定外，您因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，</strong
                >
                <strong>小牛建讯</strong>
                <strong>不承担责任</strong>。
            </p>
            <p>
                5.4
                您同意，对小牛建讯服务中出现的广告信息，您应审慎判断其真实性和可靠性，除法律明确规定外，您应对依该广告信息进行的交易负责。
            </p>
            <h2>
                <strong>六、用户违法行为</strong>
            </h2>
            <p>
                6.1 您在使用本服务时须遵守法律法规，不得利用本服务从事违法违规行为，包括但不限于：
            </p>
            <p>
                （1）发布、传送、传播、储存危害国家安全统一、破坏社会稳定、违反公序良俗、侮辱、诽谤、淫秽、暴力以及任何违反国家法律法规的内容；
            </p>
            <p>
                （2）发布、传送、传播、储存侵害他人知识产权、商业秘密等合法权利的内容；
            </p>
            <p>（3）恶意虚构事实、隐瞒真相以误导、欺骗他人；</p>
            <p>（4）发布、传送、传播广告信息及垃圾信息；</p>
            <p>（5）冒用他人名义使用本服务；</p>
            <p>
                （6）从事不法交易行为，
                如洗钱、恐怖融资、贩卖枪支、毒品、禁药、盗版软件、黄色淫秽物品、其他本公司认为不得使用本服务进行交易的物品等；
            </p>
            <p>
                （7）非法使用他人银行账户（包括信用卡账户）或无效银行账户（包括信用卡账户）交易；
            </p>
            <p>（8）从事任何可能含有电脑病毒或是可能侵害本服务系统、资料之行为；</p>
            <p>（9）其他法律法规禁止的行为或小牛建讯有正当理由认为不适当之行为。</p>
            <p>
                6.2
                如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求小牛建讯给予协助。
                <strong>造成损害的，您应依法予以赔偿，小牛建讯不承担任何责任</strong>。
            </p>
            <p>
                6.3
                如果小牛建讯发现或收到他人举报您发布的信息违反本条约定，小牛建讯有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，小牛建讯有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账号使用，追究法律责任等措施。
            </p>
            <p>
                6.4
                <strong
                    >您违反本条约定，导致任何第三方损害的，您应当独立承担责任；小牛建讯因此遭受损失的，您也应当一并赔偿</strong
                >。
            </p>
            <p>&nbsp;</p>
            <h2>
                <strong>七、</strong>
                <strong>协议的变更</strong>
            </h2>
            <p>
                您知悉并同意，在监管要求更新或业务调整等原因下，我们可能会单方修订本协议部分条款。
                若有重大修订，我们会通过消息推送或其他显著方式向您发出通知，您可以查阅最新版本的协议条款，
                修订后的协议将在通知发出之日起生效。本协议条款修订后，如果您继续使用小牛建讯服务，
                即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用小牛建讯服务。
            </p>
            <h2>
                <strong>八、产品风险提示</strong>
            </h2>
            <p>
                <strong>8.1</strong>
                <strong>您明确知道</strong>
                <strong>小牛建讯</strong>
                <strong>是通过互联网向您提供服务的，若互联网故障会导致</strong>
                <strong>小牛建讯</strong>
                <strong>无法正常向您提供服务。</strong>
                <strong>小牛建讯</strong>
                <strong>不对互联网故障、延迟等原因导致的信息延迟、错误负责。</strong>
            </p>
            <p>
                <strong>8.2</strong>
                <strong>您在使用</strong>
                <strong>小牛建讯</strong>
                <strong>产品过程中，对于</strong>
                <strong>小牛建讯</strong>
                <strong>提供的信息数据内容，您不应将其视作</strong>
                <strong>小牛建讯</strong>
                <strong>明示、默示地承诺信息全部符合实际情况。</strong>
            </p>
            <p>
                <strong>8.3</strong>
                <strong>您必须保护好自己软件和交易账户的用户名与密码，</strong>
                <strong>小牛建讯</strong>
                <strong>对因个人原因导致密码被盗的后果不承担负责</strong>
            </p>
            <h2>
                <strong>九、协议的生效与变更</strong>
            </h2>
            <p>
                9.1
                <strong>您点击或确认注册</strong>
                <strong>小牛建讯</strong>
                <strong>并使用</strong>
                <strong>小牛建讯</strong>
                <strong>的服务即视为您已阅读本协议并接受本协议的约束</strong>。
            </p>
            <p>
                9.2 小牛建讯有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款。
            </p>
            <p>
                9.3
                本协议条款变更后，如果您继续使用小牛建讯提供的软件或服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用小牛建讯提供的软件或服务。
            </p>
            <h2>
                <strong>十、服务的变更、中断、终止</strong>
            </h2>
            <p>
                10.1 小牛建讯可能会对服务内容进行变更，也可能会中断、中止或终止服务。
            </p>
            <p>
                <strong>10.2</strong>
                <strong>您理解并同意，</strong>
                <strong>小牛建讯</strong>
                <strong>有权自主决定经营策略。在</strong>
                <strong>小牛建讯</strong>
                <strong>发生合并、分立、收购、资产转让时，</strong>
                <strong>小牛建讯</strong>
                <strong>可向第三方转让本服务下相关资产；</strong>
                <strong>小牛建讯</strong>
                <strong
                    >也可在单方通知您后，将本协议下部分或全部服务转交由第三方运营或履行。具体受让主体以</strong
                >
                <strong>小牛建讯</strong>
                <strong>通知的为准。</strong>
            </p>
            <p>
                10.3 如发生下列任何一种情形，小牛建讯有权不经通知而中断或终止向您提供的服务：
            </p>
            <p>
                <strong
                    >（1）根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；</strong
                >
            </p>
            <p>
                <strong>（2）您违反相关法律法规或本协议的约定；</strong>
            </p>
            <p>
                <strong>（3）按照法律规定或主管部门的要求；</strong>
            </p>
            <p>
                <strong>（4）出于安全的原因或其他必要的情形。</strong>
            </p>
            <p>
                10.4
                小牛建讯有权按本协议3.2条的约定进行收费。若您未按时足额付费，小牛建讯有权中断、中止或终止提供服务。
            </p>
            <p>
                10.5
                您有责任自行备份存储在本服务中的数据。如果您的服务被终止，小牛建讯可以从服务器上永久地删除您的数据,但法律法规另有规定的除外。服务终止后，小牛建讯没有义务向您返还数据。
            </p>
            <h2>
                <strong>十一、争议解决及其他</strong>
            </h2>
            <p>
                11.1&nbsp;本协议的成立、生效、履行、解释及争议解决，应适用中华人民共和国大陆地区法律（为避免疑义，不包括冲突法）。
            </p>
            <p>
                <strong>11.</strong>
                <strong>2</strong>
                <strong>&nbsp;</strong>
                <strong>若您就本协议或在使用</strong>
                <strong>小牛建讯</strong>
                <strong>服务过程中与</strong>
                <strong>小牛建讯</strong>
                <strong
                    >之间发生任何纠纷或争议，
                    首先应友好协商解决；一方以书面形式的协商意见发出之日起三十（30）日后，
                    双方仍未就争议解决方案达成一致的，应将该争议提交</strong
                >
                <strong>仕硕科技</strong>
                <strong>有限公司所在地有管辖权的人民法院诉讼解决。</strong>
            </p>
            <p>
                11.3&nbsp;本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
            </p>
            <p>
                11.4&nbsp;本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
            </p>
            <p>
                11.5&nbsp;当您采用纸质合同或其他纸质文件（以下统称&ldquo;
                <strong>纸质合同</strong>&rdquo;）的形式购买小牛建讯服务时，
                您亦获得小牛建讯用户身份，享有本协议项下的权利，并应履行本协议项下的义务。纸质合同未尽事宜，以本协议规定为准。
            </p>
            <p>&nbsp;</p>
            <p>仕硕科技有限公司</p>
            <p>&nbsp;</p>
        </div>
        <div slot="footer">
            <a-button type="primary" @click="closeModal">确认</a-button>
        </div>
    </a-modal>
</template>

<script>
export default {
    name: 'user-service',
    data() {
        return {
            show: false
        }
    },
    methods: {
        closeModal() {
            this.show = false
        },
        showModal() {
            this.show = true
        }
    }
}
</script>

<style>
.agreement-detail {
    height: 500px;
    overflow-y: scroll;
}
</style>
