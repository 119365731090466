<template>
  <div id="qualificationSelect" class="qualification-select">
    <div v-for="(qua, index) in item.list" :key="index" class="select-item">
      <tree-checkbox-single-select
          v-model="qua.qualificationId"
          :designQualificationOptions="designQualificationOptions"
          :fakeQualification="fakeQualification"
          :qualificationOptions="qualificationOptions"
          class="tree-select"
          containerId="qualificationSelect"
          @isSetFormVaue="setOptionDisabled"
      ></tree-checkbox-single-select>
      <!-- <a-tree-select
  @change="selectQualification"
  v-model="qua.qualificationId"
  allow-clear
  class="tree-select"
  treeNodeFilterProp="label"
  treeDefaultExpandAll
  treeNodeLabelProp="label"
  showSearch
  :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
  placeholder="请输入选择资质"
>


  <a-tree-select-node
    v-for="qua in quaOptions"
    :key="qua.key"
    :value="qua.value"
    :selectable="false"
    :checkable="false"
    :label="qua.label"
    :title="qua.title"
  >
    <a-tree-select-node
      v-for="children in qua.children"
      :key="children.key"
      :value="children.value"
      :disabled="children.disabled"
      :label="children.label"
      :title="children.title"
    ></a-tree-select-node>
  </a-tree-select-node>
</a-tree-select> -->
      <a-button v-show="index === 0" class="btns" icon="plus" @click="addMoreQualification"
      >添加更多资质
      </a-button
      >
      <a-button
          v-show="index !== 0"
          class="btns"
          icon="delete"
          type="danger"
          @click="removeThisQualification(index)"
      >
        移除此条资质
      </a-button>
    </div>
  </div>
</template>

<script>
// import { universalModule } from '@/store/modules/universal'
import {TreeSelect} from 'ant-design-vue'
import TreeCheckboxSingleSelect from '@/components/TreeCheckboxSingleSelect.vue'
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'qualification-select',
  components: {
    aTreeSelect: TreeSelect,
    aTreeSelectNode: TreeSelect.TreeNode,
    TreeCheckboxSingleSelect
  },
  props: {
    item: {
      type: [Array, Object],
      defautl() {
        return {}
      }
    }
  },

  computed: {
    ...mapState({
      designQualificationOptions: state => state.designQualificationOptions,
      qualificationOptions: state => state.typeQualificationOptions,

    })
  },
  data() {
    return {
      fakeQualification: [],
      // qualificationOptions: [],
      qualifications: [],
      quaOptions: []
    }
  },
  methods: {
    ...mapMutations([
      'setFakerQualification',
      'setQualification',
      'setShowLoginModal'
    ]),
    removeThisQualification(index) {
      this.$emit('removeThisQualification', index)
      this.setOptionDisabled()
    },
    addMoreQualification() {
      this.setOptionDisabled()
      this.$emit('addMoreQualification')
    },
    // selectQualification() {
    //   this.setOptionDisabled()
    // },
    setOptionDisabled() {
      // console.log(this.item.list)
      const specialArr = []
      this.qualificationOptions.forEach(q => {
        let find = undefined
        q.children.forEach(c => {
          c.children.forEach(e => {
            find = this.item.list.find(l => {
              return e.id === l.qualificationId
            })
            e.disabled = false
            if (find) {
              specialArr.push(c)
            }
          })
        })
      })
      // console.log(specialArr)
      specialArr.forEach(s => {
        s.children.forEach(e => {
          e.disabled = true
        })
      })
      // this.qualificationOptions.forEach((q) => {
      //   q.children.forEach((c, i) => {
      //     if (specialArr.includes(i)) {
      //       console.log(i)
      //     }
      //   })

      // })
    },
    getTypeQualification() {
      this.$http.get('qualification/selQualificationAllByIndustry').then(r => {
        this.setQualification(r)
      })
    },
    getQalicationList() {
      // 获取资质列表
      this.fakeQualification = []
      this.$http.get('qualification/selQualificationAll').then(r => {
        r.forEach(e => {
          e.qualificationLevels.forEach(q => {
            this.fakeQualification.push({
              label: e.qualificationName + q.level,
              value: q.id
            })
          })
        })
        this.setFakerQualification(this.fakeQualification)
      })
    }
  },
  mounted() {
    this.getTypeQualification()
    // universalModule.getQualification()
    this.getQalicationList()
  },
  watch: {
    qualificationOptions(val) {
      this.quaOptions = this.$deepCopy(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.qualification-select {
  .select-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .tree-select {
      width: 75%;
    }

    .btns {
      margin-left: 20px;
    }
  }
}
</style>
