<template>
    <div @click="closePrview" class="image-preview">
        <div class="image-content" :style="'background-image:url(' + imageUrl + ')'">
            <a-icon @click="closePrview" class="close-icon" type="close-circle" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'image-preview',
    props: {
        imageUrl: {
            type: String,
            default: ''
        }
    },
    methods: {
        closePrview() {
            this.$emit('closePrview')
        }
    }
}
</script>

<style lang="scss" scoped>
.image-preview {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1010;
    background-color: rgba($color: #000000, $alpha: 0.3);
    .image-content {
        width: 70%;
        height: 70%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        .close-icon {
            font-size: 28px;
            color: #ffffff;
            position: absolute;
            right: 0px;
            top: -20px;
            cursor: pointer;
        }
    }
}
</style>
