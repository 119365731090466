<template>
  <div class="vip-page">
    <div style="background-image:url('http://cdn.public.xnjx666.com//upload/gw/static/img/vip_bg.png')"
      class="version-choose">
      <div class="main-title">加入VIP，享受更多服务</div>
      <div class="vip-carousel">
        <img :src="laba" alt="小牛建讯" />
        <a-carousel :autoplaySpeed="10000" :dots="false" autoplay dot-position="right">
          <div v-for="(item, key) in fakeNews" :key="key" class="news">
            <span>{{ item }}</span>
          </div>
        </a-carousel>
        <a-carousel :autoplaySpeed="10000" :dots="false" autoplay dot-position="right">
          <div v-for="(item, key) in fakeNewsList" :key="key" class="news">
            <span>{{ item }}</span>
          </div>
        </a-carousel>
      </div>
      <div class="surplusTime-title" v-if="showSpringFrame">
        VIP会员调价倒计时：
        {{ this.surplusTime }}
      </div>
      <div class="choose-content">
        <div v-for="(item, key) in versionList" :key="key" :class="{
          versonSelect: selectIndex === key,
          version: showSpringFrame === true,
          versionSpringFrame: showSpringFrame === false
        }" @mouseleave="mouseLeave(key)" @mouseover="mouseOver(key)">
          <div v-if="item.type && showSpringFrame" class="discount">
            限时优惠
          </div>
          <div class="hotSale" v-if="item.type === 'enterprisePlus' && !showSpringFrame">
            <img :src="hotSale" width="64px" height="28px" alt="小牛建讯" />
          </div>

          <div class="title">
            <p :class="{
              selectedTitle: key === 0 || key === 2,
              noSelectedTitle: key === 1 || key === 3 || key === 4
            }">
              {{ item.title }}
            </p>
            <span class="subtitle">
              {{ item.subtitle }}
            </span>
          </div>
          <div v-if="item.tips" class="versiontips">{{ item.tips }}</div>
          <div v-if="item.type" class="price">
            <div class="price-item">
              <div v-if="showSpringFrame">
                <div class="number">
                  <span class="now">当前<span> ¥ </span></span>
                  <span class="point">{{ item.amount }}</span>
                  <span class="year">/年</span>
                  <!-- <span style="font-size:14px ">元</span> -->
                </div>
                <div class="average">
                  <div v-if="item.oldAmount === '下架停售'" class="personal">
                    <span class="last">调后</span>
                    <span class="trimPrice">{{ item.oldAmount }}</span>
                  </div>
                  <div v-if="item.oldAmount !== '下架停售'" class="otherPrice">
                    <span class="last">调后<span> ¥ </span> </span>
                    <span class="trimPrice">{{ item.oldAmount }}</span>
                    <span class="year">/年</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="showSpringFrameNumber">
                  <span class="number">¥</span>
                  <span class="point">{{ item.amount }}</span>
                  <span class="year">/年</span>
                  <!-- <span style="font-size:14px ">元</span> -->
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.type === ''" class="group">
            <p class="groupTitle">主账号可以增减子账号<br />解决人员流动问题</p>
            <ul>
              <li class="info">
                <span>系统集成</span> <span>界面风格定制</span>
              </li>
              <li>
                <span>个性化需求</span>
              </li>
            </ul>
          </div>
          <div>
            <a-button class="open-btns" @click="openPayMent(item, key)">{{ item.text }}
            </a-button>
          </div>
          <!--          <div v-if="item.type" class="other">-->
          <!--            支付后可开发票，详情咨询客服<br/>13438870357-->
          <!--          </div>-->
          <!--          <div v-if="item.type === ''" class="other">-->
          <!--            如需开通定制版请联系客服查询报价<br/>客服热线：13438870357-->
          <!--          </div>-->
        </div>
      </div>
      <div class="customerService">
        所有版本的会员只要支付后都可开发票，详情咨询客服：13438870357
      </div>
    </div>

    <div class="contrast-table">
      <div id="tableWarp" class="table-warp">
        <div v-show="showFakeTableHead" :style="fakeClass" class="fake-title">
          <div class="fake-table-head">服务介绍</div>
          <div class="fake-table-head">免费体验版<br />(有效期3天)</div>
          <div class="fake-table-head">VIP个人版</div>
          <div class="fake-table-head">VIP集团版</div>
          <div class="fake-table-head">VIP企业版</div>
          <div class="fake-table-head">VIP定制版</div>
        </div>
        <a-table :columns="columns" :data-source="tableData" :pagination="false" bordered class="table" rowKey="index">
          <div slot="descriptionTitle" class="table-column-title">服务介绍</div>
          <div slot="freeTitle" class="table-column-title">
            免费体验版<br /><span class="info">(有效期3天)</span>
          </div>
          <div slot="personTitle" class="table-column-title">
            VIP个人版 <br />
<!--            <span class="personTitleInfo" v-if="!showSpringFrame">已下架</span>-->
          </div>
          <div slot="enterpriseTitle" class="table-column-title">
            VIP团队版
            <p class="adjustmentText" v-if="showSpringFrame">
              调价后更名为:<br />VIP团队版
            </p>
          </div>
          <div slot="enterprisePlusTitle" class="table-column-title">
            VIP企业版
            <p class="adjustmentText" v-if="showSpringFrame">
              调价后更名为:<br />VIP企业版
            </p>
          </div>
          <div slot="customerTitle" class="table-column-title">VIP集团版</div>
          <div slot="teamTitle" class="table-column-title">VIP定制版</div>
          <template slot="description" slot-scope="item">
            <div class="service-title">{{ item.title }}</div>
            <p class="service-text">{{ item.text }}</p>
          </template>
          <template slot="check" slot-scope="item">
            <div v-if="item === 'check'">
              <a-icon type="check" />
            </div>
            <div v-else>{{ item }}</div>
          </template>
        </a-table>
      </div>
    </div>
    <div class="product">
      <div class="productTitle">
        <div class="productTitleTop">
          <div class="productTitleText">
            为什么大家都使用小牛建讯开展资质服务？
          </div>
        </div>
      </div>
      <div class="productMain">
        <ul>
          <li>
            <div class="productMainText  singular">
              <p>数据全面、精准、更新快</p>
              <p>
                智能AI大数据架构，全网数据自动更新，结合人工24小时内手动抽样校验，保证数据更新快的同时更加精准有效输入搜索关键筛选条件，3秒即可查询全网建筑资质信息。
              </p>
              <a-button class="productMainTextBtn" type="primary" @click="goToProduct">立即体验
              </a-button>
            </div>
            <img src="http://cdn.public.xnjx666.com//upload/gw/static/img/describe1.png" alt="小牛建讯" />
          </li>
          <li>
            <img class="dbNum" src="http://cdn.public.xnjx666.com//upload/gw/static/img/describe2.png" alt="小牛建讯" />
            <div class="productMainText">
              <p>更懂您的营销系统</p>
              <p>
                20年资深资质团队全程参与研发设计，懂行情、懂政策，更懂资质服务需要什么！资质查询、资质社区、进出货查询，量身为资质服务定制的营销系统！
              </p>
              <a-button class="productMainTextBtn" type="primary" @click="goToProduct">立即体验
              </a-button>
            </div>
          </li>
          <li>
            <div class="productMainText singular">
              <p>多维筛选覆盖全网95%资质信息</p>
              <p>
                100+筛选维度，覆盖全网95%建筑资质信息，3s精准锁定目标客户；操作简单，全程小牛VIP客服一对一服务，体验更轻松。
              </p>
              <a-button class="productMainTextBtn" type="primary" @click="goToProduct">立即体验
              </a-button>
            </div>
            <img src="http://cdn.public.xnjx666.com//upload/gw/static/img/describe3.png" alt="小牛建讯" />
          </li>
        </ul>
      </div>
    </div>
    <a-modal :visible="modalVisible" centered :closable="false" width="880px" @cancel="closeModal">
      <div slot="title">
        <div style="height:44px; display:flex;align-items:center;justify-content: space-between">
          <div style="color:#181818;font-size: 20px;font-weight:500">
            小牛建讯产品服务
          </div>
          <a-icon style="color:#686868" @click="closeModal" :component="closeVue" />
        </div>
      </div>
      <div class="buy-info">
        <div class="info-item">
          <span class="info-title">订购服务：</span>
          <span class="info-text"> {{ buyType }}</span>
        </div>
        <div class="info-item">
          <span class="info-title"> 团队人数：</span>
          <span class="info-text">
            {{ teamNum }}
          </span>
        </div>
        <div class="info-item">
          <span class="info-title">有效期：</span>
          <span class="info-text">{{ buyTime }} </span>
        </div>
      </div>
      <div class="buy-methods">
        <div class="amount">
          <div class="amount-title">订单金额：</div>
          <div class="amount-num">￥{{ buyAmount }}.00</div>
        </div>
        <div class="method-btns">
          <div class="method-btn" @click="selectPayMethods(1)" :class="{ isSelectMethod: methodIndex === 1 }">
            <img :src="methodIndex === 1 ? transPayW : transPay" width="36" style="margin-bottom:8px" />
            对公转账
          </div>
          <div class="method-btn" @click="selectPayMethods(2)" :class="{ isSelectMethod: methodIndex === 2 }">
            <img :src="methodIndex === 2 ? aliPayW : aliPay" width="36" style="margin-bottom:8px" />
            支付宝
          </div>
        </div>
        <div class="method-info">
          <div v-if="methodIndex === 1" class="bank">
            <img :src="bankPay" class="bank-logo" />
            <div class="bank-info">
              <div class="bank-list-item">
                <div class="text-light">开户名称：</div>
                <div class="text-primary">仕硕科技有限公司</div>
              </div>
              <div class="bank-list-item">
                <div class="text-light">开户银行：</div>
                <div class="text-primary">
                  招商银行股份有限公司成都世纪城支行
                </div>
              </div>
              <div class="bank-list-item">
                <div class="text-light">账号：</div>
                <div class="text-primary">1289 1170 1910 501</div>
              </div>
              <div class="bank-list-item">
                <div class="text-light">转账备注：</div>
                <div class="text-warning">小牛建讯注册账号</div>
              </div>
            </div>
            <div class="copy-icon" @click="copyTransInfo">
              <img :src="copyIcon" width="18" height="18" />复制转账信息
            </div>
          </div>

          <div class="pay-tips">
            <div>说明：</div>
            <div>
              1.充值成功后，1个工作日内开通<br />
              2.请保留支付凭证，联系客服开具发票，服务电话：13438870357
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <a-button type="primary" @click="confirmOrder">确认</a-button>
      </div>
    </a-modal>

    <a-modal :visible="showAliPayModal" centered width="880px" :closable="false" @cancel="closePayModal">
      <div slot="title">
        <div style="height:44px; display:flex;align-items:center;justify-content: space-between">
          <div style="color:#181818;font-size: 20px;font-weight:500">
            正在支付……
          </div>
          <a-icon style="color:#686868" @click="closePayModal" :component="closeVue" />
        </div>
      </div>
      <div style="display:flex;align-items:center">
        <img :src="mascot" style="margin-right:80px" width="226" height="274" />
        <div style="font-size: 16px;color:#181818">
          <div style="margin-bottom:16px">支付成功了</div>
          <div @click="goToOrderPage" style="margin-bottom:60px;cursor:pointer;color:#00ab94">
            立即查看订单详情>>
          </div>
          <div style="margin-bottom:16px">如果支付失败了</div>
          <div style="margin-bottom:16px;color:#FF7F28">
            请联系客服：400-903-8966
          </div>
        </div>
      </div>
      <div slot="footer">
        <a-button type="primary" @click="closePayModal">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import bg from '@/assets/bg_1.png'
import wechatPay from '@/assets/wechatPay.jpg'
import laba from '@/assets/vipImage/laba.png'
import hotSale from '@/assets/vipImage/hotSale.png'
import bankPay from '@/assets/vipImage/bank.png'
import aliPay from '@/assets/vipImage/zhifubao.png'
import aliPayW from '@/assets/vipImage/zhifubao_w.png'
import transPay from '@/assets/vipImage/trans.png'
import transPayW from '@/assets/vipImage/trans_w.png'
import copyIcon from '@/assets/vipImage/copy_icon.png'
import closeVue from '@/components/icon/closeIcon.vue'
import mascot from '@/assets/vipImage/mascot.png'
import Cookies from 'js-cookie'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  name: 'vip-page',
  computed: {
    ...mapState(['surplusTime', 'showSpringFrame', 'userInfo']),
    projectEnv() {
      if (process.env.VUE_APP_TYPE) {
        return process.env.VUE_APP_TYPE
      } else {
        return 'online'
      }
    }
  },
  data() {
    return {
      closeVue,
      transPayW,
      aliPayW,
      copyIcon,
      // surplusTime:'0天0时0分0秒',\
      mascot,
      transPay,
      hotSale,
      timer: '',
      laba,
      showFakeTableHead: false,
      bg,
      wechatPay,
      aliPay,
      bankPay,
      buyType: '',
      buyTime: '',
      buyAccount: '',
      buyAmount: '',
      payMethods: [
        { name: '对公转账', value: 1, icon: 'bank' },
        { name: '支付宝支付', value: 2, icon: 'alipay' }
        // { name: "微信支付", value: 3, icon: "wechat" }
      ],
      methodIndex: 1,
      modalTitle: '',
      modalVisible: false,
      selectIndex: 0,
      fakeNews: [],
      fakeNewsList: [],
      teamNum: 10,
      oneYearOld: 7200,
      twoYearOld: 13800,
      oneYear: 5680,
      twoYear: 9988,
      selectTeamIndex: 1,
      bill: '',
      columns: [
        {
          dataIndex: 'description',
          width: '324px',
          align: 'center',
          key: 'description',
          slots: { title: 'descriptionTitle' },
          scopedSlots: { customRender: 'description' }
        },
        {
          dataIndex: 'free',
          width: '146px',
          align: 'center',
          slots: { title: 'freeTitle' },
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {}
            }
            if (index === 3) {
              obj.attrs.rowSpan = 3
            } else if (index === 4) {
              obj.attrs.rowSpan = 0
            } else if (index === 5) {
              obj.attrs.rowSpan = 0
            } else if (index === 6) {
              obj.attrs.rowSpan = 2
            } else if (index === 7) {
              obj.attrs.rowSpan = 0
            }
            return obj
          }
        },
        {
          dataIndex: 'person',
          width: '146px',
          align: 'center',
          slots: { title: 'personTitle' },
          scopedSlots: { customRender: 'check' },
          customRender: (value, row, index) => {
            let obj = {
              children: value,
              attrs: {}
            }
            if (index === 3) {
              obj.attrs.rowSpan = 3
            } else if (index === 4) {
              obj.attrs.rowSpan = 0
            } else if (index === 5) {
              obj.attrs.rowSpan = 0
            } else if (index === 6) {
              obj.attrs.rowSpan = 2
            } else if (index === 7) {
              obj.attrs.rowSpan = 0
            } else {
              obj = {
                children: value === 'check' ? <a-icon type="check" /> : value,
                attrs: {}
              }
            }
            return obj
          }
        },
        {
          dataIndex: 'enterprise',
          width: '146px',
          align: 'center',
          slots: { title: 'enterpriseTitle' },
          scopedSlots: { customRender: 'check' },
          customRender: (value, row, index) => {
            let obj = {
              children: value,
              attrs: {}
            }
            if (index === 3) {
              obj.attrs.rowSpan = 3
            } else if (index === 4) {
              obj.attrs.rowSpan = 0
            } else if (index === 5) {
              obj.attrs.rowSpan = 0
            } else if (index === 6) {
              obj.attrs.rowSpan = 2
            } else if (index === 7) {
              obj.attrs.rowSpan = 0
            } else {
              obj = {
                children: value === 'check' ? <a-icon type="check" /> : value,
                attrs: {}
              }
            }
            return obj
          }
        },
        {
          dataIndex: 'enterprisePlus',
          width: '146px',
          align: 'center',
          slots: { title: 'enterprisePlusTitle' },
          scopedSlots: { customRender: 'check' },
          customRender: (value, row, index) => {
            let obj = {
              children: value,
              attrs: {}
            }
            if (index === 3) {
              obj.attrs.rowSpan = 3
            } else if (index === 4) {
              obj.attrs.rowSpan = 0
            } else if (index === 5) {
              obj.attrs.rowSpan = 0
            } else if (index === 6) {
              obj.attrs.rowSpan = 2
            } else if (index === 7) {
              obj.attrs.rowSpan = 0
            } else {
              obj = {
                children: value === 'check' ? <a-icon type="check" /> : value,
                attrs: {}
              }
            }
            return obj
          }
        },
        {
          dataIndex: 'customer',
          width: '146px',
          align: 'center',
          slots: { title: 'customerTitle' },
          scopedSlots: { customRender: 'check' },
          customRender: (value, row, index) => {
            let obj = {
              children: value,
              attrs: {}
            }
            if (index === 3) {
              obj.attrs.rowSpan = 3
            } else if (index === 4) {
              obj.attrs.rowSpan = 0
            } else if (index === 5) {
              obj.attrs.rowSpan = 0
            } else if (index === 6) {
              obj.attrs.rowSpan = 2
            } else if (index === 7) {
              obj.attrs.rowSpan = 0
            } else {
              obj = {
                children: value === 'check' ? <a-icon type="check" /> : value,
                attrs: {}
              }
            }
            return obj
          }
        },
        {
          dataIndex: 'team',
          align: 'center',
          width: '146px',
          slots: { title: 'teamTitle' },
          scopedSlots: { customRender: 'check' },
          customRender: (value, row, index) => {
            let obj = {
              children: value,
              attrs: {}
            }
            if (index === 3) {
              obj.attrs.rowSpan = 3
            } else if (index === 4) {
              obj.attrs.rowSpan = 0
            } else if (index === 5) {
              obj.attrs.rowSpan = 0
            } else if (index === 6) {
              obj.attrs.rowSpan = 2
            } else if (index === 7) {
              obj.attrs.rowSpan = 0
            } else {
              obj = {
                children: value === 'check' ? <a-icon type="check" /> : value,
                attrs: {}
              }
            }
            return obj
          }
        }
      ],
      tableData: [],
      tablePosition: {},
      fakeClass: {},
      contrastData: [
        {
          description: {
            title: '主从账号设置和管理',
            text: '主账号可以增减子账号，解决人员流动问题，主账号统一开发票'
          },
          free: '-',
          person: '-',
          enterprise: 'check',
          team: 'check',
          customer: 'check',
          enterprisePlus: 'check'
        },
        {
          description: {
            title: '团队人数',
            text: '主从账号上限总数，解决人员流动问题，团队协作必备'
          },
          free: '1',
          person: '1',
          enterprise: '10',
          team: '无上限',
          customer: '50',
          enterprisePlus: '20'
        },
        {
          description: {
            title: '数据分析',
            text:
              '重点省份资质数据分析，新成立无资质企业，申报资质企业，审批未通过企业'
          },
          free: '-',
          person: 'check',
          enterprise: 'check',
          team: 'check',
          customer: 'check',
          enterprisePlus: 'check'
        },
        {
          description: {
            title: '收货查询',
            text: '多条件组合查询定位客户，资质情况，企业工商、风险信息'
          },
          free: '20次/天',
          person: '50次/天',
          enterprise: '2000次/天',
          team: '不限次',
          customer: '25000次/天',
          enterprisePlus: '4000次/天'
        },
        {
          description: {
            title: '出货查询',
            text: '有无资质分别查询资质相关性推荐，出货成功率更高'
          },
          free: '20次/天',
          person: '300次/天',
          enterprise: '2000次/天',
          team: '不限次',
          customer: '25000次/天',
          enterprisePlus: '4000次/天'
        },
        {
          description: {
            title: '人员缺口名录',
            text:
              '平台自动检测企业资质人员配备情况，根据查询条件智能推荐有人员需求的相关企业'
          },
          free: '20次/天',
          person: '50次/天',
          enterprise: '2000次/天',
          team: '不限次',
          customer: '25000次/天',
          enterprisePlus: '4000次/天'
        },
        {
          description: {
            title: '供需配对',
            text: '系统自动匹配供求信息，配对成功，短信互推，第一时间抓住商机'
          },
          free: '-',
          person: '-',
          enterprise: '-',
          team: '-',
          customer: '-',
          enterprisePlus: '-'
        },
        {
          description: {
            title: '中介资源',
            text: '重点省份中介及资源分布情况'
          },
          free: '-',
          person: 'check',
          enterprise: 'check',
          team: 'check',
          customer: 'check',
          enterprisePlus: 'check'
        },
        {
          description: {
            title: '公示公告',
            text: '重点地区批前公示、批后公告、控股变化企业信息查询'
          },
          free: '-',
          person: 'check',
          enterprise: 'check',
          team: 'check',
          customer: 'check',
          enterprisePlus: 'check'
        },
        {
          description: {
            title: '知识库',
            text: '政策文件,资质百科、标准、问答等应有尽有'
          },
          free: '-',
          person: 'check',
          enterprise: 'check',
          team: 'check',
          customer: 'check',
          enterprisePlus: 'check'
        },
        {
          description: {
            title: '我的客户',
            text: '客户跟踪管理,收货/出货数据统一管理'
          },
          free: '-',
          person: '个人记录',
          enterprise: '团队记录',
          team: '集团记录',
          customer: '集团记录',
          enterprisePlus: '企业记录'
        },
        {
          description: {
            title: '定制服务',
            text: '  更多个性化定制服务'
          },
          free: '-',
          person: '-',
          enterprise: '-',
          team: 'check',
          customer: '-',
          enterprisePlus: '-'
        },
        {
          description: {
            title: '专属客服',
            text: '1对1专属客服服务，赋能资质服务'
          },
          free: '-',
          person: '免费',
          enterprise: '免费',
          team: '免费',
          customer: '免费',
          enterprisePlus: '免费'
        }
      ],
      versionList: [
        {
          title: 'VIP企业Plus版会员',
          subtitle: '调价后更名为：VIP企业版',
          type: 'enterprisePlus',
          version: 'V_ENTERPRISE_PLUS_VIP',
          versionCN: 'VIP企业版',
          amount: '29800',
          oldAmount: '25800',
          teamNum: 20,
          text: '立即开通',
          tips: '最多可支持20个账号使用'
        },
        {
          title: 'VIP个人版会员',
          type: 'person',
          versionCN: 'VIP个人版',
          version: 'V_PERSONAL_VIP',
          amount: '3200',
          oldAmount: '下架停售',
          text: '立即开通',
          teamNum: 1,
          tips: ''
        },
        {
          title: 'VIP企业版会员',
          subtitle: '调价后更名为：VIP团队版',
          type: 'enterprise',
          versionCN: 'VIP团队版',
          version: 'V_ENTERPRISE_VIP',
          amount: '19800',
          oldAmount: '16800',
          text: '立即开通',
          teamNum: 10,
          tips: '最多可支持10个账号使用'
        },

        {
          title: 'VIP集团版会员',
          type: 'team',
          amount: '46800',
          versionCN: 'VIP集团版',
          version: 'V_TEAM_VIP',
          oldAmount: '35800',
          text: '立即开通',
          teamNum: 50,
          tips: '账号数量无上限!'
        },
        {
          title: 'VIP定制版会员',
          type: '',
          amount: '',
          oldAmount: '',
          text: '立即开通',
          tips: ''
        }
      ],
      versionData: '',
      aliOrderId: '',
      checkInval: null,
      showAliPayModal: false
    }
  },
  methods: {
    ...mapMutations(['setShowLoginModal']),
    ...mapActions(['getEndTime']),
    copyTransInfo() {
      copyThisText(
        '仕硕科技有限公司，招商银行股份有限公司成都世纪城支行，1289 1170 1910 501，小牛建讯注册账号'
      )
      this.$message.success('转账信息已复制到粘贴板')
    },
    mouseOver(key) {
      this.selectIndex = key
    },
    mouseLeave(key) {
      this.selectIndex = key
    },
    setBillValue() {
      switch (this.selectTeamIndex) {
        case 1:
          this.bill = this.oneYear
          break
        case 2:
          this.bill = this.twoYear
          break
        default:
          this.bill = ''
          break
      }
    },
    closeModal() {
      this.modalVisible = false
      this.methodIndex = 1
    },
    closePayModal() {
      clearInterval(this.checkInval)
      this.showAliPayModal = false
    },
    goToOrderPage() {
      // 开发环境
      if (this.projectEnv === 'dev') {
        window.open('http://localhost:8080/#/longna/userCenter/memberOrder')
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open(
          'http://www.xnjx666.com:9000/#/longna/userCenter/memberOrder'
        )
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open('http://192.168.0.108:8099/#/longna/userCenter/memberOrder')
      }
    },
    goToXiaoniuReceipt() {
      // 开发环境
      if (this.projectEnv === 'dev') {
        window.open('http://localhost:8080/#/longna/receipt')
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open('http://www.xnjx666.com:9000/#/longna/receipt')
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open('http://192.168.0.108:8099/#/longna/receipt')
      }
    },
    openPayMent(item, index) {
      if (index === 3) {
        window.open(
          'http://p.qiao.baidu.com/cps/chat?siteId=16126914&userId=29006515&siteToken=021ffcc1cc48017af59aa14863832a01'
        )
      } else {
        this.beforeSendHttp().then(res => {
          if (res) {
            // const account = Cookies.get('memberName')
            let modalData = this.versionList.find((item, key) => key === index)
            this.buyService(modalData).then(buyRes => {
              if (buyRes) {
                this.buyType = modalData.versionCN
                this.buyTime = '1年'
                this.teamNum = modalData.teamNum
                this.buyAmount = modalData.amount
                this.modalVisible = true
                this.versionData = modalData.version
              }
            })
          }
        })
      }
    },
    // 确认订单
    confirmOrder() {
      if (this.userInfo.roleLevel !== 2) {
        this.$message.warning('请使用公司管理员账号付款。')
        return
      }
      const params = {
        jumpAddress: 'longna/userCenter/memberOrder',
        // jumpAddress: 'vip',
        orderService: this.versionData,
        downTradeType: 'WEBSITE_PAY',
        payMode: this.methodIndex === 1 ? 'TRANSFER_ACCOUNTS' : 'ALIPAY'
      }
      this.$http.get('order/createOrder', params).then(res => {
        if (this.methodIndex === 1) {
          this.$message.success('您的订单已创建成功，请立即支付')
          this.modalVisible = false
        } else {
          this.aliOrderId = res.orderNum
          this.checkInval = setInterval(() => {
            this.chekOrderStatus()
          }, 5000)
          this.modalVisible = false
          this.showAliPayModal = true
          this.delayCloseModal()
          window.open(res.msg)
        }
      })
    },
    delayCloseModal() {
      setTimeout(() => {
        this.showAliPayModal = false
      }, 30000)
    },
    chekOrderStatus() {
      this.$http
        .get('order/checkOrderStatus', { orderNum: this.aliOrderId })
        .then(res => {
          console.log(res)
          if (res === 'PAYMENT_SUCCESSFUL') {
            this.$message.success('交易成功')
            // this.goBackOrderList()
            clearInterval(this.checkInval)
          }
        })
        .catch(() => {
          clearInterval(this.checkInval)
        })
    },
    // 后台埋点
    async buyService(modalData) {
      // console.log(this.userInfo.version)
      const params = {
        companyVersionTypeEnum: modalData.versionCN,
        orderChannelEnum: 'VIP_PAGE'
      }
      return this.$http.post('order/point/add', params).then(() => {
        // this.isNotpay = false
        // localStorage.setItem('isCreatOrder', '1')
        return true
      })
    },
    goToXiaoniuLogin() {
      // 开发环境
      let url = ''
      if (this.projectEnv === 'dev') {
        url = 'http://localhost:8080/#/login'
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        url = 'http://www.xnjx666.com:9000/#/login'
      } else if (this.projectEnv === 'test') {
        // 测试环境
        url = 'http://192.168.0.108:8099/#/login'
      }
      location.href = url + '?fromUrl=' + location.href
    },
    async beforeSendHttp() {
      try {
        const id = Cookies.get('userId')
        if (!id) {
          this.$message.warning('您暂时未登录，请登录后再进行操作')
          setTimeout(() => {
            this.goToXiaoniuLogin()
          }, 1000)
          throw 'no login'
        } else {
          return true
        }
      } catch (e) {
        console.log('err', e)
        throw e
      }
    },

    selectPayMethods(value) {
      this.methodIndex = value
    },
    setFakeTableHead() {
      if (!this.$isServer) {
        const tableDom = document.getElementById('tableWarp')
        this.tablePosition = tableDom.getBoundingClientRect()
        this.fakeClass = {
          width: this.tablePosition.width + 'px',
          left: this.tablePosition.left + 'px',
          top: 0
        }
        if (this.tablePosition.top <= 0) {
          //是否固定 table 头部
          this.showFakeTableHead = false
        } else {
          this.showFakeTableHead = false
        }
      }
    },
    goToProduct() {
      this.goToXiaoniuReceipt()
    },
    generateRandomPhone(){
      let phoneList = []
      const start = [153, 158, 157, 182, 188, 184, 130, 131, 132, 134, 135, 136, 137, 138, 139, 152, 178, 189, 159, 186, 187, 185, 150, 155, 170, 199, 156]
      for (let index = 0; index < 100; index++) {
        const p1 = Math.floor(Math.random() * (start.length))
        const p2 = Math.floor(Math.random() * 10)
        const p3 = Math.floor(Math.random() * 10)
        const p4 = Math.floor(Math.random() * 10)
        const p5 = Math.floor(Math.random() * 10)
        const p6 = Math.floor(Math.random() * 15 + 1)
        const item = `${start[p1]}****${p2}${p3}${p4}${p5} ${p6}天前购买了1年${p2 % 2 === 0 ? 'VIP团队版':'VIP企业版'}`
        phoneList.push(item)
      }
      this.fakeNews = phoneList
      this.fakeNewsList = [...phoneList].reverse()
    }
  },
  destroyed() {
    clearInterval(this.checkInval)
  },
  mounted() {
    this.$nextTick(() => {
      this.setBillValue()
      this.$emit('goBackTop')
      const self = this
      window.addEventListener('scroll', self.setFakeTableHead)
    })
    this.contrastData.forEach((e, i) => {
      this.tableData.push({
        ...e,
        index: i
      })
    })
    this.generateRandomPhone()
  },
  created() {
    this.timer = setInterval(this.getEndTime, 1000)
    if (!this.showSpringFrame) {
      this.versionList = [
        {
          title: 'VIP团队版会员',
          type: 'enterprise',
          amount: '19800',
          oldAmount: '16800',
          versionCN: 'VIP团队版',
          version: 'V_ENTERPRISE_VIP',
          text: '立即开通',
          teamNum: 10,
          tips: '最多可支持10个账号使用'
        },
        {
          title: 'VIP企业版会员',
          // subtitle: '调价后更名为：VIP企业版',
          type: 'enterprisePlus',
          versionCN: 'VIP企业版',
          version: 'V_ENTERPRISE_PLUS_VIP',
          amount: '29800',
          oldAmount: '25800',
          text: '立即开通',
          teamNum: 20,
          tips: '最多可支持20个账号使用'
        },
        {
          title: 'VIP集团版会员',
          versionCN: 'VIP集团版',
          version: 'V_TEAM_VIP',
          type: 'team',
          amount: '46800',
          oldAmount: '35800',
          text: '立即开通',
          teamNum: 50,
          tips: '最多支持50个账号使用'
        },
        {
          title: 'VIP定制版会员',
          type: '',
          amount: '',
          oldAmount: '',
          teamNum: '-',
          text: '立即开通',
          tips: ''
        }
      ]
      this.selectIndex = 1
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
    if (!this.$isServer) {
      const self = this
      window.removeEventListener('scroll', self.setFakeTableHead)
    }
  }
}
</script>

<style lang="scss">
.text-light {
  color: #686868;
}

.text-primary {
  color: #282828;
}

.text-warning {
  color: #ff7f28;
}

.addBgc {
  background-color: rgb(230, 247, 255);
  transition: 0.5s;
}

.removeBgc {
  transition: 0.5s;
}

.copy-icon {
  border: 1px solid #2194ff;
  color: #2194ff;
  font-size: 14px;
  display: flex;
  padding: 3px 8px;
  border-radius: 2px;
  width: fit-content;
  position: absolute;
  right: 0;
  cursor: pointer;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  list-style: none;
}

.vip-page {
  td {
    pointer-events: none !important;
  }
}

.vip-carousel {
  height: 48px;
  width: 690px;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: center;
  border-radius: 50px;
  margin: 0 auto;

  img {
    margin-left: 24px;
    height: 24px;
    margin-right: 16px;
  }

  .ant-carousel {
    width: 320px;
    .slick-slide {
      height: 48px;
      line-height: 48px;
      font-weight: 400;
      font-size: 14px;
      color: #ff7f28;
      //line-height: 20px;
    }
  }

  .news {
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    font-weight: 400;
    color: #ff7f28;
  }
}

.surplusTime-title {
  margin-top: 16px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  width: 404px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: linear-gradient(90deg, #ffb651 0%, #ff7f28 100%);
  border-radius: 28px;
}

.table-column-title {
  font-size: 14px;
  color: #542200;
  text-align: center;
  font-weight: 500;

  .info {
    font-weight: normal;
  }

  .adjustmentText {
    margin-top: 3px;
    font-size: 12px;
    font-weight: 500;
    color: rgba(84, 34, 0, 0.6);
    line-height: 17px;
  }
}

.buy-info {
  width: 100%;
  font-size: 16px;

  .info-item {
    color: #686868;
    margin-bottom: 16px;

    .info-title {
      width: 80px;
      display: inline-block;
    }

    .info-text {
      color: #282828;
    }
  }
}

.buy-methods {
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  border-radius: 2px;
  border: 1px dashed #a0a6ab;

  .amount {
    margin-bottom: 30px;
    display: flex;
    line-height: 32px;
    align-items: center;

    .amount-title {
      color: #282828;
      font-size: 18px;
    }

    .amount-num {
      color: #ff7f28;
      font-size: 32px;
    }
  }

  .method-btns {
    width: 100%;
    display: flex;

    .method-btn {
      text-align: center;
      width: 120px;
      height: 98px;
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 40px;
      cursor: pointer;
      border: 1px solid #ededed;

      &:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 171, 148, 0.5);
        border-color: #00ab94;
      }
    }

    .isSelectMethod {
      box-shadow: 0px 0px 10px 0px rgba(0, 171, 148, 0.5);
      border-color: #00ab94;
      color: #fff;
      background-color: #00ab94;
    }
  }

  .method-info {
    width: 100%;
    margin-top: 30px;
    border-top: 1px dashed #a2a2a2;

    .pay-qr {
      margin: 0 auto;
      display: block;
      height: 240px;
    }

    .bank {
      display: flex;
      align-items: center;
      position: relative;
      margin-bottom: 22px;
      margin-top: 30px;

      .bank-logo {
        width: 140px;
        height: 103px;
        margin-right: 22px;
      }

      .bank-info {
        position: relative;
        font-size: 14px;

        .bank-list-item {
          display: flex;
          margin-bottom: 4px;
          align-items: center;

          .text-light {
            width: 70px;
          }
        }

        .bank-tips {
          width: 380px;
          color: #a0a6ab;
          position: absolute;
          font-size: 12px;
        }
      }
    }

    .pay-tips {
      width: 100%;
      margin-top: 20px;
      text-align: left;
      font-size: 14px;
      display: flex;
      align-items: flex-start;
      color: #a0a6ab;
    }
  }
}

.vip-page {
  background-color: #ffffff;

  .service-title {
    font-size: 14px;
    font-weight: 500;
    color: #542200;
    line-height: 20px;
    width: 100%;
    text-align: center;
  }

  .service-text {
    font-size: 14px;
    font-weight: 400;
    color: #542200;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 0;
    //padding: 0 10px;
  }
}
</style>
<style lang="scss" scoped>
.table /deep/ table {
  border: 1px solid #eff3f6 !important;
}

.table /deep/ .ant-table-bordered .ant-table-thead>tr>th {
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
  background-color: #f8f4f2 !important;
  padding: 12px !important;
}

.table /deep/ .ant-table-bordered .ant-table-tbody>tr>td {
  border-right: 1px solid #eff3f6 !important;
  border-bottom: 1px solid #eff3f6 !important;
  padding: 12px !important;
  color: #686868;
  font-size: 14px;
}

.home-headerNav {
  background-color: rgba(226, 201, 158, 0.15);
  border: 1px solid #d8b277;
}

.vip-page {
  width: 100vw;
  min-width: 1200px;
  display: flex;
  flex-direction: column;
  align-self: center;

  .version-choose {
    width: 100%;
    height: 640px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    position: relative;

    .main-title {
      font-size: 32px;
      font-weight: 500;
      color: #542200;
      line-height: 45px;
      margin-bottom: 16px;
    }

    .choose-content {
      margin-top: 96px;
      width: 1200px;
      display: flex;
      align-items: center;
      position: relative;
      justify-content: space-between;

      .version {
        width: 240px;
        height: 280px;
        box-sizing: border-box;
        background-color: #ffffff;
        border-radius: 8px;
        flex-shrink: 0;
        position: relative;

        .ant-btn-primary {
          text-shadow: none !important;
          box-shadow: none !important;
        }

        .discount {
          position: absolute;
          top: -12px;
          right: -15px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          text-align: center;
          width: 61px;
          height: 24px;
          background: linear-gradient(90deg, #ffb651 0%, #ff7f28 100%);
          border-radius: 12px 12px 12px 0;
        }

        .title {
          margin-top: 24px;
          width: 100%;
          height: 49px;
          background: linear-gradient(270deg,
              rgba(255, 182, 81, 0) 0%,
              rgba(255, 127, 40, 0.1) 100%);
          font-weight: 600;
          text-align: center;

          .noSelectedTitle {
            font-size: 20px;
            color: #542200;
            margin-bottom: 0;
            line-height: 49px;
          }

          .selectedTitle {
            margin-bottom: 0;
            margin-top: 3px;
            font-size: 20px;
            font-weight: 600;
            color: #542200;
            line-height: 28px;
          }
        }

        .subtitle {
          font-size: 12px;
          font-weight: 400;
          color: rgba(84, 34, 0, 0.63);
          line-height: 17px;
        }

        .versiontips {
          margin-top: 24px;
          font-size: 12px;
          font-weight: 400;
          color: #542200;
          line-height: 17px;
        }

        .price {
          position: absolute;
          top: 123px;
          width: 100%;

          .price-item {
            width: 160px;
            height: 120px;
            margin: 0 auto;
            //background-color: #ffffff;
            text-align: center;

            .number {
              margin-top: 5px;

              .now {
                font-size: 14px;
                font-weight: 400;
                color: #ff7f28;
                line-height: 20px;

                span {
                  margin-left: 5px;
                }
              }

              .point {
                font-size: 18px;
                font-weight: bold;
                color: #ff7f28;
                line-height: 30px;
              }

              .year {
                margin-left: 5px;
                font-size: 14px;
                font-weight: 400;
                color: #ff7f28;
                line-height: 20px;
              }
            }

            .average {
              margin-top: 6px;

              .personal {
                .last {
                  font-size: 14px;
                  font-weight: 400;
                  color: #ff7f28;
                  line-height: 20px;
                }

                .trimPrice {
                  margin-left: 10px;
                  font-size: 20px;
                  font-weight: 400;
                  color: #ff7f28;
                  line-height: 20px;
                }
              }

              .otherPrice {
                .last {
                  font-size: 14px;
                  font-weight: 400;
                  color: #ff7f28;
                  line-height: 20px;

                  span {
                    margin-left: 10px;
                  }
                }

                .trimPrice {
                  font-size: 18px;
                  font-weight: bold;
                  color: #ff7f28;
                  line-height: 30px;
                }

                .year {
                  margin-left: 5px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #ff7f28;
                  line-height: 20px;
                }
              }
            }

            .showSpringFrameNumber {
              color: #ff7f28;

              .number {
                font-size: 18px;
                margin-right: 5px;
              }

              .point {
                font-size: 32px;
                font-weight: bold;
                line-height: 28px;
              }

              .year {
                font-size: 18px;
              }
            }
          }
        }

        .group {
          .groupTitle {
            text-align: center;
            margin-top: 15px;
            font-size: 12px;
            font-weight: 400;
            color: #542200;
          }

          ul {
            margin-top: 16px;

            .info {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
            }
          }

          li {
            text-align: center;
            margin-top: 8px;
          }

          span {
            font-size: 16px;
            font-weight: 500;
            color: #ff7f28;
          }
        }

        .selected {
          border-color: #2196f3 !important;
          box-shadow: 2px 2px 1px 1px #e9ecef;
        }

        .team {
          margin-top: 40px;
        }

        .open-btns {
          width: 160px;
          position: absolute;
          left: 40px;
          top: 195px;
          height: 40px;
          background: #ff7f28;
          box-sizing: border-box;
          padding: 0 !important;
          border-radius: 26px;
          border: 4px solid rgba(255, 127, 40, 0.2);
          font-size: 17px;
          font-weight: 400;
          color: #ffffff;
        }

        .multip-tips {
          height: 26px;
          line-height: 26px;
          font-size: 12px;
        }

        .versiontips {
          margin-top: 24px;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #542200;
        }

        .other {
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #838383;
          position: absolute;
          bottom: 25px;
          left: 44px;
        }

        .number-iput {
          margin-top: 20px;
        }
      }

      .versionSpringFrame {
        text-align: center;
        width: 240px;
        height: 280px;
        box-sizing: border-box;
        background-color: #ffffff;
        border-radius: 8px;
        flex-shrink: 0;
        position: relative;

        .ant-btn-primary {
          text-shadow: none !important;
          box-shadow: none !important;
        }

        .discount {
          position: absolute;
          top: -12px;
          right: -15px;
          font-size: 12px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          width: 61px;
          height: 24px;
          background: linear-gradient(90deg, #ffb651 0%, #ff7f28 100%);
          border-radius: 12px 12px 12px 0;
        }

        .hotSale {
          position: absolute;
          top: -1px;
          right: -1px;
        }

        .title {
          margin-top: 24px;
          width: 100%;
          height: 49px;
          background: linear-gradient(270deg,
              rgba(255, 182, 81, 0) 0%,
              rgba(255, 127, 40, 0.1) 100%);
          font-weight: 600;
          text-align: center;

          .noSelectedTitle {
            font-size: 20px;
            color: #542200;
            margin-bottom: 0;
            line-height: 49px;
          }

          .selectedTitle {
            margin-bottom: 0;
            //margin-top: 3px;
            font-size: 20px;
            font-weight: 600;
            color: #542200;
            line-height: 49px;
          }
        }

        .subtitle {
          font-size: 12px;
          font-weight: 400;
          color: rgba(84, 34, 0, 0.63);
          line-height: 17px;
        }

        .versiontips {
          margin-top: 24px;
          font-size: 12px;
          font-weight: 400;
          color: #542200;
          line-height: 17px;
        }

        .price {
          position: absolute;
          top: 139px;
          width: 100%;

          .price-item {
            width: 160px;
            height: 120px;
            margin: 0 auto;
            //background-color: #ffffff;
            text-align: center;

            .number {
              margin-top: 5px;

              .now {
                font-size: 14px;
                font-weight: 400;
                color: #ff7f28;
                line-height: 20px;

                span {
                  margin-left: 5px;
                }
              }

              .point {
                font-size: 18px;
                font-weight: bold;
                color: #ff7f28;
                line-height: 30px;
              }

              .year {
                margin-left: 5px;
                font-size: 14px;
                font-weight: 400;
                color: #ff7f28;
                line-height: 20px;
              }
            }

            .average {
              margin-top: 6px;

              .personal {
                .last {
                  font-size: 14px;
                  font-weight: 400;
                  color: #ff7f28;
                  line-height: 20px;
                }

                .trimPrice {
                  margin-left: 10px;
                  font-size: 20px;
                  font-weight: 400;
                  color: #ff7f28;
                  line-height: 20px;
                }
              }

              .otherPrice {
                .last {
                  font-size: 14px;
                  font-weight: 400;
                  color: #ff7f28;
                  line-height: 20px;

                  span {
                    margin-left: 10px;
                  }
                }

                .trimPrice {
                  font-size: 18px;
                  font-weight: bold;
                  color: #ff7f28;
                  line-height: 30px;
                }

                .year {
                  margin-left: 5px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #ff7f28;
                  line-height: 20px;
                }
              }
            }

            .showSpringFrameNumber {
              color: #ff7f28;

              .number {
                margin-right: 5px;

                font-size: 18px;
              }

              .point {
                font-size: 32px;
                font-weight: bold;
                line-height: 28px;
              }

              .year {
                font-size: 18px;
              }
            }
          }
        }

        .group {
          .groupTitle {
            text-align: center;
            margin-top: 15px;
            font-size: 12px;
            font-weight: 400;
            color: #542200;
          }

          ul {
            margin-top: 16px;

            .info {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
            }
          }

          li {
            text-align: center;
            margin-top: 8px;
          }

          span {
            font-size: 16px;
            font-weight: 500;
            color: #ff7f28;
          }
        }

        .selected {
          border-color: #2196f3 !important;
          box-shadow: 2px 2px 1px 1px #e9ecef;
        }

        .team {
          margin-top: 40px;
        }

        .open-btns {
          width: 160px;
          position: absolute;
          left: 40px;
          top: 216px;
          height: 40px;
          background: #ff7f28;
          box-sizing: border-box;
          padding: 0 !important;
          border-radius: 26px;
          border: 4px solid rgba(255, 127, 40, 0.2);
          font-size: 17px;
          font-weight: 400;
          color: #ffffff;
        }

        .multip-tips {
          height: 26px;
          line-height: 26px;
          font-size: 12px;
        }

        .versiontips {
          margin-top: 24px;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #542200;
        }

        .other {
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #838383;
          position: absolute;
          bottom: 25px;
          left: 44px;
        }

        .number-iput {
          margin-top: 20px;
        }
      }

      .versonSelect {
        transform: scale(1.2, 1.2);
        //width: 260px;
        //height: 342px;
        background: linear-gradient(180deg, #ffb651 0%, #ff7f28 100%);
        box-shadow: 0 0 24px 0 #ff7f28;
        border-radius: 8px;
        border: 1px solid #ff812a;
        flex-shrink: 0;
        position: relative;

        .versiontips {
          color: #ffffff !important;
        }

        .ant-btn-primary {
          text-shadow: none !important;
          box-shadow: none !important;
        }

        .discount {
          color: #ff7f28;
          background: #fdfdfd;
        }

        .title {
          background: linear-gradient(270deg,
              rgba(255, 213, 175, 0) 0%,
              rgba(255, 180, 115, 0.5) 17%,
              #ff9337 100%);

          color: #ffffff;

          .noSelectedTitle {
            color: #ffffff;
          }

          .selectedTitle {
            color: #ffffff;
          }
        }

        .subtitle {
          color: #ffffff;
        }

        .price {
          .price-item {
            .number {
              .now {
                color: #ffffff;
              }

              .point {
                color: #ffffff;
              }

              .year {
                color: #ffffff;
              }
            }

            .average {
              .personal {
                .last {
                  color: #ffffff;
                }

                .trimPrice {
                  color: #ffffff;
                }
              }

              .otherPrice {
                .last {
                  font-size: 14px;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 20px;

                  span {
                    margin-left: 10px;
                  }
                }

                .trimPrice {
                  font-size: 18px;
                  font-weight: bold;
                  color: #ffffff;
                  line-height: 30px;
                }

                .year {
                  margin-left: 5px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #ffffff;
                  line-height: 20px;
                }
              }
            }

            .showSpringFrameNumber {
              color: #ffffff;

              .number {
                font-size: 18px;
                margin-right: 5px;
              }

              .point {
                font-size: 32px;
                font-weight: bold;
                line-height: 28px;
              }

              .year {
                font-size: 18px;
              }
            }
          }
        }

        .group {
          .groupTitle {
            text-align: center;
            margin-top: 15px;
            font-size: 12px;
            font-weight: 400;
            color: #ffffff;
          }

          ul {
            margin-top: 24px;

            .info {
              display: flex;
              align-items: center;
              justify-content: space-evenly;
            }
          }

          li {
            text-align: center;
            margin-top: 8px;
          }

          span {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
          }
        }

        .selected {
          border-color: #2196f3 !important;
          box-shadow: 2px 2px 1px 1px #e9ecef;
        }

        .team {
          margin-top: 40px;
        }

        .open-btns {
          background: #ffffff;
          padding: 0 !important;
          border: none;
          //border: 4px solid rgba(173, 70, 0, 0.2);
          color: #ff7f28;
        }

        .multip-tips {
          height: 26px;
          line-height: 26px;
          font-size: 12px;
        }

        .versiontips {
          margin-top: 24px;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #542200;
        }

        .other {
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          color: #838383;
          position: absolute;
          bottom: 25px;
          left: 44px;
        }

        .number-iput {
          margin-top: 20px;
        }
      }
    }

    .customerService {
      position: absolute;
      bottom: 30px;
      font-size: 14px;
      font-weight: 400;
      color: #ff8129;
      line-height: 20px;
    }
  }

  .contrast-table {
    width: 100%;
    margin-top: 80px;

    .personTitleInfo {
      font-size: 14px;
      font-weight: 500;
      color: #ff8029;
      line-height: 20px;
    }

    .table-warp {
      margin: 0 auto;
      background-color: #ffffff;
      min-width: 1200px;
      max-width: 1200px;
      position: relative;

      .fake-title {
        color: #c68f49;
        width: 100%;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3000;
        // justify-content: space-between;
        border: 1px solid #eff3f6;
        border-right: none;
        background-color: #eff3f6;
        flex-shrink: 0;
        flex-grow: 0;
        box-sizing: border-box;

        .fake-table-head {
          font-size: 18px;
          height: 80px;
          box-sizing: border-box;
          padding: 12px;
          border-right: 1px solid #eff3f6;
          line-height: 24px;
          text-align: center;
          font-weight: bold;
          color: #c68f49;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 15%;

          &:first-child {
            width: 25%;
          }

          &:last-child {
            border-right: none;
          }
        }

        // height: 24px;
      }
    }
  }

  .product {
    width: 1200px;
    margin: 80px auto 0;

    .productTitle {
      .productTitleTop {
        display: flex;
        //justify-content: space-between;
        align-items: center;

        .productTitleText {
          padding: 0 5px;
          font-size: 36px;
          font-weight: bold;
          color: #d19c55;
          margin: 0 auto;
        }
      }
    }

    .productMain {
      padding-top: 16px;

      ul {
        li {
          width: 1200px;
          margin-top: 40px;
          display: flex;
          justify-content: space-between;

          .singular,
          .dbNum {
            margin-right: 40px;
          }

          .productMainText {
            margin-top: 88px;
            text-align: left;
            position: relative;

            p:first-child {
              font-size: 24px;
              font-weight: 500;
              color: #ff8800;
              line-height: 33px;
            }

            p:nth-child(2) {
              margin-top: 16px;
              font-size: 14px;
              font-weight: 400;
              color: #303032;
              line-height: 20px;
            }

            .productMainTextBtn {
              position: absolute;
              bottom: 33px;
              right: 0;
              width: 160px;
              height: 40px;
              background-color: #ff7f28;
              border-radius: 24px;
              border-color: transparent;
              font-weight: 400;
              font-size: 16px;
              font-weight: 500;
              color: #ffffff;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
</style>
