<template>
  <!-- 搜索结果 -->
  <div class="w1200 search-result">
    <div class="crumbs">
      <router-link :to="{ path: '/' }" >首页</router-link>
      /
      <router-link :to="{ path: '/helpCenter' }" >帮助中心</router-link>
      /
      <p><span>搜索结果</span></p>
    </div>
    <div class="title">
      <h4>所有与<span>“{{ this.searchValue || '' }}”</span>相关的搜索</h4>
      <p>找到了{{ total || 0 }}个结果</p>
    </div>

    <!-- 有结果 -->
    <div v-if="this.resultList.length > 0" class="result">
      <div class="box"></div>
      <ul class="result-list">
        <router-link v-for="(item, index) in resultList" :key="index" :to="{ path: '/details/' + item.dynamicId }"
                     class="result-list-item" tag="li">
          <h3 class="textHide" v-html="item.title"></h3>
          <p class="textHide_line2" v-html="item.context"></p>
        </router-link>
      </ul>
      <!--    分页-->
      <div class="page">
        <a-pagination
            v-model="current"
            :default-current="current"
            :defaultPageSize="size"
            :total="total"
            class="pagination"
            @change="changePage"
        />
      </div>
    </div>

    <!-- 无结果 -->
    <div v-else class="pcNodata">
      <img :src="pcNodata" alt="">
    </div>
  </div>
</template>

<script>
import pcNodata from '@/assets/helpCenter/pc_nodata.png'

export default {
  data() {
    return {
      pcNodata,
      current:1,
      size:20,
      total:0,
      searchValue: '',
      resultList: []
    }
  },
  created() {
    this.init()
  },
  watch: {
    '$route': function (to, from) {
      this.init()
    }
  },
  methods: {
    changePage(pageNumber) {
      this.current = pageNumber
      this.getList()
      const appDom =
          document.body.scrollTop === 0
              ? document.documentElement
              : document.body
      appDom.scrollTop = document.querySelector('.result').offsetTop-20
      //document.querySelector('.result').offsetTop=0
      console.log(document.querySelector('.result').offsetTop)
      // document.querySelector('.result').scrollTo(0,0)

    },
    getList() {
      const params = {
        current: this.current,
        size: this.size,
        searchKey: this.searchValue
      }
      this.$http.post('dynamic/search', params).then(res => {
        // console.log(res)
        this.total=res.total
        let result = this.HighlightKeywords(res.list, this.searchValue)
        this.resultList = result
      })
    },
    // 初始化
    init() {
      let {searchValue} = this.$route.query
      this.searchValue = searchValue
      this.search()
    },
    // 搜索
    search() {
      this.current=1
      const params = {
        current: this.current,
        size: this.size,
        searchKey: this.searchValue
      }
      this.$http.post('dynamic/search', params).then(res => {
        // console.log(res)
        this.total=res.total
        let result = this.HighlightKeywords(res.list, this.searchValue)
        this.resultList = result
      })
    },

    // 高亮关键字
    HighlightKeywords(data, keyWord) {
      let result = []
      data.forEach(item => {
        if (JSON.stringify(item).indexOf(keyWord) > -1) {

          item.title = JSON.parse(JSON.stringify(item.title).replace(/<[^>]+>/g, ''))
          item.context = JSON.parse(JSON.stringify(item.context).replace(/<[^>]+>/g, ''))

          item.title = JSON.parse(JSON.stringify(item.title).replace(new RegExp(keyWord, 'g'), `<span>${keyWord}</span>`))
          item.context = JSON.parse(JSON.stringify(item.context).replace(new RegExp(keyWord, 'g'), `<span>${keyWord}</span>`))
          result.push(item)
        }
      })

      return result
    }
  }
}
</script>

<style lang="scss">
.w1200 {
  width: 1200px;
  margin: 0 auto;
}

.textHide {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; //作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  -webkit-line-clamp: 1; //显示的行
}

.textHide_line2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; //作为弹性伸缩盒子模型显示。
  -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
  -webkit-line-clamp: 2; //显示的行
}

.search-result {
  margin-bottom: 92px;

  .crumbs {
    margin: 24px 0 32px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #585858;
    display: flex;

    p {
      margin: 0 8px;
      cursor: pointer;
    }

    p:first-child {
      margin-left: 0;
    }

    span {
      color: #181818;
    }
  }

  .title {
    h4 {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #585858;

      span {
        color: #00AB94;
      }
    }

    p {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #585858;
    }
  }

  .result {
    .box {
      width: 32px;
      height: 8px;
      margin: 32px 0;
      background: #00AB94;
    }

    .result-list {
      .result-list-item {
        padding: 0 0 24px;
        margin: 0 0 24px;
        border-bottom: 1px solid #E6E9F0;

        h3 {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #181818;
          cursor: pointer;
        }

        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #585858;
          margin: 0;
        }

        span {
          color: #00AB94;
        }
      }

      .result-list-item:last-child {
        border-bottom: none;
      }
    }
  }

  .pcNodata {
    margin: 80px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 400px;
      height: 200px;
    }
  }
  .page{
    width: 100%;
    position: relative;
    .pagination{
      position: absolute;
      right: 0;
    }
  }
}

</style>