<template>
  <div class="shipment-filter" id="shipmentFilter">
    <div class="filter-item">
      <div class="title-label">区域：</div>
      <a-tree-select
        v-model="area"
        @change="selectArea"
        placeholder="请选择区域 "
        allow-clear
        treeCheckable
        id="shipmentTree"
        :disabled="otherDisabled"
        :getPopupContainer="getPopupContainer"
        aria-readonly
        :filterTreeNode="notFilter"
        class="filter-input"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :showCheckedStrategy="SHOW_PARENT"
      >
        <a-tree-select-node
          v-for="item in areaOptions"
          :key="item.areaCode"
          :value="item.areaCode"
          :title="item.areaName"
          :disabled="item.disabled"
        >
          <a-tree-select-node
            v-for="child in item.cityList"
            :value="child.areaCode"
            :key="child.areaCode"
            :disabled="item.disabled"
            :title="child.areaName"
          ></a-tree-select-node>
        </a-tree-select-node>
      </a-tree-select>
    </div>
    <div class="filter-item">
      <div class="title-label">有无施工资质：</div>
      <div class="filter-input">
        <a-radio-group
          :disabled="otherDisabled"
          @change="setHaveQualification"
          v-model="haveQualification"
        >
          <a-radio :value="false">无</a-radio>
          <a-radio :value="true">有</a-radio>
        </a-radio-group>
      </div>
    </div>
    <div class="filter-item" v-if="haveQualification">
      <div class="title-label">出售施工资质：</div>
      <div class="have-qualication-select">
        <tree-checkbox-single-select
          v-model="commendQua"
          containerId="shipmentFilter"
          :disabled="otherDisabled"
          @isSetFormVaue="selectQualification"
          :fakeQualification="fakeQualification"
          :qualificationOptions="qualificationOptions"
          class="single-checkbox"
        ></tree-checkbox-single-select>

        <div class="tips">
          说明：如果您想要出售某一资质，您可以通过此条件筛选出没有该资质的企业
        </div>

        <div class="recommend-qua" v-if="recommendBtns.length">
          <div class="title-label">资质共性分析：</div>
          <a-button
            class="recommend-btn"
            :type="
              qualifications === item.qualificationIds ? 'primary' : 'default'
            "
            @click="setRecommednQualification(item)"
            v-for="(item, index) in recommendBtns"
            :key="index"
            >{{ item.qualificationName }}</a-button
          >
        </div>
        <div class="tips" v-if="recommendBtns.length">
          说明：选择“出售资质”后，系统将根据企业共性进行大数据关联演算，形成推荐搭配，表示持有“资质共性分析”中资质项的企业，是购买该资质的高意向客户
        </div>
      </div>
    </div>
    <div class="filter-item" v-if="!haveQualification">
      <div class="title-label">企业名关键词：</div>
      <a-select
        allowClear
        :disabled="otherDisabled"
        @change="selectEnterpriseKeywords"
        :getPopupContainer="getPopupContainer"
        placeholder="请选择企业名称的关键词查询"
        class="filter-input"
        v-model="enterpriseKeyWord"
      >
        <a-option
          v-for="(item, key) in enterpriseKeyWordOption"
          :label="item.label"
          :value="item.value"
          :key="key"
          >{{ item.label }}</a-option
        >
      </a-select>
    </div>
    <div class="filter-item">
      <div class="title-label">关键字：</div>
      <div class="have-radio-input">
        <a-input
          :disabled="otherDisabled"
          v-model="keyWord"
          allow-clear
          @change="isNeedSetPhoneDisabled"
          class="input"
          placeholder="请输入企业名称或法定代表人"
        />
        <div class="type-radio">
          <a-radio-group
            :disabled="otherDisabled"
            v-model="keyWordType"
            class="group"
          >
            <a-radio :value="2">模糊</a-radio>
            <a-radio :value="1">精准</a-radio>
          </a-radio-group>
          <a-tooltip>
            <template slot="title">
              <p class="radio-tips">
                关键字搜索是指包含关键词的内容进行检索，可选择模糊或者精准搜索，提升数据精准度。
              </p>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </div>
      </div>
    </div>
    <div class="filter-item">
      <div class="title-label">联系电话：</div>
      <div class="have-radio-input">
        <a-input
          :disabled="phoneDisabled"
          v-model="phoneNum"
          @change="isNeedSetPhoneDisabled"
          allowClear
          class="input"
          placeholder="请清空其他筛选条件后 输入11位手机号码查询"
        />
        <div class="warning-tips" v-if="phoneNum">
          提示：若要选择或修改以上其他查询项，请先清空联系电话
        </div>
      </div>
    </div>
    <div class="filter-item">
      <a-button
        size="large"
        class="large-button"
        @click="clickFindBtns"
        type="primary"
        >查询</a-button
      >
      <a-button
        size="large"
        class="large-button"
        @click="e => reSetFilter(false)"
        >重置</a-button
      >
    </div>
  </div>
</template>

<script>
import {
  Select,
  Input,
  TreeSelect,
  Radio,
  Tooltip,
  Modal
} from 'ant-design-vue'
import { mapState } from 'vuex'
import TreeCheckboxSingleSelect from '@/components/TreeCheckboxSingleSelect.vue'
export default {
  name: 'shipment-filter',
  components: {
    aTreeSelect: TreeSelect,
    aTreeSelectNode: TreeSelect.TreeNode,
    aSelect: Select,
    aOption: Select.Option,
    aRadio: Radio,
    aTooltip: Tooltip,
    aRadioGroup: Radio.Group,
    aInput: Input,
    TreeCheckboxSingleSelect
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      fakeQualification: state => state.fakeQualificationOptions,
      qualificationOptions: state => state.typeQualificationOptions
    }),
    isSuperAdmin() {
      return this.userInfo.roleLevel === 1
    }
  },
  data() {
    return {
      customerQuaOptions: [],
      history: {},
      gangAoTai: [810000, 820000, 710000],
      enterpriseKeyWord: undefined,
      enterpriseKeyWordOption: [
        { label: '建筑', value: '建筑' },
        { label: '建设', value: '建设' },
        { label: '工程', value: '工程' },
        { label: '劳务', value: '劳务' },
        { label: '安装', value: '安装' },
        { label: '装饰', value: '装饰' },
        { label: '科技', value: '科技' },
        { label: '市政', value: '市政' },
        { label: '钢结构', value: '钢结构' },
        { label: '电力工程', value: '电力工程' },
        { label: '分公司', value: '分公司' },
        { label: '办事处', value: '办事处' }
      ],
      phoneNum: '',
      keyWordType: 2,
      keyWord: '',
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      haveQualification: false,
      area: [],
      areaCode: [],
      areaOptions: [],
      qualifications: undefined,
      defaultExpend: [],
      // qualificationOptions: [],
      recommendQuaOptions: [],
      establishmentDate: [],
      filter: {},
      commendQua: undefined,
      recommendQualifications: undefined,
      recommendBtns: [],
      // fakeQualification: [],
      filterCondition: null,
      phoneDisabled: false,
      otherDisabled: false
    }
  },
  methods: {
    onlyPhone(phone) {
      const haveQualification = this.haveQualification
      this.reSetFilter().then(() => {
        this.haveQualification = haveQualification
        this.phoneNum = phone
        localStorage.removeItem('filterPhone')
        this.isNeedSetPhoneDisabled()
        this.clickFindBtns()
      })
    },
    onlyKeyword(keyWord) {
      const haveQualification = this.haveQualification
      this.reSetFilter().then(() => {
        this.haveQualification = haveQualification
        this.keyWord = keyWord
        localStorage.removeItem('filterKeyword')
        this.clickFindBtns()
      })
    },
    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('shipmentFilter')
    },
    notFilter() {
      return false
    },
    async reSetFilter() {
      this.enterpriseKeyWord = undefined
      this.phoneNum = ''
      this.keyWordType = 2
      this.keyWord = ''
      this.area = []
      this.recommendBtns = ''
      this.areaCode = []
      this.qualifications = undefined
      this.establishmentDate = []
      this.recommendQualifications = undefined
      this.commendQua = undefined
      this.haveQualification = false
      this.otherDisabled = false
      this.phoneDisabled = false
      // this.isNeedSetPhoneDisabled()
    },
    notHaveFilterOption() {
      // console.log(this.recommendQualifications)
      if (
        !this.keyWord &&
        !this.enterpriseKeyWord &&
        !this.phoneNum &&
        !this.qualifications &&
        !this.recommendQualifications &&
        !this.commendQua
      ) {
        return true
      } else {
        return false
      }
    },
    async setFilterOption() {
      try {
        const isCellPhone = /^1(3|4|5|6|7|8|9)\d{9}$/
        if (this.phoneNum) {
          if (!isCellPhone.test(this.phoneNum)) {
            this.$message.error('请输入正确的手机号码进行查询', 0.5)
            throw false
            // return
          }
        }
        if (this.notHaveFilterOption()) {
          if (!this.area.length) {
            this.$message.error('请选择区域后再进行查询', 0.5)
            throw false
          }
        }

        if (this.establishmentDate.length) {
          const start = new Date(this.establishmentDate[0]).getTime()
          const end = new Date(this.establishmentDate[1]).getTime()
          if (start > end) {
            this.$message.error('成立开始时间不能大于结束时间', 0.5)
            throw false
          }
        }
      } catch (e) {
        const a = e
        // console.log(e)
        throw a
      }

      this.filter = {
        recommendQualificationId: this.recommendQualifications
          ? this.recommendQualifications
          : undefined,
        sellingQualificationId: this.commendQua,
        areaCodeList: this.areaCode,
        haveQualification: this.phoneNum ? undefined : this.haveQualification,
        enterpriseKeyWord: this.enterpriseKeyWord,
        keyWord: this.keyWord,
        keyWordType: this.keyWordType,
        phoneNum: this.phoneNum
      }
    },
    clickFindBtns() {
      this.setFilterOption().then(() => {
        this.$emit('clickFindBtns')
      })
    },
    setFilterToFindList(num, size) {
      const pageNum = num ? num : this.$route.query.pageNum
      const pageSize = size ? size : this.$route.query.pageSize
      // if (this.filterCondition) {
      //     const params = {
      //         filterCondition: JSON.stringify({
      //             ...this.filter,
      //             commendQua: this.commendQua,
      //             pageSize: pageSize,
      //             pageNum: pageNum
      //         }),
      //         companyId: this.userInfo.companyId || 1, // todo:测试数据
      //         saveUserName: this.userInfo.name,
      //         saveUserId: this.userInfo.id,
      //         conditionName: this.userInfo.name + '的最后保存数据',
      //         id: this.filterCondition.id
      //     }
      //     this.$http.put('filter/precise/updateConditionByPage', params).then(() => {
      //         console.log('更新成功')
      //     })
      // } else {
      //     const params = {
      //         filterCondition: JSON.stringify({
      //             ...this.filter,
      //             pageSize: pageSize,
      //             pageNum: pageNum
      //         }),
      //         companyId: this.userInfo.companyId || 1, // todo:测试数据
      //         saveUserName: this.userInfo.name,
      //         saveUserId: this.userInfo.id,
      //         conditionName: this.userInfo.name + '的最后保存数据'
      //     }
      //     this.$http.post('filter/precise/saveConditionByPage', params).then(() => {
      //         console.log('保存成功')
      //     })
      // }
      this.$emit('setFilter', { filter: this.filter, pageNum, pageSize })
    },
    selectArea(value) {
      this.areaCode = []
      value.forEach(v => {
        const find = this.areaOptions.find(a => {
          return a.areaCode === v
        })
        const inCode = this.areaCode.find(a => {
          return a === v
        })
        if (!inCode) {
          if (find) {
            this.areaCode.push(v)
            if (find.cityList.length) {
              this.areaCode = this.areaCode.concat(
                find.cityList.map(c => {
                  return c.areaCode
                })
              )
            }
          } else {
            this.areaCode.push(v)
          }
        }
      })
    },
    async getAreaList() {
      await this.$http.get('regionCode/selList').then(r => {
        this.areaOptions = []
        this.areaOptions = r.map(a => {
          return {
            ...a,
            disabled: false
          }
        })
        this.areaOptions.forEach(e => {
          if (this.gangAoTai.includes(e.areaCode)) {
            e.disabled = true
          }
        })
        const filterPhone = localStorage.getItem('filterPhone')
        const filterKeyword = localStorage.getItem('filterKeyword')
        // this.getFilterHistory()

        if (filterPhone) {
          this.onlyPhone(filterPhone)
        } else if (filterKeyword) {
          this.onlyKeyword(filterKeyword)
        } else if (!Object.keys(this.history).length) {
          const userArea = this.userInfo.useAreaName.split('/')
          const findProvince = r.find(e => {
            return e.areaName === userArea[0]
          }) // 找到省份
          if (findProvince) {
            this.area = [findProvince.areaCode]
            this.areaCode = [
              findProvince.areaCode,
              ...findProvince.cityList.map(c => {
                return c.areaCode
              })
            ]
          } else {
            this.areaCode = []
          }
          this.setFilterOption().then(() => {
            this.setFilterToFindList()
          })
        }
      })
    },
    selectQualification(val) {
      if (!val) {
        this.recommendBtns = []
        this.recommendQualifications = ''
        return
      }

      if (this.haveQualification) {
        this.$http
          .get('precise/selRecommendQualification', {
            qualificationId: this.commendQua,
            areaCodes: '320000'
          })
          .then(res => {
            this.recommendBtns = res
          })
      }
    },
    setHaveQualification() {
      if (this.haveQualification && this.commendQua) {
        this.$http
          .get('precise/selRecommendQualification', {
            qualificationId: this.commendQua,
            areaCodes: '320000'
          })
          .then(res => {
            this.recommendBtns = res
          })
      } else {
        this.qualifications = ''
        this.recommendBtns = []
        this.recommendQualifications = undefined
      }
      if (this.haveQualification) {
        this.enterpriseKeyWord = undefined
      }
      this.isNeedSetPhoneDisabled()
    },
    selectRecommentQualifications(val) {
      this.qualifications = val
      this.isNeedSetPhoneDisabled()
    },
    setRecommednQualification(item) {
      this.qualifications = item.qualificationIds
      this.recommendQualifications = item.qualificationIds
    },
    selectEnterpriseKeywords(val) {
      if (val === '分公司' || val === '办事处') {
        Modal.info({
          title: '温馨提示',
          content: '某些分公司和办事处也有购买意向'
        })
      }
      this.isNeedSetPhoneDisabled()
    },
    getFilterHistory() {
      const params = {
        conditionEnum: 'PRECISE_QUERY'
      }
      this.$http.get('filter/queryCondition', params).then(res => {
        if (res.filterCondition) {
          this.history = JSON.parse(res.filterCondition)
          this.setHistoryToFilter(this.history)
        } else {
          this.getAreaList()
        }
      })
    },
    async setHistoryToFilter(data) {
      this.enterpriseKeyWord = data.enterpriseKeyWord
      this.phoneNum = data.phoneNum
      this.keyWordType = data.keyWordType
      this.keyWord = data.keyWord
      this.area = []
      if (data.areaCodeList) {
        await this.getAreaList().then(() => {
          this.selectArea(data.areaCodeList)
          this.area = data.areaCodeList
        })
      } else {
        await this.getAreaList()
      }

      this.recommendQualifications = data.recommendQualificationId
        ? data.recommendQualificationId
        : undefined
      this.qualifications = data.recommendQualificationId
        ? data.recommendQualificationId
        : undefined
      // console.log(this.qualifications)
      this.haveQualification = data.haveQualification || false
      if (data.establishmentDateEnd && data.establishmentDateStart) {
        this.establishmentDate = [
          data.establishmentDateStart,
          data.establishmentDateEnd
        ]
      }
      if (data.haveQualification) {
        this.commendQua = data.commendQua || undefined
        await this.setHaveQualification()
      }
      const pageNum = data.pageNum || 1
      const pageSize = data.pageSize || 10

      this.setFilterOption().then(() => {
        this.$emit('setFilter', { filter: this.filter, pageSize, pageNum })
      })
      this.isNeedSetPhoneDisabled()
    },
    isNeedSetPhoneDisabled() {
      if (
        !this.commendQua &&
        !this.areaCode &&
        !this.haveQualification &&
        !this.recommendQualifications &&
        !this.enterpriseKeyWord &&
        !this.keyWord &&
        !this.phoneNum
      ) {
        this.otherDisabled = false
        this.phoneDisabled = false
      } else {
        if (this.phoneNum) {
          this.otherDisabled = true
          this.phoneDisabled = false
        } else {
          this.otherDisabled = false
          this.phoneDisabled = false
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      const dom = document.getElementById('shipmentTree')
      const areaTreeInput = dom.getElementsByTagName('input')[0]
      const areaTreeFather = areaTreeInput.parentElement
      areaTreeFather.removeChild(areaTreeInput)
      this.getFilterHistory()
    })
  }
}
</script>
<style lang="scss" scoped>
.shipment-filter {
  width: 1200px;
  background-color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 16px 25px 24px 25px;
  position: relative;
  .filter-item {
    display: flex;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 16px;
    flex-shrink: 0;
    .title-label {
      font-size: 14px;
      color: #666666;
      height: 32px;
      line-height: 32px;
      flex-shrink: 0;
      width: 115px;
    }
    .filter-input {
      height: 32px;
      line-height: 32px;
      min-width: 336px;
    }
    .have-radio-input {
      display: flex;
      align-items: center;
      .input {
        margin-right: 15px;
        width: 336px;
      }
    }
    .large-button {
      width: 126px;
      margin-right: 16px;
    }
    .have-qualication-select {
      .recommend-select {
        height: 32px;
        line-height: 32px;
        min-width: 336px;
        margin-bottom: 6px;
      }
      .single-checkbox {
        width: 336px;
      }
      .recommend-qua {
        margin-top: 10px;
        // margin: 10px 0;
        display: flex;
        flex-wrap: wrap;
        .recommend-btn {
          margin-bottom: 10px;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
