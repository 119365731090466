<template>
  <div class="index">
    <div
      class="banner"
      style="
        background-image: url('http://cdn.public.xnjx666.com//upload/gw/static/img/caseCenter_bg.png');
      "
    >
      <div class="text">
        <p>案例中心</p>
        <p>The Mavericks Case Center</p>
      </div>
    </div>
    <div class="container">
      <div class="list">
        <ul>
          <li
            v-for="item in listData"
            :key="item.index"
            @click="detail(item.index)"
          >
            <div class="img_div">
              <p class="img-logo">
                <img :src="item.logo" alt="小牛建讯" />
              </p>
              <p class="companyName">{{ item.companyName }}</p>
              <p class="useTime">
                <span>小牛建讯</span> <span>{{ item.useTime }}</span>
              </p>
              <div class="line"></div>
              <div class="up">
                <div>
                  <div class="upNum">
                    <p>{{ item.clue }}</p>
                    <img :src="up" alt="" />
                  </div>
                  <p class="upText">线索量</p>
                </div>
                <div>
                  <div class="upNum">
                    <p>{{ item.deal }}</p>
                    <img :src="up" alt="" />
                  </div>
                  <p class="upText">成交量</p>
                </div>
                <div>
                  <div class="upNum">
                    <p>{{ item.achievement }}</p>
                    <img :src="up" alt="" />
                  </div>
                  <p class="upText">业绩量</p>
                </div>
              </div>
              <!--                <a-button class="btns" type="primary">-->
              <!--                  <router-link-->
              <!--                    :to="{-->
              <!--                      path: `/caseDetails/${item.index}`-->
              <!--                    }"-->
              <!--                    >查看详情</router-link-->
              <!--                  >-->
              <!--                </a-button>-->
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import case1 from '@/assets/caseCenter/case1.png'
import case2 from '@/assets/caseCenter/case2.png'
import case3 from '@/assets/caseCenter/case3.png'
import case4 from '@/assets/caseCenter/case4.png'
import case5 from '@/assets/caseCenter/case5.png'
import case6 from '@/assets/caseCenter/case6.png'
import case7 from '@/assets/caseCenter/case7.png'
import case8 from '@/assets/caseCenter/case8.png'
import case9 from '@/assets/caseCenter/case9.png'
import up from '@/assets/caseCenter/ic_increase_small.png'

export default {
  name: 'caseCenter',
  data() {
    return {
      up,
      case1,
      case2,
      case3,
      case4,
      case5,
      case6,
      case7,
      case8,
      case9,
      listData: [
        {
          index: 1,
          logo: case1,
          companyName: '成都六六建筑工程咨询有限公司',
          useTime: '使用2个月后',
          clue: '70%',
          deal: '25%',
          achievement: '120%',
        },
        {
          index: 2,
          logo: case2,
          companyName: '华建博文管理咨询（北京）有限公司',
          useTime: '使用5个月后',
          clue: '120%',
          deal: '60%',
          achievement: '70%',
        },
        {
          index: 3,
          logo: case3,
          companyName: '江苏名俊企业管理有限公司',
          useTime: '使用3个月后',
          clue: '80%',
          deal: '50%',
          achievement: '70%',
        },
        {
          index: 4,
          logo: case4,
          companyName: '江苏唯诺企业管理有限公司',
          useTime: '使用3个月后',
          clue: '50%',
          deal: '35%',
          achievement: '60%',
        },
        {
          index: 5,
          logo: case5,
          companyName: '江苏元臣企业管理有限公司',
          useTime: '使用4个月后',
          clue: '75%',
          deal: '35%',
          achievement: '90%',
        },
        {
          index: 6,
          logo: case6,
          companyName: '江苏智臣教育咨询有限公司',
          useTime: '使用1个月后',
          clue: '30%',
          deal: '15%',
          achievement: '40%',
        },
        {
          index: 7,
          logo: case7,
          companyName: '四川辰轩企业服务有限公司',
          useTime: '使用3个月后',
          clue: '80%',
          deal: '50%',
          achievement: '70%',
        },
        {
          index: 8,
          logo: case8,
          companyName: '四川中象融创企业管理有限公司',
          useTime: '使用2个月后',
          clue: '70%',
          deal: '25%',
          achievement: '120%',
        },
        {
          index: 9,
          logo: case9,
          companyName: '江苏君致建设管理有限公司',
          useTime: '使用3个月后',
          clue: '65%',
          deal: '60%',
          achievement: '45%',
        },
      ],
    }
  },
  methods: {
    detail(item) {
      this.$router.push({
        path: `/caseDetails/${item}`,
      })
      //  localStorage.setItem('caseId', JSON.stringify(item))
    },
  },
  beforeCreate() {
    if (this.$route.query.id) {
      this.$router.push({
        path: `/caseDetails/${this.$route.query.id}`,
      })
    }
  },
  mounted() {
    // localStorage.removeItem('caseId')
    // localStorage.removeItem('dynamicId')
    document.title = '全国建筑企业资质查询-案例中心_小牛建讯官网'
  },
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.index {
  width: 100%;
  background-color: #f3f5fb;

  .banner {
    width: 100%;
    height: 360px;
    position: relative;

    .text {
      padding-top: 120px;
      width: 1200px;
      margin: 0 auto;
      text-align: center;

      p:first-child {
        font-size: 40px;
        font-weight: 500;
        color: #ffffff;
        line-height: 56px;
      }

      p:nth-child(2) {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      }
    }
  }

  .container {
    width: 1227px;
    margin: 46px auto;

    .breadcrumb {
      .item {
        width: 200px;
        margin: 20px 30px;
      }
    }

    .list {
      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          margin-bottom: 34px;
          border: 1px solid transparent;
          cursor: pointer;

          &:hover {
            box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            border: 1px solid #00ab94;
          }

          .img_div {
            padding: 24px;
            width: 378px;
            height: 283px;
            background: #ffffff;
            border-radius: 4px;
            .img-logo {
              width: 250px;
              height: 37px;
              //background-color: #ccc;
            }
            .companyName {
              margin-top: 12px;
              font-size: 20px;
              font-weight: 500;
              color: #181818;
              line-height: 28px;
            }
            .useTime {
              margin-top: 24px;
              font-size: 16px;
              font-weight: 500;
              color: #00ab94;
              line-height: 22px;
              span:first-child {
                margin-right: 12px;
              }
            }
            .line {
              margin-top: 24px;
              margin-bottom: 31px;
              width: 32px;
              height: 4px;
              background: #00ab94;
            }
            .up {
              display: flex;
              justify-content: space-between;

              .upNum {
                display: flex;
                align-items: center;
                p {
                  font-size: 24px;
                  font-weight: 600;
                  color: #181818;
                }
                img {
                  height: 18px;
                }
              }
              .upText {
                font-size: 14px;
                font-weight: 400;
                color: #585858;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
