<template>
  <div id="historyFilter" class="client-history">
    <div class="filter-box">
      <!-- <div class="filter-title">满足下列所有条件</div> -->
      <div class="filter-list">
        <div class="filter-item">
          <div class="label-title">企业名称：</div>
          <a-input
              v-model="enterpriseName"
              class="filter-input"
              placeholder="请输入查询企业"
          ></a-input>
        </div>
        <div class="filter-item">
          <div class="label-title">跟进状态：</div>
          <div class="select-list">
            <a-select
                v-model="followStatusPre"
                :getPopupContainer="getPopupContainer"
                allowClear
                class="half-input"
                placeholder="请选择跟进状态"
                @change="selectThePre"
            >
              <a-select-option
                  v-for="item in preOptions"
                  :key="item.value"
                  :value="item.value"
              >{{ item.label }}
              </a-select-option
              >
            </a-select>
            <a-select
                v-model="followStatusPost"
                :disabled="postDisabled"
                :dropdownMatchSelectWidth="false"
                :getPopupContainer="getPopupContainer"
                class="half-input"
                placeholder="请选择跟进状态"
            >
              <a-select-option
                  v-for="item in postOptions"
                  :key="item.value"
                  :value="item.value"
              >{{ item.label }}
              </a-select-option
              >
            </a-select>
          </div>
        </div>
        <div class="filter-item">
          <div class="label-title">状态更新时间：</div>
          <a-date-range
              v-model="dateRanger"
              :disabled-date="disabledEndDate"
              :getCalendarContainer="getPopupContainer"
              class="filter-input"
              @change="selectDateRange"
          ></a-date-range>
        </div>
        <div class="filter-item">
          <div class="label-title">数据来源：</div>

          <a-select
              v-model="clueSources"
              :getPopupContainer="getPopupContainer"
              allowClear
              class="filter-input"
              placeholder="请选择数据来源"
          >
            <a-select-option
                v-for="item in sourceOptions"
                :key="item.value"
                :value="item.value"
            >{{ item.label }}
            </a-select-option>
          </a-select>
        </div>
        <div v-if="!isSales" class="filter-item">
          <div class="label-title">跟进人：</div>
          <staffSelect ref="searchQua" @followStaffChange="followStaffChange"></staffSelect>

          <!--          <a-tree-select
                      placeholder="请选择跟进人员或部门"
                      show-search
                      v-model="followStaff"
                      treeCheckable
                      @change="setDepartmentStaff"
                      treeNodeFilterProp="title"
                      labelInValue
                      allow-clear
                      :getPopupContainer="getPopupContainer"
                      tree-default-expand-all
                      :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                      class="filter-input have-margin"
                    >
                      <a-tree-select-node
                        v-for="root in staffList"
                        :key="root.key"
                        :value="root.value"
                        :title="root.title"
                      >
                        <a-tree-select-node
                          v-for="level2 in root.children"
                          :key="level2.key"
                          :value="level2.value"
                          :title="level2.title"
                        >
                          <a-tree-select-node
                            v-for="level3 in level2.children"
                            :key="level3.key"
                            :value="level3.value"
                            :title="level3.title"
                          >
                          </a-tree-select-node>
                        </a-tree-select-node>
                      </a-tree-select-node>
                    </a-tree-select>-->
        </div>
      </div>
      <div class="operate-btns">
        <a-button class="btns rt" type="primary" @click="filterFindList">
          查询
        </a-button>
        <a-button class="btns" @click="resetFilter">重置</a-button>
      </div>
    </div>
    <div class="table-content">
      <div class="btns">
        <a-Tooltip placement="top">
          <template slot="title">
            <div class="client-tips">
              您在“客户查询”中获取的电话，选择拨打未接或是接通时跟进状态为无意向、辞职/公司倒闭/资质已售等非有意向状态的企业转入“历史记录”中。
              您可在“历史记录”中选择该企业或多个企业重新获取电话
            </div>
          </template>
          <div class="client-description">
            <a-icon class="icon" type="question-circle"/>
            历史记录规则说明
          </div>
        </a-Tooltip>
      </div>
      <a-table
          v-if="total"
          :columns="columns"
          :data-source="tableData"
          :pagination="pagination"
          rowKey="index"
      >
        <template slot="goToCompanyDetail" slot-scope="text">
          <div>
            {{ text }}
          </div>
        </template>
      </a-table>
      <block-data
          v-else
          :imageSrc="notFind"
          :text="''"
          :titleText="''"
      ></block-data>
    </div>
  </div>
</template>

<script>
import {
  Input,
  Select,
  DatePicker,
  Table,
  Drawer,
  Tooltip,
  TreeSelect
} from 'ant-design-vue'
// import { userModule } from '@/store/modules/user'
// import { detailModule } from '@/store/modules/detail'
import {mapState} from 'vuex'
import BlockData from '@/components/BlockData.vue'
import notFind from '@/assets/image/no_date_block.png'
import staffSelect from './StaffSelect.vue'
import Cookie from 'js-cookie'

export default {
  name: 'clentHistory',
  components: {
    staffSelect,
    BlockData,
    aTreeSelect: TreeSelect,
    aTreeSelectNode: TreeSelect.TreeNode,
    aTooltip: Tooltip,
    aDrawer: Drawer,
    aTable: Table,
    aInput: Input,
    aSelect: Select,
    aSelectOption: Select.Option,
    aDateRange: DatePicker.RangePicker
  },
  computed: {

    pagination() {
      return {
        total: this.total,
        pageSize: this.pageSize,
        current: this.pageNum,
        showSizeChanger: true,
        showTotal: total => {
          return `共${total}条数据`
        },
        onShowSizeChange: this.changePage,
        onChange: this.changePage
      }
    }
  },
  data() {
    return {
      isSales: false,
      userInfo: null,
      pageNum: 1,
      pageSize: 10,
      clueSources: undefined,
      notFind,
      followStaff: [],
      tableData: [],
      columns: [
        {title: '区域', dataIndex: 'areaName', width: 100},
        {
          title: '企业全称',
          dataIndex: 'enterpriseName',
          scopedSlots: {customRender: 'goToCompanyDetail'}
        },
        {
          title: '沟通资质',
          dataIndex: 'qualificationItemsText',
          width: 300
        },
        {
          title: '跟进状态',
          dataIndex: 'statusText'
        },
        {
          title: '跟进人',
          dataIndex: 'memberName'
        },
        {
          title: '状态更新时间',
          dataIndex: 'updateTime'
        },
        {
          title: '数据来源',
          dataIndex: 'clueSourcesText'
        }
      ],
      sourceText: ['', '收货查询', '出货查询'],
      sourceOptions: [
        {label: '收货查询', value: '1'},
        {label: '出货查询', value: '2'}
      ],
      total: 0,
      preOptions: [
        {label: '接通跟进', value: 1},
        {label: '拨打未通', value: 2},
        {label: '已成交', value: 3},
        {label: '未成交', value: 4}
      ],
      postDisabled: true,
      postOptions: [],
      staffList: [],
      dateRanger: [],
      startTime: '',
      endTime: '',
      enterpriseName: '',
      followStatusPost: undefined,
      followStatusPre: undefined,
      followUpUserId: [],
      showCompanyDetailDrawer: false
    }
  },
  methods: {
    followStaffChange(val) {
      if (val) {
        this.followUpUserId = val
      } else {
        this.followUpUserId= []
      }
    },
    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('historyFilter')
    },
    changePage(num, size) {
      this.pageNum = num
      this.pageSize = size
      this.getHistoryList()
    },
    goToCompanyDetail(item) {
      // this.showCompanyDetailDrawer = true
      // detailModule.setEnterpriseInfo(item)
      // this.$nextTick(() => {
      //     this.$refs.companyDrawer.setBasicInfo()
      // })
    },
    closeCompanyDetailDrawer() {
      this.showCompanyDetailDrawer = false
    },
    selectThePre(item) {
      this.followStatusPost = undefined
      switch (item) {
        case 2:
          this.postDisabled = false
          this.postOptions = [
            {label: '挂断', value: 0},
            {label: '未接', value: 1},
            {label: '空号', value: 2},
            {label: '无法接通', value: 3},
            {label: '不在服务区/国内', value: 4},
            {label: '其他', value: 5}
          ]
          this.followStatusPost = 0
          break
        case 1:
          this.postDisabled = false
          this.postOptions = [
            {label: '无意向', value: 2},
            {label: '挂断/打错/同行', value: 6},
            {label: '辞职/公司倒闭/资质已出售', value: 7}
          ]
          this.followStatusPost = 2
          break
        case 3:
          this.postDisabled = true
          this.followStatusPost = undefined
          this.postOptions = []
          break
        case 4:
          this.postDisabled = true
          this.followStatusPost = undefined
          this.postOptions = []
          break
        default:
          this.postDisabled = true
          this.followStatusPost = undefined
          this.postOptions = []
          break
      }
    },
    disabledEndDate(endValue) {
      const currentDate = new Date().getTime()
      return endValue.valueOf() > currentDate
    },
    userChange(value) {
      console.log(value)
    },
    selectDateRange(range) {
      this.startTime = range[0]
          ? this.$day(range[0]).format('YYYY-MM-DD')
          : undefined
      this.endTime = range[1]
          ? this.$day(range[1]).format('YYYY-MM-DD')
          : undefined
    },
    setDepartmentStaff(option) {
      this.followUpUserId = []
      if (option.length) {
        option.forEach(o => {
          const value = o.value.split(',')
          value.splice(0, 1)
          const filterValue = value.filter(e => {
            return e
          })
          this.followUpUserId.push(...filterValue)
        })
      }
    },
    getStaffListForDepentment() {
      const params = {
        companyId: this.userInfo.companyId
      }
      this.staffList = []
      this.$http.get('member/listAllByDepartment', params).then(r => {
        r.forEach((e, i) => {
          this.staffList.push({
            id: e.id || -1,
            children: e.subordinateOrg,
            subordinateOrg: e.subordinateOrg,
            title: e.name || '超级管理员',
            label: e.subordinateOrg,

            key: e.name ? e.name + i : '超级管理员' + i
          })
        })

        function setArrayToChildren(item, id) {
          if (item.subordinateOrg) {
            item.children = []
            item.subordinateOrg.forEach((e, j) => {
              item.children.push({
                subordinateOrg: e.subordinateOrg,
                key: e.name ? e.name + id : '超级管理员' + id,
                title: e.name || '超级管理员',
                label: e.subordinateOrg,
                children: e.subordinateOrg || []
              })
              setArrayToChildren(e, j)
            })
          } else {
            item.children = []
            item.key = item.id + item.name
            item.title = item.name
            item.label = item.subordinateOrg
          }
        }

        function setArrayValue(item) {
          let value = ''

          function loopValue(i) {
            i.children.forEach((e, j) => {
              if (e.subordinateOrg) {
                e.value = 'level' + j + ',' + setArrayValue(e)
                loopValue(e)
              } else {
                e.value = 'min,' + e.id
                value += e.id + ','
              }
            })
          }

          loopValue(item)
          return value
        }

        this.staffList.forEach(e => {
          setArrayToChildren(e, e.id)
        })
        this.staffList.forEach(e => {
          e.value = 'root,' + setArrayValue(e)
        })
      })
    },
    filterFindList() {
      this.pageSize = 10
      this.pageNum = 1
      this.getHistoryList()
    },
    resetFilter() {
      this.$refs.searchQua.clearFollowStaffId()
      this.enterpriseName = ''
      this.dateRanger = []
      this.startTime = ''
      this.endTime = ''
      this.followUpUserId = []
      this.followStaff = []
      this.followStatusPre = undefined
      this.followStatusPost = undefined
      this.clueSources = undefined
    },
    getHistoryList() {
      const params = {
        enterpriseName: this.enterpriseName,
        followStatusPost: this.followStatusPost,
        followStatusPre: this.followStatusPre,
        followUpUserId: this.followUpUserId || [],
        clueSources: this.clueSources,
        startTime: this.startTime,
        endTime: this.endTime,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      this.$http.post('followRecord/selHistoryList', params).then(res => {
        this.total = res.totalSize
        res.data.forEach((e, i) => {
          e.statusInfo = []
          if (e.followStatus) {
            e.statusInfo.push(e.followStatus)
          }
          if (e.notConnectedType) {
            e.statusInfo.push(e.notConnectedType)
          }
          e.statusText = e.statusInfo.join('/')
          e.qualificationItemsText = e.qualificationItems.join('，')
          e.index = i
          e.clueSourcesText = this.sourceText[e.clueSources]
        })
        this.tableData = res.data
      })
    }
  },
  mounted() {
    const userId = Cookie.get('userId')
    this.$http.get('member/get', {id: userId}).then(r => {
      this.userInfo = r
      if (this.userInfo.roleLevel <= 3 || this.userInfo.isLead) {
        this.getStaffListForDepentment()
      }
      this.isSales = this.userInfo.roleLevel === 4

    })


    this.getHistoryList()
  }
}
</script>

<style lang="scss" scoped>
.client-history {
  .filter-box {
    background-color: #ffffff;
    width: 100%;
    border-radius: 0 0 4px 4px;
    padding: 16px 25px;

    .filter-title {
      font-size: 16px;
      color: #909399;
    }

    .filter-list {
      width: 100%;

      .filter-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-shrink: 0;
        flex-grow: 0;
        margin-bottom: 16px;

        .label-title {
          width: 110px;
          text-align: left;
          font-size: 14px;
          flex-shrink: 0;
          color: #666666;
        }

        .select-list {
          width: 326px;
          display: flex;
          justify-content: space-between;
        }

        .half-input {
          width: 158px;
        }

        .filter-input {
          width: 326px;
        }

        .filter-btn {
          width: 86px;
          height: 36px;
          line-height: 36px;
        }

        .isSelect {
          background-color: rgba($color: #3b68e7, $alpha: 0.1);
          color: #3b68e7;
          border-color: #3b68e7;
        }

        .have-margin {
          margin-right: 24px;
        }
      }
    }

    .operate-btns {
      margin-top: 24px;
      margin-bottom: 16px;

      .btns {
        height: 40px;
        width: 126px;
        font-size: 16px;
        line-height: 40px;
      }

      .rt {
        margin-right: 24px;
      }
    }
  }

  .table-content {
    background-color: #ffffff;
    margin-top: 24px;

    .btns {
      width: 100%;
      height: 40px;
      position: relative;

      .client-description {
        position: absolute;
        right: 22px;

        .icon {
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
