import { render, staticRenderFns } from "./ProductList.vue?vue&type=template&id=19d184d6"
import script from "./ProductList.vue?vue&type=script&lang=js"
export * from "./ProductList.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./ProductList.vue?vue&type=style&index=0&id=19d184d6&prod&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "39401630"
  
)

export default component.exports