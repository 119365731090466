<template>
  <div>
    <a-modal
      title="沟通情况"
      :visible="turnOnPhone"
      :destroyOnClose="true"
      @ok="modalHandleOk"
      :zIndex="2000"
      @cancel="modalHandleCancel"
    >
      <a-form-model
        ref="quaForm"
        id="callPhoneForm"
        :model="qualificationModel"
        :labelCol="{ span: 5 }"
        :wrapperCol="{ span: 19 }"
      >
        <a-form-model-item
          label="沟通资质："
          prop="qualifications"
          :rules="{
            required: true,
            message: '请选择沟通资质',
            trigger: 'change'
          }"
        >
          <tree-checkbox-select
            :fakeQualification="fakeQualificationOptions"
            containerId="callPhoneForm"
            :qualificationOptions="vxTypeQualificationOptions"
            :designQualificationOptions="vxDesignQualificationOptions"
            v-model="qualificationModel.qualifications"
          ></tree-checkbox-select>
        </a-form-model-item>
      </a-form-model>
      <form-components
        ref="communicationForm"
        :formItems="formItems"
      ></form-components>
      <div class="turnon-tips">
        说明：
        请如实、仔细填写沟通情况，有利于提高跟踪质量。跟踪情况栏目选择“有意向”，本条客户资源自动保存到“我的客户”中，可在“我的客户”里进行跟踪管理。
      </div>
    </a-modal>
    <a-modal
      title="未接通电话"
      width="400px"
      :visible="notTurnOnPhone"
      @ok="modalHandleOk"
      :zIndex="2000"
      :destroyOnClose="true"
      @cancel="modalHandleCancel"
    >
      <div id="notTurnOn" class="not-turnon-form">
        <div class="label">未接通类型：</div>
        <a-select
          :getPopupContainer="getPopupContainer"
          class="select-input"
          v-model="notTurnOnValue"
        >
          <a-select-option
            v-for="item in notTurnOnInfo"
            :key="item.value"
            :value="item.value"
            >{{ item.label }}</a-select-option
          >
        </a-select>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Modal, Select } from 'ant-design-vue'
import FormComponents from './Form/index'
import formType from './Form/formType'
import TreeCheckboxSelect from '@/components/TreeCheckboxSelect.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'call-phone',
  components: {
    aModal: Modal,
    aSelect: Select,
    TreeCheckboxSelect,
    aSelectOption: Select.Option,
    FormComponents
  },
  computed: {
    ...mapState([
      'enterpriseItem',
      'turnOnPhone',
      'notTurnOnPhone',
      'qualificationTreeList',
      'defaultQua',
      'pageHandleFunction',
      'fakeQualificationOptions',
      'typeQualificationOptions',
      'designQualificationOptions',
      'rowQuaStatus'
    ]),
    basicInfo() {
      return this.enterpriseItem || {}
    },
    vxTypeQualificationOptions() {
      if (this.rowQuaStatus.onlyConstruction) {
        return this.typeQualificationOptions
      } else {
        return []
      }
    },
    vxDesignQualificationOptions() {
      if (this.basicInfo.clueSources === 1) {
        if (this.rowQuaStatus.onlyDesign) {
          return this.designQualificationOptions
        } else {
          return []
        }
      } else {
        return []
      }
    },
    formItems() {
      return [
        // {
        //   label: '沟通资质：',
        //   type: formType.TREESELECT,
        //   options: userModule.qualificationTreeList,
        //   value: homeModule.defaultQua,
        //   placeholder: '请选择资质',
        //   validate: [
        //     {
        //       required: true,
        //       message: '请选择',
        //       trigger: 'change',
        //     },
        //   ],
        //   name: 'qualificationItemIds',
        // },
        {
          label: '沟通情况：',
          type: formType.SELECT,
          options: [
            // { label: '未确认', value: 0 },
            { label: '有意向', value: 1 },
            { label: '无意向', value: 2 },
            // { label: '已成交', value: 3 },
            // { label: '未成交', value: 4 },
            { label: '挂断/打错/同行', value: 6 },
            { label: '辞职/公司倒闭/资质已出售', value: 7 }
          ],
          value: this.formValue.followStatus,
          placeholder: '请选择沟通情况',
          validate: [
            {
              required: true,
              message: '请选择沟通情况',
              trigger: 'change'
            }
          ],
          name: 'followStatus'
        },
        {
          label: '联系人：',
          type: formType.INPUT,
          value: this.formValue.contacts,
          placeholder: '请输入联系人',
          name: 'contacts',
          validate: [
            {
              required: true,
              message: '请输入联系人',
              trigger: 'blur'
            }
          ]
        },
        {
          label: '联系电话：',
          type: formType.SELECT,
          showSearch: true,
          value: this.formValue.phone,
          options: this.basicInfo.phoneList
            ? this.basicInfo.phoneList
                .map(e => {
                  return { value: e, label: e }
                })
                .concat(this.phoneList)
            : [],
          placeholder: '请输入联系电话',
          name: 'phone',
          props: {
            allowClear: true
          },
          searchFunction: this.setInputInOptions.bind(this),
          validate: [
            {
              validator: this.validatePhoneNumber,
              required: true,
              trigger: 'blur'
            }
          ]
        },
        {
          label: '电话是否有效：',
          type: formType.RADIO,
          show: false,
          options: [
            { label: '有效', value: 11 },
            { label: '无效', value: 10 }
          ],
          value: 11,
          name: 'phoneStatus'
        },
        {
          label: '备注：',
          type: formType.TEXTAREA,
          value: this.formValue.remarks,
          placeholder: '请输入跟进备注',
          name: 'remarks',
          validate: [
            {
              required: true,
              message: '请输入跟进备注',
              trigger: 'blur'
            }
          ]
        }
      ]
    }
  },
  data() {
    return {
      qualificationModel: {
        qualifications: []
      },
      contactsPhone: '',
      formValue: {
        followStatus: [],
        contacts: '',
        phone: '',
        remarks: ''
      },
      phoneList: [],

      notTurnOnInfo: [
        { label: '挂断', value: 0 },
        { label: '未接', value: 1 },
        { label: '空号', value: 2 },
        { label: '无法接通', value: 3 },
        { label: '不在服务区/国内', value: 4 },
        { label: '其他', value: 5 }
      ],
      notTurnOnValue: 0
    }
  },
  methods: {
    ...mapMutations([
      'changeTurnPhoneStatus',
      'changeNotTurnPhoneStatus',
      'changeEnterpriseKey'
    ]),
    setInputInOptions(value) {
      if (!value) {
        return
      }
      let inputPhone = ''
      if (this.debounce) {
        clearTimeout(this.debounce)
      }
      this.debounce = setTimeout(() => {
        inputPhone = value
        this.phoneList.push({ value: inputPhone, label: inputPhone })
        this.contactsPhone = inputPhone
        //  clearTimeout(this.debounce)                console.log(this.contactsPhone)

        // this.debounce = null
      }, 500)

      // if (!this.debounce) {
    },
    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('notTurnOn')
    },
    validatePhoneNumber(rule, value, callback) {
      const isTelephone = /^\d{3}-\d{8}$|^\d{4}-\d{7,8}$/
      const isCellPhone = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (!isCellPhone.test(value) && !isTelephone.test(value)) {
        callback(new Error('请输入正确的固话或者手机号码'))
      } else {
        callback()
      }
    },
    modalHandleOk() {
      if (this.notTurnOnPhone) {
        //电话未接通
        const params = {
          enterpriseId: this.basicInfo.enterpriseId,
          notConnectedType: this.notTurnOnValue,
          clueSources: this.basicInfo.clueSources
        }
        this.$http.post('followRecord/notConnected', params).then(() => {
          this.$message.success('操作成功', 0.5)
          this.modalHandleCancel()
          this.pageHandleFunction()
          // this.changeEnterpriseKey({
          //     prop: 'haveReceive',
          //     key: false
          // })
          //   this.modalHandleCancel()
          //   this.getFollowList()
          //   this.selectMainTitle(4)
          //   this.basicInfo.haveReceive = false
          //   this.$emit('havePhoneOperate')
        })
      } else {
        // 电话已接通
        const validate = this.$refs.communicationForm.formValidator()
        let quaFormValidate = false
        this.$refs.quaForm.validate(validate => {
          quaFormValidate = validate
        })
        // console.log(this.qualificationModel)
        if (validate && quaFormValidate) {
          const params = {
            ...validate,
            enterpriseId: this.basicInfo.enterpriseId,
            clueSources: this.basicInfo.clueSources,
            enterpriseName:
              this.basicInfo.companyName || this.basicInfo.enterpriseName
          }
          params.qualificationItemIds = this.qualificationModel.qualifications
            .map(q => {
              return q
            })
            .join(',')
          this.$http.post('followRecord/addFollowRecord', [params]).then(() => {
            this.$message.success('操作成功', 0.5)
            if (validate.followStatus !== 1) {
              this.changeEnterpriseKey({
                prop: 'haveReceive',
                key: false
              })
            }
            this.modalHandleCancel()
            this.pageHandleFunction()
          })
        }
      }
    },
    modalHandleCancel() {
      this.notTurnOnValue = 0
      this.changeTurnPhoneStatus(false)
      this.changeNotTurnPhoneStatus(false)
    }
  },
  watch: {
    defaultQua: {
      handler(val) {
        if (val) {
          this.qualificationModel.qualifications = val.map(e => {
            return parseInt(e.value, 10)
          })
          // console.log(val)
        }
      }
    },
    basicInfo: {
      handler(val) {
        this.formValue.phone = this.basicInfo.phoneList
          ? this.basicInfo.phoneList[0]
          : undefined
        this.formValue.followStatus = []
        this.formValue.contacts = ''
        this.formValue.remarks = ''
        this.phoneList = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.not-turnon-form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    font-size: 14px;
  }
  .select-input {
    width: 70%;
  }
}
</style>
