<template>
    <div>
        <a-button @click="showModal">详情</a-button>
        <a-modal
            v-model="visible"
            title="失信被执行信息"
            @ok="handleOk"
            :bodyStyle="{ padding: '20px 55px' }"
        >
            <table>
                <template v-for="(item, index) in infoList">
                    <tr :key="index" v-if="item.showFlag">
                        <td>{{ item.name }}</td>
                        <td>{{ item.detail }}</td>
                    </tr>
                </template>
            </table>
            <template slot="footer">
                <a-button key="back" @click="handleCancel" style="right: 5px;">关闭</a-button>
            </template>
        </a-modal>
    </div>
</template>

<script>
export default {
    name: 'brake-faith',
    props: {
        brakeRow: {}
    },
    data() {
        return {
            infoList: [],
            visible: false //显示弹框
        }
    },
    methods: {
        showModal() {
            this.visible = true
            //详情请求
            this.$http
                .get('business/riskinformation/queryBreakfaithInfoDetail', {
                    id: this.brakeRow.id
                })
                .then(r => {
                    // table
                    this.infoList = [
                        {
                            name: '被执行人姓名/名称：',
                            detail: r.iname || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '身份证号码/组织机构代码：',
                            detail: r.socialCreditCode || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '法定代表人或者负责人姓名：',
                            detail: r.legalPerson || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '执行法院：',
                            detail: r.court || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '省份：',
                            detail: r.areaName || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '执行依据文号：',
                            detail: r.basis || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '立案时间：',
                            detail: r.filingDate || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '案号：',
                            detail: r.caseNumber || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '做出执行依据单位:',
                            detail: r.gistUnit || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '生效法律文书确定的义务：',
                            detail: r.duty || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '被执行人的履行情况：',
                            detail: r.performance || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '已履行:',
                            detail: r.performedPart || '暂无数据',
                            showFlag: r.performance == '部分未履行'
                        },
                        {
                            name: '未履行:',
                            detail: r.unperformPart || '暂无数据',
                            showFlag: r.performance == '部分未履行'
                        },
                        {
                            name: '失信被执行人行为具体情形：',
                            detail: r.disreputTypeName || '暂无数据',
                            showFlag: true
                        },
                        {
                            name: '发布时间：',
                            detail: r.releaseDate || '暂无数据',
                            showFlag: true
                        }
                    ]
                })
        },

        handleOk() {
            this.visible = false
        },

        // 取消
        handleCancel() {
            this.visible = false
        }
    }
}
</script>

<style lang="scss" scoped>
table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    color: #606366;
    td {
        border: 1px solid #d9d9d9;
        height: 15px;
        padding: 5px;
        text-align: left;
        width: 50%;
    }
}
</style>
