import Vue from 'vue'
import Vuex from 'vuex'
import { designList } from '@/components/designQualification.js'
import httpRequestor from '@/utils/http'
import httpRequestors from '@/utils/https'

Vue.use(Vuex)

export function createStore() {
  return new Vuex.Store({
    state: {
      //倒计时
      surplusTime:'0天0时0分0秒',
      //侧边栏倒计时
      sidebarTime:'0:0:0:0',
      //弹出框倒计时
      springFrameTime:'',
      //调价前后 true:前
      showSpringFrame:false,
      verification: false,
      isListQuery: false,
      enterpriseItem: null,
      turnOnPhone: false,
      notTurnOnPhone: false,
      qualificationTreeList: [],
      defaultQua: [],
      turnOnItem: null,
      userInfo: null,
      areaOptions: [],
      fakeQualificationOptions: [],
      typeQualificationOptions: [],
      designQualificationOptions: [],
      pageHandleFunction: null,
      watchPhoneModalShow: false,
      notPhoneCountModalShow: false,
      expriedModalShow: false,
      freeOverDateModalShow: false,
      isShowLogin: false,
      isShowActivity: false,
      //登录成功的状态
      isLoginSuccess: false,
      rowQuaStatus: {
        onlyDesign: false,
        onlyConstruction: true
      },
      dynamicData: [],
      dynamicDetail: [{}, {}, {}],
      dynamicTotal: 0,
      testId: ''
    },
    mutations: {
      fetchItem(state, id) {
        state.testId = id
      },
      setRowQuaStatus(state, status) {
        state.rowQuaStatus = status
      },
      setLoginSuccess(state, status) {
        state.isLoginSuccess = status
      },
      setShowLoginModal(state, status) {
        state.isShowLogin = status
      },
      setShowActivityModal(state, status) {
        state.isShowActivity = status
      },
      setVerification(state, status) {
        state.verification = status
      },
      setListQuery(state, status) {
        state.isListQuery = status
      },
      setWatchPhoneModalShow(state, status) {
        // console.log(status)
        state.watchPhoneModalShow = status
      },
      setNotPhoneCountModalShow(state, status) {
        // console.log(status)
        state.notPhoneCountModalShow = status
      },
      setExpriedModalShow(state, status) {
        state.expriedModalShow = status
      },
      setFreeOverDateModalShow(state, status) {
        // console.log(status)
        state.freeOverDateModalShow = status
      },
      changeTurnPhoneStatus(state, status) {
        // console.log(status)
        state.turnOnPhone = status
      },
      changeNotTurnPhoneStatus(state, status) {
        state.notTurnOnPhone = status
      },
      setPageHandleFunction(state, fun) {
        state.pageHandleFunction = fun
      },
      setTurnOnItem(state, item) {
        state.turnOnItem = item
      },
      setATurnOnDefaultQua(state, item) {
        state.defaultQua = item
      },
      setEnterpriseInfo(state, item) {
        state.enterpriseItem = item
        localStorage.setItem('enterpriseItem', JSON.stringify(item))
      },
      setQualificationOptions(state, val) {
        // console.log(val)
        state.qualificationTreeList = val
      },
      setUserInfo(state, user) {
        state.userInfo = user
      },
      clearUserInfo(state, user) {
        state.userInfo = null
      },
      setQualification(state, option) {
        // state.typeQualificationOptions = option
        option.forEach(e => {
          if (e.qualificationIndustry.indexOf('设计') > -1) {
            this.commit('setDesignQualification', [...e.qualificationTypeList])
          } else {
            this.commit('setTypeQualification', [...e.qualificationTypeList])
          }
        })
      },
      setTypeQualification(state, r) {
        state.typeQualificationOptions = r.map((e, i) => {
          return {
            title: e.qualificationType,
            searchTitle: e.qualificationType,
            key: i,
            isExpend: i === 0,
            children: e.qualificationList.map(q => {
              return {
                title: q.qualificationName,
                searchTitle: q.qualificationName,

                key: q.qualificationName,
                // checkable: true,
                children: q.qualificationLevels.map(level => {
                  return {
                    id: level.id,
                    searchTitle: level.level,
                    title: level.level,
                    disabled: false
                  }
                }),
                value: []
              }
            })
          }
        })
      },
      setDesignQualification(state, r) {
        state.designQualificationOptions = r.map((e, i) => {
          if (e.qualificationType === '专业资质') {
            let haveGroup = []

            designList.forEach(d => {
              const find = e.qualificationList.filter(q => {
                return q.qualificationName.indexOf(d) > -1
              })
              find.forEach(f => {
                f.qualificationNameShow = f.qualificationName.replace(d, '')
              })
              find.unshift({
                qualificationName: d,
                onlyShow: true
              })
              haveGroup = haveGroup.concat(find)
            })
            return {
              title: e.qualificationType,
              searchTitle: e.qualificationType,
              key: i,
              isExpend: i === 0,
              isSpecial: true,
              children: haveGroup.map(q => {
                if (q.onlyShow) {
                  return {
                    title: q.qualificationName,
                    key: q.qualificationName,
                    searchTitle: q.qualificationName,
                    onlyShow: true,
                    children: [],
                    value: []
                  }
                } else {
                  return {
                    title: q.qualificationNameShow,
                    key: q.qualificationName,
                    searchTitle: q.qualificationName,
                    // checkable: true,
                    children: q.qualificationLevels.map(level => {
                      return {
                        id: level.id,
                        title: level.level,
                        searchTitle: level.level,
                        disabled: false
                      }
                    }),
                    value: []
                  }
                }
              })
            }
          } else {
            return {
              title: e.qualificationType,
              searchTitle: e.qualificationType,
              key: i,
              isExpend: i === 0,
              children: e.qualificationList.map(q => {
                return {
                  title: q.qualificationName,
                  key: q.qualificationName,
                  searchTitle: q.qualificationName,
                  // checkable: true,
                  children: q.qualificationLevels.map(level => {
                    return {
                      id: level.id,
                      title: level.level,
                      searchTitle: level.level,
                      disabled: false
                    }
                  }),
                  value: []
                }
              })
            }
          }
        })
      },
      setFakerQualification(state, option) {
        state.fakeQualificationOptions = option
      },
      setAreaOptions(state, options) {
        state.areaOptions = options
      },
      changeEnterpriseKey(state, params) {
        const { prop, key } = params
        state.enterpriseItem[prop] = key
        state.enterpriseItem = Object.assign({}, state.enterpriseItem)
        localStorage.setItem(
          'enterpriseItem',
          JSON.stringify(state.enterpriseItem)
        )
      },
      setDynamicData(state, res) {
        state.dynamicData = res.list
        state.dynamicTotal = res.total
      },
      setDynamicDetail(state, res) {
        state.dynamicDetail = res
      },
      setSurplusTime(state, res) {
        state.surplusTime = res
      },
      setSidebarTime(state, res) {
        state.sidebarTime = res
      },
      setSpringFrameTime(state, res) {
        state.springFrameTime = res
      },
      setShowSpringFrame(state, res) {
        state.showSpringFrame = res
      },


    },
    actions: {
      getUserInfo({ commit }, userId) {
        if (userId) {
          httpRequestor.get('member/get', { id: userId }).then(r => {
            commit('setUserInfo', r)
            // next()
          })
        } else {
          console.log('未登录')
        }
      },
      async getDynamicList({ commit }, searchData) {
        const dynamicTypeMap = new Map([
          ['PLAY', '公示公告'],
          ['INFO', '行业资讯'],
          ['FAQ', '资质问答'],
          ['RECOMMEND', '推荐专题']
        ])
        return await httpRequestors
          .post('dynamic/selList', searchData)
          .then(res => {
            res.list.forEach((item, index) => {
              item.index = index
              item.dynamicTypeEnum = dynamicTypeMap.get(item.dynamicTypeEnum)
            })
            commit('setDynamicData', res)
            //  this.tableData = res.list
            // this.total = res.total
          })
      },

      getEndTime({ commit }) {
        let startDate = new Date();  //开始时间，当前时间
        let endDate = new Date('2022/03/10 00:00:00'); //结束时间，需传入时间参数
        let t = endDate.getTime() - startDate.getTime();  //时间差的毫秒数
        let d = 0, h = 0, m = 0, s = 0;
        if( t >= 0 ) {
          d = Math.floor( t/1000/3600/24 );
          h = Math.floor( t/1000/60/60%24 );
          m = Math.floor( t/1000/60%60 );
          s = Math.floor( t/1000%60 );
          if (d<10) {
            d = '0' +d;
          }
          if (h<10) {
            h = '0' +h;
          }
          if (m<10) {
            m = '0' +m;
          }
          if (s<10) {
            s= '0' +s;
          }
        }else if(t===0) {
          commit('setShowSpringFrame',false)
        }

        commit('setSurplusTime', `${d}天${h}时${m}分${s}秒`)
        commit('setSidebarTime', `${d}:${h}:${m}:${s}`)
        commit('setSpringFrameTime', `${d}天${h}时${m}分${s}秒`)
      },

      getDynamicDetail({ commit }, params) {
        if (params) {
          return httpRequestor.get('dynamic/detail', params).then(res => {
            commit('setDynamicDetail', res)
          })
        }
      }
    }
  })
}
