<template>
  <div class="dynamic">
    <div :style="'background-image:url(' + dynamicBg + ')'" class="bg">
      <div class="text">
        <p>{{ dynamicText }}</p>
        <div class="line"></div>
      </div>
    </div>
    <div class="div">
      <!--  面包屑-->
      <!--      <div class="breadcrumb">
              <a-breadcrumb class="item" separator=">">
                <a-breadcrumb-item style="cursor: pointer;">
                  <router-link to="/home">
                    首页
                  </router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>探索小牛</a-breadcrumb-item>
                <a-breadcrumb-item
                  ><span style="color: #333333;">小牛动态</span></a-breadcrumb-item
                >
              </a-breadcrumb>
            </div>-->
      <!--  tab-->
      <div class="tab">
        <div class="leftTab">
          <p
              v-for="(item, index) in tabData"
              :key="index"
              :class="{ activeTab: isTab === item.val }"
              @click="
              isTab = item.val
              callback(item)
            "
          >
            {{ item.title }}
          </p>
        </div>
        <div class="rightTab">
          <p

              v-for="(item, i) in leftTabData"
              :key="i"
              :class="{ active: isCur === i }"
              @click="isCur = i
              tabChange(item) "
          >
            {{ item.title }}
          </p>
        </div>

      </div>
      <!--    内容-->
      <div class="main">
        <div class="leftMain">
          <ul>
            <li v-for="(item, index) in tableData" :key="index" @click="detail(item)">
              <img
                  :src="item.homepagePic"
                  alt="小牛建讯"
                  class="mainImg"
              />
              <div class="textLocation">
                <p
                    class="title"
                >{{ item.title }}</p>
                <p class="content">{{ item.description }}</p>
                <div class="info">
                  <div class="infoPeople">
                    <p>
                      <span>{{ item.dynamicTime }}</span>
                    </p>
                    <p>
                      <img :src="icEye" alt="小牛建讯">
                      {{ item.readCount }}
                    </p>
                  </div>

                </div>
              </div>
            </li>
          </ul>
          <!--    分页-->
          <div class="page">
            <a-pagination
                v-model="searchData.current"
                :default-current="searchData.current"
                :defaultPageSize="searchData.size"
                :total="total"
                class="pagination"
                @change="onChange"
            />
          </div>
        </div>
        <div class="rightMain">
          <a-carousel autoplay class="carousel">
            <img v-for="(item, index) in tableData" v-if="index<3" :key="index"
                 :src="item.homepagePic" alt="小牛建讯">
          </a-carousel>
          <img :src="officialaccount" alt="小牛建讯">
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import xiaoNiuBg from '@/assets/dynamic/bg_xiaoniu.png'
import questionBg from '@/assets/dynamic/bg_question.png'
import informationBg from '@/assets/dynamic/bg_information.png'
import icEye from '@/assets/dynamic/ic_eye.png'
import officialaccount from '@/assets/dynamic/pc_officialaccount.png'


import {mapState, mapMutations} from 'vuex'

export default {
  name: 'Dynamic',
  // asyncData({store, route}) {
  //   return store.dispatch('getDynamicList', {
  //     current: 1,
  //     size: 4,
  //     orderByReadCount: 1,
  //     dynamicTypeEnum: 'PLAY'
  //   })
  // },
  computed: {
    ...mapState(['dynamicData', 'dynamicTotal']),
    total() {
      return this.dynamicTotal
    },
    tableData() {
      return this.dynamicData
    }
  },
  data() {
    return {
      xiaoNiuBg,
      questionBg,
      informationBg,
      icEye,
      officialaccount,
      dynamicText: '公示公告',
      dynamicBg: xiaoNiuBg,
      bgMap: new Map([['公示公告', xiaoNiuBg], ['行业资讯', informationBg], ['资质问答', questionBg]]),
      // total: null,
      searchData: {
        current: 1,
        size: 4,
        orderByReadCount: 1,
        dynamicTypeEnum: 'PLAY'
      },
      dynamicTypeMap: new Map([
        ['PLAY', '公示公告'],
        ['INFO', '行业资讯'],
        ['FAQ', '资质问答'],
        ['RECOMMEND', '推荐专题']
      ]),
      orderByReadCountMap: new Map([
        ['最新动态', null],
        ['热门动态', 1]
      ]),
      leftTabData: [
        {val: 0, title: '热门动态'},
        {val: 1, title: '最新动态'}
      ],
      dynamicTypeEnumMap: new Map([
        ['公示公告', 'PLAY'],
        ['行业资讯', 'INFO'],
        ['资质问答', 'FAQ']
      ]),
      tabEnumMap: new Map([
        [1, '公示公告'],
        [2, '行业资讯'],
        [3, '资质问答']
      ]),
      tabData: [
        {val: 1, title: '公示公告'},
        {val: 2, title: '行业资讯'},
        {val: 3, title: '资质问答'}
      ],
      isCur: 0,
      isTab: 1,
      // tableData: []
    }
  },
  methods: {
    ...mapMutations(['setDynamicData']),
    callback(key) {
      this.dynamicText = key.title
      this.dynamicBg = this.bgMap.get(this.dynamicText)
      this.isTab = key.val
      this.searchData.dynamicTypeEnum = this.dynamicTypeEnumMap.get(this.dynamicText)
      this.searchData.current = 1
      this.getListData()
    },
    tabChange(i) {
      this.isCur = i.val
      this.searchData.orderByReadCount = this.orderByReadCountMap.get(i.title)
      this.searchData.current = 1
      this.getListData()
    },
    onChange(pageNumber) {
      this.searchData.current = pageNumber
      this.getListData()
    },
    detail(item) {
      this.$router.push({
        path: `/details/${item.dynamicId}`
      })
    },
    getListData() {
      this.$store.dispatch('getDynamicList', this.searchData)
    }
  },
  mounted() {
    // this.getCarouselImg()
    const index = this.$route.params.type
    // console.log(index)
    if (index) {
      this.isTab = parseInt(index)
      this.dynamicText = this.tabEnumMap.get(this.isTab)
      this.dynamicBg = this.bgMap.get(this.dynamicText)
      this.searchData.dynamicTypeEnum = this.dynamicTypeEnumMap.get(this.dynamicText)
      this.$nextTick(()=>{
        this.getListData()
      })
    }


  }
}
</script>

<style lang="scss" scoped>
.carousel {
  margin-bottom: 24px;
}

.ant-carousel /deep/ .slick-slide img {
  width: 344px;
  height: 194px;
  border-radius: 4px;
}

* {
  margin: 0;
  padding: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dynamic {
  width: 100%;
  background-color: #fff;

  .bg {
    width: 100%;
    height: 240px;
    display: flex;
    background-size: 100% 100%;

    .text {
      width: 1200px;
      margin: 0 auto;
      padding-top: 80px;


      p:first-child {
        font-size: 40px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 56px;
      }

      .line {
        margin-top: 16px;
        width: 64px;
        height: 8px;
        background: #00AB94;
      }
    }
  }

  .div {
    width: 1200px;
    margin: 0 auto;

    .breadcrumb {
      .item {
        width: 200px;
        margin: 20px 0;
      }
    }

    .tab {
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #E6E9F0;


      .leftTab {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        color: #585858;

        p {
          cursor: pointer;
          margin-right: 32px;
          padding-bottom: 24px;
        }

        .activeTab {
          color: #181818;
          border-bottom: 2px solid #00AB94;
        }
      }

      .rightTab {
        display: flex;
        justify-content: space-between;
        margin-right: 15px;


        .active {
          color: #00AB94;
        }

        p {
          cursor: pointer;
          font-size: 16px;
          height: 20px;
        }

        p:not(p:last-child) {
          border-right: 1px solid #A0A6AB;
          padding-right: 8px;
          margin-right: 8px;

        }
      }
    }

    .main {
      width: 1200px;
      box-sizing: border-box;
      display: flex;

      .leftMain {
        width: calc(1200px - 408px);

        ul {
          li {
            margin-top: 24px;
            cursor: pointer;
            display: flex;
            border-bottom: 1px solid #E6E9F0;
            padding-bottom: 24px;

            .mainImg {
              width: 282px;
              height: 159px;
              border-radius: 4px;
              margin-right: 24px;
            }

            .textLocation {
              width: 486px;
              position: relative;

              .title {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 20px;
                font-weight: 500;
                color: #181818;
                line-height: 28px;

                &:hover {
                  color: #00AB94;
                }

              }

              .content {
                margin-top: 12px;
                font-size: 14px;
                font-weight: 400;
                color: #585858;
                line-height: 22px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }

              .info {
                width: 100%;
                display: flex;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                font-size: 14px;
                font-weight: 400;
                color: #A0A6AB;
                line-height: 22px;

                .infoPeople {
                  width: 100%;
                  display: flex;
                  justify-content: space-between
                }
              }


            }


          }
        }

        .page {
          position: relative;
          height: 136px;
          width: 100%;

          .pagination {
            position: absolute;
            right: 0;
            top: 24px;
          }
        }

      }

      .rightMain {
        width: 344px;
        margin-left: 64px;
        margin-top: 24px;
      }


    }


  }
}
</style>
