import { render, staticRenderFns } from "./SideTool.vue?vue&type=template&id=40804402&scoped=true"
import script from "./SideTool.vue?vue&type=script&lang=js"
export * from "./SideTool.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./SideTool.vue?vue&type=style&index=0&id=40804402&prod&lang=scss")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./SideTool.vue?vue&type=style&index=1&id=40804402&prod&lang=scss&scoped=true")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "40804402",
  "6c8186e4"
  
)

export default component.exports