import { render, staticRenderFns } from "./brakeFaithDetail.vue?vue&type=template&id=7a149835&scoped=true"
import script from "./brakeFaithDetail.vue?vue&type=script&lang=js"
export * from "./brakeFaithDetail.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./brakeFaithDetail.vue?vue&type=style&index=0&id=7a149835&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "7a149835",
  "01d02521"
  
)

export default component.exports