<template>
  <div class="qr-page">
    <div class="page-tabs">
      <a-tabs :activeKey="activeKey" size="large" @change="changeRouter">
        <a-tab-pane v-for="(item, key) in tabs" :key="key" :tab="item.tab"></a-tab-pane>
      </a-tabs>
    </div>
    <component :is="tabs[activeKey].components"></component>
  </div>
</template>

<script>
import {Tabs} from 'ant-design-vue'
import processing from './ClientComponents/Processing.vue'
import MyHistory from './ClientComponents/History.vue'
import {mapState} from 'vuex'

export default {
  name: 'client-layout',
  data() {
    return {
      activeKey: 0,
      tabs: [
        {tab: '跟进中客户', components: processing},
        {tab: '历史记录', components: MyHistory}
      ]
    }
  },

  components: {
    aTabs: Tabs,
    aTabPane: Tabs.TabPane
  },
  methods: {
    changeRouter(key) {
      this.activeKey = key
    }
  },
  mounted() {

    // this.$nextTick(() => {
    //     this.activeKey = this.$route.name
    //     //  this.changeRouter(this.activeKey)
    // })
  }
}
</script>

<style lang="scss" scoped>
.qr-page {
  width: 1200px;
  margin-bottom: 40px;

  .page-tabs {
    border-radius: 4px 4px 0 0;
    height: 58px;
    background-color: #ffffff;
    // .ant-tabs-nav-container {
    //   height: 50px;
    // }
  }
}
</style>
