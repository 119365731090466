<template>
  <div class="buying-page">
    <div class="buying-filter" id="buyingFilter">
      <div class="filter-item">
        <a-breadcrumb>
          <a-breadcrumb-item><a @click="goBack">资质集市</a></a-breadcrumb-item>
          <a-breadcrumb-item> 我要卖资质</a-breadcrumb-item>
        </a-breadcrumb>
      </div>
      <div class="filter-item">
        <div class="filter-label">求购地区：</div>
        <a-select
          allow-clear
          style="width: 305px"
          :getPopupContainer="getPopupContainer"
          v-model="area"
          placeholder="请选择查询区域"
        >
          <a-option
            v-for="item in areaOptions"
            :key="item.value"
            :value="item.value"
            :disabled="item.disabled"
            >{{ item.label }}</a-option
          >
        </a-select>
      </div>
      <div class="filter-item">
        <div class="filter-label">资质：</div>
        <tree-checkbox-single-select
          v-model="qualifications"
          class="auto-width"
          containerId="buyingFilter"
          :designQualificationOptions="designQualificationOptions"
          :fakeQualification="fakeQualification"
          :qualificationOptions="qualificationOptions"
        ></tree-checkbox-single-select>
      </div>
      <div class="filter-item">
        <a-button
          size="large"
          class="large-button"
          @click="clickFindBtn"
          type="primary"
          >查询</a-button
        >
        <a-button
          size="large"
          class="large-button"
          @click="e => reSetFilter(false)"
          >重置</a-button
        >
        <a-button
          size="large"
          @click="goToBuyQualification"
          class="large-button rt-abs-button"
          >我要求购资源</a-button
        >
      </div>
    </div>
    <div class="table-content">
      <div class="buy-tools">
        <div class="tips buy-tips">
          小牛建讯为您查询出
          <span class="primary-text">{{ total }}</span>
          个符合条件的求购信息
        </div>
        <div class="sort-tool">
          <div
            class="tools"
            @click="changeSortRule(0)"
            :class="{ selected: sortIndex === 0 }"
          >
            默认排序
          </div>
          <div class="dividing-line"></div>
          <div
            class="tools"
            @click="changeSortRule(1)"
            :class="{ selected: sortIndex === 1 }"
          >
            最新
          </div>
        </div>
      </div>
      <hr class="line" />
      <div class="table-list">
        <div class="loading" v-show="dataLoading">
          <a-spin size="large" tip="加载中......" />
        </div>
        <div v-if="tableData.length" style="min-height:200px">
          <div v-if="tableData[0].qualification">
            <table-item
              @refreshList="getBuyingList"
              :freeCount="freeCount"
              v-for="(row, index) in tableData"
              :key="index"
              :index="index"
              :row="row"
            ></table-item>
          </div>
          <div class="table-pagination">
            <a-pagination
              v-model="pageNum"
              @change="tableChange"
              @showSizeChange="tableChange"
              :showSizeChanger="true"
              :showQuickJumper="true"
              :pageSize.sync="pageSize"
              :total="total"
            ></a-pagination>
            <a-button v-if="total > pageSize" class="fake-btn">确定</a-button>
          </div>
        </div>
        <div class="no-data" v-else>
          <div class="block-content">
            <div class="block-title">没有您想要的求购信息?</div>
            <div class="block-tips">
              转让资质，就找小牛建讯，快速对接需求方，安全可靠
            </div>
            <div class="block-btns">
              <div class="speed ">
                <a-button class="btn" size="large" @click="fastSend"
                  >快速发布</a-button
                >
                <div class="tips">只需一步，买家找上门</div>
              </div>
              <div class="excellent ">
                <a-button
                  type="primary"
                  @click="goToExcellentShare"
                  class="btn"
                  size="large"
                  >优选发布
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="process"
      :style="'background-image:url(' + processBg + ')'"
    ></div>
  </div>
</template>

<script>
import { TreeSelect, Drawer, Pagination, Select, Spin } from 'ant-design-vue'
import blockData from '@/components/BlockData.vue'
import qrModal from '@/components/qrCode.vue'
import notFind from '@/assets/image/no_date_block.png'
import TableItem from './TableItem'
import processBg from '@/assets/image/qr/process.png'
import TreeCheckboxSingleSelect from '@/components/TreeCheckboxSingleSelect.vue'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'buy-qualication',
  components: {
    TableItem,
    blockData,
    qrModal,
    aPagination: Pagination,
    aDrawer: Drawer,
    aSpin: Spin,
    aSelect: Select,
    aOption: Select.Option,
    aTreeSelect: TreeSelect,
    aTreeSelectNode: TreeSelect.TreeNode,
    TreeCheckboxSingleSelect
  },
  data() {
    return {
      dataLoading: false,
      sortIndex: 0,
      condition: null,
      qualificationIds: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      tableData: [{}],
      notFind,
      freeCount: 0,
      areaOptions: [],
      qualifications: undefined,
      defaultExpend: [],
      area: undefined,
      areaData: [],
      areaCode: '',
      lastFilterSave: null,
      haveMyBuyData: false,
      processBg
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      designQualificationOptions:state => state.designQualificationOptions,
      fakeQualification: state => state.fakeQualificationOptions,
      qualificationOptions: state => state.typeQualificationOptions
    }),
    isAdmin() {
      return this.userInfo.roleLevel === 2
    }
  },
  methods: {
    goToBuyQualification() {
      this.$router.push({
        name: 'editBuyingInfo',
        query: {
          from: this.$route.name
        }
      })
    },
    goToExcellentShare() {
      this.$router.push({
        name: 'excellentShare',
        query: {
          channel: '1',
          isEdit: 1
        }
      })
    },
    fastSend() {
      this.$emit('showFastModal')
    },
    changeSortRule(index) {
      this.sortIndex = index
      this.getBuyingList()
    },
    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('buyingFilter')
    },
    clickFindBtn() {
      this.pageSize = 10
      this.pageNum = 1
      this.getBuyingList()
    },
    reSetFilter() {
      this.qualifications = undefined
      this.areaCode = []
      this.area = 'none'
    },
    tableChange(pageNum, pageSize) {
      this.pageSize = pageSize
      this.pageNum = pageNum
      this.$emit('goBackToTop')
      this.getBuyingList()
    },
    selectQualification(item) {
      console.log(item)
    },
    goBack() {
      this.$router.push({
        name: 'Product',
        query: {
          current: 3
        }
      })
    },
    goToMyBuying() {
      if (this.haveMyBuyData) {
        this.$router.push({
          name: 'myBuying'
        })
      } else {
        this.$router.push({
          name: 'editBuyingInfo',
          query: {
            isFromTable: 1
          }
        })
      }
    },
    goToEditMyBuying() {
      this.$router.push({
        name: 'editBuyingInfo',
        query: {
          isFromTable: 1
        }
      })
    },
    getAreaOptions() {
      this.$http.get('regionCode/selList').then(r => {
        this.areaOptions = []
        this.areaData = r
        const gangAoTai = [810000, 820000, 710000]
        // 去除港澳台
        r.forEach(option => {
          if (gangAoTai.includes(option.areaCode)) {
            this.areaOptions.push({
              label: option.areaName,
              value: option.areaCode,
              disabled: true
            })
          } else {
            this.areaOptions.push({
              label: option.areaName,
              value: option.areaCode,
              disabled: false
            })
          }
        })
        this.areaOptions.unshift({
          label: '不限',
          value: 'none'
        })
        this.area = 'none'
      })
    },
    setDefaultExpendKey() {
      this.qualificationOptions.forEach(e => {
        this.defaultExpend.push(e.key)
      })
    },
    getBuyingList() {
      this.dataLoading = true
      const params = {
        areaCode: typeof this.area === 'number' ? [this.area] : [],
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        newestSort: this.sortIndex === 1, // 是否最新
        qualificationIds: this.qualifications ? [this.qualifications] : []
      }
      this.$http.post('buy/buyResourcesList', params).then(res => {
        res.data.forEach(e => {
          e.contactsPhoneText =
            '<span ondblclick="copyThisText(' +
            e.contactsPhone +
            ')">' +
            e.contactsPhone +
            '</span>'
        })
        this.dataLoading = false
        this.tableData = res.data
        this.total = res.totalSize
        this.getFreeWatchCount()
      })
    },
    getFreeWatchCount() {
      this.$http.get('free/count/queryBuyCheckCount').then(res => {
        this.freeCount = res ? res.freeCount : 0
      })
    },
    getMyBuyingList() {
      this.$http.post('buy/myBuyResourcesList').then(res => {
        this.haveMyBuyData = !!res.length
      })
    }
  },
  mounted() {
    this.getAreaOptions()
    if (this.isAdmin) {
      this.getMyBuyingList()
    }
    this.getBuyingList()
  }
}
</script>

<style lang="scss" scoped>
.buying-page {
  width: 1200px;
  .buying-filter {
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 25px;
    position: relative;
    .search-title {
      color: #909399;
      font-size: 16px;
    }

    .filter-item {
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 16px;
      align-items: center;
      flex-shrink: 0;
      .filter-label {
        font-size: 14px;
        color: #666666;
        width: 80px;
      }
      .long-label {
        width: 100px;
      }
      .filter-search {
        width: 306px;
        margin-right: 24px;
      }
      .auto-width {
        flex-grow: 0;
        margin-right: 24px;
        min-width: 306px;
        //  max-width: calc(100% - 330px);
      }
      .large-button {
        width: 126px;
        margin-right: 16px;
      }
      .rt-abs-button {
        position: absolute;
        right: 0px;
      }
    }
  }
  .line {
    width: 100%;
    margin: 0;
    border-right: none;
    border-left: none;
    border-bottom: 0;
    margin-top: 15px;
    height: 1px;
    border-top: solid 1px #f2f2f2;
  }

  .buy-tools {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .sort-tool {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 110px;
      .dividing-line {
        height: 15px;
        background-color: #999999;
        width: 1px;
      }
      .tools {
        cursor: pointer;
        &:hover {
          color: #4d7cfe;
        }
      }
      .selected {
        color: #4d7cfe;
      }
    }
  }
  .table-operate {
    margin-top: 10px;
    padding: 10px 21px;
    border: solid 1px #f2f2f2;
    background-color: #f2f2f2;
    position: relative;
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    .add-btn {
      position: absolute;
      right: 21px;
    }
  }
  .table-list {
    .table-pagination {
      margin-top: 10px;
      width: 100%;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .no-data {
    height: 400px;
    width: 100%;
    position: relative;
    .block-content {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 700px;
      height: 350px;
      border-radius: 6px;
      border: 1px solid #ccc;
      .block-title {
        font-size: 19px;
        color: #333;
        margin-top: 80px;
        text-align: center;
        font-weight: bold;
      }
      .block-tips {
        margin-top: 30px;
        font-size: 14px;
        text-align: center;
      }
      .block-btns {
        margin-top: 30px;
        display: flex;
        align-items: top;
        justify-content: center;
        text-align: center;
        .btn {
          margin: 0 15px;
          width: 120px;
        }
        // text-align: center;
      }
    }
  }
  .process {
    width: 100%;
    background-size: cover;
    height: 148px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
