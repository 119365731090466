<template>
  <div :style="'background-image:url(' + warp + ')'" class="level-bg">
    <!--    <div-->
    <!--        :style="'background-image:url(' + shipment + ')'"-->
    <!--        class="shipment-btn btn"-->
    <!--        @click.stop="goToShipment"-->
    <!--    ></div>-->
    <!--    <div-->
    <!--        :style="'background-image:url(' + customer + ')'"-->
    <!--        class="customer-btn btn"-->
    <!--        @click.stop="goToServer"-->
    <!--    ></div>-->
    <div
        class="notice_btn"
        @click.stop="goToVipPage"
    >
      调价详情
    </div>
    <!--    <div class="surplusTime">-->
    <!--      <span class="springFrameTimeText">-->
    <!--         公测结束后，我们将对产品价格进行调整-->
    <!--      </span>-->
    <!--      <span class="springFrameTime">-->
    <!--         {{ this.springFrameTime }}-->
    <!--      </span>-->
    <!--    </div>-->
  </div>
</template>

<script>
import warp from '@/assets/levelUp/notice.png'
import shipment from '@/assets/levelUp/shipment_btn.png'
import customer from '@/assets/levelUp/customer_btn.png'
import {mapState} from 'vuex'

export default {
  name: 'level-up',
  data() {
    return {
      timer: '',
      warp,
      shipment,
      customer,
      userIpArea: null
    }
  },
  computed: {
    ...mapState(['springFrameTime']),
    projectEnv() {
      if (process.env.VUE_APP_TYPE) {
        return process.env.VUE_APP_TYPE
      } else {
        return 'online'
      }
    }
  },
  methods: {
    goToShipment() {
      let url = ''
      if (this.projectEnv === 'dev') {
        url = 'http://localhost:8080/#/'
      } else if (
          this.projectEnv === 'online' ||
          this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        url = 'http://www.xnjx666.com:9000/#/'
      } else if (this.projectEnv === 'test') {
        // 测试环境
        url = 'http://192.168.0.108:8099/#/'
      }
      let xiaoniuPage =
          'longna/shipment?' + '&userArea=' + JSON.stringify(this.userIpArea)

      window.open(url + xiaoniuPage)
    },
    goToServer() {
      let url = ''
      if (this.projectEnv === 'dev') {
        url = 'http://localhost:8080/#/'
      } else if (
          this.projectEnv === 'online' ||
          this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        url = 'http://www.xnjx666.com:9000/#/'
      } else if (this.projectEnv === 'test') {
        // 测试环境
        url = 'http://192.168.0.108:8099/#/'
      }
      const xiaoniuPage = 'shareholder/sell'

      window.open(url + xiaoniuPage)
    },
    goToVipPage() {
      this.$router.push({
        name: 'Vip',
        query: {
          fromUrl: this.$route.name
        }
      })
    },
    getAreaIp() {
      this.$http.get('areaIp/get').then(res => {
        this.userIpArea = res
      })
    }
  },
  mounted() {
    this.getAreaIp()
  },
  created() {
    this.timer = setInterval(this.getEndTime, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
<style lang="scss" scoped>
.level-bg {
  width: 571px;
  height: 492px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .notice_btn {
    cursor: pointer;
    position: absolute;
    left:143px;
    bottom: -100px;
    width: 280px;
    height: 56px;
    background: linear-gradient(105deg, #FF884D, #FA428A, #FF09C1);
    border-radius: 27px;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 56px;
    text-align: center;
    letter-spacing:3px;

  }

  .shipment-btn {
    left: 78px;
    bottom: 71px;
  }

  .surplusTime {
    position: absolute;
    bottom: 38px;
    left: 118px;
    text-align: center;

    .springFrameTimeText {
      font-size: 12px;
      font-weight: 800;
      color: #914200;
      line-height: 29px;
    }

    .springFrameTime {
      margin-left: 14px;
      font-size: 12px;
      font-weight: 800;
      color: #EB1100;
      line-height: 29px;
    }
  }

  .customer-btn {
    right: 78px;
    bottom: 71px;
  }

  .btn {
    cursor: pointer;
    position: absolute;
    width: 188px;
    height: 71px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
