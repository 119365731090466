<template>
  <div id="app">
    <a-layout class="layout">
      <a-layout-header>
        <home-header></home-header>
        <div class="price-adjustment">
          <a-button type="primary" @click="showModal"
                    style="width:0px; height:0px;background-color:transprant;border:0px"></a-button>
          <a-modal class="activity" v-model="open" @ok="hideModal">
            <img src="../assets/price_adjustment.png" alt="">
            <template slot="header">
              <div></div>
            </template>
            <template slot="footer">
              <div></div>
            </template>
          </a-modal>
        </div>

      </a-layout-header>
      <a-layout-content class="main-content">
        <router-view
          class="router-content"
          @clearMember="clearMember"
          @closeAllAdv="closeAllAdv"
          @showSomeAdv="haveAdv"
          @goBackTop="goBackTop"
          :key="$route.path"
          @showLoginModal="showLoginModal"
        />
      </a-layout-content>
      <a-layout-footer class="footer">
        <page-footer></page-footer>
      </a-layout-footer>
    </a-layout>
    <call-phone></call-phone>
    <side-tool :notAdv="notAdv" @goBackTop="goBackTop"></side-tool>
    <lf-tool v-show="$route.meta.title !== 'Product'"></lf-tool>
    <!-- <login-modal ref="login"></login-modal> -->
    <!--      5013//体验版-->
    <a-modal
      :visible="watchPhoneModalShow"
      title="温馨提示"
      @cancel="closeVipModal"
    >
      <p style="text-align:center">
        当前使用版本过低，继续使用请先升级成VIP会员
      </p>
      <div style="text-align:center"><a @click="goToVip">我要升级>></a></div>
      <div slot="footer">
        <a-button type="primary" @click="closeVipModal">确认</a-button>
      </div>
    </a-modal>
    <!--  5008    -->
    <a-modal
      :visible="notPhoneCountModalShow"
      title="温馨提示"
      @cancel="closeVipModal"
    >
      <p style="text-align:center">
        您今日的“查看电话”次数已使用完毕，继续使用请先升级成VIP集团版
      </p>
      <div style="text-align:center"><a @click="goToVip">我要升级>></a></div>
      <div slot="footer">
        <a-button type="primary" @click="closeVipModal">确认</a-button>
      </div>
    </a-modal>
    <!--  2005    -->
    <a-modal
      :visible="expriedModalShow"
      title="温馨提示"
      @cancel="closeVipModal"
    >
      <p style="text-align:center">
        当前使用版本已过期，继续使用请先升级成VIP会员
      </p>
      <div style="text-align:center"><a @click="goToVip">我要升级>></a></div>
      <div slot="footer">
        <a-button type="primary" @click="closeVipModal">确认</a-button>
      </div>
    </a-modal>
    <!--      5012-->
    <a-modal
      :visible="freeOverDateModalShow"
      title="温馨提示"
      @cancel="closeVipModal"
    >
      <p style="text-align:center">
        您今日的“查询条件”次数已使用完毕，继续使用请先升级成VIP会员
      </p>
      <div style="text-align:center"><a @click="goToVip">我要升级>></a></div>
      <div slot="footer">
        <a-button type="primary" @click="closeVipModal">确认</a-button>
      </div>
    </a-modal>
    <!--      3008-->
    <a-modal
      :visible="verification"
      title="温馨提示"
      @cancel="closeVerification"
    >
      <div style="text-align:center;">
        <p class="verText">
          当前操作频繁，请进行验证
        </p>
        <div class="verificationImg">
          <img :src="verificationImg" alt="小牛建讯" />
          <div class="link"></div>
          <span class="refresh" @click="refresh">点击刷新</span>
        </div>
      </div>
      <div class="verInput">
        <a-input
          style="width: 300px;height: 42px;"
          v-model="code"
          placeholder="请输入正确答案"
        ></a-input>
      </div>
      <div slot="footer">
        <a-button type="primary" @click="confirmVerification">确认</a-button>
      </div>
    </a-modal>
  </div>
</template>
<script>
import logo from '@/assets/header/large_logo.png'
import pageFooter from '@/components/PageFooter.vue'
import CallPhone from '@/components/CallPhone.vue'
import SideTool from '@/components/SideTool.vue'
import LfTool from '@/components/LfTool.vue'
import HomeHeader from '@/components/HomeHeader.vue'
import { mapState, mapMutations } from 'vuex'
import Cookie from 'js-cookie'
import ContactPhoneVue from './components/EnterpriseDetail/ContactPhone.vue'

export default {
  name: 'layout',
  components: {
    pageFooter,
    CallPhone,
    SideTool,
    HomeHeader,
    LfTool
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      verification: state => state.verification,
      watchPhoneModalShow: state => state.watchPhoneModalShow,
      notPhoneCountModalShow: state => state.notPhoneCountModalShow,
      vxExpriedModalShow: state => state.expriedModalShow,
      freeOverDateModalShow: state => state.freeOverDateModalShow
    }),
    expriedModalShow() {
      return this.vxExpriedModalShow
    },
    buyVersion() {
      return this.userInfo ? this.userInfo.buyVersion : 'V_FREE_TEMP'
    }
  },
  watch: {
    verification: {
      handler(val) {
        if (val) {
          this.getImg()
        }
      }
    }
  },
  data() {
    return {
      code: '',
      verificationImg: '',
      logo,
      memberName: '',
      menuList: [
        { title: '首页', path: '/home' },
        { title: 'VIP会员', path: '/vip' },
        { title: '下载', path: '/download' }
        // { title: '帮助中心', path: '' }
      ],
      notAdv: true,
      open: false,
    }
  },
  methods: {
    showModal(){
    this.open = true;
    },
    hideModal(){   
            this.open = false;
     },
    ...mapMutations([
      'setWatchPhoneModalShow',
      'setNotPhoneCountModalShow',
      'setExpriedModalShow',
      'setFreeOverDateModalShow',
      'setVerification',
      'setListQuery'
    ]),
    closeAllAdv() {
      this.notAdv = true
    },
    haveAdv() {
      // console.log('thisway')
      this.notAdv = false
    },
    refresh() {
      this.getImg()
    },
    getImg() {
      this.$http.download('/verification/getImage').then(res => {
        this.verificationImg = window.URL.createObjectURL(res)
      })
    },
    closeVerification() {
      this.$message.error('请进行验证!')
    },
    confirmVerification() {
      if (this.code) {
        this.$http
          .get('/verification/getVerification', { code: this.code })
          .then(res => {
            this.setListQuery(true)
            this.$message.success('验证成功!')
            this.code = null
            this.setVerification(false)
          })
          .catch(err => {
            this.refresh()
            this.code = null
          })
      } else {
        this.$message.error('请输入验证码!')
      }
    },
    async closeVipModal() {
      this.setWatchPhoneModalShow(false)
      this.setNotPhoneCountModalShow(false)
      this.setExpriedModalShow(false)
      this.setFreeOverDateModalShow(false)
    },
    goToVip() {
      this.closeVipModal().then(() => {
        this.$router.push({
          name: 'Vip'
        })
      })
    },
    clearMember() {
      this.memberName = ''
    },
    goBackTop() {
      if (!this.$isServer) {
        const appDom =
          document.body.scrollTop === 0
            ? document.documentElement
            : document.body
        appDom.scrollTop = 0
      }
    },
    goToLogin() {
      this.$router.push({
        name: 'Login'
      })
    },
    showLoginModal() {
      this.clearMember()
      this.$refs.login.show()
    },
    goToPath(item) {
      if (item.path === this.$route.path) {
        return
      }
      // if (item.path === '/download') {
      //     location.href = 'http://www.xnjx666.com/'
      //     return
      // }
      this.$router.push({
        path: item.path
      })
    }
    /*     logoutAccount() {
        this.$http.post('user/logout').then(() => {
            this.$message.success('已成功退出登录')
            setTimeout(() => {
                location.reload()
            }, 1500)
        })
    }*/
  },
  mounted() {
    this.$nextTick(() => {
      this.memberName = Cookie.get('memberName')
      window.setExpriedModalShow = this.setExpriedModalShow
      window.setVerification = this.setVerification
    })
this.showModal()
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100vh;
  width: 100%;
  min-width: 1200px;
}

#nav {
  padding: 30px;
}

.have-rt-margin {
  margin-right: 15px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.layout {
  min-height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*position: relative;*/
  flex-shrink: 0 !important;
}

.main-content {
  background-color: #ffffff;
  flex-grow: 1 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.layout > .ant-layout-header {
  /*position: absolute;*/
  /*top: 0;*/
  /*left: 0;*/
  /*right: 0;*/
  margin: 0 auto;
  background-color: transparent;
  height: 66px;
  text-align: center;
  display: flex;
  align-items: flex-end;
  padding: 0;
  justify-content: center;
  min-width: 1200px;
}
</style>

<style lang="scss" scoped>
.price-adjustment{
  width: 0px;
  height: 0px;
}
 .activity /deep/ .ant-modal-content{
  background-color: transparent !important;
  width: 0px;
}
.activity /deep/ .ant-modal-close{
  top:7px;
  right:-636px;
}
.activity /deep/ .ant-modal{
  top:294px;
  right: 66px;
}
.activity /deep/.ant-modal-body{
  img{
max-width: 631px !important;
height: 369px !important;
  }

}
.activity /deep/.ant-modal-footer{
  border-top:0px
}
.activity /deep/ .ant-modal-close-x{
  width: 35px;
  height: 35px;
  line-height: 33px;
  border-radius: 50%;
  background:rgba(25, 12, 5,0.5);
    color:#fff;
}
.verificationImg {
  display: flex;
  align-items: center;
  margin-left: 80px;
  margin-top: 20px;
  img {
    height: 45px;
    width: 200px;
  }
  .link {
    margin: 0 20px;
    height: 35px;
    width: 1px;
    background-color: #d9d9d9;
  }
  .refresh {
    width: 56px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4d7cfe;
    line-height: 20px;
  }
}
.verInput {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.verText {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #595959;
  line-height: 20px;
}
// @media screen and (max-width: 2000px) {
.ant-layout-header {
  width: 100vw;
  // background-color: #00195e;
}

.router-content {
  width: 100vw;
  // padding-top: 90px;
}

// }
// @media screen and (max-width: 1400px) {
// .ant-layout-header,
// .router-content {
//     width: 1200px;
// }
// }
.layout {

  .top-tool {
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;

    .top-logo {
      // width: 120px;
      height: 46px;
    }

    .rt-menu {
      display: flex;
      font-size: 14px;
      // font-weight: bold;
      color: #fff;

      .menu-item {
        height: 66px;
        line-height: 66px;
        cursor: pointer;
        margin-right: 25px;
        width: fit-content;
        position: relative;

        &:hover {
          &:after {
            width: 100%;
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            height: 2px;
            background-color: #fff;
          }
        }
      }

      .server-phone {
        height: 66px;
        line-height: 66px;
      }

      .logout {
        display: inline-block;
        cursor: pointer;
      }
    }
  }
}

</style>
