<template>
  <div class="home-headerNav">
    <div class="home-header">
      <a class="header-logo" href="http://www.xnjx666.com" target="_blank">
        <!-- <div v-if="isShowNav">
        </div> -->
        <img class="logo" :src="greenLogo" alt="小牛建讯" />

        <!-- <div v-else>
          <img class="logo" :src="blueLogo" alt="小牛建讯" />
        </div> -->
      </a>
      <div class="menu-list">
        <router-link
          class="router-item"
          :class="{ isHover: $route.meta.title === 'Home' }"
          :to="{ name: 'Home' }"
        >
          首页
        </router-link>
        <!-- <router-link
          class="router-item"
          :class="{ isHover: $route.meta.title === 'iframeIndex' }"
          :to="{ name: 'iframeIndex' }"
        >
          企业名录
        </router-link> -->
        <div
          class="router-item"
          @click="goToXiaoniuReceipt"
          :class="{ isHover: $route.meta.title === 'Product' }"
          :to="{ name: 'Product', params: { current: '0' } }"
        >
          产品
        </div>
        <router-link
          class="is-vip"
          :class="{ isHover: $route.meta.title === 'Vip' }"
          :to="{ name: 'Vip' }"
        >
          <span class="vip-text">VIP会员</span>
        </router-link>
        <div
          class="router-item"
          :class="{ isHover: $route.meta.title === 'explore' }"
        >
          <router-link :to="{ path: '/helpCenter' }" class="route-link-item">
            探索小牛
          </router-link>
          <div class="sub-menu-list">
            <router-link class="sub-menu" :to="{ path: '/helpCenter' }">
              帮助中心
            </router-link>
            <!-- <router-link class="sub-menu" :to="{ name: 'dynamic',params: { type:'1' } }">
              小牛动态
            </router-link> -->
            <router-link class="sub-menu" :to="{ name: 'caseCenter' }">
              案例中心
            </router-link>
            <router-link class="sub-menu" :to="{ name: 'aboutUs' }">
              关于我们
            </router-link>
            <router-link class="sub-menu" :to="{ name: 'contactUs' }">
              联系我们
            </router-link>
          </div>
        </div>
        <router-link
          class="router-item"
          :class="{ isHover: $route.meta.title === 'Download' }"
          :to="{ name: 'Download' }"
        >
          软件下载
        </router-link>
        <div v-if="!userInfo" class="login-btn" @click="goToXiaoniuLogin">
          <!-- <img :src="vipTopIcon" v-if="vipPage" alt="小牛建讯" /> -->
          <!-- <img :src="user" v-if="!vipPage" alt="小牛建讯" /> -->
          <span class="login-text">
            登录/注册
          </span>
        </div>
        <div v-else class="login-btn">
          <!-- <img :src="vipTopIcon" v-if="vipPage" alt="小牛建讯" /> -->
          <!-- <img :src="user" v-if="!vipPage" alt="小牛建讯" /> -->
          <span class="login-text">
            {{ memberName }}
          </span>
          <div class="logout-btns">
            <div class="logout-text" @click="logout">退出登录</div>
          </div>
        </div>
      </div>
    </div>
    <loginModal></loginModal>
    <activity-modal></activity-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Cookie from 'js-cookie'
import blueLogo from '@/assets/header/blueLogo.png'
import logo from '@/assets/header/logo.png'
import greenLogo from '@/assets/banner/logo.png'
import user from '@/assets/header/user.png'
import vipTopIcon from '@/assets/header/vipTopIcon.png'
import loginModal from '@/views/components/LoginModal.vue'
import ActivityModal from '@/views/components/ActivityModal.vue'

export default {
  name: 'home-header',
  components: {
    loginModal,
    ActivityModal
  },
  inject: ['reload'],
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      isLoginSuccess: state => state.isLoginSuccess
    }),
    memberName() {
      return this.userInfo ? this.userInfo.memberName : ''
    },
    menuList() {
      const list = [
        {
          title: '首页',
          routeName: 'Home',
          meta: 'Home',
          icon: '',
          children: []
        },
        {
          title: '产品',
          routeName: 'Product',
          meta: 'Product',
          icon: '',
          children: []
        },
        {
          title: 'VIP会员',
          routeName: 'Vip',
          meta: 'Vip',
          icon: '',
          children: []
        },
        {
          title: '探索小牛',
          routeName: 'aboutUs',
          meta: 'explore',
          icon: '',
          children: [
            { title: '小牛动态', routeName: 'dynamic' },
            { title: '案例中心', routeName: 'caseCenter' },
            { title: '关于我们', routeName: 'aboutUs' },
            { title: '联系我们', routeName: 'contactUs' }
          ]
        },
        {
          title: '软件下载',
          routeName: 'Download',
          meta: 'Download',
          icon: '',
          children: []
        }
        // {
        //   title: '登录/注册',
        //   routeName: '',
        //   icon: user,
        //   isLogin: true,
        //   isShowLogin: true,
        //   children: []
        // }
      ]
      // if (this.userInfo) {
      //   list.splice(list.length - 1, 1, {
      //     title: this.userInfo.memberName,
      //     icon: user,
      //     isLogin: true,
      //     isShowLogin: false,
      //     children: [
      //       {
      //         title: '退出登录',
      //         isLogout: true,
      //         routeName: 'Login'
      //       }
      //     ]
      //   })
      // } else if (this.$route.name === 'Login') {
      //   list.splice(list.length - 1, 1)
      // }
      return list
    },
    vipPage() {
      return this.$route.name === 'Vip'
    },
    projectEnv() {
      if (process.env.VUE_APP_TYPE) {
        return process.env.VUE_APP_TYPE
      } else {
        return 'online'
      }
    }
  },
  data() {
    return {
      vipTopIcon,
      blueLogo,
      logo,
      user,
      greenLogo,
      isLogout: false,
      isShowNav: true
    }
  },
  methods: {
    ...mapMutations(['setUserInfo', 'setShowLoginModal']),
    // 在新页面打开新的小牛收货
    goToXiaoniuReceipt() {
      // 开发环境
      if (this.projectEnv === 'dev') {
        window.open('http://localhost:8080/#/longna/receipt')
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open('http://www.xnjx666.com:9000/#/longna/receipt')
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open('http://192.168.0.108:8099/#/longna/receipt')
      }
    },
    goToXiaoniuLogin() {
      // 开发环境
      if (this.projectEnv === 'dev') {
        window.open('http://localhost:8080/#/login')
      } else if (
        this.projectEnv === 'online' ||
        this.projectEnv === 'preview'
      ) {
        // 预发  正式环境
        window.open('http://www.xnjx666.com:9000/#/login')
      } else if (this.projectEnv === 'test') {
        // 测试环境
        window.open('http://192.168.0.108:8099/#/login')
      }
    },
    showLoginModal() {
      this.setShowLoginModal(true)
    },
    logout() {
      const routerList = ['Product', 'enterpriseDetail', 'shared', 'sell']
      const flagRouter = routerList.indexOf(this.$route.name)
      if (flagRouter > -1) {
        this.$http.post('user/logout').then(() => {
          Cookie.remove('JSESSIONID')
          Cookie.remove('userArea')
          Cookie.remove('memberName')
          Cookie.remove('userId')
          Cookie.remove('qualificationId')
          this.setUserInfo(null)
          this.$message.success('退出登录成功', 0.5)
          this.$router.push({
            name: 'Product',
            query: {
              current: 0
            }
          })
          this.reload()
          this.isLogout = true
        })
      } else {
        this.$http.post('user/logout').then(() => {
          Cookie.remove('JSESSIONID')
          Cookie.remove('userArea')
          Cookie.remove('memberName')
          Cookie.remove('userId')
          Cookie.remove('qualificationId')
          this.setUserInfo(null)
          this.$message.success('退出登录成功', 0.5)
          this.reload()
          setTimeout(() => {
            this.setShowLoginModal(false)
            this.isLogout = true
          }, 500)
        })
      }
    }
  },

  mounted() {
    var _that = this
    window.onscroll = function() {
      const scrollT =
        document.documentElement.scrollTop || document.body.scrollTop // 滚动条的垂直偏移
      if (scrollT > 600) {
        //xx为临界的垂直位移值
        if (document.querySelector('.home-headerNav')) {
          document.querySelector('.home-headerNav').className = 'box-active'
          _that.isShowNav = false
        }
        //修改导航栏的样式，让他呈现吸顶的状态
      } else {
        //删除吸顶的样式,恢复正常的样式
        if (document.querySelector('.box-active')) {
          _that.isShowNav = true
          document.querySelector('.box-active').className = 'home-headerNav'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box-active {
  z-index: 222;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  border: 1px dashed #dcdcdc;
  width: 100%;
  // .router-item,
  // .route-link-item {
  //   color: #282828 !important;
  //   &:hover {
  //     background-color: #f4f5ff;
  //   }
  // }
  // .isHover {
  //   color: #00ab94 !important;
  //   background: #ffffff !important;
  //   &:hover {
  //     background-color: #f4f5ff !important;
  //   }
  // }
  // .login-text-vip {
  //   color: #030303 !important;
  // }
}
.home-headerNav {
  width: 100%;
  background-color: #fff;
}
.home-header {
  height: 66px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 222;
  .header-logo {
    display: flex;
    align-items: flex-end;

    .logo {
      height: 40px;
    }
  }
}
.menu-list {
  display: flex;
  align-items: center;
  height: 33px;
  line-height: 33px;

  justify-content: space-between;
  .router-item {
    padding: 0 14px;
    margin-left: 24px;
    font-size: 16px;
    font-weight: 500;
    box-sizing: content-box;
    cursor: pointer;
    position: relative;
    color: #282828;
    .route-link-item {
      color: #282828;
    }
    &:hover {
      color: #00ab94;
      .sub-menu-list {
        display: flex;
      }
      .route-link-item {
        color: #00ab94;
      }
    }
  }
  .isHover {
    color: #00ab94;
    .route-link-item {
      color: #00ab94;
    }
  }
  .is-vip {
    padding: 0 14px;
    margin-left: 24px;
    font-size: 16px;
    font-weight: 500;
    box-sizing: content-box;
    cursor: pointer;
    position: relative;
    .vip-text {
      color: #ff7f28;
      // background-image: linear-gradient(to right, #dfc193, #d4a260);
      // color: transparent;
      background-clip: text;
    }
    &:hover {
      color: #00ab94;
    }
  }
  .login-btn {
    padding: 0 14px;
    margin-left: 28px;
    font-size: 16px;
    font-weight: 500;
    box-sizing: content-box;
    cursor: pointer;
    position: relative;
    z-index: 222;

    // img {
    //   height: 23px;
    //   vertical-align: middle;
    //   margin-right: 5px;
    // }
    .login-text {
      color: #282828;
      &:hover {
        color: #00ab94;
      }
    }

    .logout-btns {
      display: none;
      left: 14px;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
      position: absolute;
      text-align: center;
      padding: 7px 0px;
      width: 120px;
      background-color: #fff;
      border-radius: 4px;
      .logout-text {
        width: 100%;
        padding: 3px 0;
        color: #282828;
      }
      &:hover {
        .logout-text {
          background-color: #f2f6f8;
        }
      }
    }
    &:hover {
      .logout-btns {
        display: block;
      }
    }
  }
  .sub-menu-list {
    display: none;
    position: absolute;
    // display: flex;
    flex-direction: column;
    border-radius: 6px;
    right: 0px;
    z-index: 333;
    width: 100%;
    background-color: #fff;
    padding: 10px 0;
    font-size: 16px;

    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
    .sub-menu {
      text-align: center;
      padding: 6px 12px;
      color: #282828;
      &:hover {
        background-color: #f2f6f8;
      }
    }
  }
}
</style>
