<template>
    <div class="edit-buying" :class="{ fullscreen: !isFromTable }">
        <div class="second-title" v-if="!isFromTable">
            <p class="isActive">发布求购信息</p>
            <div class="rt-btns">
                <a-button class="have-rt-margin" type="primary" @click="beforeSubmit"
                    >提交</a-button
                >
                <a-button @click="cancelAndGoBack">取消</a-button>
            </div>
        </div>
        <div class="buy-form">
            <form-index
                ref="buyForm"
                :labelCol="{ span: 5 }"
                :formItems="formItems"
                :fakeQualification="fakeQualification"
                containerId="componentForm"
                :qualificationOptions="qualificationOptions"
                v-model="formValue"
                @validatePhone="isReallyPhone"
                @setVerifyCode="setVerifyCode"
            ></form-index>
        </div>
        <div v-if="isFromTable" class="table-form-btn">
            <a-button type="primary" size="large" class="one-btn-submit" @click="beforeSubmit"
                >一键求购</a-button
            >
            <div class="tips">求购信息发布成功后系统默认保留7天，7天后会自动失效</div>
        </div>
    </div>
</template>

<script>
import formType from '@/components/Form/formType'
import formIndex from '@/components/Form/index.vue'
import verifCode from '@/components/verifyCode.vue'
import { mapState, mapMutations } from 'vuex'

export default {
    name: 'edit-buying',
    components: {
        formIndex
    },
    props: {
        isFromTable: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            userInfo: state => state.userInfo,
            fakeQualification: state => state.fakeQualificationOptions,
            qualificationOptions: state => state.typeQualificationOptions,
            vxAreaOptions: state => state.areaOptions
        }),
        areaOptions() {
            const options = [{ label: '不限', value: 0, disabled: false }, ...this.vxAreaOptions]
            options.forEach(e => {
                if (this.gangAoTai.includes(e.value)) {
                    e.disabled = true
                }
            })
            return options
        },
        formItems() {
            return [
                {
                    label: '资质类别及等级:',
                    name: 'qualificationId',
                    value: this.formValue.qualificationId,

                    type: formType.SINGLECHECKBOKTREE,
                    validate: [
                        {
                            required: true,
                            message: '请选择资质类别及等级',
                            trigger: 'change'
                        }
                    ]
                },
                {
                    label: '求购地区:',
                    value: this.formValue.wantAreaCode,
                    options: this.areaOptions,
                    name: 'wantAreaCode',
                    type: formType.SELECT,
                    placeholder: '请选择地区，如四川省，如若没有确定地区可选择不限',
                    validate: [
                        {
                            required: true,
                            message: '请选择求购地区',
                            trigger: 'change'
                        }
                    ]
                },
                {
                    label: '落地地区:',
                    value: this.formValue.destinationAreaCode,
                    options: this.areaOptions,
                    name: 'destinationAreaCode',
                    placeholder: '请选择地区，如四川省，如若没有确定地区可选择不限',
                    type: formType.SELECT,
                    validate: [
                        {
                            required: true,
                            message: '请选择落地地区',
                            trigger: 'change'
                        }
                    ]
                },
                {
                    label: '联系人:',
                    value: this.formValue.contactsPerson,
                    name: 'contactsPerson',
                    placeholder: '请输入联系人姓名',
                    type: formType.INPUT,
                    validate: [
                        {
                            required: true,
                            message: '请输入联系人',
                            trigger: 'blur'
                        }
                    ]
                },
                {
                    label: '联系方式:',
                    value: this.formValue.contactsPhone,
                    placeholder: '请输入手机号码',
                    name: 'contactsPhone',
                    type: formType.INPUT,
                    validate: [
                        {
                            required: true,
                            validator: this.isPhoneNumber,
                            trigger: 'blur'
                        }
                    ]
                },
                {
                    label: '短信验证码:',
                    value: this.formValue.verificationCode,
                    name: 'verificationCode',
                    component: verifCode,
                    type: formType.COMPONENT,
                    ref: 'code',
                    validate: [
                        {
                            required: true,
                            message: '请输入短信验证码',
                            trigger: 'blur'
                        }
                    ]
                },
                {
                    label: '备注:',
                    value: this.formValue.remarks,
                    name: 'remarks',
                    placeholder: '请输入需要详细备注说明的信息，注意不能填写手机号和公司名称',
                    type: formType.TEXTAREA
                }
            ]
        }
    },
    data() {
        return {
            gangAoTai: [810000, 820000, 710000],
            editId: '',
            isEdit: false,
            formValue: {
                qualificationId: undefined,
                wantAreaCode: [],
                destinationAreaCode: [],
                contactsPerson: '',
                contactsPhone: '',
                verificationCode: '',
                remarks: ''
            }
        }
    },
    methods: {
        isPhoneNumber(rule, value, callback) {
            const mobileTest = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/
            if (value === '') {
                callback(new Error('请输入电话号码'))
            } else if (!mobileTest.test(value)) {
                callback(new Error('请输入正确的电话号码'))
            } else {
                callback()
            }
        },
        setVerifyCode(code) {
            this.formValue.verificationCode = code
        },
        isReallyPhone(promise) {
            const validate = this.$refs.buyForm.formItemValidator('contactsPhone')
            promise.resolve({ validate, phone: this.formValue.contactsPhone })
        },
        cancelAndGoBack() {
            if (this.$route.query.from) {
                this.$router.push({
                    name: this.$route.query.from
                })
            } else if (this.isFromTable) {
                this.formValue.qualificationId = undefined
                this.formValue.wantAreaCode = []
                this.formValue.destinationAreaCode = []
                this.formValue.contactsPerson = ''
                this.formValue.contactsPhone = ''
                this.formValue.verificationCode = ''
                this.formValue.remarks = ''
                this.$refs.buyForm.$refs.code[0].clearValue()
                this.$emit('submitBuyInfo')
            }

            // this.$router.go(-1)
            // this.$router.push({
            //     name: 'myBuying',
            //     query: {
            //         pId: '6'
            //     }
            // })
        },
        getTypeQualifications() {
            this.qualificationOptions = []
            this.$http.get('qualification/selQualificationAllByType').then(r => {
                const qualificationOptions = r.map((e, i) => {
                    return {
                        title: e.qualificationType,
                        key: i,
                        isExpend: i === 0,
                        children: e.qualificationList.map(q => {
                            return {
                                title: q.qualificationName,
                                key: q.qualificationName,
                                // checkable: true,
                                children: q.qualificationLevels.map(level => {
                                    return {
                                        id: level.id,
                                        title: level.level,
                                        disabled: false
                                    }
                                }),
                                value: []
                            }
                        })
                    }
                })
                this.qualificationOptions = this.$deepCopy(qualificationOptions)
            })
        },
        getQalicationList() {
            // 获取资质列表
            this.fakeQualification = []
            this.$http.get('qualification/selQualificationAll').then(r => {
                r.forEach(e => {
                    e.qualificationLevels.forEach(q => {
                        this.fakeQualification.push({
                            label: e.qualificationName + q.level,
                            value: q.id
                        })
                    })
                })
                this.getTypeQualifications()
            })
        },
        setEditValue() {
            const buyingItem = JSON.parse(localStorage.getItem('buyingItem'))
            this.formValue = {
                qualificationId: buyingItem.qualificationId,
                wantAreaCode: buyingItem.wantAreaCode,
                destinationAreaCode: buyingItem.destinationAreaCode,
                contactsPerson: buyingItem.contactsPerson,
                contactsPhone: buyingItem.contactsPhone,
                verificationCode: '',
                remarks: buyingItem.remarks
            }
            this.editId = buyingItem.id
        },
        beforeSubmit() {
            const isCanSubmit = this.$refs.buyForm.formValidator()
            if (isCanSubmit) {
                const params = {
                    ...this.formValue
                }
                if (this.isEdit) {
                    params.id = this.editId
                    this.$http.put('buy', params).then(() => {
                        this.$message.success('更新成功', 0.5)
                        // this.$router.push({
                        //     name: 'myBuying',
                        //     query: {
                        //         pId: '6'
                        //     }
                        // })
                        this.cancelAndGoBack()
                    })
                } else {
                    this.$http
                        .post('buy', params)
                        .then(() => {
                            if (!this.isFromTable) {
                                this.$message.success('发布成功', 0.5)
                            }
                            this.cancelAndGoBack()
                        })
                        .catch(err => {
                            if (err.code === '5010') {
                              this.$message.warn('出售与求购相关使用次数已用完', 0.5)
                            }
                        })
                }
            }
        }
    },
    async mounted() {
        this.$nextTick(() => {
            this.isEdit = !!parseInt(this.$route.query.isEdit, 10)
            if (this.isEdit) {
                this.setEditValue()
            }
        })
    }
}
</script>

<style lang="scss" scoped>
.edit-buying {
    .rt-btns {
        position: absolute;
        right: 20px;
    }
    .buy-form {
        width: 100%;
        padding: 24px;
        box-sizing: border-box;
        background-color: #ffffff;
    }
    .table-form-btn {
        width: 100%;
        text-align: center;
        .one-btn-submit {
            width: 300px;
            margin-bottom: 10px;
        }
    }
}
.fullscreen {
    .buy-form {
        width: 1000px;
    }
    width: 1200px;
    margin-bottom: 40px;
    background-color: #ffffff;
}
</style>
