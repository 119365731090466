import { render, staticRenderFns } from "./Download.vue?vue&type=template&id=da25990c&scoped=true"
import script from "./Download.vue?vue&type=script&lang=js"
export * from "./Download.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Download.vue?vue&type=style&index=0&id=da25990c&prod&lang=scss&scoped=true")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "da25990c",
  "fd1c1606"
  
)

export default component.exports