<template>
  <a-modal
    class="indexPage"
    :visible="isShowLogin"
    :footer="null"
    :width="800"
    :maskClosable="false"
    @cancel="setModalVisible"
  >
    <div class="main">
      <div class="mainImg">
        <img :src="loginBg" alt="小牛建讯" height="100%" />
      </div>
      <div class="login-info">
        <div class="login-content">
          <div class="login-title">{{ loginTitle }}</div>
          <div v-if="useWx">
            <wx-login
              @showBtn="showBtn"
              @showBind="showBind"
              @backMethods="backMethods"
            ></wx-login>
          </div>
          <div v-if="useVerify">
            <verify-code></verify-code>
          </div>
          <div class="use-methods" v-show="useMethods">
            <div class="methods-title">
              <div class="methods-line"></div>
              <div class="title-lable">其他登录方式</div>
              <div class="methods-line"></div>
            </div>
            <div v-if="useWx" class="other-methods" @click="useVerifyLogin">
              <a-icon type="safety-certificate" style="margin-right:6px " />
              验证码
            </div>
            <div v-if="useVerify" class="other-methods" @click="useWxLogin">
              <a-icon type="wechat" style="margin-right:6px " />
              微信
            </div>
            <!--            <div class="textTips">-->
            <!--              如有疑问，请联系您的专属管家：13438870357-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import loginBg from '@/assets/login_bg.png'
import wxLogin from '@/views/components/wxLogin.vue'
import verifyCode from '@/views/components/verifyLogin.vue'

export default {
  name: 'LoginModal',
  computed: {
    ...mapState({
      isShowLogin: state => state.isShowLogin
    })
  },
  components: {
    wxLogin,
    verifyCode
  },
  inject: ['reload'],
  data() {
    return {
      loginBg,
      useWx: true,
      useVerify: false,
      useMethods: true,
      loginTitle: '微信扫码登录'
    }
  },
  methods: {
    ...mapMutations(['setShowLoginModal']),
    setModalVisible() {
      this.setShowLoginModal(false)
      this.reload()
    },
    showBind(title) {
      this.loginTitle = title
      this.useMethods = false
    },
    backMethods(title) {
      this.useWx = true
      this.loginTitle = title
      this.useMethods = true
    },
    showBtn() {
      this.loginTitle = ''
      this.useMethods = false
    },
    useVerifyLogin() {
      this.useWx = false
      this.useVerify = true
      this.loginTitle = '验证码登录'
    },
    useWxLogin() {
      this.useWx = true
      this.useVerify = false
      this.loginTitle = '微信扫码登录'
    }
  },
  created() {
    // console.log(this.isShowLogin);
  }
}
</script>

<style scoped lang="scss">
.indexPage /deep/ .ant-modal-body {
  padding: 0 !important;
}

.indexPage /deep/ .ant-modal-content {
  height: 435px;
}

.main {
  display: flex;
  .mainImg {
    height: 435px;
    border-radius: 4px;
    background-color: #0c1a4a;
    z-index: 999;
  }

  .login-info {
    width: 426px;
    height: 435px;
  }

  .login-content {
    background-color: #fff;
    //width: 100%;
    //height: 100%;
    border-radius: 6px;
    //position: absolute;
    //top: 80px;
    //bottom: 0;
    margin: auto;
    text-align: center;
    right: 0;
    padding: 43px 0 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    .login-title {
      font-size: 24px;
      color: #02060b;
      font-weight: 400;
      width: 100%;
      text-align: center;
      //margin-bottom: 20px;
    }

    .use-methods {
      position: absolute;
      bottom: 15px;
    }

    .methods-title {
      margin-top: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .title-lable {
        margin: 0 20px;
        font-size: 16px;
        color: #5d5d5d;
        flex-shrink: 0;
      }

      .methods-line {
        width: 135px;
        height: 1px;
        flex-shrink: 0;
        background-color: #dcdcdc;
      }
    }

    .other-methods {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #1b8ffa;
      font-size: 16px;
      margin-top: 15px;
    }
    .textTips {
      margin-top: 20px;
      font-size: 12px;
      font-weight: 400;
      color: #5d5d5d;
    }
  }
}
</style>
