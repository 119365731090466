import Vue from 'vue'
import Router from 'vue-router'

import Vip from '../views/Vip.vue'
import App from '../App.vue'
import Home from '../views/Home.vue'
import Layout from '../views/Layout.vue'
import Download from '../views/Download.vue'
import AboutUs from '../views/Explore/AboutUs.vue'
import CaseCenter from '../views/Explore/CaseCenter.vue'
import ContactUs from '../views/Explore/ContactUs.vue'
import Dynamic from '../views/Explore/Dynamic.vue'
import Details from '../views/Explore/Details.vue'
import EnterpriseDetail from '../views/EnterpriseDetail.vue'
import product from '../views/product/Index.vue'
import Shared from '../views/QR/Shared/Index.vue'
import ExcellentShare from '../views/QR/Shared/ExcellentShare.vue'
import sell from '../views/QR/Buy/Index.vue'
import editBuyingInfo from '../views/QR/Buy/Edit.vue'
import sharedDetail from '../views/QR/Shared/Detail.vue'
import iframeIndex from '../views/iframe/index.vue'
import HelpCenter from '../views/HelpCenter/index.vue'
import HelpCenterDefault from '../views/HelpCenter/default.vue'
import HelpCenterResult from '../views/HelpCenter/result.vue'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'App',
    component: App,
    // redirect: { name: 'Home' },
    children: [
      {
        path: '/',
        name: 'Index',
        //  redirect: { name: 'Home' },
        component: Layout,
        children: [
          {
            path: '',
            name: 'Home',
            component: Home,
            meta: { title: 'Home' }
          },
          {
            path: 'iframeIndex',
            name: 'iframeIndex',
            component: iframeIndex,
            meta: { title: 'iframeIndex' }
          },
          {
            path: 'vip',
            name: 'Vip',
            component: Vip,
            meta: { title: 'Vip' }
          },
          {
            path: 'download',
            name: 'Download',
            component: Download,
            meta: { title: 'Download' }
          },
          {
            path: 'aboutUs',
            name: 'aboutUs',
            component: AboutUs,
            meta: { title: 'explore' }
          },
          {
            path: 'caseCenter',
            name: 'caseCenter',
            component: CaseCenter,
            meta: { title: 'explore' }
          },
          {
            path: 'contactUs',
            name: 'contactUs',
            component: ContactUs,
            meta: { title: 'explore' }
          },
          {
            path: 'dynamic/:type',
            name: 'dynamic',
            component: Dynamic,
            meta: { title: 'explore' }
          },
          {
            path: 'details/:dynamicId',
            name: 'details',
            component: Details,
            meta: { title: 'explore' }
          },
          {
            path: 'caseDetails/:caseId',
            name: 'caseDetails',
            component: Details,
            meta: { title: 'explore' }
          },
          {
            path: 'enterpriseDetail/:id/:clueSources/:isFromClient/:risk',
            name: 'enterpriseDetail',
            component: EnterpriseDetail,
            meta: { title: 'Product' }
          },
          {
            path: 'helpCenter',
            name: 'HelpCenter',
            component: HelpCenter,
            meta: { title: 'HelpCenter' },
            children: [
              {
                path: '/',
                name: 'HelpCenterDefault',
                component: HelpCenterDefault,
                meta: { title: 'HelpCenter' }
              },
              {
                path: 'result',
                name: 'result',
                component: HelpCenterResult,
                meta: { title: 'HelpCenter' }
              }
            ]
          },
          {
            path: 'product/:current',
            name: 'Product',
            component: product,
            meta: { title: 'Product' },
            children: [
              {
                path: 'shared',
                name: 'shared',
                component: Shared,
                meta: { title: 'Product' }
              },
              {
                path: 'excellentShare',
                name: 'excellentShare',
                component: ExcellentShare,
                meta: { title: 'Product' }
              },
              {
                path: 'sell',
                name: 'sell',
                component: sell,
                meta: { title: 'Product' }
              },
              {
                path: 'editBuyingInfo',
                name: 'editBuyingInfo',
                component: editBuyingInfo,
                meta: { title: 'Product' }
              },
              {
                path: 'detail',
                name: 'sharedDetail',
                component: sharedDetail,
                meta: { title: 'Product' }
              }
            ]
          }
        ]
      }
    ]
  },
  { path: '*', component: Vip }
]

export function createRouter() {
  return new Router({
    mode: 'history',
    routes
  })
}
