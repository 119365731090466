import { render, staticRenderFns } from "./Vip.vue?vue&type=template&id=76bbcce9&scoped=true"
import script from "./Vip.vue?vue&type=script&lang=js"
export * from "./Vip.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./Vip.vue?vue&type=style&index=0&id=76bbcce9&prod&lang=scss")
if (style0.__inject__) style0.__inject__(context)
var style1 = require("./Vip.vue?vue&type=style&index=1&id=76bbcce9&prod&lang=scss&scoped=true")
if (style1.__inject__) style1.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "76bbcce9",
  "4e2b3038"
  
)

export default component.exports