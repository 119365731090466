<template>
  <div class="table-filter">
    <div class="filter-item">
      <div class="filter-label">区域：</div>
      <a-tree-select
        v-model="area"
        @focus="focusInput"
        @change="selectArea"
        placeholder="请选择区域 "
        allow-clear
        treeCheckable
        id="areaTree"
        :disabled="otherDisabled"
        :getPopupContainer="getPopupContainer"
        aria-readonly
        :filterTreeNode="notFilter"
        class="auto-width"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :showCheckedStrategy="SHOW_PARENT"
      >
        <a-tree-select-node
          v-for="item in areaOptions"
          :key="item.areaCode"
          :value="item.areaCode"
          :title="item.areaName"
          :disabled="item.disabled"
        >
          <a-tree-select-node
            v-for="child in item.cityList"
            :value="child.areaCode"
            :key="child.areaCode"
            :disabled="item.disabled"
            :title="child.areaName"
          ></a-tree-select-node>
        </a-tree-select-node>
      </a-tree-select>
    </div>
    <div v-if="isNotSpecialProvince">
      <div class="filter-item" @click="focusInput">
        <div class="filter-label long-label">资质：</div>
        <tree-checkbox-select
          v-model="qualifications"
          containerId="tableFilter"
          :disabled="otherDisabled"
          :fakeQualification="fakeQualification"
          :qualificationOptions="qualificationOptions"
          :designQualificationOptions="designQualificationOptions"
          class="tree-search-select  auto-width"
        ></tree-checkbox-select>
        <div class="type-radio">
          <a-radio-group
            v-model="isAll"
            class="group"
            :disabled="otherDisabled"
          >
            <a-radio :value="1">包含</a-radio>
            <a-radio :value="2">仅有</a-radio>
          </a-radio-group>
          <a-tooltip>
            <template slot="title">
              <p class="radio-tips">
                “包含”指查询结果的企业为只包含已选资质且有其他资质；“仅有
                ”指查询结果的企业只有已选择资质。
              </p>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </div>
      </div>
      <div class="filter-item">
        <div class="filter-label">关键字：</div>
        <a-input
          class="filter-search"
          @focus="focusInput"
          v-model="keyWord"
          @change="isNeedSetPhoneDisabled"
          :disabled="otherDisabled"
          allowClear
          placeholder="请输入企业名称或法定代表人"
        />
        <div class="type-radio">
          <a-radio-group
            v-model="keywordsType"
            class="group"
            :disabled="otherDisabled"
          >
            <a-radio :value="2">模糊</a-radio>
            <a-radio :value="1">精准</a-radio>
          </a-radio-group>
          <a-tooltip>
            <template slot="title">
              <p class="radio-tips">
                关键字搜索是指包含关键词的内容进行检索，可选择模糊或者精准搜索，提升数据精准度。
              </p>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </div>
        <div class="advanced-btns">
          <a-button
            :type="showAdvanced ? 'primary' : 'default'"
            @click="showAdvanceFilter"
            icon="filter"
            class="have-rt-margin"
            >{{ showAdvanced ? '收起马赛克串货' : '马赛克串货' }}
          </a-button>
          <a-tooltip>
            <template slot="title">
              <p class="radio-tips">
                被打码的证书图片，根据证书部分信息查询持有企业信息
              </p>
            </template>
            <a-icon type="question-circle" />
          </a-tooltip>
        </div>
      </div>
      <div v-show="showAdvanced">
        <div class="filter-item">
          <div class="filter-label long-label">联系电话：</div>
          <a-input
            class="filter-search   "
            v-model="telephone"
            @change="isNeedSetPhoneDisabled"
            :disabled="phoneIsDisabled"
            allowClear
            placeholder="请清空其他筛选条件后 输入11位手机号码查询"
          />
          <div class="warning-tips" v-if="telephone">
            提示：若要选择或修改以上其他查询项，请先清空联系电话
          </div>
        </div>
        <div class="filter-item">
          <div class="filter-label long-label">股东类型:</div>
          <a-select
            allowClear
            showArrow
            @change="isNeedSetPhoneDisabled"
            :disabled="otherDisabled"
            :getPopupContainer="getPopupContainer"
            v-model="shareholderType"
            placeholder="请选择股东类型"
            class="filter-search   "
          >
            <a-option
              v-for="item in shareholderOptions"
              :value="item.value"
              :key="item.value"
              :title="item.label"
              >{{ item.label }}
            </a-option>
          </a-select>
        </div>
        <div class="filter-item">
          <div class="filter-label long-label">公司类型:</div>
          <a-select
            allowClear
            showArrow
            @change="isNeedSetPhoneDisabled"
            :getPopupContainer="getPopupContainer"
            :disabled="otherDisabled"
            v-model="companyType"
            placeholder="请选择公司类型"
            class="filter-search   "
          >
            <a-option
              v-for="item in companyTypeOptions"
              :value="item.value"
              :key="item.value"
              :title="item.label"
              >{{ item.label }}
            </a-option>
          </a-select>
        </div>
        <div class="filter-item">
          <div class="filter-label long-label">注册资本：</div>
          <div class="register-captial">
            <a-input-number
              placeholder="最小资本"
              :disabled="otherDisabled"
              v-model="registerCapitalStart"
              class="captial-input"
              :min="0.0001"
            />
            <span>万</span>
            <div class="register-line"></div>
            <a-input-number
              placeholder="最大资本"
              :disabled="otherDisabled"
              v-model="registerCapitalEnd"
              class="captial-input"
              :min="0.0001"
            />
            <span>万</span>
          </div>

          <!--          <a-input-->
          <!--            class="filter-search"-->
          <!--            v-model="registerCapital"-->
          <!--            @change="isNeedSetPhoneDisabled"-->
          <!--            allowClear-->
          <!--            :disabled="otherDisabled"-->
          <!--            placeholder="请输入正数查询，如1000.0或1000.12"-->
          <!--          />-->
        </div>

        <div class="filter-item">
          <div class="filter-label long-label">企业成立时间：</div>
          <!-- <a-date-range-picker
class="filter-search"
format="YYYY-MM-DD"
@change="isNeedSetPhoneDisabled"
valueFormat="YYYY-MM-DD"
:disabled="otherDisabled"
v-model="establishmentDate"
:disabled-date="disabledEndDate"
:placeholder="['请选择日期范围', '请选择日期范围']"
></a-date-range-picker> -->
          <date-input
            @input="isNeedSetPhoneDisabled"
            :disabled="otherDisabled"
            v-model="establishmentDate"
          ></date-input>
        </div>
        <div class="filter-item">
          <div class="filter-label long-label">证书编号：</div>
          <a-input
            class="filter-search   "
            v-model="certificateNo"
            @change="isNeedSetPhoneDisabled"
            :disabled="otherDisabled"
            allowClear
            placeholder="请输入资质证书编号，至少输入两位数查询"
          />
        </div>
        <div class="filter-item">
          <div class="filter-label long-label">证书有效日期：</div>
          <a-date-range-picker
            class="filter-search   "
            format="YYYY-MM-DD"
            :getCalendarContainer="getPopupContainer"
            @change="isNeedSetPhoneDisabled"
            :disabled="otherDisabled"
            v-model="effectiveDate"
            valueFormat="YYYY-MM-DD"
            :placeholder="['请选择日期范围', '请选择日期范围']"
          ></a-date-range-picker>
        </div>
      </div>
    </div>
    <div class="filter-item">
      <a-button
        size="large"
        class="large-button"
        @click="setFilterToFindList"
        type="primary"
        >查询
      </a-button>
      <a-button
        size="large"
        class="large-button"
        @click="e => reSetFilter(false)"
        >重置
      </a-button>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import { TreeSelect, Select, DatePicker } from 'ant-design-vue'
import TreeCheckboxSelect from '@/components/TreeCheckboxSelect.vue'
import DateInput from '@/components/Form/DateInput.vue'

import Cookie from 'js-cookie'

export default {
  name: 'table-filter',
  components: {
    TreeCheckboxSelect,
    aSelect: Select,
    aOption: Select.Option,
    aTreeSelect: TreeSelect,
    aTreeSelectNode: TreeSelect.TreeNode,
    aDateRangePicker: DatePicker.RangePicker,
    DateInput
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      fakeQualification: state => state.fakeQualificationOptions,
      qualificationOptions: state => state.typeQualificationOptions,
      designQualificationOptions: state => state.designQualificationOptions
    }),
    userEnable(){
      return  this.userInfo ? this.userInfo.enable : -1
    }
  },
  data() {
    return {
      vipContent: '',
      showTreeCheck: false,
      openDropdown: false,
      treeData: [],
      otherDisabled: false,
      phoneIsDisabled: false,
      gangAoTai: [810000, 820000, 710000],
      isNotSpecialProvince: true,
      keywordsType: 2, // 搜索是精准还是模糊
      isReceived: [], // 有无被领取
      qualificationCount: [], // 资质数量 废弃
      qualifications: [], // 资质选择
      reallyQua: [], // 真正的资质选择  上面的是用来展示的
      certificateNo: '', // 证书施工编号
      effectiveDate: [], // 有效日期
      printNo: [], // 发证日期
      companyName: '', // 公司姓名 this.keyword
      legalPerson: '', // 法人名称 废弃
      telephone: '', // 电话号码
      areaCode: [], // 地区
      registeredCapital: '', // 注册资本 废弃
      shareholderName: '', // 股东信息  this.keyword
      shareholderType: undefined, // 股东类型
      companyType: undefined, // 公司类型
      registerCapital: '',
      registerCapitalStart: '', // 注册资本最小值
      registerCapitalEnd: '', // 注册资本最大值
      establishmentDate: [], // 成立时间
      isAll: 1,
      showAdvanced: false,
      minCount: 0,
      maxCount: '',
      keyWord: '',
      area: [],
      receivedOptions: [
        { value: '1', label: '有' },
        { value: '2', label: '无' }
      ],
      shareholderOptions: [
        { value: '自然人股东', label: '自然人股东' },
        { value: '企业法人', label: '企业法人' },
        { value: '国有经济', label: '国有经济' },
        { value: '外商投资', label: '外商投资' },
        { value: '其他类型', label: '其他类型' }
      ],
      companyTypeOptions: [
        { value: '有限责任公司', label: '有限责任公司' },
        { value: '股份有限公司', label: '股份有限公司' },
        { value: '个人独资企业', label: '个人独资企业' },
        { value: '集体所有制', label: '集体所有制' },
        { value: '全民所有制', label: '全民所有制' },
        { value: '合伙企业', label: '合伙企业' },
        { value: '国有经济', label: '国有经济' },
        { value: '其他类型', label: '其他类型' }
      ],
      areaOptions: [],
      SHOW_PARENT: TreeSelect.SHOW_PARENT,
      lastFilterSave: null,
      must: null,
      filterCondition: null,
      defaultExpend: [],
      pageSize: 10,
      pageNum: 1,
      isHaveResourcesList: false,
      advModal: false,
      index: 1,
      jumpImageVisible: false,
      jumpImage: '',
      firstAdv: false,
      secondLevelAdv: false
    }
  },
  methods: {
    ...mapMutations(['setShowLoginModal']),
    onlyKeyword(keyword) {
      this.reSetFilter(false).then(() => {
        this.keyWord = keyword
        localStorage.removeItem('filterKeyword')
        this.setFilterToFindList()
      })
    },
    onlyPhone(phone) {
      this.reSetFilter(false).then(() => {
        this.telephone = phone
        localStorage.removeItem('filterPhone')
        this.setFilterToFindList()
      })
    },
    getPopupContainer() {
      return this.$isServer ? '' : document.getElementById('tableFilter')
    },
    notFilter() {
      return false
    },
    selectArea(value) {
      this.areaCode = []
      value.forEach(v => {
        const find = this.areaOptions.find(a => {
          return a.areaCode === v
        })
        const inCode = this.areaCode.find(a => {
          return a === v
        })
        if (!inCode) {
          if (find) {
            this.areaCode.push(v)
            if (find.cityList.length) {
              this.areaCode = this.areaCode.concat(
                find.cityList.map(c => {
                  return c.areaCode
                })
              )
            }
          } else {
            this.areaCode.push(v)
          }
        }
      })
      let isHaveSpecial = false
      this.areaCode.forEach(e => {
        const findSpecial = this.gangAoTai.find(g => {
          return g === e
        })
        if (findSpecial) {
          isHaveSpecial = true
        }
      })
      this.isNotSpecialProvince = !isHaveSpecial
      this.setAreaCodeDisabled()
      this.isNeedSetPhoneDisabled()
    },
    setFilterValue() {
      this.must = {
        areaCodes: this.areaCode,
        certificateNo: this.certificateNo,
        companyType: this.companyType,
        effectiveDateStart: this.effectiveDate[0],
        effectiveDateEnd: this.effectiveDate[1],
        establishmentDateStart: this.establishmentDate[0],
        establishmentDateEnd: this.establishmentDate[1],
        keyWord: this.keyWord,
        keyWordType: this.keywordsType,
        qualificationIds: this.qualifications,
        qualificationQueryType: this.isAll,
        //等待更改
        registerCapitalEnd: this.registerCapitalEnd,
        registerCapitalStart: this.registerCapitalStart,
        shareholderType: this.shareholderType,
        telephone: this.telephone
      }
      return Promise.resolve(this.must)
    },
    reSetFilterToRemoveSaveFilter() {
      this.$http.delete('filter/id', this.lastFilterSave.id).then(() => {
        console.log('删除用户的保存数据')
      })
    },
    isHaveFilterCondition() {
      if (
        !this.qualifications.length &&
        !this.certificateNo.length &&
        !this.shareholderType &&
        !this.companyType &&
        !this.telephone &&
        !this.registerCapital &&
        !this.effectiveDate[0] &&
        !this.keyWord &&
        !this.area.length &&
        !this.registerCapitalEnd &&
        this.registerCapitalEnd !== 0 &&
        !this.registerCapitalStart &&
        this.registerCapitalStart !== 0 &&
        !this.establishmentDate[0]
      ) {
        //
        return false
      } else {
        return true
      }
    },
    setFilterToFindList() {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        let company = this.$route.query.company
        if (!company) {
          this.$message.error('请您先登录后再继续使用', 0.5)
        }
        setTimeout(() => {
          this.setShowLoginModal(true)
          // this.$router.push({
          //   path: "/login?from=Product"
          // });
        }, 800)
      } else {
        const keyWords = this.keyWord
        const isKanji = new RegExp('[\u4E00-\u9FA5]+') // 汉字
        const isAlphabet = new RegExp('[A-Za-z]+') // 英文
        const isCellPhone = /^1(3|4|5|6|7|8|9)\d{9}$/

        if (keyWords) {
          if (!isKanji.test(keyWords) && !isAlphabet.test(keyWords)) {
            this.$message.error('关键字查询要求至少包含一个中文或英文字符', 0.5)
            return
          }
        }
        if (!this.isHaveFilterCondition()) {
          this.$message.error('请选择输入查询条件后再进行查询', 0.5)
          return
        }

        if (
          (this.registerCapitalStart && !this.registerCapitalEnd) ||
          (!this.registerCapitalStart && this.registerCapitalEnd)
        ) {
          this.$message.error('金额填写规则有误，示例：120万 — 500万', 0.5)
          return
        }
        if (this.registerCapitalStart > 0 || this.registerCapitalEnd > 0) {
          if (this.registerCapitalEnd < this.registerCapitalStart) {
            this.$message.error('金额填写规则有误，示例：120万 — 500万', 0.5)
            return
          }
        }

        if (this.certificateNo) {
          if (this.certificateNo.length < 2) {
            this.$message.error('请至少输入两位证书编号', 0.5)
            return
          }
        }
        if (this.telephone) {
          if (!isCellPhone.test(this.telephone)) {
            this.$message.error('请输入正确的手机号码进行查询', 0.5)
            return
          }
        }
        if (this.establishmentDate.length) {
          const start = new Date(this.establishmentDate[0]).getTime()
          const end = new Date(this.establishmentDate[1]).getTime()
          if (start > end) {
            this.$message.error('成立开始时间不能大于结束时间', 0.5)
            throw false
          }
        }
        this.pageNum = 1
        this.setFilterValue().then(res => {
          // console.log(res)
          this.$emit('clickFindBtns', {
            options: res,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            isNotSpecialProvince: this.isNotSpecialProvince
          })

          // this.saveUserLastFindFilter(res)
        })
      }
    },
    async reSetFilter(isFromSelectArea) {
      // this.isReceived = [] // 有无被领取
      this.qualificationCount = [] // 资质数量 废弃
      this.qualifications = [] // 资质选择
      this.certificateNo = '' // 证书施工编号
      this.effectiveDate = [] // 有效日期
      this.printNo = [] // 发证日期
      this.companyName = '' // 公司姓名 this.keyword
      this.legalPerson = '' // 法人名称  this.keyword
      this.keyWord = ''
      this.reallyQua = [] // 真正的资质选项
      this.registeredCapital = '' // 注册资本 废弃
      this.shareholderName = '' // 股东信息  this.keyword
      this.establishmentDate = [] // 成立时间
      this.isAll = 1
      this.keywordsType = 2 // 关键字搜索类型
      this.companyType = undefined // 公司类型
      this.shareholderType = undefined // 股东类型
      this.registerCapital = '' // 注册资本
      this.registerCapitalEnd = ''
      this.registerCapitalStart = ''
      this.telephone = '' // 电话号码
      this.pageSize = 10
      this.pageNum = 1
      if (!isFromSelectArea) {
        this.area = [] // 地区
        this.areaCode = []
        this.isNotSpecialProvince = true
        this.setAreaCodeDisabled()
      }
      this.isNeedSetPhoneDisabled()
    },
    changeReceived(val) {
      if (val !== undefined) {
        this.isReceived = val
      } else {
        this.isReceived = []
      }
    },
    showAdvanceFilter() {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        setTimeout(() => {
          this.setShowLoginModal(true)
          // this.$router.push({
          //     path: '/login?from=Product'
          // })
        }, 800)
      } else {
        this.showAdvanced = !this.showAdvanced
      }
    },
    disabledEndDate(endValue) {
      const currentDate = new Date().getTime()
      return endValue.valueOf() > currentDate
    },
    selectQualificationItem(qua, value) {
      // console.log(qua, value)
      const isHaveSelect = this.qualifications.find(e => {
        return e.value === value
      })
      // console.log(isHaveSelect)
      if (isHaveSelect) {
        qua.children.forEach(q => {
          if (q.value !== value) {
            q.disabled = true
          }
        })
      } else {
        qua.children.forEach(q => {
          q.disabled = false
        })
      }
      // this.defaultExpend.push(key)
    },
    setQualificationBegin() {
      this.qualificationOptions.forEach(q => {
        q.children.forEach(c => {
          c.disabled = false
        })
      })
    },
    getAreaCityOptions() {
      this.$http.get('regionCode/selList').then(r => {
        this.areaOptions = []
        this.areaOptions = r.map(a => {
          return {
            ...a,
            disabled: false
          }
        })
        if (!this.filterCondition) {
        const userArea = this.userInfo.useAreaName.split('/')
        const findProvince = r.find(e => {
          return e.areaName === userArea[0]
        }) // 找到省份
        // console.log(findProvince)
        if (findProvince) {
          const findCity = findProvince.cityList.find(
            c => c.areaName === userArea[1]
          ) // 找到城市
          this.area = [findCity.areaCode]
          this.areaCode = [findCity.areaCode]
        }
        // if (this.keyWord || this.qualifications.length) {
        //   this.area = []
        //   this.areaCode = []
        // }
        this.setFilterValue().then(res => {
          localStorage.removeItem('receiptKeyWord')
          localStorage.removeItem('receiptQua')
          // console.log('?')
          this.$emit('getFilterList', {
              options: res,
              pageNum: this.pageNum,
              pageSize: this.pageSize,
              isNotSpecialProvince: this.isNotSpecialProvince
          })
        })
        } else {
        this.area = this.filterCondition ? this.filterCondition.areaCodes : [] // 地区
        this.areaCode = this.filterCondition
          ? this.filterCondition.areaCodes
          : [] // 地区
        this.isNeedShowAdvanced()
        }
        this.selectArea(this.area)
      })
    },
    getFilterGroup() {
      const params = {
        conditionEnum: 'CUSTOMER_INQUIRY'
      }
      const homeKeyword = localStorage.getItem('receiptKeyWord')
      const homeQua = localStorage.getItem('receiptQua')
      const filterPhone = localStorage.getItem('filterPhone')
      const filterKeyword = localStorage.getItem('filterKeyword')
      // console.log(homeKeyword)
      if (homeKeyword) {
        this.keyWord = homeKeyword
        this.getAreaCityOptions()
      } else if (filterPhone) {
        this.onlyPhone(filterPhone)
      } else if (filterKeyword) {
        this.onlyKeyword(filterKeyword)
      } else if (homeQua) {
        if (homeQua && homeQua != 'undefined') {
          this.qualifications = [JSON.parse(homeQua).key]
        }
        this.getAreaCityOptions()
      } else {
        this.$http.get('filter/queryCondition', params).then(r => {
          if (r.filterCondition) {
            this.filterCondition = JSON.parse(r.filterCondition)
            this.setLastFilterSave()
          } else {
            this.qualifications = [6];
            this.getAreaCityOptions() // 获取省份
          }
        })
      }
    },
    setLastFilterSave() {
      this.pageNum = this.filterCondition.pageNum || 1
      this.pageSize = this.filterCondition.pageSize || 10
      this.qualifications = this.filterCondition.qualificationIds || []
      this.isAll = this.filterCondition.qualificationQueryType || 1
      this.keywordsType = this.filterCondition.keyWordType || 1
      this.certificateNo = this.filterCondition.certificateNo
        ? this.filterCondition.certificateNo
        : '' // 证书施工编号
      if (this.filterCondition.effectiveDateStart) {
        this.effectiveDate[0] = this.filterCondition.effectiveDateStart // 有效开始日期
        this.effectiveDate[1] = this.filterCondition.effectiveDateEnd // 有效结束日期
      }

      this.keyWord = this.filterCondition.keyWord
        ? this.filterCondition.keyWord
        : ''
      this.establishmentDate = [
        this.filterCondition.establishmentDateStart,
        this.filterCondition.establishmentDateEnd
      ]
      this.shareholderType = this.filterCondition.shareholderType
        ? this.filterCondition.shareholderType
        : undefined // 股东类型
      this.registerCapital = this.filterCondition.registerCapital // 注册资本
      this.registerCapitalEnd = this.filterCondition.registerCapitalEnd
        ? this.filterCondition.registerCapitalEnd
        : ''
      this.registerCapitalStart = this.filterCondition.registerCapitalStart
        ? this.filterCondition.registerCapitalStart
        : ''
      this.telephone = this.filterCondition.telephone
        ? this.filterCondition.telephone
        : '' // 联系电话
      this.companyType = this.filterCondition.companyType // 公司类型

      this.getAreaCityOptions()
    },
    setAreaCodeDisabled() {
      // 控制港澳台地区不能被选择
      if (!this.isNotSpecialProvince && this.areaCode.length) {
        // 选了港澳台就不能选其他的省
        this.areaOptions.forEach(a => {
          if (!this.gangAoTai.includes(a.areaCode)) {
            a.disabled = true
          } else {
            a.disabled = false
          }
        })
      } else if (this.isNotSpecialProvince && this.areaCode.length) {
        // 选了其他省不能选港澳台
        this.areaOptions.forEach(a => {
          if (!this.gangAoTai.includes(a.areaCode)) {
            a.disabled = false
          } else {
            a.disabled = true
          }
        })
      } else {
        this.areaOptions.forEach(a => {
          // 什么都不选
          a.disabled = false
        })
      }
    },
    isNeedShowAdvanced() {
      if (
        this.registerCapitalStart ||
        this.companyType ||
        this.shareholderType ||
        this.registerCapital ||
        this.telephone ||
        this.certificateNo.length ||
        this.effectiveDate[0] ||
        this.establishmentDate[0]
      ) {
        this.showAdvanced = true
      } else {
        this.showAdvanced = false
      }

      this.setFilterValue().then(res => {
        this.$emit('getFilterList', {
          options: res,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          isNotSpecialProvince: this.isNotSpecialProvince
        })
      })
    },
    isNeedSetPhoneDisabled() {
      if (!this.isHaveFilterCondition()) {
        this.phoneIsDisabled = false
        this.otherDisabled = false
      } else {
        if (this.telephone) {
          this.otherDisabled = true
        } else {
          this.otherDisabled = false
        }
      }
    },
    focusInput() {
      const hvaeUserId = Cookie.get('userId')
      if (!this.userEnable) {
        this.$message.error('您的账号已停用，请联系您的公司管理员或小牛建讯客服启用账号！', 0.5)
      }else if (!hvaeUserId) {
        this.$message.error('请您先登录后再继续使用', 0.5)
        this.setShowLoginModal(true)
      }
    },
    // 处理从门户网站跳转到本项目
    webPortalToThis(){
      let company = this.$route.query.company
      if (company) {
        this.focusInput()
        this.keyWord = company
        this.$http.get('regionCode/selList').then(r => {
          this.areaOptions = []
          this.areaOptions = r.map(a => {
            return {
              ...a,
              disabled: false
            }
          })
        })
        this.setFilterToFindList()

        return true
      }

      return false
    }
  },

  mounted() {
    // const qualificationId = Cookie.get('qualificationId')
    // if (qualificationId !== 'null' && qualificationId !== 'undefined' && qualificationId) {
    //     this.qualifications.push(parseInt(qualificationId))
    // } else {
    //     this.qualifications = []
    // }
    const hvaeUserId = Cookie.get('userId')
    let isCompany = this.webPortalToThis()
    //console.log('hvaeUserId',hvaeUserId);
    if (hvaeUserId && !isCompany) {
      this.$nextTick(() => {
        this.getFilterGroup()
        const dom = document.getElementById('areaTree')
        const areaTreeInput = dom.getElementsByTagName('input')[0]
        const areaTreeFather = areaTreeInput.parentElement
        areaTreeFather.removeChild(areaTreeInput)
      })
    }


  }
}
</script>

<style lang="scss" scoped>
.customer-tree-select-item {
  display: flex;
  align-items: center;

  .customer-tree-title {
    width: 120px;
  }
}

.table-filter {
  width: 100%;
  background-color: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 25px 25px 10px 25px;
  position: relative;

  .search-title {
    color: #909399;
    font-size: 16px;
  }

  .advanced-btns {
    position: absolute;
    right: 25px;
  }

  .filter-item {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    align-items: center;
    flex-shrink: 0;
    // &:last-child {
    //     margin-bottom: 5px;
    // }

    .tree-select {
      flex-grow: 0;
      margin-right: 24px;
      width: 336px;
      max-width: 100%;
    }

    .filter-label {
      font-size: 14px;
      color: #666666;
      width: 100px;
      flex-shrink: 0;
    }

    .filter-search {
      width: 336px;
      margin-right: 24px;
    }

    .auto-width {
      flex-grow: 0;
      margin-right: 24px;
      min-width: 336px;
      //  max-width: calc(100% - 330px);
    }

    .number-count {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .have-margin {
        margin: 0 10px;
      }
    }

    .type-radio {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      width: 180px;

      .group {
        margin-right: 5px;
      }

      .radio-tips {
        margin-bottom: 0;
        font-size: 13px;
        color: #909399;
      }
    }

    .large-button {
      width: 126px;
      margin-right: 16px;
    }

    .register-captial {
      display: flex;
      width: 445px;
      justify-content: space-between;
      align-items: center;

      .register-line {
        width: 30px;
        height: 1px;
        margin: 0 15px;
        background-color: #909399;
      }

      .captial-input {
        width: 150px;
      }
    }
  }

  .tree-search-select {
    position: relative;

    .tree-checkbox-slot {
      position: absolute;
      z-index: 1001;
      border-radius: 4px;
      outline: none;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    }
  }

  .collapse-expend {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    right: 25px;
    bottom: 10px;
    color: #4d7cfe;
  }
}
</style>
